/**
 * @module menu-items/menuItems
 * @description This module exports the `menuItems` object that contains an array of menu items
 * including `dashboard`, `partnerdashboard`, `administration`, and `reviewer`. These items represent 
 * different sections of the application and are imported from separate modules.
 * The `menuItems` object is structured to be used in rendering or managing the menu system within the application.
 */
import administration from './administration';
import dashboard from './dashboard';
import partnerdashboard from './partnerDashboard';
import reviewer from './reviewer';

// ==============================|| MENU ITEMS ||============================== //
/**
 * `menuItems` object containing an array of menu items.
 * @type {Object}
 * @property {Array} items - An array of menu items which include dashboard, partnerdashboard,
 * administration, and reviewer modules.
 */
const menuItems = {
  items: [dashboard, partnerdashboard, administration, reviewer]
};

export default menuItems;
