/**
 * @module Pages/AdminPages/AdminComponents/TotalCommissionPaidCard
 * @description This module contains the `TotalCommissionPaidCard` component, which fetches and displays 
 * the total commission paid. It shows the amount in a dashboard card, and provides navigation to a detailed 
 * report page when clicked.
 */
import { Skeleton } from '@mui/material';
import DashboardCard from 'components/commonComponents/cards/DashboardCard';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchTotalCommissionPaid } from 'services/adminService';
import { fCurrencyRupee } from 'utils/formatNumber';
/**
 * The `TotalCommissionPaidCard` component fetches the total commission paid data from the backend and 
 * displays it in a dashboard card. The card is clickable, and when clicked, it navigates to a detailed 
 * commission report page.
 * 
 * @component
 * @returns {JSX.Element} A dashboard card displaying the total commission paid.
 */
const TotalCommissionPaidCard = () => {
  const [commissionPaidData, setCommissionPaidData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  /**
   * Fetches the total commission paid data from the backend and updates the state.
   * 
   * This function is executed inside the `useEffect` hook to fetch data once when the component mounts.
   * 
   * @async
   * @function
   * @returns {void}
   */
  useEffect(() => {
    const fetchCommissionPaidData = async () => {
      try {
        const response = await fetchTotalCommissionPaid();
        const { totalCommission } = response.data;
        setCommissionPaidData({ totalCommission });
      } catch (error) {
        console.error('Error fetching total commission paid data:', error);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };

    fetchCommissionPaidData();
  }, []);
  /**
   * Handles the click event on the dashboard card. Redirects the user to the commission reports page.
   * 
   * @function
   * @returns {void}
   */
  const handleClick = () => {
    navigate('/admin/commissionReports', { state: { fromAdminDashboard: true } });
  };

  return (
    <>
      {
        loading ? (
          <Skeleton variant="rectangular" height={135} sx={{ borderRadius: '1.25rem' }} />
        ) : (
          <DashboardCard
            title="Total Commission Paid"
            subtitle={fCurrencyRupee(commissionPaidData.totalCommission || 0)}
            handleClick={handleClick}
            isClickable
          />
        )
      }
    </>
  );
};

export default TotalCommissionPaidCard;
