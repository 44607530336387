import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';

/**
 * @module Components/DesignSystem/CustomDialog
 * @description This module contains the CustomDialog component used across the application.
 */
/**
 * CustomDialog is a custom modal dialog component that uses MUI's `Dialog` component.
 * It includes customizable title, content, and actions sections.
 * The dialog allows for custom positioning and styling, and includes a close button in the top-right corner.
 *
 * @component
 * @param {boolean} open - Flag to control the visibility of the dialog.
 * @param {function} onClose - Function to handle closing the dialog.
 * @param {React.ReactNode} children - The content to be rendered inside the dialog.
 *
 * @returns {JSX.Element} A customized `Dialog` component with a close button and custom styles.
 */
const CustomDialog = ({ open, onClose, children }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    borderRadius: '15px',
                    backgroundColor: '#FFFFFF',
                    position: 'absolute',
                    top: '9rem',
                    left: '25rem',
                    right: '6.5625rem',
                    bottom: '3.125rem',
                    boxShadow: 24,
                    margin: '0', // Ensure no default margins
                },
            }}
            maxWidth={false} // Disable default maxWidth
        >
            {children}
        </Dialog>
    );
};

/**
 * CustomDialogTitle is a custom title component for the dialog, which includes a close button.
 * The title can be customized by passing content as `children`.
 *
 * @component
 * @param {React.ReactNode} children - The title content to display in the dialog.
 * @param {function} onClose - Function to handle closing the dialog when the close button is clicked.
 *
 * @returns {JSX.Element} The custom dialog title with a close button.
 */
const CustomDialogTitle = ({ children, onClose }) => {
    return (
        <>
            <DialogTitle sx={{ display: 'flex', flexDirection: 'column', gap: '4px', paddingBottom: 0 }}>
                {children}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.primary.main,
                }}
            >
                <CloseIcon />
            </IconButton>
        </>
    );
};

/**
 * CustomDialogContent is a custom content section for the dialog.
 * It renders the children content inside the dialog body.
 *
 * @component
 * @param {React.ReactNode} children - The content to display inside the dialog body.
 *
 * @returns {JSX.Element} The custom dialog content.
 */
const CustomDialogContent = ({ children }) => {
    return <DialogContent sx={{ paddingTop: 0 }}>{children}</DialogContent>;
};

/**
 * CustomDialogActions is a custom actions section for the dialog.
 * It renders the action buttons or any other content placed in the actions section.
 *
 * @component
 * @param {React.ReactNode} children - The action buttons or content to display in the actions section.
 *
 * @returns {JSX.Element} The custom dialog actions.
 */
const CustomDialogActions = ({ children }) => {
    return <DialogActions sx={{ paddingRight: '25px', paddingBottom: '25px' }}>{children}</DialogActions>;
};

export { CustomDialog, CustomDialogActions, CustomDialogContent, CustomDialogTitle };
