/**
 * @module Helpers/ProtectedRoutes
 *
 * @description This module provides the `ProtectedRoutes` component, which ensures route protection by verifying user authentication and role-based access control.
 * It redirects users to the appropriate page based on their role or to the login page if they are not authenticated.
 */


import { ContextProvider } from 'App';
import FullScreenLoader from 'components/DesignSystem/FullScreenLoader';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

/**
 * `ProtectedRoutes` is a React component that handles authentication and role-based access control.
 * It verifies if a user is logged in and redirects them based on their role or the login status.
 *
 * @component
 *
 * @returns {JSX.Element} - Returns an `Outlet` component if the user is authenticated, a loader while fetching data, or a redirect to the login page if unauthenticated.
 *
 * @example
 * import ProtectedRoutes from './ProtectedRoutes';
 * import { Routes, Route } from 'react-router-dom';
 *
 * const App = () => {
 *   return (
 *     <Routes>
 *       <Route element={<ProtectedRoutes />}>
 *         <Route path="/admin/analyticaldashboard" element={<AdminDashboard />} />
 *         <Route path="/landingPage" element={<LandingPage />} />
 *       </Route>
 *     </Routes>
 *   );
 * };
 */
const ProtectedRoutes = () => {
    const { loggedIn, fetchUser, setLoggedIn } = useContext(ContextProvider); // Access global context
    const navigate = useNavigate(); // Hook for navigation
    const location = useLocation(); // Provides information about the current route
    const [loading, setLoading] = useState(true); // State to track loading status

    /**
     * A stable version of the `fetchUser` function to ensure dependencies are consistent.
     * Fetches user data, validates the user's role, and handles redirection.
     *
     * @async
     */
    const stableFetchUser = useCallback(async () => {
        try {
            const response = await fetchUser();  // Fetch user details
            const { user } = response.data;

            // Role-based redirection
            if (user.role === 'admin') {
                navigate('/admin/analyticaldashboard', { replace: true });
            } else if (user.role === 'testator') {
                if (user.profile_saved && ['/welcome', '/journey', '/personalDetails', '/addressDetails', '/existingDetails', '/profileCompletion'].some((path) => location.pathname.includes(path))) {
                    navigate('/landingPage'); // Redirect testator to landing page
                }
            } else if (user.role === 'partner') {
                if (user.profile_saved && ['/welcome', '/journey', '/personalDetails', '/addressDetails', '/existingDetails', '/profileCompletion'].some((path) => location.pathname.includes(path))) {
                    navigate('/partnerLandingPage'); // Redirect partner to landing page
                }
            } else if (user.role === 'reviewer') {
                navigate('/reviewer/reviewerDashboard'); // Redirect reviewer to dashboard
            }

            setLoggedIn(true); // Mark user as logged in
            setLoading(false); // Stop loading
        } catch (err) {
            console.error('Failed to fetch user:', err);
            setLoggedIn(false); // Mark user as logged out
            setLoading(false); // Stop loading
            if (location.pathname !== '/login') {
                navigate('/login', { replace: true }); // Redirect to login if unauthenticated
            }
        }
    }, [fetchUser, setLoggedIn, location, navigate]); // Dependencies for useCallback

    useEffect(() => {
        /**
         * Effect to handle the user authentication check.
         * It calls the `stableFetchUser` function if the user is not logged in.
         */
        if (!loggedIn) {
            stableFetchUser(); // Check and fetch user data
        } else {
            setLoading(false); // Stop loading if already logged in
        }
    }, [loggedIn, stableFetchUser]); // Dependencies for useEffect

    if (loading) {
        return <FullScreenLoader />; // Show loader while checking authentication
    }

    return loggedIn ? <Outlet /> : <Navigate to="/login" />; // Render protected routes or redirect to login
};

export default ProtectedRoutes;