import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Box, useTheme } from '@mui/material';
import Icon from '../Icon';

/**
 * @module Components/DesignSystem/Dropdown
 * @description This module contains the Dropdown component used across the application.
 */
/**
* Styled component for the custom select box.
* @param {Object} theme - The MUI theme object used for styling.
* @param {boolean} isOpen - Determines if the dropdown is open.
* @param {string} dropdownheight - The height of the dropdown.
* @param {string} dropdownWidth - The width of the dropdown.
* @param {string} dropdownColor - The background color of the dropdown.
* @param {boolean} error - Whether there is an error with the select box.
* @param {boolean} disabled - Whether the select box is disabled.
* @returns {React.Element} The styled select box.
*/
const CustomSelectBox = styled(Box)(
  ({ theme, isOpen, dropdownheight, dropdownWidth, dropdownColor, error, disabled }) => ({
    border: disabled
      ? `1px solid ${theme.palette.grey[500]}95`
      : isOpen
        ? `1px solid ${theme.palette.grey[100]}`
        : `1px solid ${error ? 'red' : 'transparent'}`,
    borderRadius: isOpen ? '10px 10px 0 0' : '10px',
    height: dropdownheight ?? '45px',
    width: dropdownWidth ?? '100%',
    padding: '8px 12px',
    cursor: disabled ? 'not-allowed' : 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: dropdownColor ?? theme.palette.grey[200],
    color: disabled ? theme.palette.grey[500] : 'inherit',
    '&:hover': {
      border: !disabled && !error ? `1px solid ${theme.palette.grey[100]}` : '',
    },
    pointerEvents: disabled ? 'none' : 'auto',
  })
);

/**
 * Styled component for the options list container.
 * @param {Object} theme - The MUI theme object used for styling.
 * @param {string} dropdownColor - The background color of the options list.
 * @param {boolean} hasOptions - Determines if there are any options in the list.
 * @returns {React.Element} The styled options list.
 */
const OptionsList = styled(Box)(({ theme, dropdownColor, hasOptions }) => ({
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: '0 0 10px 10px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: dropdownColor ?? theme.palette.grey[200],
  width: '100%',
  position: 'absolute',
  zIndex: 1,
  marginTop: '-1px',
  maxHeight: hasOptions ? '200px' : 'auto', // Set height based on options
  overflowY: hasOptions ? 'auto' : 'hidden', // Enable scrolling only if options exist
}));

/**
 * Styled component for each option item in the dropdown.
 * @param {Object} theme - The MUI theme object used for styling.
 * @param {boolean} isSelected - Determines if the option is selected.
 * @param {boolean} isLastItem - Determines if this is the last option in the list.
 * @returns {React.Element} The styled option item.
 */
const OptionItem = styled(Box)(({ theme, isSelected, isLastItem }) => ({
  padding: '12px 16px',
  cursor: 'pointer',
  color: isSelected ? theme.palette.grey[600] : theme.palette.grey[400],
  borderBottom: isLastItem ? 'none' : '1px dashed #E0E0E0',
  '&:hover': {
    color: theme.palette.grey[600],
  },
}));

/**
 * Styled component for the "no options" message.
 * @param {Object} props - The props passed to the component.
 * @param {string} variant - The typography variant for the message.
 * @returns {React.Element} The styled "no options" message.
 */
const NoOptionsMessage = styled(({ variant, ...props }) => <Typography variant={variant} {...props} />)(
  ({ theme }) => ({
    padding: '12px 16px',
    textAlign: 'center',
    color: theme.palette.grey[500],
  })
);


/**
 * Custom dropdown component allowing the user to select from a list of options.
 * 
 * @param {string} name - The name of the input field.
 * @param {Array<Object>} options - List of options to display in the dropdown, where each option has a 'label' and 'value'.
 * @param {string} placeholder - The placeholder text to display when no option is selected.
 * @param {function} onBlur - The function to call when the dropdown loses focus.
 * @param {boolean} error - Whether there is an error with the dropdown input.
 * @param {string} helperText - Text to display when there is an error.
 * @param {function} handleChange - Function to handle the change in the selected option.
 * @param {string} value - The current selected value.
 * @param {string} dropdownColor - The background color of the dropdown.
 * @param {string} dropdownheight - The height of the dropdown.
 * @param {boolean} disabled - Whether the dropdown is disabled.
 * @param {string} noOptionsMessage - Message to display when no options are available.
 * @param {string} dropdownWidth - The width of the dropdown.
 * 
 * @returns {React.Element} The dropdown component.
 */
const Dropdown = ({
  name = '',
  options = [],
  placeholder = 'Select',
  onBlur,
  error,
  helperText,
  handleChange,
  value,
  dropdownColor,
  dropdownheight = '45px',
  disabled = false,
  noOptionsMessage = 'No options available',
  dropdownWidth = '100%',
}) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Update selectedOption when the value changes
    const option = options.find((opt) => opt.value === value);
    setSelectedOption(option ? option.label : '');
  }, [value, options]);

  const handleToggle = () => {
    if (!disabled) {
      setIsOpen((prev) => !prev);
    }
  };

  const handleSelect = (option) => {
    if (selectedOption === option.label) {
      // Clear selection if the clicked option is already selected
      setSelectedOption('');
      handleChange({ target: { name, value: '' } });
    } else {
      setSelectedOption(option.label);
      setIsOpen(false);
      handleChange({ target: { name, value: option.value } });
    }
  };

  return (
    <Box sx={{ position: 'relative', width: dropdownWidth }} ref={dropdownRef}>
      <CustomSelectBox
        onClick={handleToggle}
        isOpen={isOpen}
        error={error}
        onBlur={onBlur}
        dropdownColor={dropdownColor}
        dropdownheight={dropdownheight}
        dropdownWidth={dropdownWidth}
        disabled={disabled}
      >
        <Typography
          variant="bodyText3"
          sx={{
            fontStyle: selectedOption ? 'normal' : 'italic',
            color: selectedOption ? 'inherit' : theme.palette.grey[400],
          }}
        >
          {selectedOption || placeholder}
        </Typography>
        <Icon name="down-arrow-icon.png" height={6} width={10} />
      </CustomSelectBox>

      {isOpen && !disabled && (
        <OptionsList dropdownColor={dropdownColor} hasOptions={options.length > 0}>
          {options.length > 0 ? (
            options.map((option, index) => (
              <OptionItem
                key={option.value}
                onClick={() => handleSelect(option)}
                isSelected={selectedOption === option.label}
                isLastItem={index === options.length - 1}
              >
                <Typography variant="bodyText3">{option.label}</Typography>
              </OptionItem>
            ))
          ) : (
            <Box padding={1}>
              <NoOptionsMessage variant="bodyText3">{noOptionsMessage}</NoOptionsMessage>
            </Box>
          )}
        </OptionsList>
      )}
      {error && (
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end', mr: 2 }}>
          <Typography variant="errorText" sx={{ mt: 0.5 }}>
            {helperText}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Dropdown;
