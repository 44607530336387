import {
    styled,
    TableCell,
    TablePagination,
    TableRow,
    Typography,
    useTheme
} from '@mui/material';

/**
 * @module Components/DesignSystem/Table
 * @description This module contains the Table component used across the application.
 */
/**
 * Custom styled TableRow component with customized cell borders and background.
 * 
 * @component
 * @example
 * <CustomTableRow>
 *   <CustomTableCell>Data</CustomTableCell>
 * </CustomTableRow>
 * 
 * @returns {JSX.Element} A custom TableRow with styling for each cell.
 */
const CustomTableRow = styled(TableRow)(({ theme }) => ({
    '& td': {
        borderBottom: '5px solid white',
        borderTop: '5px solid white',
        background: theme.palette.grey[200],
    },
    '& td:first-of-type': {
        borderTopLeftRadius: '12px', // Top-left radius for the first cell
        borderBottomLeftRadius: '12px', // Bottom-left radius for the first cell
    },
    '& td:last-of-type': {
        borderTopRightRadius: '12px', // Top-right radius for the last cell
        borderBottomRightRadius: '12px', // Bottom-right radius for the last cell
    },
}));

/**
 * Custom TableCell component with optional typography color and text formatting.
 * 
 * @component
 * @example
 * <CustomTableCell typographyColor="primary.main">Data</CustomTableCell>
 * 
 * @param {Object} props - The props passed to the TableCell.
 * @param {React.ReactNode} props.children - The content to display inside the cell.
 * @param {string} [props.typographyColor] - The color for the typography (optional).
 * 
 * @returns {JSX.Element} A TableCell with custom typography.
 */
const CustomTableCell = ({ children, typographyColor = '', ...props }) => {
    const theme = useTheme();
    return (
        <TableCell {...props}>
            <Typography variant="smallText" color={typographyColor || theme.palette.grey[600]}>
                {children}
            </Typography>
        </TableCell>
    );
};

/**
 * Custom TablePagination component with styled pagination controls.
 * 
 * @component
 * @example
 * <CustomTablePagination
 *   count={100}
 *   rowsPerPage={10}
 *   page={0}
 *   onPageChange={handlePageChange}
 *   onRowsPerPageChange={handleRowsPerPageChange}
 * />
 * 
 * @param {Object} props - The props passed to the TablePagination.
 * @param {number} props.count - Total number of rows in the table.
 * @param {number} props.rowsPerPage - Number of rows to display per page.
 * @param {number} props.page - The current page index.
 * @param {function} props.onPageChange - Callback function to handle page change.
 * @param {function} props.onRowsPerPageChange - Callback function to handle rows per page change.
 * @param {Array<number>} [props.rowsPerPageOptions=[5, 10, 25, 50, 100]] - Options for selecting the number of rows per page.
 * 
 * @returns {JSX.Element} A custom styled TablePagination component.
 */
const CustomTablePagination = ({
    rowsPerPageOptions = [5, 10, 25, 50, 100],
    count,
    rowsPerPage,
    page,
    onPageChange,
    onRowsPerPageChange,
}) => {
    const theme = useTheme();

    return (
        <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            sx={{
                '& .MuiTablePagination-selectLabel': {
                    color: theme.palette.grey[600],
                    fontFamily: 'DM Sans, sans-serif !important',
                    fontWeight: 400,
                    fontSize: '14px',
                    marginTop: '10px',
                },
                '& .MuiTablePagination-displayedRows': {
                    color: theme.palette.grey[600],
                    fontFamily: 'DM Sans, sans-serif !important',
                    fontWeight: 400,
                    fontSize: '14px',
                },
                '& .MuiTablePagination-select': {
                    color: theme.palette.grey[600],
                    fontFamily: 'DM Sans, sans-serif !important',
                    fontWeight: 400,
                    fontSize: '14px',
                },
            }}
            backIconButtonProps={{
                sx: {
                    background: theme.palette.primary.lighter,
                    color: theme.palette.primary.main,
                    '&:hover': {
                        background: `${theme.palette.primary.light}90`,
                        color: `${theme.palette.primary.main}110`,
                    },
                    padding: '2px',
                    borderRadius: '50px',
                    marginRight: '4px',
                    border: `1px solid ${theme.palette.primary.light}`
                },
            }}
            nextIconButtonProps={{
                sx: {
                    background: theme.palette.primary.lighter,
                    color: theme.palette.primary.main,
                    '&:hover': {
                        background: `${theme.palette.primary.light}90`,
                        color: `${theme.palette.primary.main}110`,
                    },
                    padding: '2px',
                    borderRadius: '50px',
                    border: `1px solid ${theme.palette.primary.light}`
                },
            }}
        />
    );
};

export { CustomTableCell, CustomTablePagination, CustomTableRow };
