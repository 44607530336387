/**
 * @module Components/FeatureComponent/DeleteAccountSection
 *
 * @description A section that allows the user to delete their account after confirming the action through a dialog box.
 * It includes a confirmation dialog where users need to check three conditions before proceeding with the deletion.
 * The component handles state for the alert dialog, checkboxes, and performs an API call to delete the account.
 */
import { Box, Typography, useTheme } from '@mui/material';
import AlertDialog from 'components/DesignSystem/AlertDialog';
import CheckBox from 'components/DesignSystem/CheckBox';
import useSwalAlert from 'components/DesignSystem/SwalAlert/SwalAlert';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteAccountApi } from 'services/profileService';

/**
 * DeleteAccountSection is a React functional component that handles the logic for deleting the user's account.
 * It presents a dialog box where users must confirm their choice and check boxes to acknowledge the consequences
 * before account deletion.
 *
 * @component
 */
const DeleteAccountSection = () => {
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState();
    /**
     * State to manage the status of checkboxes in the delete confirmation dialog.
     * The checkboxes represent the user's acknowledgment of the deletion consequences.
     *
     * @type {Object}
     * @property {boolean} check1 - Checkbox for acknowledging deletion of user data.
     * @property {boolean} check2 - Checkbox for acknowledging termination of subscriptions.
     * @property {boolean} check3 - Checkbox for acknowledging deletion of wills.
     */
    const [checkboxes, setCheckboxes] = useState({
        check1: false,
        check2: false,
        check3: false,
    });

    /**
     * Access the Material UI theme.
     *
     * @type {object}
     */
    const theme = useTheme();

    /**
     * Custom hook to show SweetAlert for notifications.
     *
     * @function
     * @returns {Function} The function to trigger the SweetAlert notification.
     */
    const showSwalAlert = useSwalAlert();
    const navigate = useNavigate();

    /**
     * Handles opening the delete confirmation alert when the user clicks on "Delete Account".
     * Sets the state to open the alert dialog.
     *
     * @function
     */
    const handleDelete = () => {
        setIsDeleteAlertOpen(true);
    };

    /**
     * Handles closing the delete confirmation alert when the user cancels or closes the dialog.
     * Sets the state to close the alert dialog.
     *
     * @function
     */
    const handleDeleteAlertClose = () => {
        setIsDeleteAlertOpen(false);
    };

    /**
     * Performs the account deletion process after checking that all required checkboxes are checked.
     * If successful, it shows a success alert and navigates to the login page. If the deletion fails,
     * it shows an error alert.
     *
     * @async
     * @function
     */
    const deleteAccount = async () => {
        if (allChecked) {
            // Proceed only if all checkboxes are checked
            try {
                await deleteAccountApi(); // Call API to delete account
                handleDeleteAlertClose(); // Close the confirmation alert dialog
                // Show success notification
                showSwalAlert({
                    position: 'top-center',
                    icon: 'success',
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: 'Account deleted successfully',
                    showConfirmButton: false,
                    timer: 3000,
                });
                // Redirect to login page after account deletion
                navigate('/login', { replace: true });
                // Reset the checkboxes after successful deletion
                setCheckboxes({
                    check1: false,
                    check2: false,
                    check3: false,
                });
            } catch (err) {
                // Show error notification if the account deletion fails
                showSwalAlert({
                    position: 'top-center',
                    icon: 'error',
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: 'Failed to delete account',
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
        }
    };

    /**
     * Handles changes in the checkbox state when the user interacts with any checkbox.
     * Updates the checkbox state based on the event target (checkbox that was clicked).
     *
     * @function
     * @param {Object} event - The event object from the checkbox change.
     * @param {string} event.target.name - The name of the checkbox.
     * @param {boolean} event.target.checked - The checked status of the checkbox.
     */
    const handleCheckboxChange = (event) => {
        setCheckboxes((prev) => ({
            ...prev,
            [event.target.name]: event.target.checked,
        }));
    };

    /**
     * Determines if all checkboxes are checked.
     *
     * @type {boolean}
     * @constant
     */
    const allChecked = Object.values(checkboxes).every(Boolean);
    return (
        <>
            {/* Button to trigger the delete account process */}
            <Typography
                variant="suggestiveText"
                onClick={handleDelete}
                sx={{
                    color: theme.palette.grey[300],
                    textDecoration: 'none',
                    cursor: 'default', // Initially, no cursor change
                    '&:hover': {
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        color: theme.palette.grey[400],
                    },
                }}
            >
                Delete Account
            </Typography>
            {/* Confirmation Dialog for account deletion */}
            <AlertDialog
                open={isDeleteAlertOpen}
                onClose={handleDeleteAlertClose}
                modelTitle="Confirm Delete Account"
                title="If you proceed"
                dialogWidth="650px"
                titleSx={{ textAlign: 'left' }}
                primaryButtonLabel="Cancel"
                onSecondaryButtonClick={deleteAccount}
                onPrimaryButtonClick={handleDeleteAlertClose}
                onSecondaryButtonDisabled={!allChecked}
            >
                <Box
                    color={theme.palette.grey[400]}
                    sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '16px' }}
                    textAlign="start"
                >
                    {/* Checkbox for data deletion acknowledgment */}
                    <Box sx={{ display: 'flex', gap: '8px', alignItems: 'flex-start' }}>
                        <CheckBox
                            sx={{ width: '42px', marginTop: '4px' }}
                            checked={checkboxes.check1}
                            onChange={handleCheckboxChange}
                            name="check1"
                        />
                        <Typography variant="bodyText4">
                            All your user data will be permanently deleted, including login credentials and any other personal
                            information associated with your account.
                        </Typography>
                    </Box>
                    {/* Checkbox for subscription termination acknowledgment */}
                    <Box sx={{ display: 'flex', gap: '8px', alignItems: 'flex-start' }}>
                        <CheckBox
                            sx={{ width: '42px', marginTop: '4px' }}
                            checked={checkboxes.check2}
                            onChange={handleCheckboxChange}
                            name="check2"
                        />
                        <Typography variant="bodyText4">
                            All your subscriptions will be terminated. If you wish to use our service again in the future, you will
                            need to sign up and register as a new user
                        </Typography>
                    </Box>
                    {/* Checkbox for will deletion acknowledgment */}
                    <Box sx={{ display: 'flex', gap: '8px', alignItems: 'flex-start' }}>
                        <CheckBox
                            sx={{ width: '62px', marginTop: '4px' }}
                            checked={checkboxes.check3}
                            onChange={handleCheckboxChange}
                            name="check3"
                        />
                        <Typography variant="bodyText4">
                            If you have created any wills using our service, they will be deleted from our system as part of the
                            account deletion process. To ensure you have a copy for your records, we recommend saving a digital and
                            printed copy if you have not already done
                        </Typography>
                    </Box>
                </Box>
            </AlertDialog>
        </>
    );
};

export default DeleteAccountSection;
