import { Box, Typography, useTheme } from '@mui/material';
import { ReactComponent as EqualDistributionIcon } from 'assets/equal-distribution-icon.svg';
import { ReactComponent as EverythingToSpouseIcon } from 'assets/everything-to-spouse-icon.svg';
import { ReactComponent as PrimaryDistributionIcon } from 'assets/primary-distribution-icon.svg';
import { ContainedButton1 } from 'components/DesignSystem/Button';
import {
    CustomDialog,
    CustomDialogActions,
    CustomDialogContent,
    CustomDialogTitle,
} from 'components/DesignSystem/CustomDialog';
import { useCallback, useState } from 'react';
import SelectDistributionModalCard from './cards/SelectDistributionModalCard';

/**
 * A modal component for selecting a distribution method for assets.
 *
 * @module Components/FeatureComponent/SelectDistributionModal
 * @description Allows users to select a predefined or custom distribution method for assets. Options include giving
 * everything to a spouse, equal distribution, or custom distribution. The modal also handles the logic for calculating
 * and setting distribution mappings.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Determines if the modal is open or closed.
 * @param {Function} props.onClose - Callback function triggered to close the modal.
 * @param {Object} props.generics - Contains generic data such as beneficiaries and their relations.
 * @param {Function} props.setMappings - Callback function to update asset distribution mappings.
 * @param {Array} props.filteredUsers - List of users filtered based on specific criteria.
 * @returns {JSX.Element} The rendered SelectDistributionModal component.
 */
const SelectDistributionModal = ({ open, onClose, generics, setMappings, filteredUsers }) => {
    const theme = useTheme();
    // State to track the selected distribution option
    const [selectedOption, setSelectedOption] = useState('');
    // State for tracking selections in the left column
    const [leftColumnSelection, setLeftColumnSelection] = useState(new Set());
    // State for tracking selections in the middle column
    const [middleColumnSelection, setMiddleColumnSelection] = useState(new Set());

    /**
     * Handles the logic for evenly distributing assets among selected beneficiaries.
     *
     * @function distributeEvenly
     * @description Combines the left and middle column selections and generates mappings
     * for asset distribution based on asset type. Resets state and closes the modal.
     */
    const distributeEvenly = useCallback(() => {
        const combinedSelection = new Set([...leftColumnSelection, ...middleColumnSelection]);
        setMappings(
            filteredUsers?.map((as) => {
                const distributionType = as.sub_type === 'livestock' ? 'quantity' : 'percentage';

                return {
                    id: -1,
                    PropertyId: as.id,
                    distribution_type: distributionType,
                    mapping: Array.from(combinedSelection)?.map((b) => {
                        if (['pets', 'car', 'two_wheeler', 'large_vehicles', 'other_vehicles'].includes(as.sub_type)) {
                            // Check if there is only one beneficiary
                            const isSingleBeneficiary = combinedSelection.size === 1;

                            return {
                                beneficiary_id: b,
                                share: isSingleBeneficiary ? (100).toFixed(2) : 0,
                            };
                        }

                        if (as.sub_type === 'livestock') {
                            // Check if there is only one beneficiary
                            const isSingleBeneficiary = combinedSelection.size === 1;
                            return {
                                beneficiary_id: b,
                                share: isSingleBeneficiary ? as.information?.number : 0,
                            };
                        }
                        // For other types, calculate share as percentage with two decimal places
                        return {
                            beneficiary_id: b,
                            share: (100 / (combinedSelection.size ?? 1)).toFixed(2),
                        };
                    }),
                };
            })
        );

        // Reset states
        onClose();
        setLeftColumnSelection(new Set());
        setMiddleColumnSelection(new Set());
        setSelectedOption('');
    }, [generics?.assets, leftColumnSelection, middleColumnSelection]);

    /**
     * Handles the card selection logic based on the card's title.
     *
     * @function handleCardClick
     * @param {string} title - The title of the card clicked by the user.
     */
    const handleCardClick = (title) => {
        if (selectedOption === title) {
            setSelectedOption(null); // Deselect if the same card is clicked
        } else {
            if (title === 'give_everything_to_spouse') {
                setSelectedOption(title);
                generics?.beneficiary
                    ?.filter((bene) => bene?.relation?.toLowerCase() === 'spouse')
                    ?.forEach((bene) => {
                        setLeftColumnSelection((dist) => {
                            const newDist = new Set(dist);
                            newDist.add(bene.id);
                            return newDist;
                        });
                    });
                setMiddleColumnSelection(new Set());
            } else if (title === 'equal_distribution') {
                setSelectedOption(title);
                generics?.beneficiary?.forEach((bene) => {
                    setMiddleColumnSelection((dist) => {
                        const newDist = new Set(dist);
                        newDist.add(bene.id);
                        return newDist;
                    });
                });
                setSelectedOption('middle');
                setLeftColumnSelection(new Set());
            } else if (title === 'custom_distribution') {
                setSelectedOption(title);
                setMiddleColumnSelection(new Set());
            }
            setSelectedOption(title); // Select the clicked card
        }
    };
    
    // Determines if the Save/Next button should be enabled
    const isSaveButtonEnabled = selectedOption !== null;

    return (
        <CustomDialog open={open} onClose={onClose} maxWidth={false}>
            <CustomDialogTitle onClose={onClose}>
                <Typography variant="heading1" color={theme.palette.secondary.main}>
                    Select Distribution Method
                </Typography>
                <Typography maxWidth="628px" variant="bodyText4" color={theme.palette.grey[400]}>
                    Who gets to inherit your assets is an intimate choice. Take as much time as you need to make this important
                    decision.
                </Typography>
            </CustomDialogTitle>
            <CustomDialogContent>
                <Box sx={{ display: 'flex', gap: '15px', margin: '25px 0' }}>
                    {generics?.beneficiary?.some((bene) => bene?.relation?.toLowerCase() === 'spouse') && (
                        <SelectDistributionModalCard
                            icon={EverythingToSpouseIcon}
                            title="Give everything to spouse"
                            onClick={() => handleCardClick('give_everything_to_spouse')}
                            isSelected={selectedOption === 'give_everything_to_spouse'}
                        />
                    )}
                    <SelectDistributionModalCard
                        icon={EqualDistributionIcon}
                        title={`Equal Distribution`}
                        onClick={() => handleCardClick('equal_distribution')}
                        isSelected={selectedOption === 'equal_distribution'}
                    />
                    <SelectDistributionModalCard
                        icon={PrimaryDistributionIcon}
                        title="Custom Distribution"
                        onClick={() => handleCardClick('custom_distribution')}
                        isSelected={selectedOption === 'custom_distribution'}
                    />
                </Box>
                <Box>
                    <Typography variant="bodyText4" fontStyle="italic" color={theme.palette.grey[400]}>
                        Don’t worry, you can change this later.
                    </Typography>
                </Box>
            </CustomDialogContent>
            <CustomDialogActions>
                <ContainedButton1
                    onClick={distributeEvenly}
                    sx={{ minHeight: '45px', minWidth: '122px' }}
                    disabled={!isSaveButtonEnabled}
                >
                    <Typography variant="bodyText3">Next</Typography>
                </ContainedButton1>
            </CustomDialogActions>
        </CustomDialog>
    );
};

export default SelectDistributionModal;
