/**
 * @module ProfileCompletionPage
 * @description This module renders the profile completion page where users are informed that their profile is complete 
 * and can now proceed to create their will. It also provides an option to show help resources via a Help button.
 */
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { ContextProvider } from 'App';
import { ReactComponent as HelpIcon } from 'assets/help-button.svg';
import ActionButtons from 'components/actionsButtons';
import Icon from 'components/DesignSystem/Icon';
import Lottie from 'components/DesignSystem/Lottie';
import HelpSection from 'components/featureComponent/helpResourceSection/HelpResourceSection';
import { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { updateProfileSaved } from 'services/profileService';

/**
 * ProfileCompletionPage is a React component that displays the profile completion message
 * and allows users to proceed to create their Will. It also provides a Help button to access additional resources.
 * 
 * @returns {JSX.Element} The ProfileCompletionPage component.
 */
const ProfileCompletionPage = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const { user, fetchUser } = useContext(ContextProvider);

    /**
    * handleClose closes the Help Section modal.
    */
    const handleClose = () => setOpen(false);

    /**
    * handleUpdateProfile updates the user's profile and navigates to the 'myPeople' page.
    * It also handles any errors that may occur during the update process.
    */
    const handleUpdateProfile = async () => {
        try {
            const response = await updateProfileSaved();
            navigate('/myPeople');
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    return (
        <>
            <Helmet>
                <title> Profile Complete | Get Will Done </title>
            </Helmet>
            <Box sx={{ position: 'absolute', top: "1.9rem", left: "1.9rem", width: '16rem', height: '8rem' }}>
                <Icon name="will-logo.svg" width={'50%'} height={'40%'} />
            </Box>
            <Box
                sx={{
                    height: '100%',
                    margin: 'auto',
                    padding: 10,
                    paddingTop: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingBottom: '150px'
                }}
            >
                {/* Image */}
                <Box
                    sx={{
                        width: '6.977rem', height: '6.976rem', mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative'
                    }}
                >
                    <Lottie name={'Loader'} size={111} />
                </Box>
                <Typography
                    variant="title2"
                    gutterBottom
                    align="center" // Center align text
                >
                    You are all set, {user.profile?.first_name}.
                </Typography>
                <Typography
                    variant="bodyText3"
                    gutterBottom
                    align="center" // Center align text
                    mt={2.5}
                >
                    Now that you have finished making your profile, you can go ahead and create your Will.
                </Typography>
                <Box sx={{ position: 'relative', bottom: -70 }}>
                    <Box sx={{ cursor: 'pointer' }} onClick={handleUpdateProfile}>
                        <Lottie name='GwdButtonNew' height={'45px'} />
                    </Box>
                </Box>
            </Box>
            <ActionButtons />
            <IconButton
                sx={{
                    position: 'fixed',
                    bottom: '3.125rem',
                    right: '3.125rem',
                    bgcolor: '#FFF3E0',
                    color: '#FF8C42',
                    '&:hover': {
                        bgcolor: '#FFE0B2',
                    },
                }}
                onClick={() => setOpen(true)}
            >
                <HelpIcon />
            </IconButton>
            <HelpSection
                open={open}
                handleClose={handleClose}
            />
        </>
    );
};

export default ProfileCompletionPage;
