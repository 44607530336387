import axios from "axios";
/**
 * Fetches the total number of testators.
 * @module adminService/fetchTotalClients
 * @description
 * This module provides a function to fetch the total number of testators from the backend.
 * It supports an optional parameter to include a list of testators alongside the total count.
 * @param {boolean} [list=false] - Whether to include the list of testators in the response.
 * @returns {Promise<Object>} The API response containing the total number of testators.
 */
export const fetchTotalClients = async (list = false) => {
    const response = await axios.get(`/admin/total-testators${list ? '?list=true' : ''}`);
    return response;
}

/**
 * Fetches the total number of partners.
 * @module adminService/fetchTotalPartners
 * @description
 * This module provides a function to fetch the total number of partners from the backend.
 * It supports an optional parameter to include a list of partners alongside the total count.
 * @param {boolean} [list=false] - Whether to include the list of partners in the response.
 * @returns {Promise<Object>} The API response containing the total number of partners and, optionally, the list of partners.
 * 
 */
export const fetchTotalPartners = async (list = false) => {
    const response = await axios.get(`/admin/total-partners${list ? '?list=true' : ''}`);
    return response;
}

/**
 * Fetches the top performing partners.
 * @module adminService/fetchTopPerformingPartners
 * @description
 * This function fetches data on the top-performing partners. It supports an optional `list` parameter
 * to include a detailed list of the top-performing partners along with the total count.
 * @param {boolean} [list=false] - Whether to include the list of top-performing partners in the response. Default is `false`.
 * @returns {Promise<Object>} The API response containing the top-performing partners and, optionally, the list of partners.
 */
export const fetchTopPerformingPartners = async (list = false) => {
    const response = await axios.get(`/admin/top-performing-partner${list ? '?list=true' : ''}`);
    return response;
}

/**
 * Fetches the conversion ratio of signups to paid users.
 * @module adminService/fetchConversionRatio
 * @description
 * This function fetches the conversion ratio of signups to paid users. It supports an optional `list` parameter
 * to include a detailed list of users or data points related to the conversion ratio.
 * @param {boolean} [list=false] - Whether to include the list of signups vs paid users in the response. Default is `false`.
 * @returns {Promise<Object>} The API response containing the conversion ratio data and, optionally, a detailed list of users or data points.
 */
export const fetchConversionRatio = async (list = false) => {
    const response = await axios.get(`/admin/signup-vs-paid-ratio${list ? '?list=true' : ''}`);
    return response;
}

/**
 * Fetches the average time taken for will generation.
 * @module adminService/fetchAverageTimeOfWillGeneration
 * @description
 * This function fetches the average time taken for generating wills. It returns the data regarding the 
 * average time of will generation in the system.
 * @returns {Promise<Object>} The API response containing the average time for will generation.
 */
export const fetchAverageTimeOfWillGeneration = async () => {
    const response = await axios.get(`/admin/AvgTimeOfWillGeneration`);
    return response;
}

/**
 * Fetches the repeat rate of customers.
 * @module adminService/fetchRepeatRateOfCustomers
 * @description
 * This function fetches the repeat rate of customers, which indicates the percentage of customers 
 * who make repeat purchases or engage with the service multiple times. It supports an optional `list` 
 * parameter to include a detailed list of customers who are repeat users.
 * @param {boolean} [list=false] - Whether to include the list of repeat customers in the response. Default is `false`.
 * @returns {Promise<Object>} The API response containing the repeat rate and, optionally, a list of repeat customers.
 */
export const fetchRepeatRateOfCustomers = async (list = false) => {
    const response = await axios.get(`/admin/repeat-rate${list ? '?list=true' : ''}`);
    return response;
}

/**
 * Fetches the total revenue.
 * @module adminService/fetchTotalRevenue
 * @description
 * This function fetches the total revenue generated by the system. The response includes the overall
 * revenue figure for the specified period or for the entire system.
 * @param {boolean} [list=false] - Whether to include the list of repeat customers in the response. Default is `false`.
 * @returns {Promise<Object>} The API response containing the total revenue data.
 */
export const fetchTotalRevenue = async () => {
    const response = await axios.get(`/admin/total-revenue`);
    return response;
}

/**
 * Fetches the total commission paid.
 * @module adminService/fetchTotalCommissionPaid
 * @description
 * This function fetches the total commission paid by the system, including any commissions paid to 
 * partners.
 * @returns {Promise<Object>} The API response containing the total commission paid data.
 */
export const fetchTotalCommissionPaid = async () => {
    const response = await axios.get(`/admin/total-commission`);
    return response;
}

/**
 * Fetches the total GST paid.
 * @module adminService/fetchTotalGstPaid
 * @description
 * This function fetches the total GST (Goods and Services Tax) paid by the system. The response includes
 * the total GST amount paid within a specific period or for the entire system.
 * @returns {Promise<Object>} The API response containing the total GST paid data.
 */
export const fetchTotalGstPaid = async () => {
    const response = await axios.get(`/admin/total-gst`);
    return response;
}

/**
 * Fetches the total number of complete wills.
 * @module adminService/fetchCompleteWills
 * @description
 * This function fetches the total count of complete wills processed in the system. It supports an optional 
 * `list` parameter that, when set to `true`, includes a detailed list of complete wills along with the total count.
 * @param {boolean} [list=false] - Whether to include the list of complete wills in the response. Default is `false`.
 * @returns {Promise<Object>} The API response containing the total number of complete wills and, optionally, a list of complete wills.
 */
export const fetchCompleteWills = async (list = false) => {
    const response = await axios.get(`/admin/complete-wills-count${list ? '?list=true' : ''}`);
    return response;
}

/**
 * Fetches the total number of incomplete wills.
 * @module adminService/fetchInCompleteWills
 * @description
 * This function fetches the total count of incomplete wills processed in the system. It supports an optional 
 * `list` parameter that, when set to `true`, includes a detailed list of incomplete wills along with the total count.
 * @param {boolean} [list=false] - Whether to include the list of incomplete wills in the response. Default is `false`.
 * @returns {Promise<Object>} The API response containing the total number of incomplete wills and, optionally, a list of incomplete wills.
 */
export const fetchInCompleteWills = async (list = false) => {
    const response = await axios.get(`/admin/incomplete-wills-count${list ? '?list=true' : ''}`);
    return response;
}