/**
 * @module Pages/AdminPages/AdminComponents/AverageTimeCard
 * @description This module contains the AverageTimeCard component used to display the average time taken to generate a will.
 */
import { Skeleton } from '@mui/material';
import DashboardCard from 'components/commonComponents/cards/DashboardCard';
import { useEffect, useState } from 'react';
import { fetchAverageTimeOfWillGeneration } from 'services/adminService';

/**
 * A card component that displays the average time to complete a will generation.
 * 
 * The component fetches the average time data from the API (`/admin/average-time-of-will-generation`) and displays it inside a card. 
 * While the data is being fetched, a loading skeleton is displayed to indicate that the content is loading.
 * Once the data is successfully retrieved, the average time is shown in the card's subtitle. If no data is available, it defaults to '--'.
 *
 * @component
 * @returns {JSX.Element} A loading skeleton while data is being fetched, or a `DashboardCard` component displaying the average time to complete a will.
 */
const AverageTimeCard = () => {
  const [averageTimeData, setAverageTimeData] = useState({});
  const [loading, setLoading] = useState(true);

  /**
 * Fetches the average time data for will generation and updates the state.
 * 
 * This effect runs once when the component mounts and fetches data from the API endpoint to get the average time for will generation.
 * It updates the state with the retrieved data and sets the loading state to `false` once the data is fetched.
 */
  useEffect(() => {
    const fetchAverageTimeData = async () => {
      try {
        const response = await fetchAverageTimeOfWillGeneration();
        const { averageTimeOfWillGeneration } = response.data;
        setAverageTimeData({ averageTimeOfWillGeneration });
      } catch (error) {
        console.error('Error fetching average time data:', error);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };

    fetchAverageTimeData();
  }, []);

  return (
    <>
      {
        loading ? (
          <Skeleton variant="rectangular" height={135} sx={{ borderRadius: '1.25rem' }} />
        ) : (
          <DashboardCard
            title="Average Time To Complete Will"
            subtitle={(averageTimeData.averageTimeOfWillGeneration ?? '--') || 0}
            isClickable
            sx={{
              cursor: 'default',
              '&:hover': {
                backgroundColor: 'grey.200',
                border: '1px solid transparent',
                transform: 'none',
                boxShadow: 'none',
              },
            }}
          />
        )
      }
    </>
  );
};

export default AverageTimeCard;
