/**
 * @module Components/FeatureComponent/Header
 * 
 * @description This module defines the `Header` component, which serves as a top header section for the application.
 * It includes a company logo, an optional "Exit Demo" button, and a profile icon. The layout is styled using Material-UI.
 */

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ExitDemoButton from 'components/actionsButtons/ExitDemoButton';
import Logo from 'components/logo/Logo';
import { ProfileIcon } from 'views/dashboard/PageContainer';

/**
 * StyledHeader - A styled `Box` component that serves as the container for the header section.
 * 
 */
const StyledHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    gap: theme.spacing(3), // Adjust spacing based on your design
    width: '100%',
    boxSizing: 'border-box',
    // padding: theme.spacing(0, 3, 0, 0),
    maxWidth: '100%',
}));

/**
 * StyledLogoContainer - A styled `Box` component to arrange the logo and right-side elements in a row.
 * 
 */
const StyledLogoContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '100%',
}));

/**
 * StyledDivider - A styled `Box` component to render a horizontal divider below the header elements.
 * 
 */
const StyledDivider = styled(Box)(({ theme }) => ({
    height: 1,
    width: '100%',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
}));

/**
 * Header Component
 * 
 * A React component that renders the application's top header section.
 * Includes a company logo, an optional "Exit Demo" button, and a profile icon.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} [props.showExitButton=false] - Determines whether the "Exit Demo" button is displayed.
 * @returns {JSX.Element} A React component rendering the header section.
 * 
 * @example
 * // Example usage of the Header component
 * return <Header showExitButton={true} />
 */
const Header = ({ showExitButton = false }) => {
    return (
        <StyledHeader>
            <StyledLogoContainer>
                <Box sx={{ width: '190px', height: '95px', marginTop: '27px' }} >
                    <Logo disabledLink />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
                    {showExitButton && <ExitDemoButton />}
                    <ProfileIcon />
                </Box>
            </StyledLogoContainer>
            <StyledDivider />
        </StyledHeader>
    );
};

export default Header;
