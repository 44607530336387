/**
 * @module Views/MyWill/DocumentPreview
 * 
 * @description This module provides the `DocumentPreview` component which renders a preview of a document using HTML content.
 * The component uses Material-UI's `Box` component to render a styled container and utilizes the `dangerouslySetInnerHTML`
 * attribute to render raw HTML content inside a `Box`. This approach is useful when you want to display dynamic HTML content
 * like styled documents, reports, or templates.
 * 
 * @example
 * // Example of using the DocumentPreview component
 * <DocumentPreview documentHtml="<h1>Document Title</h1><p>Document content goes here...</p>" />
 */

import { Box } from '@mui/material';
import './documentPreview.scss';

/**
 * A component that previews a document by rendering HTML content.
 * 
 * This component wraps the provided `documentHtml` inside a `Box` element with an id of `documentPreviewContainer`.
 * It applies a transition effect for the content rendering. The `dangerouslySetInnerHTML` property is used to render 
 * the raw HTML content.
 * 
 * @function DocumentPreview
 * @param {Object} props - The properties passed to the `DocumentPreview` component.
 * @param {string} props.documentHtml - The raw HTML string to be rendered inside the preview.
 * @returns {React.Element} - The rendered component containing the document preview.
 */
const DocumentPreview = ({ documentHtml }) => {

  return (
    <Box id="documentPreviewContainer">
      <Box
        id="documentPreview"
        sx={{
          transition: 'transform 0.3s ease',
        }}
      >
        {/* Render the HTML content */}
        <Box dangerouslySetInnerHTML={{ __html: documentHtml }} />
      </Box>
    </Box>
  );
};

export default DocumentPreview;
