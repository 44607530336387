import { Box, Typography } from '@mui/material';
import AssetInfoCard from 'components/commonComponents/cards/AssetsCard';
import { noop } from 'lodash';

/**
 * A section component for displaying asset information cards with a title and icon.
 *
 * @module Components/FeatureComponent/AssetInfoCardSection
 * @description Renders a section that displays a list of assets as individual cards.
 * It includes a title with an icon, and each asset card has edit and delete functionalities.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title to display for the section.
 * @param {React.ElementType} props.icon - A React component to render as the icon for the section title.
 * @param {Array} [props.assets=[]] - An array of asset objects to display. Each object should represent an asset's data.
 * @param {Function} [props.onEditClick=noop] - Callback function triggered when the edit action is clicked for an asset. Receives the asset ID as an argument.
 * @param {Function} [props.onDeleteClick=noop] - Callback function triggered when the delete action is clicked for an asset. Receives the asset object as an argument.
 * @returns {JSX.Element} The rendered AssetInfoCardSection component.
 */
const AssetInfoCardSection = ({ title, icon: Icon, assets = [], onEditClick = noop, onDeleteClick = noop }) => {
    return (
        <Box sx={{ marginBottom: '44px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                <Box sx={{ marginRight: '8px', height: '30px' }}>
                    <Icon height='30px' />
                </Box>
                <Typography variant="bodyText2">
                    {title}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
                {assets.map((asset, index) => (
                    <AssetInfoCard
                        key={index}
                        asset={asset}
                        onEdit={() => onEditClick(asset.id)}
                        onDelete={() => onDeleteClick(asset)}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default AssetInfoCardSection;
