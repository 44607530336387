import axios from "axios";
/**
 * Fetches a list of generics based on the provided type.
 * @module myPeopleService/fetchGenerics
 * @description
 * This function retrieves a list of generics from the server based on the specified `type`. 
 * It makes a GET request to the `/generics/{type}` endpoint where `{type}` is replaced with 
 * the provided parameter. The server response contains the generics related to that specific type.
 * @param {string} type - The type of generics to fetch (e.g., 'medications', 'materials').
 * @returns {Promise<Object>} The API response containing the list of generics for the given type.
 */
export const fetchGenerics = async (type) => {
    const response = await axios.get(`/generics/${type}`);
    return response;
}

/**
 * Adds new generic data of a specified type.
 * @module myPeopleService/addGenerics
 * @description
 * This function sends a POST request to the server to add new generic data of the specified type.
 * The `type` parameter determines the category of the generic data.
 * The `genericData` parameter contains the actual data to be added.
 * @param {string} type - The type of generic data to be added.
 * @param {Object} genericData - The data to be added, structured based on the specified type.
 * @returns {Promise<Object>} The API response containing the result of the add operation.
 */
export const addGenerics = async (type, genericData) => {
    const response = await axios.post(`/generics/${type}`, genericData);
    return response;
}

/**
 * Updates the generic data for a specified type and ID.
 * @module myPeopleService/updateGenerics
 * @description
 * This function sends a POST request to update the generic data for a specific type and ID. 
 * The request is made to the `/generics/{type}/{id}` endpoint with the provided `genericData`.
 * @param {string} type - The type of the generic data to update.
 * @param {string|number} id - The unique identifier of the generic data to be updated.
 * @param {Object} genericData - The new data to update the generic entry with.
 * @param {Object} genericData.property - A key-value pair representing the data to be updated.
 * @returns {Promise<Object>} The API response containing the status of the update operation.
 */
export const updateGenerics = async (type, id, genericData) => {
    const response = await axios.post(`/generics/${type}/${id}`, genericData);
    return response;
}

/**
 * Deletes a generic resource based on the provided type and ID..
 * @module myPeopleService/deleteGenerics
 * @description
 * This function deletes a generic resource from the server. It makes a DELETE request 
 * to the `/generics/{type}/{id}` endpoint, where `type` refers to the resource type 
 * and `id` refers to the specific resource ID.
 * @param {string} type - The type of the resource to be deleted
 * @param {string|number} id - The ID of the resource to be deleted.
 * @returns {Promise<Object>} The API response after the delete operation.
 */
export const deleteGenerics = async (type, id) => {
    const response = await axios.delete(`/generics/${type}/${id}`);
    return response;
}

/**
 * Fetches the count of witnesses.
 * @module myPeopleService/fetchWitnessCount
 * @description
 * This function retrieves the list of witnesses from the server by making a GET request 
 * to the `/generics/witness` endpoint. It then returns the count of witnesses based on the response.
 * If no witnesses are found, it returns 0.
 * @returns {number} The count of witnesses.
 */
export const fetchWitnessCount = async () => {
    const response = await axios.get('/generics/witness');
    return response.data?.generics.length ?? 0;
};

/**
 * Fetches the count of executors.
 * @module myPeopleService/fetchExecutorCount
 * @description
 * This function retrieves the count of executors from the server. It makes a GET request to the 
 * `/generics/executor` endpoint and returns the length of the executor data. If no data is found, 
 * it returns `0`.
 * @returns {Promise<number>} The count of executors (or `0` if no executors are found).
 * @returns {number} The count of witnesses.
 */
export const fetchExecutorCount = async () => {
    const response = await axios.get('/generics/executor');
    return response.data?.generics.length ?? 0;
};