import { Box, useTheme } from '@mui/material';
import Lottie from '../Lottie';

/**
 * @module Components/DesignSystem/FullScreenLoader
 * @description This module contains the FullScreenLoader component used across the application.
 */
/**
 * FullScreenLoader component displays a full-screen loading animation.
 * It covers the entire screen with a white background and centers the loading animation.
 * The loader is rendered using a Lottie animation component.
 * 
 * @component
 * 
 * @returns {React.Element} The c component.
 */
const FullScreenLoader = () => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: `${theme.palette.common.white}`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
            }}
        >
            <Box sx={{ borderRadius: '50%' }}>
                <Lottie name={'FullscreenLoader'} size={200} />
            </Box>
        </Box>
    );
};

export default FullScreenLoader;
