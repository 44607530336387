import { Popover, Box, Typography, Divider, MenuList, MenuItem, ListItemIcon, useTheme } from '@mui/material';
import { HiOutlineUserCircle } from 'react-icons/hi2';
import { IoIosLogOut } from 'react-icons/io';
import { noop } from 'lodash';

/**
 * @module Components/DesignSystem/ProfileMenu
 * @description This module contains the ProfileMenu component used across the application.
 */
/**
 * A menu component displaying user profile information and options for profile and logout.
 * This component is displayed within a `Popover` and allows interaction with user-specific actions.
 *
 * @component
 * @example
 * // Example usage:
 * <ProfileMenu
 *   anchorEl={anchorElement}
 *   open={true}
 *   onClose={onClose}
 *   name="John Doe"
 *   email="john.doe@example.com"
 *   handleProfileClick={handleProfileAction}
 *   handleLogoutClick={handleLogoutAction}
 * />
 *
 * @param {Object} props - The properties for the component.
 * @param {HTMLElement} props.anchorEl - The DOM element to anchor the `Popover` to.
 * @param {function} [props.onClose=noop] - Callback function to close the `Popover`. Defaults to a no-op function.
 * @param {boolean} [props.open=false] - Boolean to control whether the `Popover` is open.
 * @param {string} [props.name=''] - The name of the user to display.
 * @param {string} [props.email=''] - The email of the user to display.
 * @param {function} [props.handleProfileClick=noop] - Callback function for the profile button click action.
 * @param {function} [props.handleLogoutClick=noop] - Callback function for the logout button click action.
 * @param {boolean} [props.hideProfileButton=false] - Boolean to control whether the profile button is visible.
 *
 * @returns {React.Element} The `ProfileMenu` component wrapped in a `Popover` with profile and logout options.
 */
const ProfileMenu = ({
    anchorEl = false,
    onClose = noop,
    open = false,
    name = '',
    email = '',
    handleProfileClick = noop,
    handleLogoutClick = noop,
    hideProfileButton = false,
}) => {
    const theme = useTheme();

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            onClose={onClose}
            open={open}
            slotProps={{ paper: { sx: { width: '240px' } } }}
        >
            <Box sx={{ p: '16px 20px ' }}>
                <Box>
                    <Typography variant="bodyText2">{name}</Typography>
                </Box>
                <Box>
                    <Typography variant="bodyText4">{email}</Typography>
                </Box>
            </Box>
            <Divider />
            <MenuList disablePadding sx={{ p: '8px', '& .MuiMenuItem-root': { borderRadius: 1 } }}>
                {!hideProfileButton && (
                    <MenuItem onClick={handleProfileClick}>
                        <ListItemIcon>
                            <HiOutlineUserCircle color={theme.palette.primary.main} fontSize="var(--icon-fontSize-md)" />
                        </ListItemIcon>
                        <Typography variant="bodyText4">Profile</Typography>
                    </MenuItem>
                )}
                <MenuItem onClick={handleLogoutClick}>
                    <ListItemIcon>
                        <IoIosLogOut color={theme.palette.primary.main} />
                    </ListItemIcon>
                    <Typography variant="bodyText4">Logout</Typography>
                </MenuItem>
            </MenuList>
        </Popover>
    );
};

export default ProfileMenu;
