/**
 * @module Pages/OnboardingFlow/WelcomePage
 * @description This module defines the `WelcomePage` component, which is the first step in the onboarding 
 * flow for creating a will. The page introduces the user to the `Get Will Done` platform and explains the 
 * process of creating a will in four simple steps. It features a welcoming message, information about the 
 * process, and a "Next" button to navigate to the next step in the onboarding journey.
 */
import { useTheme } from '@emotion/react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import ActionButtons from 'components/actionsButtons';
import { ContainedButton1 } from 'components/DesignSystem/Button';
import Icon from 'components/DesignSystem/Icon';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

export default function WelcomePage() {
  const theme = useTheme();
  const navigate = useNavigate(); // Initialize useNavigate
  const isLgScreen = useMediaQuery(theme.breakpoints.up('xl'));
  const handleNext = () => {
    navigate('/journey'); // Navigate to the JourneyPage
  };

  return (
    <>
      <Helmet>
        <title> Welcome | Get Will Done </title>
      </Helmet>
      <Grid container>
        {/* <Grid container> */}
        <Grid item xs={12} sm={3} md={2} lg={2} xl={2} alignItems={'center'} sx={{ pt: '2%', pl: '2%' }}>
          <Icon name="will-logo.svg" width={'65%'} height={'45%'} />
        </Grid>
        <Grid item sm={9} md={10} lg={10} xl={10}>
          <Box sx={{ paddingX: '5%', paddingY: '8%' }}>
            <Box>
              <Typography variant="title1" gutterBottom sx={{ mb: '15px' }}>
                Welcome to GetWillDone!
              </Typography>
            </Box>
            <Box sx={{ mb: '60px', mt: '15px' }}>
              <Typography variant="bodyText3" color={theme.palette.grey[400]} sx={{ mb: '60px' }}>
                We are glad to be your companion on this important journey
                <Box component="span" sx={{ display: 'block' }}>
                  of creating your Will.
                </Box>
              </Typography>
            </Box>
            <Box sx={{ mb: '60px' }}>
              <Typography variant="bodyText3" color={theme.palette.grey[400]} sx={{ mb: '60px' }}>
                Here, you set the pace; we simply provide the map and the
                <Box component="span" sx={{ display: 'block' }}>
                  directions. A fully decked-out Dashboard will display your progress
                </Box>
                and lead you to FAQs and resourceful videos.
              </Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="bodyText3" color={theme.palette.grey[400]}>
                As for creating your Will itself, that's a smooth
                <Box
                  component="span"
                  sx={{ display: 'block', mt: 1 }} // Adjust the value of mt (margin-top) as needed
                >
                  <Typography variant="heading1" component="span" color={theme.palette.secondary.main}>
                    4-step process.
                  </Typography>
                </Box>
              </Typography>
            </Box>
            <ContainedButton1
              sx={{
                minWidth: '125px', // Adjust this value as needed for your design
                marginTop: { xl: '8%', lg: '8%', md: '10%', sm: '16%', xs: '25%' },
                // marginTop: '5%'
              }}
              onClick={handleNext} // Add onClick handler
            >
              <Typography variant="bodyText3">Next</Typography>
            </ContainedButton1>
            <ActionButtons />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
