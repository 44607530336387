/**
 * @module Theme/Typography
 * 
 * @description This module defines a typography configuration for a web application. It includes font families, font sizes, 
 * line heights, and responsive typography for different screen sizes. It uses helper functions to convert pixel values 
 * to `rem` units for consistent scaling. The typography system consists of various predefined text styles (such as largeTitle, title1, heading1, etc.) 
 * to ensure a consistent visual hierarchy across the application.
 * 
 * @example
 * import typography from './typography';
 * const titleStyle = typography.largeTitle; // Use largeTitle style in a component
 */

/**
 * Converts a pixel value to `rem` units for consistent scaling across different screen sizes.
 * This function assumes a base font size of 16px for 1rem.
 * 
 * @function
 * @name pxToRem
 * 
 * @param {number} value - The pixel value to convert to rem.
 * 
 * @returns {string} The equivalent `rem` value as a string, e.g., `2rem` for a 32px value.
 * 
 * @example
 * pxToRem(16); // Returns '1rem'
 */
function pxToRem(value) {
  return `${value / 16}rem`;
}

/**
 * Generates responsive font sizes for different screen sizes (small, medium, large).
 * The sizes are defined for different breakpoints: 600px, 900px, and 1200px, respectively.
 * This function ensures that text sizes adapt to the screen size, improving readability on different devices.
 * 
 * @function
 * @name responsiveFontSizes
 * 
 * @param {Object} breakpoints - The font sizes for different screen sizes.
 * @param {number} breakpoints.sm - Font size for small screens (600px and above).
 * @param {number} breakpoints.md - Font size for medium screens (900px and above).
 * @param {number} breakpoints.lg - Font size for large screens (1200px and above).
 * 
 * @returns {Object} The media queries object with the responsive font sizes.
 * 
 * @example
 * responsiveFontSizes({ sm: 16, md: 20, lg: 24 });
 * // Returns the media query object with font sizes for small, medium, and large screens.
 */
function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

const FONT_PRIMARY = 'Gentium Plus, serif'; // Font for primary headings and titles
const FONT_SECONDARY = 'DM Sans, sans-serif'; // Font for body text and secondary headings

/**
 * The typography configuration for the application. It contains various text styles with defined font family, 
 * font weight, font size, and line height. The `responsiveFontSizes` function is applied to ensure the typography 
 * scales well across devices.
 * 
 * @constant {Object} typography
 * @property {string} fontFamily - The primary font family for the application (Gentium Plus, serif).
 * @property {Object} largeTitle - Style for large title text.
 * @property {Object} title1 - Style for title1 text.
 * @property {Object} title2 - Style for title2 text.
 * @property {Object} heading1 - Style for heading1 text (secondary font).
 * @property {Object} heading2 - Style for heading2 text (secondary font).
 * @property {Object} bodyText1 - Style for bodyText1 text (secondary font).
 * @property {Object} bodyText2 - Style for bodyText2 text (secondary font).
 * @property {Object} bodyText3 - Style for bodyText3 text (secondary font).
 * @property {Object} bodyText4 - Style for bodyText4 text (secondary font).
 * @property {Object} smallText - Style for smallText (secondary font).
 * @property {Object} smallText2 - Style for smallText2 (secondary font).
 * @property {Object} suggestiveText - Style for suggestiveText (secondary font).
 * @property {Object} errorText - Style for errorText (secondary font, red color).
 * @property {Object} mainContent - Style for the main content area, including background color and layout properties.
 * 
 * @example
 * const titleStyle = typography.largeTitle; // Get the style for largeTitle
 */
const typography = {
  fontFamily: FONT_PRIMARY, // Primary font for headings and titles
  largeTitle: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 400,
    fontSize: pxToRem(60),
    ...responsiveFontSizes({ sm: 40, md: 60, lg: 60 }), // Responsive font sizes
  },
  title1: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 400,
    fontSize: pxToRem(50),
    lineHeight: '100%',
    ...responsiveFontSizes({ sm: 40, md: 50, lg: 50 }),
  },
  title2: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 400,
    fontSize: pxToRem(35),
    lineHeight: '100%',
    ...responsiveFontSizes({ sm: 30, md: 35, lg: 35 }),
  },
  heading1: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    fontSize: pxToRem(25),
    lineHeight: '135%',
    ...responsiveFontSizes({ sm: 20, md: 25, lg: 25 }),
  },
  heading2: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    fontSize: pxToRem(22),
    lineHeight: '135%',
    ...responsiveFontSizes({ sm: 22, md: 22, lg: 22 }),
  },
  bodyText1: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    fontSize: pxToRem(20),
    lineHeight: '135%',
    ...responsiveFontSizes({ sm: 20, md: 20, lg: 20 }),
  },
  bodyText2: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 500,
    fontSize: pxToRem(20),
    lineHeight: '135%',
    ...responsiveFontSizes({ sm: 20, md: 20, lg: 20 }),
  },
  bodyText3: {
    fontFamily: FONT_SECONDARY,
    fontSize: pxToRem(18),
    fontWeight: 400,
    lineHeight: '135%',
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },
  bodyText4: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    fontSize: pxToRem(16),
    lineHeight: '135%',
    ...responsiveFontSizes({ sm: 16, md: 16, lg: 16 }),
  },
  smallText: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    fontSize: pxToRem(14),
    lineHeight: '135%',
    ...responsiveFontSizes({ sm: 14, md: 14, lg: 14 }),
  },
  smallText2: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    fontSize: pxToRem(13),
    lineHeight: '135%',
    ...responsiveFontSizes({ sm: 14, md: 14, lg: 14 }),
  },
  suggestiveText: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    fontSize: pxToRem(12),
    lineHeight: '135%',
    ...responsiveFontSizes({ sm: 12, md: 12, lg: 12 }),
  },
  errorText: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    color: '#F51D1D',
    fontStyle: 'italic',
    fontSize: pxToRem(12),
    lineHeight: '135%',
    ...responsiveFontSizes({ sm: 12, md: 12, lg: 12 }),
  },
  mainContent: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    minHeight: 'calc(100vh - 88px)',
    flexGrow: 1,
    padding: '20px',
    marginTop: '24px',
    marginRight: '24px',
    marginBottom: '24px',
    borderRadius: '20px',
    marginLeft: '360px !important',
  },
};

export default typography;
