import { ContextProvider } from "App";
import { useContext } from "react";
import ExitDemoButton from "./ExitDemoButton";
import SkipToWillEditorButton from "./SkipToEditorButton";

/**
 * Renders action buttons (`ExitDemoButton` and `SkipToWillEditorButton`) for users in a demo session.
 *
 * The buttons are only displayed if:
 * - The user's role is "partner".
 * - The demo session is active (`isDemoActive` is stored as "true" in session storage).
 *
 * @component
 * @returns {JSX.Element|null} A fragment containing the action buttons or `null` if conditions are not met.
 */
const ActionButtons = () => {
  const { user } = useContext(ContextProvider);

  // Check if the demo session is active
  const isDemoActive = sessionStorage.getItem('isDemoActive') === 'true';

  // Render nothing if user role is not "partner" or demo is inactive
  if (user.role !== 'partner' || !isDemoActive) {
    return null;
  }

  return (
    <>
      <ExitDemoButton
        sx={{
          position: 'fixed',
          top: '3.25rem',
          right: '6.75rem',
          zIndex: 1000,
        }}
      />
      <SkipToWillEditorButton
        sx={{
          position: 'fixed',
          bottom: '9.938rem',
          right: '6.75rem',
          zIndex: 1000,
        }}
      />
    </>
  );
};

export default ActionButtons;
