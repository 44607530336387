/**
 * @module PartnerSignUpForm
 * 
 * @description This module contains a React component for handling partner registration and OTP verification. 
 * The form allows users to enter their details, select a partner type, accept terms and conditions,
 * and verify their email via an OTP sent to their email address. It includes OTP expiration handling, 
 * form validation, and reCAPTCHA verification.
 * 
 */


import axios from 'axios';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Box, Divider, FormControlLabel, Link, Stack, Typography, useTheme } from '@mui/material';
// components
import { ContainedButton1 } from 'components/DesignSystem/Button';
import CheckBox from 'components/DesignSystem/CheckBox';
import { Dropdown } from 'components/DesignSystem/DropDown';
import InputField from 'components/DesignSystem/InputField';
import useSwalAlert from 'components/DesignSystem/SwalAlert';
import ReCaptcha from 'components/featureComponent/loginFlow/ReCaptcha';
import { useStateWithCallback } from 'hooks/useStateWithCallback';
import Iconify from '../../../components/iconify';
import { OTPField } from '../../../components/OTPField';

/**
 * List of partner types that the user can select during sign-up.
 * @type {Array<Object>}
 * @constant
 */
const partnerTypeOptions = [
    {
        value: 'Wealth Manager',
        label: 'Wealth Manager',
    },
    {
        value: 'Corporate',
        label: 'Corporate',
    },
    {
        value: 'Bank',
        label: 'Bank',
    },
];

/**
 * @component
 * 
 * `PartnerSignUpForm` component handles the partner registration process, including OTP generation and validation.
 * 
 * @returns {JSX.Element} The rendered form for user registration and OTP verification.
 * 
 * @example
 * return (
 *   <PartnerSignUpForm />
 * );   
 */
export default function PartnerSignUpForm() {
    const navigate = useNavigate(); // Hook to navigate to different pages.
    const [partnerType, setPartnerType] = useState('Wealth Manager');  // Stores the selected partner type.
    const [companyName, setCompanyName] = useState('');  // Stores company name.
    const [firstName, setFirstName] = useState('');  // Stores first name.
    const [lastName, setLastName] = useState('');  // Stores last name.
    const [firstNameError, setFirstNameError] = useState('');  // Error message for first name validation.
    const [lastNameError, setLastNameError] = useState('');  // Error message for last name validation.
    const [isRecaptchaVerified, setIsRecaptchaVerified] = useState('');  // Stores reCAPTCHA verification status.
    const [phone, setPhone] = useState('');  // Stores phone number.
    const [email, setEmail] = useState('');  // Stores email address.
    const [password, setPassword] = useState('');  // Stores password.
    const [confirmPassword, setConfirmPassword] = useState('');  // Stores confirm password.
    const [step, setStep] = useState(0);  // Tracks the current step in the sign-up process.
    const [potp, setPOTP] = useState('');  // Stores the phone OTP.
    const [eotp, setEOTP] = useState('');  // Stores the email OTP.
    const [accepted, setAccepted] = useState(false);  // Stores whether terms and conditions are accepted.
    const interval = useRef(null);  // Reference for the OTP timer interval.
    const [loading, setLoading] = useState(false);  // Indicates whether a request is in progress.
    const theme = useTheme(); // Retrieves the theme from MUI.
    // Password validation regex.
    const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\\$%^&*()_+])[a-zA-Z0-9!@#\\$%^&*()_+]{8,}$/;
    const [seconds, setSeconds] = useStateWithCallback(900, (sec) => {
        if (sec <= 0) {
            if (interval.ref !== null) clearInterval(interval.ref); // Stops timer when it reaches zero.
            //   toastr.error("OTPs have expired. Please click resend to generate new OTPs");
            showSwalAlert({
                icon: 'error',
                title: `OTPs have expired. Please click resend to generate new OTPs`,
                timer: 3000,
            });
        }
    });
    const showSwalAlert = useSwalAlert(); // Custom alert function for notifications.

    // Timer formatting to display in minutes and seconds
    const timer = useMemo(() => {
        const date = new Date(seconds * 1000);
        const isoDate = date.toISOString();
        return isoDate.substring(14, 19);
    }, [seconds]);

    /**
    * Handles the form submission for registration.
    * Sends the form data to the server to register the partner.
    * 
    * @returns {void}
    */
    const handleClick = useCallback(() => {
        setLoading(true); // Set loading state to true while processing.
        axios
            .post('/auth/register', {
                userType: 'partner',
                email,
                phone,
                password,
                eotp,
                profile: {
                    partnerType,
                    companyName,
                    first_name: firstName,
                    last_name: lastName,
                },
            })
            .then(() => {
                // Display success alert upon successful registration.
                showSwalAlert({
                    icon: 'success',
                    title: `Registration Complete! Please proceed to create your Will.`,
                    timer: 3000,
                });
                // navigate(navigate().pathname.replace('/partner', ''));
                setTimeout(() => {
                    window.location.replace('/');
                    setLoading(false);
                }, 3000);
                // navigate("/login", { replace: true });
            })
            .catch((e) => {
                // Handle error case when registration fails.
                if (e?.response?.status === 400) {
                    // toastr.error("Email or mobile already in use. Please proceed to login!");
                    showSwalAlert({
                        icon: 'error',
                        title: e.response.data.message,
                        timer: 3000,
                    });
                } else {
                    // toastr.error("Registration Failed! Please check the fields and try again");
                    showSwalAlert({
                        icon: 'error',
                        title: `Registration Failed! Please check the fields and try again`,
                        timer: 3000,
                    });
                }
                setLoading(false);
            });
        // }
    }, [email, password, eotp]);

    /**
    * Generates OTPs for the email and phone and handles OTP expiration.
    * 
    * @param {boolean} [fromReset=false] - Indicates whether the OTP is being resent.
    * @returns {void}
    */
    const generateOTPs = useCallback((fromReset = false) => {
        axios
            .post('/auth/pre-register', {
                email,
                phone,
            })
            .then(() => {
                setStep(1);
                if (interval.ref !== null) clearInterval(interval.ref);
                setSeconds(900);
                interval.ref = setInterval(() => {
                    setSeconds((s) => s - 1);
                }, 1000);
                showSwalAlert({
                    icon: "success",
                    title: `OTP has been ${fromReset ? 'resent' : 'sent'} to your email`,
                    timer: 3000
                });
            })
            .catch((e) => {
                if (e?.response?.status === 400) {
                    // toastr.error("Email or mobile already in use. Please proceed to login!");
                    showSwalAlert({
                        icon: 'error',
                        title: `Email already in use. Please proceed to login!`,
                        timer: 3000,
                    });
                } else {
                    // toastr.error("Unable to generate OTP! Please check the email");
                    showSwalAlert({
                        icon: 'error',
                        title: `Unable to generate OTP! Please check the email`,
                        timer: 3000,
                    });
                }
            });
    }, [email, phone]);

    /**
    * Handles the key press event (Enter) to trigger form actions based on the current step.
    * 
    * @param {KeyboardEvent} e - The keyboard event.
    * @returns {void}
    */
    useEffect(() => {
        const handleEnter = (e) => {
            if (e.code?.toLowerCase() === 'enter' && !e.repeat && accepted) {
                e.preventDefault();
                if (step === 0) {
                    if (
                        /^\+\d{1,3}-\d{6,10}$/.test(phone ?? '') &&
                        /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email ?? '') &&
                        password.length > 7
                    ) {
                        generateOTPs();
                    }
                } else if (step === 1) {
                    if (potp.length === 6 && eotp.length === 6 && seconds > 0) {
                        handleClick();
                    }
                }
            }
        };
        window.addEventListener('keyup', handleEnter);
        return () => {
            window.removeEventListener('keyup', handleEnter);
        };
    }, [phone, email, password, eotp, potp, step, handleClick, accepted]);

    /**
      * Handles change in the partner type selection.
      * 
      * @param {Object} event - The event triggered by the change in partner type.
      * @returns {void}
    */
    const handlePartnerTypeChange = (event) => {
        setPartnerType(event.target.value);
    };

    /**
    * Handles the reCAPTCHA verification.
    * 
    * @param {string} value - The reCAPTCHA response value.
    * @returns {void}
    */
    const onCaptchaChange = (value) => {
        console.log('Captcha value:', value);
        setIsRecaptchaVerified(value);
    };

    /**
     * Checks whether the form submit button should be disabled based on form validity.
     * 
     * @returns {boolean} Whether the submit button should be disabled.
     */
    const checkIsDisabled = () => {
        if (
            !accepted ||
            !isRecaptchaVerified ||
            !partnerType ||
            firstNameError ||
            lastNameError ||
            !firstName ||
            !lastName ||
            (!!password && !passwordRegex.test(password)) ||
            (!!confirmPassword && !!password && password !== confirmPassword) ||
            !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email ?? '')
        ) {
            return true;
        }
        // }
        return false;
    };

    const isResetDisabled = seconds > 840;
    return (
        <>
            <Stack spacing={3} paddingBottom={'40px'}>
                {
                    [
                        <>
                            <Typography variant="title1">Create an account</Typography>
                            <Typography
                                variant="bodyText3"
                                color={theme.palette.grey[400]}
                                gutterBottom
                                marginBottom={'16px'}
                                marginTop={'8px'}
                            >
                                Please create your account
                            </Typography>
                            <>
                                <Dropdown
                                    name="partnerType"
                                    value={partnerType}
                                    options={partnerTypeOptions}
                                    handleChange={handlePartnerTypeChange}
                                    placeholder="Partner Type"
                                />
                                <InputField
                                    name="company_name"
                                    placeholder="Company Name"
                                    value={companyName}
                                    fullWidth
                                    onChange={(e) => setCompanyName(e.target.value)}
                                />
                                <InputField
                                    name="first_name"
                                    placeholder="First Name"
                                    value={firstName}
                                    fullWidth
                                    onChange={(e) => {
                                        if (/^[a-zA-Z]+$/.test(e.target.value)) {
                                            setFirstNameError(null);
                                        } else {
                                            setFirstNameError('Only alphabets are allowed');
                                        }
                                        setFirstName(e.target.value);
                                    }}
                                />
                                {firstNameError && (
                                    <Typography
                                        variant="smallText2"
                                        sx={{ marginTop: '2px!important' }}
                                        color="error"
                                    >{`${firstNameError}`}</Typography>
                                )}
                                <InputField
                                    name="last_name"
                                    placeholder="Last Name"
                                    value={lastName}
                                    fullWidth
                                    onChange={(e) => {
                                        if (/^[a-zA-Z]+$/.test(e.target.value)) {
                                            setLastNameError(null);
                                        } else {
                                            setLastNameError('Only alphabets are allowed');
                                        }
                                        setLastName(e.target.value);
                                    }}
                                />
                                {lastNameError && (
                                    <Typography
                                        variant="smallText2"
                                        sx={{ marginTop: '2px!important' }}
                                        color="error"
                                    >{`${lastNameError}`}</Typography>
                                )}
                                <InputField
                                    name="email"
                                    error={!!email && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)}
                                    helperText={!!email && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email) && 'Please enter a valid email'}
                                    placeholder="Email"
                                    value={email}
                                    fullWidth
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </>
                            <InputField
                                name="password"
                                type="password"
                                placeholder="Password"
                                value={password}
                                error={!!password && !passwordRegex.test(password)}
                                fullWidth
                                helperText={
                                    !!password &&
                                    !passwordRegex.test(password) &&
                                    'Password should be at least 8 characters long and must contain at least one uppercase, one lowercase, one number and one special case character'
                                }
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <InputField
                                error={!!confirmPassword && !!password && password !== confirmPassword}
                                helperText={
                                    !!confirmPassword &&
                                    !!password &&
                                    password !== confirmPassword &&
                                    'Password mismatch! Please enter same password in both fields'
                                }
                                name="confirm-password"
                                type="password"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                fullWidth
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />

                            <ReCaptcha sitekey="6LfYHrYoAAAAABadQVuKliDO4491RrZ7ThRkEAL6" onChange={onCaptchaChange} />
                            <FormControlLabel
                                sx={{ m: 0, gap: '12px' }}
                                control={
                                    <CheckBox
                                        checked={accepted}
                                        onChange={(e, value) => {
                                            setAccepted(value);
                                        }}
                                    />
                                }
                                label={
                                    <Typography align={'center'} variant="bodyText4" sx={{ mb: 0 }}>
                                        I have read and accept the{' '}
                                        <Link
                                            target={'_blank'}
                                            href={'https://getwilldone.com/terms-and-conditions/'}
                                            color={theme.palette.secondary.main}
                                        >
                                            Terms and Conditions
                                        </Link>
                                    </Typography>
                                }
                            />

                            <ContainedButton1
                                disabled={checkIsDisabled()}
                                fullWidth
                                size="large"
                                type="button"
                                variant="contained"
                                onClick={() => generateOTPs()}
                            >
                                <Typography variant="bodyText4">Sign Up</Typography>
                            </ContainedButton1>
                            <Typography variant="bodyText4">
                                Already have an account?{' '}
                                <Typography
                                    variant="bodyText4"
                                    component={'span'}
                                    sx={{
                                        color: (theme) => theme.palette.secondary.main,
                                        textDecoration: 'underline',
                                        mb: 5,
                                        '&:hover': {
                                            cursor: 'pointer',
                                        },
                                    }}
                                    onClick={() => navigate('/login')}
                                    color={theme.palette.secondary.main}
                                >
                                    Log in here
                                </Typography>
                            </Typography>
                        </>,
                        <Box
                        >
                            <Box sx={{ mb: "5px" }}>
                                <Typography variant="title1" sx={{ mb: "5px" }} gutterBottom>
                                    OTP Verification
                                </Typography>
                            </Box>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="bodyText3" sx={{ mb: "30px" }} color={theme.palette.grey[400]}>
                                    An OTP has been sent to {email.replace(/(.{2})(.*)(.@.*)/, "$1****$3")}
                                </Typography>
                            </Box>
                            <Box sx={{ marginBottom: '12px' }}>
                                <OTPField
                                    containerStyle={{ justifyContent: 'space-around' }}
                                    inputStyle={{
                                        fontSize: theme.typography.fontSize,
                                        width: theme.spacing(4),
                                        padding: theme.spacing(1.5),
                                    }}
                                    value={eotp}
                                    onChange={setEOTP}
                                    numInputs={6}
                                    isInputNum
                                    isInputSecure
                                />
                            </Box>
                            <Typography
                                variant="suggestiveText"
                                sx={{ mb: 5 }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: seconds <= 0 ? 'error.main' : seconds <= 60 ? 'warning.main' : theme.palette.secondary.main,
                                    }}
                                >
                                    <Iconify
                                        sx={{ color: seconds <= 0 ? 'error.main' : seconds <= 60 ? 'warning.main' : theme.palette.secondary.main, }}
                                        icon="uil:clock"
                                    />{' '}
                                    {seconds > 0 ? timer : 'OTP Expired! Please click resend to generate new ones'}
                                </Box>
                            </Typography>
                            <Box sx={{ mt: 2, mb: 1 }}>
                                <Typography
                                    variant="suggestiveText"
                                    gutterBottom
                                    sx={{
                                        color: isResetDisabled ? theme.palette.grey[300] : theme.palette.secondary.main,
                                        textDecoration: isResetDisabled ? 'none' : 'underline',
                                        mb: "8px",
                                        cursor: isResetDisabled ? 'not-allowed' : 'pointer',
                                    }}
                                    onClick={(e) => {
                                        if (!isResetDisabled) {
                                            e.preventDefault();
                                            generateOTPs(true);
                                        }
                                    }}                                >
                                    Resend OTP
                                </Typography>
                            </Box>
                            <ContainedButton1
                                disabled={loading || seconds <= 0 || (eotp.length !== 6 && potp.length !== 6)}
                                fullWidth
                                type="submit"
                                onClick={handleClick}
                                sx={{ height: '40px', mb: '50px' }}
                            >
                                <Typography variant='bodyText3'>
                                    Verify
                                </Typography>
                            </ContainedButton1>
                            <Divider
                                sx={{
                                    mb: "15px",
                                    width: "402px",
                                    borderColor: theme.palette.grey[100]
                                }}
                            />

                            <Typography variant="bodyText4">
                                Back to <Link
                                    color={theme.palette.secondary.main}
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = '/login';
                                    }}
                                >
                                    Log In
                                </Link>
                            </Typography>
                        </Box>,
                    ][step]
                }
            </Stack>
        </>
    );
}
