import axios from "axios";
/**
 * Fetches the list of subscription services.
 * @module subscriptionServices/fetchServices
 * @description
 * This function retrieves a list of all subscription services from the server. It makes a GET request 
 * to the `/subscriptions` endpoint and returns the server response containing the details of the services.
 * @returns {Promise<Object>} The API response containing the list of subscription services.
 */
export const fetchServices = async () => {
    const response = axios.get("/subscriptions");
    return response;
}

/**
 * Fetches the list of active coupons.
 * @module subscriptionServices/fetchActiveCoupons
 * @description
 * This function retrieves a list of currently active coupons from the server. It makes a GET request 
 * to the `/coupons/active` endpoint and returns the server response containing coupon details.
 * @returns {Promise<Object>} The API response containing the list of active coupons.
 */
export const fetchActiveCoupons = async () => {
    const response = axios.get("/coupons/active");
    return response;
}

/**
 * Sends a request for a quote for a specific type.
 * @module subscriptionServices/RequestForQuote
 * @description
 * This function sends a PATCH request to the `/testator/request/call` endpoint to request a quote 
 * for a specified type. The request includes a comment stating "Request for a quote" and the provided 
 * `type` parameter, which determines the type of request being made.
 * @param {string} type - The type of the request for the quote (e.g., "standard", "premium").
 * @returns {Promise<Object>} The API response confirming the request has been made.
 */
export const RequestForQuote = async (type) => {
    const response = axios.patch("/testator/request/call", { comment: `Request for a quote`, type });
    return response;
}

/**
 * Sends a request for a call for the specified type of testator.
 * @module subscriptionServices/RequestForCall
 * @description
 * This function sends a PATCH request to the server to request a call for the specified testator type. 
 * The `type` of the testator is included in the request payload along with a comment stating 
 * "Request for a call". The function returns the server response.
 * @param {string} type - The type of the testator for the call request.
 * @returns {Promise<Object>} The API response confirming the request for a call.
 */
export const RequestForCall = async (type) => {
    const response = axios.patch("/testator/request/call", { comment: `Request for a call`, type });
    return response;
}

/**
 * Validates a coupon code.
 * @module subscriptionServices/validateCoupon
 * @description
 * This function validates a coupon code by sending a POST request to the `/coupons/validate/{couponCode}` 
 * endpoint. It returns the response from the server, which indicates whether the coupon is valid 
 * or not along with any associated details (e.g., discount percentage, expiry date).
 * @param {string} couponCode - The coupon code to be validated.
 * @returns {Promise<Object>} The API response containing the validation result of the coupon code.
 */
export const validateCoupon = async (couponCode) => {
    const response = axios.post(`/coupons/validate/${couponCode}`);
    return response;
}

/**
 * Subscribes a testator to a service with optional coupon and offer.
 * @module subscriptionServices/subscribeApi
 * @description
 * This function subscribes a testator to a service. It sends a POST request to the `/testator/subscribe` endpoint 
 * with the provided coupon code, offer details, and subscription IDs. The server processes the subscription 
 * and returns the response.
 * @param {Object} params - The subscription details.
 * @param {string} params.couponCode - The coupon code to apply (optional).
 * @param {string} params.offer - The offer associated with the subscription (optional).
 * @param {Array<string>} params.subscriptionIds - The list of subscription IDs for the testator.
 * @returns {Promise<Object>} The API response containing the subscription status and details.
 */
export const subscribeApi = async ({ couponCode, offer, subscriptionIds }) => {
    const response = axios.post(`/testator/subscribe`, { couponCode, offer, subscriptionIds });
    return response;
}

/**
 *  Checks the payment status for a specific user's subscription.
 * @module subscriptionServices/checkPaymentStatusApi
 * @description
 * This function checks the payment status for a testator's subscription by making a POST request
 * to the `/testator/check/payment/{userSubscription}` endpoint, where `{userSubscription}` is a 
 * unique identifier for the user's subscription. The response indicates whether the payment was successful.
 * @param {string} userSubscription - The unique identifier for the user's subscription.
 * @returns {Promise<Object>} The API response containing the payment status for the user's subscription.
 */
export const checkPaymentStatusApi = async (userSubscription) => {
    const response = axios.post(`/testator/check/payment/${userSubscription}`);
    return response;
}

/**
 * Validates a partner using a referral code.
 * @module subscriptionServices/checkValidatePartnerApi
 * @description
 * This function validates a partner by checking the provided referral code. It sends a POST request 
 * to the `/partners/validate/{referralCode}` endpoint and returns the server response with the validation result.
 * @param {string} referralCode - The referral code to be validated.
 * @returns {Promise<Object>} The API response containing the validation result.
 */
export const checkValidatePartnerApi = async (referralCode) => {
    const response = axios.post(`/partners/validate/${referralCode}`);
    return response;
}





