/**
 * @module Views/ProfileIcon
 * @description A React module that contains the ProfileIcon component used to display the profile icon
 * with hover effects, and ProfileMenu for user profile interaction such as logging out.
 */ 
import { Box, Divider, IconButton, Tooltip, useTheme } from '@mui/material';
import { ContextProvider } from 'App';
import { ReactComponent as HelpIcon } from 'assets/help-button.svg';
import { ReactComponent as ProfileIconSelected } from 'assets/profile-icon-selected.svg';
import { ReactComponent as ProfileIconNil } from 'assets/profile-icon.svg';
import ExitDemoButton from 'components/actionsButtons/ExitDemoButton';
import Icon from 'components/DesignSystem/Icon';
import ProfileMenu from 'components/DesignSystem/ProfileMenu';
import useSwalAlert from 'components/DesignSystem/SwalAlert';
import HelpSection from 'components/featureComponent/helpResourceSection/HelpResourceSection';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUserApi } from 'services/profileService';
import { MENU_OPEN } from 'store/actions';

/**
 * ProfileIcon component displays the user's profile icon and shows the ProfileMenu when clicked.
 * It also handles user interactions like navigating to the profile page and logging out.
 *
 * @component
 * @example
 * <ProfileIcon />
 *
 * @returns {JSX.Element} Rendered ProfileIcon component
 */
export const ProfileIcon = () => {
    const [isHovered, setIsHovered] = useState(false); // Track hover state for the icon
    const [anchorEl, setAnchorEl] = useState(null); // Track the anchor element for the ProfileMenu
    const { user, setUser, setLoggedIn } = useContext(ContextProvider); // Access user context
    const navigate = useNavigate(); // Hook for navigation
    const dispatch = useDispatch(); // Hook for dispatching actions
    const showSwalAlert = useSwalAlert(); // Custom hook for showing SweetAlert notifications
    const hideProfile = user?.role === 'admin' || user?.role === 'reviewer'; // Hide profile button for certain roles

    /**
     * Handles mouse entering the profile icon area.
     */
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    /**
     * Handles mouse leaving the profile icon area.
     */
    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    /**
     * Handles click on the profile icon to open the profile menu.
     *
     * @param {object} e - The click event object.
     */
    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    /**
     * Closes the profile menu.
     */
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const profileOpen = Boolean(anchorEl); // Determine if the ProfileMenu should be open

    /**
     * Handles the profile menu click to navigate to the profile page.
     */
    const handleProfileClick = () => {
        dispatch({ type: MENU_OPEN, id: null });
        navigate('/profilePage'); // Replace with your profile screen route
        handleMenuClose();
    };

    /**
     * Handles the logout action, clearing user data and showing a SweetAlert notification.
     */
    const handleLogoutClick = async () => {
        try {
            await logoutUserApi();
            setUser({});
            setLoggedIn(false);
            sessionStorage.removeItem('isDemoActive');
            showSwalAlert({
                position: 'top-center',
                icon: 'success',
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: 'Logged out!',
                showConfirmButton: false,
                timer: 3000,
            });
        } catch (err) {
            console.log('err ', err);
        } finally {
            navigate('/login', { replace: true });
            handleMenuClose();
        }
    };

    return (
        <>
            <Tooltip title="Go to Profile">
                <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick}>
                    {isHovered ? (
                        <ProfileIconSelected
                            height={'48px'}
                            width={'48px'}
                            style={{ border: '1px solid red', borderRadius: '48px' }}
                        />
                    ) : (
                        <ProfileIconNil height={'48px'} width={'48px'} />
                    )}
                </Box>
            </Tooltip>
            <ProfileMenu
                hideProfileButton={hideProfile}
                anchorEl={anchorEl}
                open={profileOpen}
                onClose={handleMenuClose}
                name={user?.full_name}
                email={user?.email}
                handleProfileClick={handleProfileClick}
                handleLogoutClick={handleLogoutClick}
            />
        </>
    );
};

/**
 * PageContainer component wraps the page content and includes a header section with profile actions
 * and help button, and a content section for displaying children components.
 *
 * @component
 * @example
 * <PageContainer showHelpIcon={true} showBackButton={false}>
 *   <SomeContent />
 * </PageContainer>
 *
 * @param {object} props - The component's props.
 * @param {boolean} [props.showHelpIcon=true] - Controls whether the help icon should be shown.
 * @param {string} [props.defaultFaqType='general'] - Default FAQ section type for the help section.
 * @param {boolean} [props.showBackButton=false] - Controls whether the back button is displayed.
 * @param {React.ReactNode} props.children - The content to be rendered inside the page container.
 *
 * @returns {JSX.Element} Rendered PageContainer component with children.
 */
const PageContainer = ({ children, showHelpIcon = true, defaultFaqType = 'general', showBackButton = false }) => {
    const theme = useTheme(); // Material-UI theme hook
    const [openHelpSection, setOpenHelpSection] = useState(false); // State to control the visibility of the help section

    /**
     * Closes the help section.
     */
    const handleHelpSectionClose = () => setOpenHelpSection(false);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                    position: 'relative',
                    bgcolor: theme.palette.grey[0],
                }}
            >
                {/* Profile section */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: '1.25rem',
                    }}
                >
                    <ExitDemoButton />
                    <ProfileIcon /> {/* Use the ProfileIcon component */}
                </Box>

                {/* <Divider sx={{ my: 2 }} /> */}
                {showBackButton ? (
                    <BackButtonWithDivider />
                ) : (
                    <Divider sx={{ marginLeft: '8px', borderBottomWidth: '1px', my: 2 }} />
                )}

                {/* Content area */}
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {children}
                </Box>

                {/* Bulb icon */}
                {showHelpIcon && (
                    <IconButton
                        sx={{
                            position: 'fixed',
                            bottom: '3.125rem',
                            right: '3.125rem',
                            bgcolor: '#FFF3E0',
                            color: '#FF8C42',
                            '&:hover': {
                                bgcolor: '#FFE0B2',
                            },
                        }}
                        onClick={() => setOpenHelpSection(true)}
                    >
                        <HelpIcon />
                    </IconButton>
                )}
            </Box>
            <HelpSection open={openHelpSection} handleClose={handleHelpSectionClose} defaultFaqType={defaultFaqType} />
        </>
    );
};

export default PageContainer;

/**
 * BackButtonWithDivider component renders a back button with a divider.
 * It is used in the PageContainer component for navigation.
 *
 * @component
 * @example
 * <BackButtonWithDivider />
 *
 * @returns {JSX.Element} Rendered BackButtonWithDivider component with navigation and divider.
 */
const BackButtonWithDivider = () => {
    const navigate = useNavigate(); // Hook for navigation

    /**
     * Handles click on the back button to navigate to the previous page.
     */
    const handleBackClick = () => {
        navigate(-1); // Navigate one step back in the browser history
    };

    const theme = useTheme(); // Material-UI theme hook

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: '5px' }}>
            <IconButton onClick={handleBackClick} sx={{ background: theme.palette.grey[200] }}>
                <Icon name="back-arrow-icon.png" width={6.5} height={9} />
            </IconButton>
            <Divider sx={{ flexGrow: 1, marginLeft: '8px', borderBottomWidth: '1px' }} />
        </Box>
    );
};
