/**
 * @module Pages/AdminPages/AdminComponents/TopPerformingPartnerCard
 * @description This module contains the `TopPerformingPartnerCard` component, which fetches and displays 
 * the data for the top-performing partner. It shows a dashboard card with the partner's name and navigates 
 * to a detailed page when clicked.
 */
import { Skeleton } from '@mui/material';
import DashboardCard from 'components/commonComponents/cards/DashboardCard';
import { startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchTopPerformingPartners } from 'services/adminService';
/**
 * The `TopPerformingPartnerCard` component fetches data about the top-performing partner and displays 
 * it in a dashboard card. The component also provides navigation to a detailed page on click.
 * 
 * @component
 * @returns {JSX.Element} A dashboard card showing the name of the top-performing partner.
 */
const TopPerformingPartnerCard = () => {
  const [topPerformingPartnerData, setTopPerformingPartnerData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  /**
   * Fetches the top-performing partner data from the backend.
   * 
   * This function is executed inside the `useEffect` hook to fetch data once when the component mounts.
   * 
   * @async
   * @function
   * @returns {void}
   */
  useEffect(() => {
    const fetchTopPerformingPartner = async () => {
      try {
        const response = await fetchTopPerformingPartners();
        setTopPerformingPartnerData(response?.data || {});
      } catch (error) {
        console.error('Error fetching top performing data:', error);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };
    fetchTopPerformingPartner();
  }, []);
 /**
   * Redirects the user to the top-performing partner details page.
   * 
   * This function is called when the user clicks the `DashboardCard`.
   * 
   * @function
   * @returns {void}
   */
  const redirectPage = () => {
    navigate('/admin/topPerformer');
  };

  return (
    <>
      {
        loading ? (
          <Skeleton variant="rectangular" height={135} sx={{ borderRadius: '1.25rem' }} />
        ) : (
          <DashboardCard
            title="Top Performing Partner"
            subtitle={startCase(topPerformingPartnerData?.partner_name) || '--'}
            handleClick={redirectPage}
            isClickable
          />
        )
      }
    </>
  );
};

export default TopPerformingPartnerCard;
