/**
 * @module Theme/Shadows
 *
 * @description This module defines and exports a function to generate various shadow styles used in the application.
 * These shadows are applied to various UI elements, such as cards, buttons, or modals, to provide depth effects
 * by using different levels of transparency based on the grey color palette.
 * The shadow styles are represented in the CSS box-shadow format and use the `alpha` function from `@mui/material/styles`
 * to control the transparency of the shadow color.
 */
import { alpha } from '@mui/material/styles';
import palette from './palette';

/**
 * Generates an array of shadow styles based on the grey color with various levels of transparency.
 * Each shadow style consists of three components with different opacities (`transparent1`, `transparent2`, `transparent3`),
 * representing varying shadow intensities.
 * These shadows are primarily used for UI elements such as cards, modals, and buttons to create visual depth.
 *
 * @function
 * @name shadows
 *
 * @returns {string[]} An array of shadow styles with increasing depth. Each shadow is defined by multiple levels of opacity:
 *
 * - `none` - No shadow.
 * - `0px 2px 1px -1px ${transparent1}, 0px 1px 1px 0px ${transparent2}, 0px 1px 3px 0px ${transparent3}` - A light shadow.
 * - `0px 3px 1px -2px ${transparent1}, 0px 2px 2px 0px ${transparent2}, 0px 1px 5px 0px ${transparent3}` - A slightly deeper shadow.
 * - `0px 3px 3px -2px ${transparent1}, 0px 3px 4px 0px ${transparent2}, 0px 1px 8px 0px ${transparent3}` - A medium depth shadow.
 * - `0px 2px 4px -1px ${transparent1}, 0px 4px 5px 0px ${transparent2}, 0px 1px 10px 0px ${transparent3}` - A deeper shadow.
 * - and so on, progressively increasing the shadow depth with each entry.
 *
 * The transparency levels for the shadows are defined as follows:
 * - `transparent1`: `alpha(color, 0.2)` (light opacity).
 * - `transparent2`: `alpha(color, 0.14)` (medium opacity).
 * - `transparent3`: `alpha(color, 0.12)` (dark opacity).
 *
 * These shadows can be used to apply different levels of depth to elements within the UI.
 *
 * @example
 * const shadowStyles = shadows();
 * // Usage in styles:
 * // const shadow = shadowStyles[2]; // This will use the medium depth shadow at index 2.
 */
const color = palette.grey[500];

export default function shadows() {
  // Define transparent versions of the grey color with different opacity levels
  const transparent1 = alpha(color, 0.2);
  const transparent2 = alpha(color, 0.14);
  const transparent3 = alpha(color, 0.12);

  // Return an array of shadows with varying depths
  return [
    'none',
    `0px 2px 1px -1px ${transparent1},0px 1px 1px 0px ${transparent2},0px 1px 3px 0px ${transparent3}`,
    `0px 3px 1px -2px ${transparent1},0px 2px 2px 0px ${transparent2},0px 1px 5px 0px ${transparent3}`,
    `0px 3px 3px -2px ${transparent1},0px 3px 4px 0px ${transparent2},0px 1px 8px 0px ${transparent3}`,
    `0px 2px 4px -1px ${transparent1},0px 4px 5px 0px ${transparent2},0px 1px 10px 0px ${transparent3}`,
    `0px 3px 5px -1px ${transparent1},0px 5px 8px 0px ${transparent2},0px 1px 14px 0px ${transparent3}`,
    `0px 3px 5px -1px ${transparent1},0px 6px 10px 0px ${transparent2},0px 1px 18px 0px ${transparent3}`,
    `0px 4px 5px -2px ${transparent1},0px 7px 10px 1px ${transparent2},0px 2px 16px 1px ${transparent3}`,
    `0px 5px 5px -3px ${transparent1},0px 8px 10px 1px ${transparent2},0px 3px 14px 2px ${transparent3}`,
    `0px 5px 6px -3px ${transparent1},0px 9px 12px 1px ${transparent2},0px 3px 16px 2px ${transparent3}`,
    `0px 6px 6px -3px ${transparent1},0px 10px 14px 1px ${transparent2},0px 4px 18px 3px ${transparent3}`,
    `0px 6px 7px -4px ${transparent1},0px 11px 15px 1px ${transparent2},0px 4px 20px 3px ${transparent3}`,
    `0px 7px 8px -4px ${transparent1},0px 12px 17px 2px ${transparent2},0px 5px 22px 4px ${transparent3}`,
    `0px 7px 8px -4px ${transparent1},0px 13px 19px 2px ${transparent2},0px 5px 24px 4px ${transparent3}`,
    `0px 7px 9px -4px ${transparent1},0px 14px 21px 2px ${transparent2},0px 5px 26px 4px ${transparent3}`,
    `0px 8px 9px -5px ${transparent1},0px 15px 22px 2px ${transparent2},0px 6px 28px 5px ${transparent3}`,
    `0px 8px 10px -5px ${transparent1},0px 16px 24px 2px ${transparent2},0px 6px 30px 5px ${transparent3}`,
    `0px 8px 11px -5px ${transparent1},0px 17px 26px 2px ${transparent2},0px 6px 32px 5px ${transparent3}`,
    `0px 9px 11px -5px ${transparent1},0px 18px 28px 2px ${transparent2},0px 7px 34px 6px ${transparent3}`,
    `0px 9px 12px -6px ${transparent1},0px 19px 29px 2px ${transparent2},0px 7px 36px 6px ${transparent3}`,
    `0px 10px 13px -6px ${transparent1},0px 20px 31px 3px ${transparent2},0px 8px 38px 7px ${transparent3}`,
    `0px 10px 13px -6px ${transparent1},0px 21px 33px 3px ${transparent2},0px 8px 40px 7px ${transparent3}`,
    `0px 10px 14px -6px ${transparent1},0px 22px 35px 3px ${transparent2},0px 8px 42px 7px ${transparent3}`,
    `0px 11px 14px -7px ${transparent1},0px 23px 36px 3px ${transparent2},0px 9px 44px 8px ${transparent3}`,
    `0px 11px 15px -7px ${transparent1},0px 24px 38px 3px ${transparent2},0px 9px 46px 8px ${transparent3}`,
  ];
}
