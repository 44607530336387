/**
 * @module GwdImageContentCard
 * @description This module represents a content card for a subscription-based service. It displays details such as 
 * the service's price, any applicable discounts, and the user's subscription status. It allows users to select 
 * a subscription if they haven't already subscribed, and it provides expiration information for existing subscriptions.
 */
import { Box, Button, Typography, useTheme, Stack } from '@mui/material';
import ButtonCheckBox from 'components/DesignSystem/ButtonCheckBox';
import Icon from 'components/DesignSystem/Icon';
import { format } from 'date-fns';
import { formatPrice } from 'utils/formatPrice';

/**
 * GwdImageContentCard component displays a card with subscription-related details including price, discounts,
 * subscription status, and provides the option to select a subscription.
 * 
 * @component
 * @param {Object} props - The props for the component
 * @param {number} props.price - The original price of the service or subscription
 * @param {Array} props.checkedSubscriptions - List of subscriptions that the user has checked or selected
 * @param {number} props.discountedPrice - The discounted price of the service (if applicable)
 * @param {Object} props.row - The individual subscription object containing details about the subscription
 * @param {boolean} props.subscribed - Whether the user is already subscribed to the service
 * @param {Function} props.getSubscribedExpiry - Function that returns the expiry date of the user's subscription
 * @param {Function} props.onPurchaseClick - Function that handles the action of purchasing or selecting a subscription
 * @param {Object} props.user - The current user object with details about the user, such as their role and subscription status
 * 
 * @returns {JSX.Element} The rendered GwdImageContentCard component
 */
const GwdImageContentCard = ({ price, checkedSubscriptions, discountedPrice, row, subscribed, getSubscribedExpiry, onPurchaseClick, user, }) => {
  const theme = useTheme();
  return (
    <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '21px', overflow: 'hidden', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', mb: '30px', position: 'relative', }} >
      <Box>
        <Icon name="make-your-own-will-img.png" width={'100%'} height={'100%'} />
      </Box>
      <Box sx={{ backgroundColor: theme.palette.secondary.main, padding: '21px 20px 24px', color: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'absolute', bottom: 0, width: '100%', }}>
        <Box>
          <Typography variant="smallText" sx={{ display: 'flex', alignItems: 'center', mb: 1 }} gutterBottom={false}>
            <Icon name="blue-tick-icon.png" style={{ marginRight: '5px' }} />
            Create & generate your Will easily & hassle-free
          </Typography>
          <Typography variant="smallText" sx={{ display: 'flex', alignItems: 'center' }} gutterBottom={false}>
            <Icon name="blue-tick-icon.png" style={{ marginRight: '5px' }} />
            Make any changes you need to your Will for 30 days
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '10px', }}>
          <Stack justifyContent={'center'} spacing={1} direction="row" sx={{ marginRight: '16px' }}>
            {discountedPrice > -1 ? (
              <strike>
                <Typography variant="bodyText4">₹{price === 0 ? 'Free' : formatPrice(price)}</Typography>
              </strike>
            ) : (
              <Typography variant="bodyText4">{row.price === 0 ? 'Free' : `₹${formatPrice(price)}`}</Typography>
            )}
          </Stack>
          {discountedPrice > -1 && (
            <Stack justifyContent={'center'} direction="row">
              {row.price > 0 && (
                <Typography fontWeight={'bold'} variant="bodyText4">
                  Offer Price: ₹
                </Typography>
              )}

              <Typography variant="bodyText4" fontWeight={'bold'}>
                {row.discounted_price === 0 ? 'Free' : formatPrice(row.discounted_price)}
              </Typography>
            </Stack>
          )}
          {((row.id === 1 && !subscribed)) && (
            <ButtonCheckBox
              checked={checkedSubscriptions.some((checkedSubscription) => checkedSubscription.id === row.id)}
              onChange={() => {
                onPurchaseClick(row);
              }}
              disabled={user.role === 'partner' && user.partner_enroll_status !== 'APPROVED'}
            />
          )}
          {row.id === 1 && subscribed && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography sx={{ margin: '6px' }} variant="suggestiveText" textAlign={'center'}>
                Your subscription will expire on {format(new Date(getSubscribedExpiry), 'dd-MM-yyyy')}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default GwdImageContentCard;
