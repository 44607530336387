/**
 * @module App
 *
 * This module defines the main application component for the React application.
 * It integrates various functionalities such as routing, analytics, user authentication, GDPR consent management, idle timeout, and screenshot prevention.
 * The app component manages global state and provides context to child components.
 *
 * // In the main entry file (index.js or similar):
 * ReactDOM.render(<App />, document.getElementById('root'));
 */
import axios from 'axios';
import IdleTimeout from 'components/IdleTimeout';
import GDPR from 'layout/Gdpr';
import NavigationScroll from 'layout/NavigationScroll';
import { createContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import usePreventScreenshot from './hooks/usePreventScreenshot';
import Routes from './routes';
import './styles.css';
import ThemeProvider from './theme';

// Google Analytics 4 Tracking ID - retrieved from environment variable or defaults to "G-HTKPF0G88W"
const GA4_TRACKING_ID = process.env.REACT_APP_GA4_TRACKING_ID ?? 'G-HTKPF0G88W';
// Initialize Google Analytics 4 with the tracking ID
ReactGA.initialize(GA4_TRACKING_ID);

/**
 * @context ContextProvider
 *
 * This context provides global state to the application, including the user's data, login state, and functions to manage authentication status.
 * It is made available to all components within the application to manage and retrieve user data and login state.
 *
 * @type {React.Context}
 * @property {object} user - The user object containing user data.
 * @property {function} fetchUser - A function that fetches user data from the backend.
 * @property {boolean} loggedIn - The logged-in state of the user.
 * @property {function} setLoggedIn - A function to update the logged-in state.
 * @property {function} setUser - A function to update the user data.
 */
export const ContextProvider = createContext({});

/**
 * The main application component that initializes global settings, context, and routing.
 * It manages user authentication, Google Analytics page view tracking, idle timeout, GDPR consent, and screenshot prevention.
 * The component also wraps the entire app in a theme provider to apply consistent styles.
 *
 * @function
 * @name App
 *
 * @returns {JSX.Element} The main application component, including the context provider, routes, and various global functionality like idle timeout and GDPR consent.
 *
 * @example
 * // Usage of the App component:
 * return (
 *   <App />
 * );
 */
export default function App() {
    // State to store user data and login status
    const [user, setUser] = useState({});
    const [loggedIn, setLoggedIn] = useState(false);
    const location = useLocation(); // Hook to access current location (URL path) for routing

    /**
     * Fetches user data from the backend and updates the user state.
     * The user data is stored in the `user` state variable and can be used across components.
     *
     * @async
     * @function fetchUser
     * @returns {Promise} The axios response from the backend.
     */
    const fetchUser = async () => {
        const response = await axios.get('/auth/user');
        setUser(response.data.user); // Set the user data in state
        return response; // Return the response for further use
    };

    /**
     * Effect hook that triggers every time the page URL (pathname) changes.
     * This sends a page view event to Google Analytics to track page views.
     *
     * @effect
     * @returns {void}
     */
    useEffect(() => {
        // Send the pageview hit to GA4
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    }, [location.pathname]); // Triggered on path change

    usePreventScreenshot(); // Custom hook to prevent screenshots (assuming the hook sets up event listeners for this functionality)

    return (
        <ThemeProvider>
            {/* Provide user data, authentication state, and relevant functions through context */}
            <ContextProvider.Provider value={{ user, fetchUser, loggedIn, setLoggedIn, setUser }}>
                {/* Layout component to handle scroll positioning during navigation */}
                <NavigationScroll>
                    {/* Routes component for rendering the app's routes */}
                    <Routes />
                    {/* Idle timeout functionality only shown when the user is logged in */}
                    {loggedIn && <IdleTimeout />}
                    {/* GDPR consent prompt shown if the user is logged in and has not accepted the consent */}
                    {!user.consentAccepted && loggedIn && <GDPR />}
                </NavigationScroll>
            </ContextProvider.Provider>
        </ThemeProvider>
    );
}
