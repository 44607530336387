

import { Box, Card as MuiCard, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

/**
 * @module Components/FeatureComponent/CreateDistributionCard
 * @description A styled card component for selecting beneficiaries or assets in the distribution modal.
 * The card visually indicates whether it is selected and triggers a callback on selection.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {string} props.name - The name to display on the card, such as a beneficiary's name.
 * @param {boolean} props.isSelected - Indicates whether the card is currently selected.
 * @param {Function} props.onSelect - Callback function triggered when the card is clicked.
 * Receives the updated selection state as an argument.
 *
 * @returns {JSX.Element} The CreateDistributionCard component.
 */

const StyledCard = styled(MuiCard)(({ theme, isSelected }) => ({
    padding: '8px 16px',
    maxWidth: '300px',
    borderRadius: '10px',
    backgroundColor: isSelected ? '#FFF3EA' : '#F7F7F7',
    border: isSelected ? '1px solid #FFD5B5' : '1px solid transparent',
    cursor: 'pointer',
    transition: 'background-color 0.3s, border 0.3s',
    boxShadow: 'none',
}));

const CreateDistributionCard = ({ name, isSelected, onSelect }) => {
    const theme = useTheme();

     /**
     * Handles click events on the card.
     * Toggles the selection state and calls the `onSelect` callback.
     */
    const handleClick = () => {
        onSelect(!isSelected);
    };
    return (
        <StyledCard isSelected={isSelected} onClick={handleClick}>
            <Typography variant='bodyText3' color={theme.palette.grey[600]}>{name}</Typography>
        </StyledCard>
    );
};

export default CreateDistributionCard;
