/**
 * @module Components/PhoneField
 * 
 * @description The `PhoneField` component is a custom input field that allows users to input a phone number 
 * along with a country dial code. It displays a dropdown to select a country, which automatically
 * updates the country code and dial number. This field integrates with Material UI's design system 
 * for consistent UI styling and behavior.
 * 
 * @component
 * 
 * @example
 * <PhoneField 
 *   name="phone" 
 *   value={phoneValue} 
 *   handleChange={handlePhoneChange} 
 *   onBlur={handleBlur} 
 *   error={phoneError} 
 *   helperText={phoneHelperText}
 *   placeholder="Enter phone number"
 * />
 * 
 * @param {Object} props - The properties for the component.
 * @param {string} props.name - The name of the input field, used for identifying the field.
 * @param {string} props.value - The current value of the phone number input, formatted as `countryCode:countryDial:phoneNumber`.
 * @param {function} props.handleChange - The callback function to handle changes to the input value.
 * @param {function} props.onBlur - The callback function that is triggered when the input loses focus.
 * @param {boolean} props.error - Indicates whether the input has an error.
 * @param {string} props.helperText - The text to display below the input field when there is an error.
 * @param {boolean} [props.fullWidth=true] - Whether the input field should take up the full width.
 * @param {string} [props.type="number"] - The type of the input field.
 * @param {boolean} [props.disabled=false] - Whether the input field is disabled.
 * @param {string} [props.placeholder] - Placeholder text for the input field.
 * @param {Object} [props.sx] - Additional custom styles for the component.
 * 
 * @returns {JSX.Element} The rendered `PhoneField` component with an input field for phone numbers, 
 * including a country dial code selector.
 */

import { Box, Button, Popper, Typography, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import 'react-phone-input-material-ui/lib/style.css';
import rawCountries from 'utils/rawCountries';
import { CustomAutocomplete } from './DesignSystem/AutoComplete/CustomAutocomplete';
import InputField from './DesignSystem/InputField';

// Extract countries and their details from the raw data
const countries = rawCountries.map((c) => ({ country: c[0], code: c[2], dial: c[3] })).filter((c) => !!c.dial);

const PhoneField = ({
    name,
    value,
    handleChange,
    onBlur,
    error,
    helperText,
    fullWidth = true,
    type = 'number',
    disabled = false,
    placeholder,
    sx,
}) => {
    const [val, setVal] = useState(value); // State to store the phone number input
    const [anchorEl, setAnchorEl] = useState(null); // State to store the anchor element for dropdown
    const [open, setOpen] = useState(false); // State to control dropdown visibility
    const theme = useTheme(); // Access Material UI theme for consistent styling

    const [country, selectCountry] = useState({
        country: 'India', // Default country
        code: 'in', // Default country code
        dial: '91', // Default country dial code
    });

    const dropdownRef = useRef(null); // Reference to the dropdown menu for detecting clicks outside

    /**
     * Handle clicks outside the dropdown to close it.
     * @param {Event} event - The event object from the click.
     */
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpen(false)
            setAnchorEl(false)
        }
    };

    // Effect to add/remove event listener for clicks outside the dropdown
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Synchronize local state with the `value` prop from the parent
    useEffect(() => {
        setVal(value ? value.split(':')[2] : '');
        const code = value ? value.split(':')[0] : 'in';
        selectCountry(
            countries.find((c) => c.code === code) ?? {
                country: 'India',
                code: 'in',
                dial: '91',
            }
        );
    }, [value]);

    /**
     * Toggle the visibility of the country dropdown.
     * @param {Event} event - The event object from the button click.
     */
    const toggleMenu = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setOpen((o) => !o);
    };

    /**
     * Handle changes to the phone number input.
     * @param {Event} e - The input change event.
     */
    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        const phone = inputValue ? `${country.code}:${country.dial}:${inputValue}` : '';
        setVal(inputValue);
        handleChange({ target: { name, value: phone } });
    };

    return (
        <Box className={'react-tel-input'} sx={{ width: '100%', input: { pl: '0!important', }, '.flag': { pl: 0 }, ...sx }}>
            <InputField
                id={name}
                name={name}
                value={val}
                onChange={handleInputChange}
                onBlur={onBlur}
                type={type}
                disabled={disabled}
                placeholder={placeholder}
                variant="outlined"
                fullWidth={fullWidth}
                error={error}
                helperText={helperText}
                InputProps={{
                    startAdornment: (
                        <Box>
                            <Button
                                disabled={disabled}
                                size={'small'}
                                startIcon={<div className={`flag ${country.code}`} />}
                                variant={'text'}
                                sx={{ color: 'black', padding: 0, paddingRight: '16px' }}
                                onClick={toggleMenu}
                            >
                                <Typography variant="bodyText3" color={theme.palette.grey[600]} >
                                    +{country.dial}
                                </Typography>
                            </Button>

                        </Box>
                    ),
                }}
            />
            <Popper sx={{ zIndex: 1301 }} placement={'auto'} open={open} anchorEl={anchorEl} ref={dropdownRef}>
                <Box sx={{ width: 300, background: 'white' }}>
                    <CustomAutocomplete
                        open
                        placeholder='Search'
                        getOptionLabel={(option) => option.country}
                        value={country}
                        onChange={(e, v) => {
                            selectCountry(
                                v ?? {
                                    country: 'India',
                                    code: 'in',
                                    dial: '91',
                                }
                            );
                            const updatedValue = `${v?.code}:${v?.dial}:${val}`;
                            setVal(val); // Update local state to reflect the change
                            handleChange({ target: { name, value: updatedValue } });
                            toggleMenu(e);
                        }}
                        options={countries}
                    />
                </Box>
            </Popper>
        </Box>
    );
};

export default PhoneField;
