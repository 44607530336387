/**
 * @module Pages/ProfileProcessPages/ExistingWillDetailsPage
 * @description This module defines the ExistingWillDetailsPage component. The component renders a form to upload an existing Will.
 * It allows the user to upload a Will file, specify if the Will is registered, and provide additional details if applicable.
 * The form is validated using Yup, and Formik is used for form handling. The component also includes functionality for file upload,
 * conditional fields for registration details, and SweetAlert notifications for success or failure of the upload process.
 */
import { Box, Grid, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { ContextProvider } from 'App';
import { ReactComponent as HelpIcon } from 'assets/help-button.svg';
import ActionButtons from 'components/actionsButtons';
import BackButtonWithDivider from 'components/DesignSystem/BackButtonWithDivider';
import Icon from 'components/DesignSystem/Icon';
import RadioField from 'components/DesignSystem/RadioGroup';
import useSwalAlert from 'components/DesignSystem/SwalAlert';
import HelpSection from 'components/featureComponent/helpResourceSection/HelpResourceSection';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { uploadWillApi } from 'services/profileService';
import formValidation from 'utils/helper/formValidations';
import * as Yup from 'yup';
import DateInput from '../../components/DateInput';
import { ContainedButton1 } from '../../components/DesignSystem/Button';
import InputField from '../../components/DesignSystem/InputField';
import FileUpload from '../../components/fileUpload';

/**
 * ExistingWillDetailsPage component.
 *
 * The ExistingWillDetailsPage component renders a form to upload an existing Will, with additional fields for registration details
 * if the Will is registered. The form handles the signing date, registration details, and file upload, with validation provided
 * by Formik and Yup. Upon submission, the Will file is uploaded to the server, and SweetAlert notifications are displayed based
 * on the result of the upload process.
 *
 * @returns {JSX.Element} The JSX element of the ExistingWillDetailsPage component.
 */
const ExistingWillDetailsPage = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state
    const { user, fetchUser } = useContext(ContextProvider);
    const showSwalAlert = useSwalAlert();
    const handleClose = () => setOpen(false);

    /**
     * Initial form values for the Existing Will form.
     */
    const initialValues = {
        signing_date: null,
        registrar_place: '',
        registrar_number: '',
        serial_number: '',
        file: null,
        isRegistered: null,
    };

    /**
     * Yup validation schema for the Existing Will form.
     */
    const validationSchema = Yup.object().shape({
        signing_date: formValidation.dateValidation.required('required field'),
        file: Yup.mixed().required('File is required'),
        isRegistered: Yup.boolean().required('required field'),

        // Apply when conditionally only if isRegistered is true
        registrar_place: Yup.string()
            .nullable()
            .when('isRegistered', {
                is: true,
                then: () => formValidation.registrarPlaceValidation,
            }),
        registrar_number: Yup.string()
            .nullable()
            .when('isRegistered', {
                is: true,
                then: () => formValidation.numberValidation.required('required field'),
            }),
        serial_number: Yup.string()
            .nullable()
            .when('isRegistered', {
                is: true,
                then: () => formValidation.numberValidation.required('required field'),
            }),
    });

    /**
     * Formik hook for managing form state and submission.
     */
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true); // Set loading to true
                // Convert file to base64
                const base64File = await convertFileToBase64(values.file);

                // Prepare payload
                const WillData = {
                    file: base64File,
                    signed_at: values.signing_date,
                    registered_with: values.isRegistered ? values.registrar_place : '', // Set to empty if not registered
                    registered_office_number: values.isRegistered ? values.registrar_number : '', // Set to empty if not registered
                    serial_number: values.isRegistered ? values.serial_number : '', // Set to empty if not registered
                };

                // Send the data to the API
                await uploadWillApi({ will: WillData });

                // Show SweetAlert2 notification and wait for it to close
                await showSwalAlert({
                    title: 'Will uploaded successfully',
                    icon: 'success',
                    timer: 1500,
                });

                // Reset form and navigate to another page
                navigate('/profileCompletion');
                formik.resetForm();
            } catch (err) {
                // Show SweetAlert2 notification and wait for it to close
                await showSwalAlert({
                    title: 'Failde to upload Will',
                    icon: 'error',
                    timer: 1500,
                });
                console.log('Failed to upload file');
            } finally {
                setLoading(false); // Reset loading state
            }
        },
    });

    /**
     * Convert the file to base64 format.
     * @param {File} file The file to be converted.
     * @returns {Promise<string>} A promise that resolves to the base64 encoded string.
     */
    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    /**
     * Handle the change in the 'Is the Will registered?' radio button.
     * @param {Object} event The event triggered by the radio button change.
     */
    const radioOptions = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
    ];

    const handleRadioChange = (event) => {
        const { value } = event.target;
        const isRegistered = value === 'true'; // Determine if registered
        formik.setFieldValue('isRegistered', isRegistered); // Update Formik's state
    };

    return (
        <>
            <Helmet>
                <title> Existing Will | Get Will Done </title>
            </Helmet>
            <Grid container>
                <Grid item xs={12} sm={3} md={2} lg={2} xl={2} alignItems={'center'} sx={{ pt: '2%', pl: '2%' }}>
                    <Icon name="will-logo.svg" width={'65%'} height={'45%'} />
                </Grid>
                <Grid item sm={9} md={10} lg={10} xl={10}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid
                            container
                            sx={{
                                maxWidth: 850,
                                height: '100%',
                                paddingX: '5%',
                                paddingY: '5%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <Box>
                                <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', width: '100%' }}>
                                    <BackButtonWithDivider />
                                    <Typography variant="title1" gutterBottom>
                                        Existing Will
                                    </Typography>
                                </Box>

                                <Stack spacing={0.7} mt={2}>
                                    <Typography variant="bodyText3" sx={{ color: theme.palette.grey[400] }}>
                                        Do you have an existing Will, {user.profile?.first_name}? If so, please upload it below.
                                    </Typography>
                                    <Typography variant="bodyText3" sx={{ color: theme.palette.grey[400] }}>
                                        If not, that is okay. You can proceed to the final step.
                                    </Typography>
                                </Stack>

                                <Grid container spacing={3} mt={1.5}>
                                    <Grid item xs={12} md={7} sm={12}>
                                        <DateInput
                                            name="signing_date"
                                            value={formik.values.signing_date}
                                            label="Signing Date"
                                            onChange={(value) => formik.setFieldValue('signing_date', value)}
                                            helperText={formik.touched.signing_date && formik.errors.signing_date}
                                            error={formik.touched.signing_date && Boolean(formik.errors.signing_date)}
                                            onBlur={formik.handleBlur}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <RadioField
                                            label="Is the Will registered?"
                                            name="isRegistered"
                                            value={formik.values.isRegistered}
                                            onChange={handleRadioChange}
                                            options={radioOptions}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.isRegistered && Boolean(formik.errors.isRegistered)}
                                            helperText={formik.touched.isRegistered && formik.errors.isRegistered}
                                        />
                                    </Grid>

                                    {formik.values.isRegistered && (
                                        <>
                                            <Grid item xs={12}>
                                                <InputField
                                                    fullWidth
                                                    name="registrar_place"
                                                    placeholder="Sub-Registrar's Office Place*"
                                                    value={formik.values.registrar_place}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.registrar_place && Boolean(formik.errors.registrar_place)}
                                                    helperText={formik.touched.registrar_place && formik.errors.registrar_place}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <InputField
                                                    fullWidth
                                                    name="registrar_number"
                                                    placeholder="Sub-Registrar's Office Number*"
                                                    value={formik.values.registrar_number}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.registrar_number && Boolean(formik.errors.registrar_number)}
                                                    helperText={formik.touched.registrar_number && formik.errors.registrar_number}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <InputField
                                                    fullWidth
                                                    name="serial_number"
                                                    placeholder="Serial Number*"
                                                    value={formik.values.serial_number}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.serial_number && Boolean(formik.errors.serial_number)}
                                                    helperText={formik.touched.serial_number && formik.errors.serial_number}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12}>
                                        <FileUpload
                                            name="file"
                                            label="Upload File"
                                            accept="application/pdf"
                                            maxFileSize={10485760} // 10MB
                                            value={formik.values.file}
                                            onChange={(file) => formik.setFieldValue('file', file)}
                                            error={formik.errors.file && formik.touched.file ? formik.errors.file : null}
                                        />
                                    </Grid>
                                </Grid>

                                <Box sx={{ display: 'flex', marginTop: 5 }}>
                                    <ContainedButton1
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        sx={{ width: 138, height: 45, marginRight: 2 }}
                                        disabled={!(formik.isValid && formik.dirty) || loading} // Disable if loading
                                    >
                                        <Typography variant="bodyText3">Save</Typography>
                                    </ContainedButton1>
                                    <ContainedButton1
                                        color="secondary"
                                        sx={{ width: 138, height: 45 }}
                                        onClick={() => navigate('/profileCompletion')}
                                        disabled={(formik.isValid && formik.dirty) || loading} // Disable if loading
                                    >
                                        <Typography variant="bodyText3">Do it Later</Typography>
                                    </ContainedButton1>
                                </Box>
                            </Box>
                        </Grid>
                    </form>
                </Grid>
            </Grid>

            <ActionButtons />
            <IconButton
                sx={{
                    position: 'fixed',
                    bottom: '3.125rem',
                    right: '3.125rem',
                    bgcolor: '#FFF3E0',
                    color: '#FF8C42',
                    '&:hover': {
                        bgcolor: '#FFE0B2',
                    },
                }}
                onClick={() => setOpen(true)}
            >
                <HelpIcon />
            </IconButton>
            <HelpSection open={open} handleClose={handleClose} />
        </>
    );
};

export default ExistingWillDetailsPage;
