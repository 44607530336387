/**
 * @module Theme/GlobalStyles
 * 
 * @description This module defines global styles for the application using Material UI's `GlobalStyles` component.
 * It applies various default styles to ensure consistent layout behavior and appearance across different browsers and screen sizes.
 * These global styles reset certain properties like margins, paddings, and ensure consistent display behavior for elements like images, inputs, and lists.
 * 
 */

import { GlobalStyles as MUIGlobalStyles } from '@mui/material';

/**
 * A functional component that renders Material UI's `GlobalStyles` component.
 * This component applies the global styles to the application, ensuring that certain elements 
 * like `html`, `body`, `input`, and `img` follow consistent styling rules.
 * 
 * @function
 * @name GlobalStyles
 * 
 * @returns {JSX.Element} The JSX element containing Material UI's `GlobalStyles` with the specified styles.
 * This element applies global CSS styles to the application.
 * 
 * @example
 * // Usage of the GlobalStyles component:
 * return (
 *   <div>
 *     <GlobalStyles />
 *     <YourComponent />
 *   </div>
 * );
 */
export default function GlobalStyles() {
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
      }}
    />
  );

  return inputGlobalStyles;
}
