import axios from "axios";
/**
 * Updates the profile information of a testator.
 * @module profileService/updateProfileApi
 * @description
 * This function updates the profile details of a testator by sending a PATCH request 
 * to the `/testator` endpoint with the provided profile data.
 * @param {Object} ProfileData - An object containing the profile details to be updated.
 * @param {string} ProfileData.name - The updated name of the testator.
 * @param {string} ProfileData.email - The updated email address of the testator.
 * @param {string} [ProfileData.phone] - The updated phone number of the testator (optional).
 * @param {string} [ProfileData.address] - The updated address of the testator (optional).
 * @returns {Promise<Object>} The API response after updating the profile.
 */
export const updateProfileApi = async (ProfileData) => {
    const response = axios.patch(`/testator`, ProfileData)
    return response;
};

/**
 * Fetches data based on ZIP code and country code.
 * @module profileService/fetchDataFromZip
 * @description
 * This function retrieves data using a ZIP code and a country code by making a GET request 
 * to the `/testator/{countryCode}/{zip}` endpoint. The request includes a timeout of 8 seconds.
 * If the request is successful, the function returns the response data. If an error occurs, 
 * it logs the error to the console.
 * @param {Object} params - The parameters for the API request.
 * @param {string} params.zip - The ZIP code for the location.
 * @param {string} params.countryCode - The ISO 3166-1 alpha-2 country code (e.g., "US", "IN").
 * @returns {Promise<Object|undefined>} The response data from the API or `undefined` if an error occurs.
 */
export const fetchDataFromZip = async ({ zip, countryCode }) => {
    return axios.get(`/testator/${countryCode}/${zip}`, {
        timeout: 8000
    }).then(response => {
        return response.data;
    }).catch((err) => {
        console.error(err);
    });
};

/**
 * Uploads will data to the server.
 * @module profileService/uploadWillApi
 * @description
 * This function sends will-related data to the server for storage or processing. 
 * It makes a POST request to the `/testator/will` endpoint with the provided will data.
 * @param {Object} WillData - The will data to be uploaded.
 * @param {string} WillData.testatorName - Name of the testator.
 * @param {string} WillData.creationDate - Date when the will was created.
 * @param {string} WillData.details - Details of the will.
 * @param {number} [WillData.value] - Optional value or worth associated with the will.
 * @returns {Promise<Object>} The API response confirming the upload of the will.
 */
export const uploadWillApi = async (WillData) => {
    const response = await axios.post('/testator/will', WillData);
    return response;
}

/**
 * Deletes the testator's account.
 * @module profileService/deleteAccountApi
 * @description
 * This function sends a DELETE request to remove the testator's account from the server. 
 * The operation is irreversible and permanently deletes the associated account and its data.
 * @returns {Promise<Object>} The API response confirming the account deletion.
 */
export const deleteAccountApi = async () => {
    const response = await axios.delete(`/testator/account`);
    return response;
}

/**
 * Logs out the currently authenticated user.
 * @module profileService/logoutUserApi
 * @description
 * This function sends a POST request to the `/auth/logout` endpoint to log out the 
 * currently authenticated user. It invalidates the user's session and clears authentication tokens on the server.
 * @returns {Promise<Object>} The API response confirming the logout status.
 */
export const logoutUserApi = async () => {
    const response = await axios.post('/auth/logout');
    return response;
}

/**
 * Updates the profile saved status.
 * @module profileService/updateProfileSaved
 * @description
 * This function updates the profile saved status of a testator by sending a PATCH request 
 * to the `/testator/profile-saved` endpoint. It is used to mark the testator's profile as saved.
 * @returns {Promise<Object>} The API response indicating the status of the update operation.
 */
export const updateProfileSaved = async () => {
    const response = axios.patch(`/testator/profile-saved`)
    return response;
};
