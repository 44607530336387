/**
 * @module ServiceDetailCard
 * @description This module represents a service detail card on the service page. It displays the title, subtitle, 
 * pricing information, subscription status, and offers the user the option to select a subscription. It also provides 
 * messages if the user has an active call or review.
 */
import { Box, Button, Typography, useTheme, Stack } from '@mui/material';
import ButtonCheckBox from 'components/DesignSystem/ButtonCheckBox';
import { format } from 'date-fns';
import { formatPrice } from 'utils/formatPrice';

/**
 * ServiceDetailCard component is used to display a service's details, including its title, subtitle, price, 
 * and offer information. It also allows users to select a subscription if available and handles showing 
 * relevant messages about active calls or reviews.
 * 
 * @component
 * @param {Object} props - The props for the component
 * @param {string} [props.title=''] - The title of the service
 * @param {string} [props.subtitle=''] - The subtitle providing additional information about the service
 * @param {string} [props.price=''] - The regular price of the service
 * @param {Object} props.row - The individual service object containing details such as discounted price and id
 * @param {Array} props.checkedSubscriptions - List of subscriptions that the user has checked or selected
 * @param {Function} props.onPurchaseClick - Function that handles the action of purchasing or selecting a subscription
 * @param {Object} props.user - The current user object, containing details such as role and subscription status
 * @param {boolean} props.isCheckBoxVisible - Determines if the checkbox for subscription selection should be visible
 * @param {boolean} props.activeCall - Whether the user has an active call or not
 * @param {boolean} props.activeReview - Whether the user has an active review or not
 * 
 * @returns {JSX.Element} The rendered ServiceDetailCard component
 */
const ServiceDetailCard = ({ title = '', subtitle = '',
    price = '',
    row,
    checkedSubscriptions,
    onPurchaseClick,
    user,
    isCheckBoxVisible,
    activeCall,
    activeReview
}) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                mb: 3,
                // width: '718px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '15px',
                backgroundColor: theme.palette.secondary.lighter,
                border: `1px solid ${theme.palette.secondary.lighter}`,
                borderRadius: '10px',
            }}
        >
            {/* Left Content */}
            <Box>
                <Typography variant="bodyText3" color="#1E1C1B">
                    {title}
                </Typography>
                <Typography variant="suggestiveText" color={theme.palette.grey[400]}>
                    <Box component="span" sx={{ display: 'block' }}>
                        {subtitle}
                    </Box>
                </Typography>
            </Box>

            {/* Right Content (Price and Add Button) */}
            <Box sx={{ display: 'flex', alignItems: 'center', mx: 2 }}>
                <Stack justifyContent={"center"} spacing={1} direction="row" >
                    {row.discounted_price > -1 ? <strike><Typography variant="bodyText4" color="#1E1C1B" sx={{ mr: 2 }}>₹{price === 0 ? "Free" : formatPrice(price)}</Typography></strike> :
                        <Typography variant="bodyText4" color="#1E1C1B" sx={{ mr: 2 }}>{price === 0 ? "Free" : `₹${formatPrice(price)}`}</Typography>}
                </Stack>

                {row.discounted_price > -1 &&
                    <Stack justifyContent={"center"} direction="row">
                        {row.price > 0 && <Typography variant="bodyText4" color="#1E1C1B" sx={{ mr: 2 }}>Offer Price: ₹</Typography>}
                        <Typography variant="bodyText4" color="#1E1C1B" sx={{ mr: 2 }}
                        >{row.discounted_price === 0 ? "Free" : formatPrice(row.discounted_price)}</Typography>
                    </Stack>}
                {(isCheckBoxVisible && <ButtonCheckBox
                    checked={checkedSubscriptions.some((checkedSubscription) => checkedSubscription.id === row.id)}
                    onChange={() => {
                        onPurchaseClick(row);
                    }}
                    disabled={user.role === 'partner' && user.partner_enroll_status !== 'APPROVED'}
                    bgColor='transparent'
                />)}
                {row.calls > 0 && activeCall &&
                    <Typography variant="suggestiveText" textAlign={"center"} color={"error.main"}>You already have an active
                        call. Please wait for our call.</Typography>}
                {(activeReview && row.id === 2) && (
                    <Typography variant="suggestiveText" textAlign={"center"} color={"error.main"}>You already have an active
                        review. Please wait for our call.</Typography>
                )}
            </Box>
        </Box>
    );
};

export default ServiceDetailCard;
