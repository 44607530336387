import { Box, Dialog, DialogContent, Typography, useTheme, DialogTitle, DialogActions } from '@mui/material';
import { noop } from 'lodash';
import { ContainedButton1, TextedButton1 } from '../Button';

/**
 * @module Components/DesignSystem/AlertDialog
 * @description This module contains the AlertDialog component used across the application.
 */
/**
 * AlertDialog is a customizable dialog component used to display alerts or confirmation messages.
 * It supports multiple button configurations, allowing for either one or two action buttons, 
 * and can display dynamic content passed via children.
 * 
 * @component
 * @param {boolean} open - Controls the visibility of the dialog.
 * @param {function} onClose - Callback function triggered when the dialog is closed.
 * @param {string} modelTitle - The title displayed in the dialog header, typically used for alert/confirmation title.
 * @param {string} title - The main content or message of the dialog.
 * @param {string} [primaryButtonLabel='Keep'] - The label of the primary button, default is 'Keep'.
 * @param {string} [secondaryButtonLabel='Delete'] - The label of the secondary button, default is 'Delete'.
 * @param {function} [onPrimaryButtonClick=noop] - Callback function triggered when the primary button is clicked. Default is a no-op function.
 * @param {function} onSecondaryButtonClick - Callback function triggered when the secondary button is clicked.
 * @param {boolean} [onSecondaryButtonDisabled=false] - Controls whether the secondary button is disabled.
 * @param {string} [dialogWidth='auto'] - Defines the width of the dialog (can be set to any valid CSS width value).
 * @param {React.ReactNode} children - The content to be displayed inside the dialog. Can include any JSX elements.
 * @param {boolean} [isSingleButton=false] - If true, only the primary button will be displayed. If false, both primary and secondary buttons are displayed.
 * @param {Object} [titleSx={}] - Custom styling to be applied to the title section of the dialog.
 * 
 * @returns {JSX.Element} A Dialog component that displays an alert message with optional buttons and custom content.
 */

const AlertDialog = ({
    open,
    onClose,
    modelTitle,
    title,
    primaryButtonLabel = 'Keep',
    secondaryButtonLabel = 'Delete',
    onPrimaryButtonClick = noop,
    onSecondaryButtonClick,
    onSecondaryButtonDisabled = false,
    dialogWidth = 'auto',
    children,
    isSingleButton = false,
    titleSx = {},
}) => {
    const theme = useTheme();
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    border: `1px solid red`,  // Red border around the dialog
                    borderRadius: '35px',     // Rounded corners for the dialog
                    minWidth: dialogWidth,    // Minimum width of the dialog
                },
            }}
        >
            {/* Display modelTitle if provided */}
            {(modelTitle && <DialogTitle sx={{ m: 0, p: 2, backgroundColor: theme.palette.error.main, color: '#fff' }} textAlign='center' id="customized-dialog-title">
                <Typography variant="heading1">
                    {modelTitle}
                </Typography>
            </DialogTitle>)}
            <DialogContent sx={{ padding: '32px 40px', textAlign: 'center' }}>
                {/* Title section with custom styling */}

                <Box sx={{ marginBottom: '24px', marginTop: '16px', ...titleSx }}>
                    <Typography variant="heading2">
                        {title}
                    </Typography>
                </Box>
                {/* Display children content if provided */}
                {children && (<Box sx={{ color: "black", maxHeight: "calc(100vh - 100px)", width: 'calc(100% + 16px)', m: -1, pr: 1, overflow: 'auto' }}>
                    {children}
                </Box>)}
            </DialogContent>
            {/* DialogActions section with buttons */}
            {onSecondaryButtonClick && (<DialogActions sx={{ display: 'flex', justifyContent: 'center', gap: '16px', marginBottom: '20px' }}>
                <ContainedButton1
                    sx={{
                        background: theme.palette.error.main,
                        width: '160px',
                        height: '45px',
                        '&:hover': {
                            backgroundColor: `${theme.palette.error.main}`,
                            boxShadow: '0px 6px 6px -4px rgba(244, 123, 32, 0.3)',
                        },
                    }}
                    onClick={onPrimaryButtonClick}
                >
                    <Typography variant='bodyText3' color={theme.palette.common.white}>
                        {primaryButtonLabel}
                    </Typography>
                </ContainedButton1>
                <TextedButton1 onClick={onSecondaryButtonClick} disabled={onSecondaryButtonDisabled} sx={{ width: '160px', height: '45px' }}>
                    <Typography variant='bodyText3' color={theme.palette.error.main}>
                        {secondaryButtonLabel}
                    </Typography>
                </TextedButton1>
            </DialogActions>)}

            {/* Single button case */}
            {isSingleButton && (<DialogActions sx={{ display: 'flex', justifyContent: 'center', gap: '16px', marginBottom: '20px' }}>
                <ContainedButton1
                    sx={{
                        background: theme.palette.error.main,
                        width: '160px',
                        height: '45px',
                        '&:hover': {
                            backgroundColor: `${theme.palette.error.main}`,
                            boxShadow: '0px 6px 6px -4px rgba(244, 123, 32, 0.3)',
                        },
                    }}
                    onClick={onPrimaryButtonClick}
                >
                    <Typography variant='bodyText3' color={theme.palette.common.white}>
                        {primaryButtonLabel}
                    </Typography>
                </ContainedButton1>
            </DialogActions>)}
        </Dialog >
    );
};

export default AlertDialog;
