import { Divider, IconButton, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Icon from '../Icon';

/**
 * @module Components/DesignSystem/BackButtonWithDivider
 * @description This module contains the BackButtonWithDivider component used across the application.
 */
/**
 * BackButtonWithDivider component that provides a back button with a divider on the same line.
 * Clicking the back button navigates the user to the previous page in the browser history.
 *
 * @component
 * @example
 * <BackButtonWithDivider />
 *
 * @returns {JSX.Element} A BackButtonWithDivider component with a back button and a divider.
 */

const BackButtonWithDivider = () => {
    const theme = useTheme(); // Custom theme for styling
    const navigate = useNavigate(); // React Router hook for navigation

    /**
     * Handles the click event for the back button and navigates to the previous page.
     */
    const handleBackClick = () => {
        navigate(-1); // Navigates back to the previous page in history
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', padding: '16px 0', width: '100%' }}>
            {/* Back button */}
            <IconButton onClick={handleBackClick} sx={{ background: theme.palette.grey[200] }}>
                <Icon name="back-arrow-icon.png" width={6.5} height={9} />
            </IconButton>
            {/* Divider */}
            <Divider
                sx={{ flexGrow: 1, marginLeft: '8px', borderBottomWidth: '1px', borderColor: theme.palette.grey[100] }}
            />
        </div>
    );
};

export default BackButtonWithDivider;
