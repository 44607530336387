/**
 * @module Pages/AdminPages/AdminComponents/WillGenerationTrendsChart
 * @description This module defines the `WillGenerationTrendsChart` component, which fetches and displays the trends of will generation over time. 
 * It allows users to filter the data by predefined time ranges or custom date ranges. The data is visualized using a line chart, and users can apply filters to view trends based on selected dates.
 */
import { Box, Card, FormControl, FormHelperText, Typography, useTheme } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Chart } from 'chart.js';
import { ContainedButton1 } from 'components/DesignSystem/Button';
import CustomDatePicker from 'components/DesignSystem/CustomDatePicker';
import { Dropdown } from 'components/DesignSystem/DropDown';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { matchDataWithDateRange } from 'utils/filterDate';
import { fDate } from 'utils/formatTime';
import * as Yup from 'yup';

/**
 * Options for different time ranges that can be selected for viewing trends.
 * @constant {Array} timeRangeOptions - List of predefined time range options.
 */
const timeRangeOptions = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Last 7 Days', value: 'last7days' },
  { label: 'Last 30 Days', value: 'last30days' },
  { label: 'This Month', value: 'thismonth' },
  { label: 'Custom Range', value: 'custom' },
];

/**
 * WillGenerationTrendsChart component
 * 
 * A component that renders a chart to display trends in will generation based on the selected time range.
 * It also supports a custom date range where users can specify a start and end date.
 * 
 * @component
 */
function WillGenerationTrendsChart() {
  const [timeRange, setTimeRange] = useState('today');
  const [chart, setChart] = useState(null);
  const theme = useTheme();

    /**
   * Formik hook for managing form state (dates).
   * @type {Object}
   * @property {Object} initialValues - The initial form values.
   * @property {Function} validationSchema - Validation schema for the form.
   * @property {Function} onSubmit - The callback function to handle form submission.
   */
  const formik = useFormik({
    initialValues: {
      fromDate: '',
      toDate: '',
    },
    validationSchema: Yup.object().shape({
      fromDate: Yup.date()
        .typeError('Please enter valid date dd-mm-yyyy format')
        .min(dayjs('1900-01-01'), 'Date must be greater than 1900-01-01')
        .max(dayjs().endOf('day'), 'Date must be equal or before today'),
      toDate: Yup.date()
        .typeError('Please enter valid date dd-mm-yyyy format')
        .min(dayjs('1900-01-01'), 'Date must be greater than 1900-01-01')
        .max(dayjs().endOf('day'), 'Date must be equal or before today')
        .test({
          name: 'notBeforeAsFromDate',
          message: 'To Date must be equal or greater than from date',
          test: (toDate, values) => {
            const fromDate = values?.parent?.fromDate;
            return toDate !== null ? dayjs(fromDate).isSameOrBefore(dayjs(toDate), 'day') : false;
          },
        }),
    }),
    onSubmit: (values) => {
      fetchData(values);
    },
  });

    /**
   * Handler for changing the time range.
   * Resets the custom date fields if a predefined time range is selected.
   * 
   * @param {object} event - The change event.
   */
  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
    formik.setValues({
      fromDate: '',
      toDate: '',
    });
  };

    /**
   * Fetches data from the API and updates the chart.
   * 
   * @param {object} dateValues - The selected date range values (fromDate, toDate).
   */
  const fetchData = (dateValues) => {
    const startDate = fDate(dateValues?.fromDate?.$d, 'yyyy-MM-dd');
    const endDate = fDate(dateValues?.toDate?.$d, 'yyyy-MM-dd');

    let url = `/admin/will-generation-trends?filter=${timeRange}`;
    if (timeRange === 'custom') {
      url += `&startDate=${startDate}&endDate=${endDate}`;
    }
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const updatedData = matchDataWithDateRange(startDate, endDate, data, timeRange, 'count');
        const labels = updatedData.map((item) => item.date);
        const counts = updatedData.map((item) => item.count);

        const ctx = document.getElementById('will-generation-chart').getContext('2d');

        if (chart) {
          chart.destroy(); // Destroy the previous chart instance
        }

        const newChart = new Chart(ctx, {
          type: 'line',
          data: {
            labels,
            datasets: [
              {
                label: 'Wills Generated',
                data: counts,
                fill: false,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
                tension: 0.1,
              },
            ],
          },
          options: {
            // aspectRatio: 4.1,
            aspectRatio: 3.18,
            scales: {
              y: {
                // max: 5,
                min: 0,
                ticks: {
                  stepSize: 1,
                },
              },
            },
            plugins: {
              legend: {
                position: 'top', // Change this to 'top', 'left', 'bottom', 'right', or 'chartArea'
                align: 'end', // Aligns the legend to the end (right for 'top')
              },
            },
          },
        });

        setChart(newChart);
      })
      .catch((error) => console.error('Error fetching data:', error));
  };
  /**
   * Hook to fetch data when the component mounts or updates.
   */
  useEffect(() => {
    fetchData();
  }, []);
  /**
   * Handles the filter button click by triggering the form submission.
   */
  const handleFilterClick = () => {
    formik.handleSubmit();
  };
  /**
   * Handles date changes and updates the formik state.
   * 
   * @param {string} value - The selected date value.
   * @param {string} name - The name of the field being updated (fromDate or toDate).
   */
  const handleDateChange = (value, name) => {
    const event = {
      target: {
        name,
        value: value !== null ? dayjs(value) : null,
      },
    };
    formik.handleChange(event);
  };

  return (
    <Card sx={{ marginTop: '60px', boxShadow: 'none' }}>
      <Typography variant="title2" marginBottom="8px">
        Will Generation Trends
      </Typography>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          gap: '16px',
          margin: '16px 0',
          justifyContent: 'flex-start',
          alignItems: 'center',
          alignContent: 'center',
        }}
      >
        <Dropdown value={timeRange} handleChange={handleTimeRangeChange} placeholder="Time Range" options={timeRangeOptions} dropdownheight='100%' dropdownWidth='279px' />
        {timeRange === 'custom' && (
          <Box sx={{ display: 'flex', gap: '16px', width: '100%' }}>
            <FormControl required error={formik.errors.fromDate} fullWidth variant="standard">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CustomDatePicker
                  // label="From Date"
                  value={formik.values.fromDate}
                  name="From Date"
                  error={formik.errors.fromDate}
                  fullWidth
                  inputFormat={'dd-MM-yyyy'}
                  maxDate={dayjs()}
                  onChange={(value) => handleDateChange(value, 'fromDate')}
                  onBlur={formik.handleBlur}
                  placeholder='From Date(DD-MM-YYYY)'
                />
                {formik.touched.fromDate && formik.errors.fromDate && (
                  <FormHelperText error id={`standard-weight-helper-text-fromDate`}>
                    {formik.errors.fromDate}
                  </FormHelperText>
                )}
              </LocalizationProvider>
            </FormControl>
            <FormControl required error={formik.errors.toDate} fullWidth variant="standard">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CustomDatePicker
                  // label="To Date"
                  value={formik.values.toDate}
                  name="toDate"
                  error={formik.errors.toDate}
                  fullWidth
                  inputFormat={'dd-MM-yyyy'}
                  maxDate={dayjs()}
                  onChange={(value) => handleDateChange(value, 'toDate')}
                  onBlur={formik.handleBlur}
                  placeholder='To Date(DD-MM-YYYY)'
                />
                {formik.touched.toDate && formik.errors.toDate && (
                  <FormHelperText error id={`standard-weight-helper-text-toDate`}>
                    {formik.errors.toDate}
                  </FormHelperText>
                )}
              </LocalizationProvider>
            </FormControl>
          </Box>
        )}
        <ContainedButton1 sx={{ height: '40px', width: '10vw' }} onClick={handleFilterClick}>
          <Typography variant='bodyText3' color={theme.palette.common.white}>
            Apply
          </Typography>
        </ContainedButton1>
      </Box>
      <Box sx={{
        textAlign: 'center',
        padding: '20px',
        paddingTop: '44px',
        backgroundColor: theme.palette.grey[200],
        borderRadius: '15px'
      }}>
        <canvas id="will-generation-chart" />
      </Box>
    </Card>
  );
}

export default WillGenerationTrendsChart;
