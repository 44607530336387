// This will include .svg, .png, .jpg, and .jpeg files from the assets/images directory
const iconsContext = require.context('assets/', true, /\.(svg|png|jpg|jpeg)$/);

/**
 * Fetches the source of an icon from the assets directory or returns the given URL.
 * If the icon name doesn't match any available icon in the assets, it returns an empty string.
 *
 * @param {string} iconName - The name of the icon file to fetch from the assets/images directory.
 * @returns {string} The source of the icon, either a path from assets or the URL if an HTTP link is provided.
 */
const _getIconFromAssets = (iconName) => {
  let iconSource = '';
  try {
    if (iconName.match(/http/i)) {
      iconSource = iconName;
    } else {
      iconSource = iconsContext(`./${iconName}`);
    }
  } catch (err) {
    console.error(err);
  }
  return iconSource;
};

/**
 * Returns the source of an icon. The priority of getting the icon source is as follows:
 * 1. If `src` is provided, it returns `src`.
 * 2. If `iconName` is provided, it looks for the icon in the assets directory.
 * 3. If neither `src` nor `iconName` are provided, it returns the `fallBackImageSrc`.
 *
 * @param {Object} params - The parameters object.
 * @param {string} [params.src] - The URL of the icon image.
 * @param {string} [params.iconName] - The name of the icon file to fetch from the assets/images directory.
 * @param {string} [params.fallBackImageSrc] - The fallback image URL in case neither `src` nor `iconName` is available.
 * @returns {string} The resolved icon source, either from `src`, assets, or the fallback source.
 */
export const getIconSource = ({ src, iconName, fallBackImageSrc }) => {
  let newSource = '';
  if (src) {
    newSource = src;
  } else if (iconName) {
    newSource = _getIconFromAssets(iconName);
  } else {
    newSource = fallBackImageSrc;
  }
  return newSource;
};
