/**
 * @module Pages/AdminPages/AdminLandingPage
 * @description This module defines the `AdminLandingPage` component, which is the main dashboard for the admin interface. 
 * It presents a variety of statistics and visualizations related to user, revenue, and will generation data. 
 * The page is responsive and adjusts its layout based on the screen size.
 */
import { useTheme } from "@emotion/react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import PageContainer from "views/dashboard/PageContainer";
import AgeDemographicChart from "./adminComponents/AgeDemographicChart";
import AverageTimeCard from "./adminComponents/AverageTimeCard";
import CompleteWillsCard from "./adminComponents/CompleteWillsCard";
import ConversionRatioCard from "./adminComponents/ConversionRatioCard";
import IncompleteWillsCard from "./adminComponents/IncompleteWillsCard";
import PartnerVsDirectRevenueChart from "./adminComponents/PartnerVsDirectRevenueChart";
import RepeatRateCard from "./adminComponents/RepeatRateCard";
import RevenueTrendsCart from "./adminComponents/RevenueTrendsChart";
import ServiceWiseRevenue from "./adminComponents/ServiceWiseRevenue";
import TopPerformingPartnerCard from "./adminComponents/TopPerformingPartnerCard";
import TotalCommissionPaidCard from "./adminComponents/TotalCommissionPaidCard";
import TotalGstPaidCard from "./adminComponents/TotalGstPaidCard";
import TotalNumberOfClientsCard from "./adminComponents/TotalNumberOfClientsCard";
import TotalNumberOfPartnersCard from "./adminComponents/TotalNumberOfPartnersCard";
import TotalRevenueCard from "./adminComponents/TotalRevenueCard";
import WillGenerationTrendsChart from "./adminComponents/WillGenerationTrendsChart";
import UserJourney from "./adminComponents/UserJourney";

/**
 * AdminLandingPage component
 * 
 * The `AdminLandingPage` component serves as the primary dashboard for the admin interface. 
 * It displays various statistical cards and charts that give insights into the number of clients, partners, revenue, 
 * will generation trends, and other key metrics.
 * 
 * The layout is responsive, adjusting the number of columns in the grid based on the screen size (small, medium, or large).
 * It also includes a `Helmet` component to set the page title and meta information.
 * 
 * @component
 */
const AdminLandingPage = () => {
    const theme = useTheme();
    const navigate = useNavigate();
     // Media queries to determine screen sizes for responsive layout
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isMediumScreen = useMediaQuery('(min-width:601px) and (max-width:960px)');
    const isLargeScreen = useMediaQuery('(min-width:961px)');

    return (
        <PageContainer showHelpIcon={false}>
            <Helmet>
                <title> Admin Dashboard | Get Will Done </title>
            </Helmet>

            <Typography variant="title1" sx={{ color: theme.palette.grey[600] }}>
                Admin Dashboard
            </Typography>

            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: isSmallScreen ? '1fr' : isMediumScreen ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)',
                    gap: '16px',
                    marginTop: '16px'
                }}
            >
                <TotalNumberOfClientsCard />
                <TotalNumberOfPartnersCard />
                <TopPerformingPartnerCard />
                <ConversionRatioCard />
                <AverageTimeCard />
                <RepeatRateCard />
                <TotalRevenueCard />
                <TotalCommissionPaidCard />
                <TotalGstPaidCard />
                <CompleteWillsCard />
                <IncompleteWillsCard />
            </Box>

            <Box>
                <WillGenerationTrendsChart />
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: !isLargeScreen ? '48px' : '16px',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexWrap: isSmallScreen || isMediumScreen ? 'wrap' : 'nowrap',
                    margin: '20px 0'
                }}
            >
                <AgeDemographicChart />
                <PartnerVsDirectRevenueChart />
            </Box>

            <Box>
                <RevenueTrendsCart />
            </Box>

            <Box>
                <ServiceWiseRevenue />
            </Box>


            <Box>
                <UserJourney />
            </Box>
        </PageContainer>
    )
};

export default AdminLandingPage;