import { capitalCase } from "change-case";
/**
 * @module utils/stringUtils
 * @description
 * Provides utility functions for transforming strings into specific formats 
 * and cases while handling special cases and exceptions.
 */

/**
 * Transforms a given string into the correct case format, handling special values 
 * and acronyms. For general inputs, it converts the string to title case, 
 * applies transformations for known acronyms, and handles conjunctions 
 * or prepositions in lowercase.
 * @function getCorrectCase
 * @memberof utils/stringUtils
 * * @description
 * - Known values are directly mapped to specific formats, such as `CAS NSDL`.
 * - For general inputs, the string is transformed to title case and:
 *   - Acronyms like `epf`, `ppf`, `dob`, `invits`, `reits`, and `llp` are capitalized.
 *   - Common conjunctions and prepositions like `and`, `or`, `of`, `the`, and `in` are converted to lowercase.
 * @param {string} str - The input string to transform.
 * @returns {string} - The formatted string with the correct case.
 */
export const getCorrectCase = (str) => {
  // Directly handle known values that should remain unchanged or transformed
  const knownValues = {
    "cas_nsdl": "CAS NSDL",
    "cas_csdl": "CAS CSDL",
    // Add other known values as needed
  };

  // Check if the key exists in knownValues and return it if found
  if (knownValues[str.toLowerCase()]) {
    return knownValues[str.toLowerCase()];
  }

  // For general case transformation
  const val = capitalCase(str);
  return val
    .replace(/\b(epf|ppf|dob|invits|reits|llp)\b/gi, v => v.toUpperCase())
    .replace(/\b(and|or|of|the|in)\b/gi, v => v.toLowerCase());
};
