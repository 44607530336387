/**
 * @module Hooks/usePreventScreenshot
 *
 * @description This module provides a React hook, `usePreventScreenshot`, which prevents the user from taking screenshots or printing the page.
 * It disables the context menu, blocks the "PrintScreen" key, and prevents common keyboard shortcuts used for printing (e.g., Ctrl+P, Cmd+P).
 * The hook also shows a warning using SweetAlert2 when any restricted action is attempted.
 */

import { useEffect } from 'react';
import Swal from 'sweetalert2';

/**
 * `usePreventScreenshot` is a custom React hook that disables screenshotting and printing functionality on the page.
 * It listens to keypresses and the context menu event to block actions such as taking screenshots and printing.
 *
 * Features:
 * - Disables the browser's context menu.
 * - Prevents "PrintScreen" key actions and clipboard capture of screenshots.
 * - Blocks printing shortcuts like `Ctrl+P` and `Cmd+P`.
 * - Displays a warning message using SweetAlert2 when restricted actions are attempted.
 *
 * @function usePreventScreenshot
 *
 * @returns {void} - The hook does not return any value.
 *
 * @example
 * // Usage
 * import usePreventScreenshot from './usePreventScreenshot';
 *
 * const App = () => {
 *     usePreventScreenshot();
 *     return <div>Content here is protected from screenshots and printing.</div>;
 * };
 *
 */
const usePreventScreenshot = () => {
    /**
     * Handles the `contextmenu` event to prevent the right-click menu from appearing.
     *
     * @function handleContextMenu
     * @param {MouseEvent} event - The mouse event object.
     * @returns {void}
     */
    useEffect(() => {
        const handleContextMenu = (event) => {
            event.preventDefault();
        };

        /**
         * Handles the `keydown` event to block printing and screenshot-related keys.
         *
         * @function handleKeyDown
         * @param {KeyboardEvent} event - The keyboard event object.
         * @returns {void}
         */
        const handleKeyDown = (event) => {
            if (
                event.key === 'PrintScreen' ||
                (event.ctrlKey && event.key === 'p') ||
                (event.ctrlKey && event.shiftKey && event.key === 's') || // Ctrl+Shift+S
                (event.metaKey && event.key === 'p') || // Cmd+P on macOS
                event.code === 'PrintScreen' // Detect PrintScreen by code
            ) {
                event.preventDefault();
                Swal.fire({
                    position: 'top-center',
                    icon: 'warning',
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: 'Screenshot and printing are disabled!',
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
        };

        /**
         * Handles the `keyup` event to block clipboard usage for the PrintScreen key.
         * Clears the clipboard and displays a warning when PrintScreen is pressed.
         *
         * @function handleKeyUp
         * @param {KeyboardEvent} event - The keyboard event object.
         * @returns {void}
         */
        const handleKeyUp = (event) => {
            // Blocking default PrintScreen key
            if (event.key === 'PrintScreen' || event.code === 'PrintScreen') {
                navigator.clipboard.writeText('');
                Swal.fire({
                    position: 'top-center',
                    icon: 'warning',
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: 'Screenshot and printing are disabled!',
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
        };

        // Add event listeners
        window.addEventListener('contextmenu', handleContextMenu);
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        // Cleanup event listeners
        return () => {
            window.removeEventListener('contextmenu', handleContextMenu);
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);
};

export default usePreventScreenshot;
