/**
 * @module Pages/AdminPages/AdminComponents/CompleteWillsCard
 * @description This module contains the CompleteWillsCard component used to display the total count of completed wills, 
 * including a breakdown of individual and partner completed wills.
 */
import { Skeleton } from '@mui/material';
import DashboardCard from 'components/commonComponents/cards/DashboardCard';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchCompleteWills } from 'services/adminService';

/**
 * A card component that displays the total number of completed wills, along with a breakdown of individual and partner completed wills.
 * 
 * The component fetches data from the `/admin/completed-wills` API endpoint, which provides the total completed wills, 
 * the count of individual completed wills, and the count of partner completed wills. The data is displayed in the card's subtitle.
 * 
 * While the data is being fetched, a loading skeleton is displayed. Once the data is fetched, it is shown within a `DashboardCard`.
 * The card is clickable and redirects to the `/admin/completedWills` page when clicked.
 *
 * @component
 * @returns {JSX.Element} A loading skeleton while data is being fetched, or a `DashboardCard` displaying the completed wills count.
 */
const CompleteWillsCard = () => {
  const [completeWillData, setCompleteWillData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  /**
 * Fetches the data for the total number of completed wills, along with the breakdown of individual and partner completed wills.
 * 
 * This effect runs once when the component mounts. It fetches data from the `/admin/completed-wills` API endpoint,
 * and updates the state with the retrieved data. Once the data is fetched, the loading state is set to `false`.
 */
  useEffect(() => {
    const fetchCompleteWillsData = async () => {
      try {
        const response = await fetchCompleteWills();
        const { completeWillsCount, testatorCompleteWillsCount, partnerCompleteWillsCount } = response.data;
        setCompleteWillData({ completeWillsCount, testatorCompleteWillsCount, partnerCompleteWillsCount });
      } catch (error) {
        console.error('Error fetching complete wills data:', error);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };

    fetchCompleteWillsData();
  }, []);
  /**
    * Handles the click event on the dashboard card, navigating to the "/admin/completedWills" page.
    */
  const handleClick = () => {
    navigate('/admin/completedWills');
  };

  return (
    <>
      {
        loading ? (
          <Skeleton variant="rectangular" height={135} sx={{ borderRadius: '1.25rem' }} />
        ) : (
          <DashboardCard
            title="Total Completed Wills (Individual v/s Partner)"
            subtitle={`${completeWillData?.completeWillsCount ?? '--'} (${completeWillData?.testatorCompleteWillsCount ?? '--'
              } / ${completeWillData?.partnerCompleteWillsCount ?? '--'})`}
            handleClick={handleClick}
            isClickable
          />
        )
      }
    </>
  );
};

export default CompleteWillsCard;
