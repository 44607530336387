import PropTypes from 'prop-types';

// material-ui
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

// project imports
import { Box } from '@mui/material';
import { ContextProvider } from 'App';
import { ContainedButton1, ContainedButton2 } from 'components/DesignSystem/Button';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MENU_OPEN } from 'store/actions';
import NavCollapse from '../NavCollapse';
import NavItem from '../NavItem';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item, isPartnerDashboard }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useContext(ContextProvider);

  const goToMyWill = () => {
    dispatch({ type: MENU_OPEN, id: null });
    navigate('/myWill');
  };

  const handleServices = () => {
    navigate('/services');
  };

  // menu list collapse & items
  const items = item.children?.map((menu) => {
    switch (menu.type) {
      case 'collapse':
        return <NavCollapse key={menu.id} menu={menu} level={1} />;
      case 'item':
        return (
          <>
            <NavItem key={menu.id} item={menu} level={1} />
          </>
        );
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <>
      <List
        subheader={
          item.title && (
            <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
              {item.title}
              {item.caption && (
                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}
        {item.id === 'dashboard' && ['testator', 'partner'].includes(user.role) && !isPartnerDashboard && (
          <>
            <ContainedButton1 fullWidth onClick={goToMyWill}>
              <Typography variant="bodyText2">My Will</Typography>
            </ContainedButton1>
            {/* <Box sx={{ position: 'absolute', bottom: 50 }}> */}
            <Box
              sx={{
                marginTop: '30px',
              }}
            >
              <ContainedButton2
                sx={{
                  background: theme.palette.secondary.panelColor,
                  border: '1px solid #EFF9F915',
                  // minWidth: '328px',
                  // opacity: '80%',
                }}
                fullWidth
                onClick={handleServices}
              >
                <Typography variant="bodyText2">Our Services</Typography>
              </ContainedButton2>
            </Box>
          </>
        )}
      </List>

      {/* group divider */}
      <Divider sx={{ mt: '30px', mb: '30px' }} />
    </>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default NavGroup;
