/**
 * @module Components/FeatureComponent/Footer
 * 
 * @description This module defines the `Footer` component, a styled footer section for a webpage.
 * It includes a company logo, social media links, quick navigation links, and legal information.
 * The component uses Material-UI for styling and layout.
 */

import { Box, Stack, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from 'components/DesignSystem/Icon';
import { useNavigate } from 'react-router-dom';

/**
 * StyledFooter - A styled `Box` component that serves as the container for the footer.
 * 
 */
const StyledFooter = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: '50px',
    justifyContent: 'space-between',
    alignItems: 'flex-end', // Align children to the bottom
    width: '100%',
    boxSizing: 'border-box',
    maxWidth: '100%',
    backgroundColor: theme.palette.grey[600],
    color: '#fff'
}));

/**
 * Footer Component
 * 
 * Renders the footer of the webpage. Includes the company logo, social media icons,
 * quick navigation links, and legal text such as privacy policy and terms and conditions.
 * 
 * @component
 * @returns {JSX.Element} A React component rendering the footer section.
 * 
 * @example
 * return <Footer />
 */
const Footer = () => {
    const theme = useTheme(); // Access the Material-UI theme object
    const navigate = useNavigate(); // React Router's navigation hook

    return (
        <>
            <StyledFooter>
                {/** Company Logo and Social Media Links */}
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Icon name='GWD-full-white-logo.svg' height={90} width={174} />
                    {/** Social Media Links */}
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '77px', marginLeft: '10px', alignItems: 'center' }}>
                        <a href="https://twitter.com/getwilldone" target="_blank" rel="noopener noreferrer">
                            <Icon name='twitter-icon-white.png' height={22} width={26.58} />
                        </a>
                        <a href="https://getwilldone.com/contact.us" target="_blank" rel="noopener noreferrer">
                            <Icon name='instagram-icon-white.png' height={26} width={26} />
                        </a>
                        <a href="https://www.facebook.com/getwilldone/" target="_blank" rel="noopener noreferrer">
                            <Icon name='facebook-icon-white.png' height={24} width={24} />
                        </a>
                        <a href="https://www.youtube.com/channel/UCERQazG30MulIJGarWTYrYg" target="_blank" rel="noopener noreferrer">
                            <Icon name='youtube-icon-white.png' height={20} width={28.18} />
                        </a>
                    </Box>
                </Box>
                {/** Quick Links Section */}
                <Box>
                    <Stack spacing={0.2}>
                        <Typography variant='bodyText4' sx={{ color: theme.palette.secondary.main, marginBottom: '24px' }}>QUICK LINKS</Typography>
                        <Typography variant='bodyText4'>
                            <a href="http://www.getwilldone.com/about-us" style={{ color: 'inherit', textDecoration: 'none' }}>About Us</a>
                        </Typography>
                        <Typography variant='bodyText4' sx={{ cursor: 'pointer' }} onClick={() => navigate('/resourceHub')}>
                            FAQs
                        </Typography>
                        <Typography variant='bodyText4'>
                            <a href="http://www.getwilldone.com/contact-us" style={{ color: 'inherit', textDecoration: 'none' }}>Contact Us</a>
                        </Typography>
                    </Stack>
                </Box>
                {/** Legal Section */}
                <Box>
                    <Typography variant='smallText' sx={{ color: theme.palette.grey[400] }}>
                        <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                            <a href="https://getwilldone.com/privacy-policy/" style={{ color: 'inherit', textDecoration: 'none' }}>Privacy Policy</a>
                        </span>
                        {' | '}
                        <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                            <a href="https://getwilldone.com/terms-and-conditions/" style={{ color: 'inherit', textDecoration: 'none' }}>Terms and Conditions</a>
                        </span>
                        {' © 2023. Get Will Done. All Rights Reserved.'}
                    </Typography>
                </Box>
            </StyledFooter>
        </>
    );
};

export default Footer;
