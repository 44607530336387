/**
 * @module Pages/AdminPages/ReportsPage
 * @description This module defines the `ReportsPage` component, which allows the admin to generate and view various reports.
 * The page supports generating GST and commission reports for registered and unregistered partners. 
 * It provides filtering and sorting functionality for commission reports and allows users to download generated reports as PDF files.
 */
import { Box, Card, FormControl, FormHelperText, Grid, Paper, Stack, Table, TableBody, TableContainer, Typography, useTheme } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AWS from 'aws-sdk';
import axios from "axios";
import { ContainedButton1, TextedButton1 } from "components/DesignSystem/Button";
import CustomDatePicker from "components/DesignSystem/CustomDatePicker";
import { Dropdown } from "components/DesignSystem/DropDown";
import useSwalAlert from "components/DesignSystem/SwalAlert";
import { CustomTableCell, CustomTablePagination, CustomTableRow } from "components/DesignSystem/Table/CustomTable";
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { filter } from "lodash";
import Scrollbar from "oldLayouts/components/scrollbar/Scrollbar";
import { UserListHead } from "oldLayouts/components/user";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import PageContainer from "views/dashboard/PageContainer";
import * as Yup from 'yup';

/**
 * Table header configuration for GST reports.
 * This array contains the headers and column names for displaying GST-related reports.
 * @constant
 */
const GST_TABLE_HEAD = [
    // { id: "edit-action" },
    { id: "fromDate", label: "From Date", alignRight: false },
    { id: "toDate", label: "To Date", alignRight: false },
    { id: "totalCgst", label: "CGST", alignRight: false },
    { id: "totalSgst", label: "SGST", alignRight: false },
    { id: "totalIgst", label: "IGST", alignRight: false },
    { id: "totalGst", label: "Total GST", alignRight: false },
    { id: "action", label: "Action", alignCenter: true }
];

/**
 * Table header configuration for Commission reports.
 * This array contains the headers and column names for displaying Commission-related reports.
 * @constant
 */
const COMMISSION_TABLE_HEAD = [
    // { id: "edit-action" },
    { id: "partnerName", label: "Partner Name", alignRight: false },
    { id: "partnerCode", label: "Partner Code", alignRight: false },
    { id: "fromDate", label: "From Date", alignRight: false },
    { id: "toDate", label: "To Date", alignRight: false },
    { id: "totalDiscount", label: "Total Discount", alignRight: false },
    { id: "totalAmount", label: "Total Amount", alignRight: false },
    { id: "totalCommission", label: "Total Commission", alignRight: false },
    { id: "action", label: "Action", alignCenter: true }
];

/**
 * Comparator function to sort data in descending order.
 * @param {Object} a - The first object to compare.
 * @param {Object} b - The second object to compare.
 * @param {string} orderBy - The property by which to compare the objects.
 * @returns {number} - A negative, zero, or positive number indicating the sort order.
 */
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

/**
 * Returns the comparator function based on the order direction (asc/desc) and orderBy property.
 * @param {string} order - The sort order, either 'asc' or 'desc'.
 * @param {string} orderBy - The property by which the array will be sorted.
 * @returns {function} - A comparator function that can be used with Array.sort.
 */
function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * Applies sorting and filtering to the given array.
 * @param {Array} array - The array of data to be sorted and filtered.
 * @param {function} comparator - The comparator function used for sorting.
 * @param {string} query - The filter query string.
 * @returns {Array} - A new array that has been sorted and filtered based on the query.
 */
function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => JSON.stringify(_user ?? {}).toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

/**
 * The `ReportsPage` component displays the report generation interface for admins.
 * It allows users to generate GST and commission reports, view and filter existing reports, and download them.
 * @component
 */
export default function ReportsPage() {
    const theme = useTheme();
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState("asc");
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState("name");
    const [filterName, setFilterName] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [gstReports, setGstReports] = useState([]);
    const [commissionReports, setCommissionReports] = useState([]);
    const [reportFetch, setReportFetch] = useState(false);
    const showSwalAlert = useSwalAlert();

    /**
 * Handles the request to sort the data based on the selected property.
 * @param {Event} event - The click event triggered by a user selecting a column header.
 * @param {string} property - The property name of the column to sort by.
 */
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    /**
     * Handles the selection of all checkboxes for reports.
     * @param {Event} event - The change event triggered by selecting the "Select All" checkbox.
     */
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = gstReports.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    /**
     * Handles page changes for paginated reports.
     * @param {Event} event - The event triggered by a page change.
     * @param {number} newPage - The new page number to navigate to.
     */
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    /**
         * Handles the change in the number of rows per page for pagination.
         * @param {Event} event - The event triggered by changing the rows per page.
         */
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };
    /**
     * Handles the filtering of reports based on the input query string.
     * @param {Event} event - The event triggered by typing in the filter input field.
     */
    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };
    /**
     * Calculates the number of empty rows in the table based on the current page and rows per page.
     * This is used to ensure correct pagination display when the number of rows is less than the pagination size.
     * 
     * @constant
     */
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - commissionReports.length) : 0;
    /**
     * Filters and sorts the commission reports based on the current search filter and sort order.
     * 
     * @constant
     */
    const filteredCommissionReports = applySortFilter(commissionReports, getComparator(order, orderBy), filterName);
    /**
     * Determines whether there are no filtered commission reports and if the filter name is set.
     * This is used to display a "no results found" message if no data matches the filter.
     * 
     * @constant
     */

    const isNotFound = !filteredCommissionReports.length && !!filterName;
    /**
     * The Formik hook for managing the form state of the report generation. It includes field validation, handling of form submission, and setting errors.
     */
    const formik = useFormik({
        initialValues: { reportType: 'gst', fromDate: dayjs().subtract(1, 'month').startOf('month'), toDate: dayjs().subtract(1, 'month').endOf('month') },
        validationSchema: Yup.object().shape({
            reportType: Yup.string().required("Select the Report Type to view"),
            fromDate: Yup.date().required('From Date is required')
                .typeError('Please enter valid date dd-mm-yyyy format')
                .min(dayjs('01-01-1900'), 'Date must be greater than 01-01-1900')
                .max(dayjs().endOf('day'), 'Date must be equal or before today'),
            toDate: Yup.date().required('To Date is required')
                .typeError('Please enter valid date dd-mm-yyyy format')
                .min(dayjs('01-01-1900'), 'Date must be greater than 01-01-1900')
                .max(dayjs().endOf('day'), 'Date must be equal or before today')
                .test({
                    name: 'notBeforeAsFromDate',
                    message: 'To Date must be equal or greater than from date',
                    test: (toDate, values) => {
                        const fromDate = values.parent.fromDate;
                        console.log('value', toDate, fromDate, dayjs(fromDate).isSameOrBefore(dayjs(toDate), 'day'))
                        return (toDate !== null ? dayjs(fromDate).isSameOrBefore(dayjs(toDate), 'day') : false);
                    },
                }),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            console.log('values', values);
            setReportFetch(false);
            axios.post("/reports", values).then(response => {
                console.log('response', response);
                if (response.status === 200) {
                    setReportFetch(true);
                    if (values.reportType === 'gst')
                        setGstReports(response.data.data);
                    if (values.reportType === 'commissionUnregistered' || values.reportType === 'commissionRegistered')
                        setCommissionReports(response.data.data);
                }
                let icon = "success";
                if (response.data.data.length === 0 && (values.reportType === 'commissionUnregistered' || values.reportType === 'commissionRegistered')) {
                    icon = "error";
                }
                showSwalAlert({
                    icon: `${icon}`,
                    title: `${response.data.message}`,
                    timer: 3000
                });
            }).catch(err => {
                console.error(err);
                showSwalAlert({
                    icon: "error",
                    title: "Failed to fetch report",
                    timer: 3000
                });
            });
        }
    });
    /**
     * Handles date change for the 'From Date' and 'To Date' fields in the form.
     * This function triggers Formik's change handler to update the form state with the selected date.
     * 
     * @param {Date} value - The new date value selected.
     * @param {string} name - The name of the field being changed (either 'fromDate' or 'toDate').
     */
    const handleDateChange = (value, name) => {
        console.log('handleDateChange', name, value)
        const event = {
            target: {
                name,
                value: value !== null ? dayjs(value) : null,
            },
        };
        formik.handleChange(event);
    }
    /**
     * Generates a GST report by sending a request to the server with the specified date range.
     * The report is fetched from the server and downloaded from AWS S3 if the report is successfully generated.
     * 
     * @param {Date} fromDate - The start date of the report.
     * @param {Date} toDate - The end date of the report.
     */
    const generateGstReport = (fromDate, toDate) => {
        axios.post("/reports/generate-gst-report", { fromDate, toDate }).then(response => {
            console.log('response', response.data);
            if (response.status === 200) {
                const fileKey = response.data.fileKey;
                const s3 = new AWS.S3({
                    region: process.env.REACT_APP_AWS_REGION ?? 'ap-south-1',
                    accessKeyId: process.env.REACT_APP_AWS_KEY,
                    secretAccessKey: process.env.REACT_APP_AWS_SECRET,
                });
                // Set the parameters for the getObject request
                const params = {
                    Bucket: process.env.REACT_APP_AWS_PDF_REPORTS_BUCKET_NAME ?? "gwd-testing/gwd-pdf-reports",   // Replace with your bucket name
                    Key: `${fileKey}`, // Replace with the path to your file
                };

                // Make the getObject request
                s3.getObject(params, (err, data) => {
                    if (err) {
                        console.error('Error downloading file:', err);
                    } else {
                        // Create a Blob from the data and generate a download link
                        const blob = new Blob([data.Body], { type: 'application/octet-stream' });
                        const url = URL.createObjectURL(blob);

                        // Create a download link and trigger click to start download
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = `GWD-Detail-GST-Report-${dayjs(fromDate).format("DD-MM-YYYY")} to ${dayjs(toDate).format("DD-MM-YYYY")}.pdf`;
                        link.click();
                        link.remove();

                        // Clean up the object URL
                        URL.revokeObjectURL(url);
                    }
                });
            }
        }).catch(err => {
            console.log('err', err);
        });
    }
    /**
     * Generates a commission report for the specified partner and date range.
     * The report is fetched from the server and downloaded from AWS S3 if the report is successfully generated.
     * 
     * @param {Date} fromDate - The start date of the report.
     * @param {Date} toDate - The end date of the report.
     * @param {string} reportType - The type of commission report ('commissionRegistered' or 'commissionUnregistered').
     * @param {string|null} partnerCode - Optional: The partner code to filter the commission report by.
     */
    const generateCommissionReport = (fromDate, toDate, reportType, partnerCode = null) => {
        axios.post("/reports/generate-commission-report", { partnerCode, fromDate, toDate, reportType }).then(response => {
            console.log('response', response.data);
            if (response.status === 200) {
                const fileKey = response.data.fileKey;
                const s3 = new AWS.S3({
                    region: process.env.REACT_APP_AWS_REGION ?? 'ap-south-1',
                    accessKeyId: process.env.REACT_APP_AWS_KEY,
                    secretAccessKey: process.env.REACT_APP_AWS_SECRET,
                });
                // Set the parameters for the getObject request
                const params = {
                    Bucket: process.env.REACT_APP_AWS_PDF_REPORTS_BUCKET_NAME ?? "gwd-testing/gwd-pdf-reports",   // Replace with your bucket name
                    Key: `${fileKey}`, // Replace with the path to your file
                };

                // Make the getObject request
                s3.getObject(params, (err, data) => {
                    if (err) {
                        console.error('Error downloading file:', err);
                    } else {
                        // Create a Blob from the data and generate a download link
                        const blob = new Blob([data.Body], { type: 'application/octet-stream' });
                        const url = URL.createObjectURL(blob);

                        // Create a download link and trigger click to start download
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = `GWD-Detail-Commission-Report-${dayjs(fromDate).format("DD-MM-YYYY")} to ${dayjs(toDate).format("DD-MM-YYYY")}.pdf`;
                        link.click();
                        link.remove();

                        // Clean up the object URL
                        URL.revokeObjectURL(url);
                    }
                });
            }
        }).catch(err => {
            console.log('err', err);
        });
    }
    /**
     * The options for the report type dropdown.
     * These options allow the user to select the type of report they want to generate (Commission Registered, Commission Unregistered, or GST).
     * 
     * @constant
     */
    const reportTypeOptions = [
        {
            label: 'Commission (Registered)',
            value: 'commissionRegistered',
        },
        {
            label: 'Commission (Unregistered)',
            value: 'commissionUnregistered',
        },
        {
            label: 'GST',
            value: 'gst',
        }
    ];

    return (
        <>
            <Helmet>
                <title> Reports | Get Will Done </title>
            </Helmet>

            <PageContainer showHelpIcon={false}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="title1" gutterBottom>
                        Reports
                    </Typography>
                    {/* <div style={{ flexGrow: 1 }}>
                        <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                    </div> */}
                </Stack>
                <form noValidate onSubmit={formik.handleSubmit}>
                    <Grid container spacing={1} sx={{ marginBottom: 1 }} >
                        <Grid item xs={12} md={4}>
                            <Dropdown
                                name="reportType"
                                value={formik.values.reportType}
                                onBlur={formik.handleBlur}
                                handleChange={(event) => {
                                    setGstReports([]);
                                    setReportFetch(false);
                                    setCommissionReports([]);

                                    formik.handleChange(event)
                                }}
                                placeholder="Type"
                                options={reportTypeOptions}
                                // dropdownWidth="270px"
                                error={formik.touched.reportType && formik.errors.reportType}
                                helperText={formik.errors.reportType}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControl
                                required
                                error={formik.errors.fromDate}
                                fullWidth
                                variant="standard"
                            >
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <CustomDatePicker
                                        value={formik.values.fromDate}
                                        name="fromDate"
                                        error={formik.errors.fromDate}
                                        fullWidth
                                        inputFormat={'dd-MM-yyyy'}
                                        maxDate={dayjs()}
                                        maxDateMessage="Past dates are not allowed"
                                        onChange={(value) => handleDateChange(value, 'fromDate')}
                                        onBlur={formik.handleBlur}
                                        placeholder="From Date(DD-MM-YYYY)"
                                    />
                                    {true || formik.touched.fromDate && formik.errors.fromDate && (
                                        <FormHelperText error id={`standard-weight-helper-text-fromDate`}>
                                            {"error" || formik.errors.fromDate}
                                        </FormHelperText>
                                    )}
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControl
                                required
                                error={formik.errors.toDate}
                                fullWidth
                                variant="standard"
                            >
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <CustomDatePicker
                                        value={formik.values.toDate}
                                        name="toDate"
                                        error={formik.errors.toDate}
                                        fullWidth
                                        inputFormat={'dd-MM-yyyy'}
                                        maxDate={dayjs()}
                                        onChange={(value) => handleDateChange(value, 'toDate')}
                                        onBlur={formik.handleBlur}
                                        placeholder="To Date(DD-MM-YYYY)"
                                    />
                                    {formik.touched.toDate && formik.errors.toDate && (
                                        <FormHelperText error id={`standard-weight-helper-text-toDate`}>
                                            {formik.errors.toDate}
                                        </FormHelperText>
                                    )}
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <ContainedButton1 id={'new-generic'} type="submit" sx={{ height: '45px', width: '10vw' }}>
                                <Typography variant='bodyText3' color={theme.palette.common.white}>
                                    Apply
                                </Typography>
                            </ContainedButton1>
                        </Grid>
                        <Grid item xs={12}>{formik.errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{formik.errors.submit}</FormHelperText>
                            </Box>
                        )}</Grid>
                    </Grid>
                </form>
                <Card>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            {formik.values.reportType === 'gst' && (
                                <>
                                    <Table>
                                        <UserListHead
                                            order={order}
                                            orderBy={orderBy}
                                            headLabel={GST_TABLE_HEAD}
                                            rowCount={gstReports.length}
                                            numSelected={selected.length}
                                            onRequestSort={handleRequestSort}
                                            onSelectAllClick={handleSelectAllClick}
                                        />
                                        <TableBody>
                                            {gstReports.map((row, index) => {
                                                const { fromDate, toDate, totalCgst, totalSgst, totalIgst } = row;
                                                const totalGst = +totalCgst + +totalSgst + +totalIgst;
                                                return (<>
                                                    <CustomTableRow
                                                        sx={{ bgcolor: (theme) => "initial" }}
                                                        key={index + 1} tabIndex={-1}>
                                                        <CustomTableCell >{dayjs(fromDate).format('DD-MM-YYYY')}</CustomTableCell>
                                                        <CustomTableCell >{dayjs(toDate).format('DD-MM-YYYY')}</CustomTableCell>
                                                        <CustomTableCell >₹{totalCgst ?? 0}</CustomTableCell>
                                                        <CustomTableCell >₹{totalSgst ?? 0}</CustomTableCell>
                                                        <CustomTableCell >₹{totalIgst ?? 0}</CustomTableCell>
                                                        <CustomTableCell >₹{totalGst.toFixed(2)}</CustomTableCell>
                                                        <CustomTableCell >
                                                            {(totalCgst > 0 || totalSgst > 0 || totalIgst > 0 || totalGst > 0) && (
                                                                // <Tooltip title="Download Detail Report">
                                                                <TextedButton1
                                                                    fullWidth
                                                                    sx={{ paddingTop: '7px', paddingBottom: '7px', border: `0.5px solid ${theme.palette.primary.main}` }}
                                                                    onClick={() => generateGstReport(fromDate, toDate)}
                                                                >
                                                                    <Typography variant="bodyText4">Download</Typography>
                                                                </TextedButton1>
                                                                // </Tooltip>
                                                            )}
                                                        </CustomTableCell>
                                                    </CustomTableRow>

                                                </>);
                                            })}
                                            {emptyRows > 0 && (
                                                <CustomTableRow style={{ height: 53 * emptyRows }}>
                                                    <CustomTableCell colSpan={GST_TABLE_HEAD.length} />
                                                </CustomTableRow>
                                            )}
                                        </TableBody>

                                        {(reportFetch && gstReports.length === 0) && (
                                            <TableBody>
                                                <CustomTableRow>
                                                    <CustomTableCell align="center" colSpan={GST_TABLE_HEAD.length} sx={{ py: 3 }}>
                                                        No GST Found
                                                    </CustomTableCell>
                                                </CustomTableRow>
                                            </TableBody>
                                        )}
                                    </Table>
                                    <CustomTablePagination
                                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                        component="div"
                                        count={gstReports.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </>
                            )}

                            {['commissionRegistered', 'commissionUnregistered'].includes(formik.values.reportType) && (
                                <>
                                    {commissionReports.length > 0 && (
                                        <Box sx={{ textAlign: 'right', m: '16px' }}>
                                            {/* <Tooltip title="Download Detail Report for All Partners"> */}
                                            <TextedButton1
                                                sx={{ paddingTop: '7px', paddingBottom: '7px', border: `0.5px solid ${theme.palette.primary.main}` }}
                                                onClick={() => generateCommissionReport(formik.values.fromDate, formik.values.toDate, formik.values.reportType)}
                                            >
                                                <Typography variant="bodyText4">Download Detail Report for All Partners</Typography>
                                            </TextedButton1>
                                            {/* </Tooltip> */}
                                        </Box>
                                    )}
                                    <Table>
                                        <UserListHead
                                            order={order}
                                            orderBy={orderBy}
                                            headLabel={COMMISSION_TABLE_HEAD}
                                            rowCount={commissionReports.length}
                                            numSelected={selected.length}
                                            onRequestSort={handleRequestSort}
                                            onSelectAllClick={handleSelectAllClick}
                                        />
                                        <TableBody>
                                            {filteredCommissionReports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                                const { partnerName, partnerCode, registeredPartner, fromDate, toDate, totalOrderValue, totalPartnerCommission, totalPartnerDiscount } = row;
                                                return (<>
                                                    <CustomTableRow
                                                        sx={{ bgcolor: (theme) => "initial" }}
                                                        key={index + 1} tabIndex={-1}>
                                                        <CustomTableCell >{partnerName}</CustomTableCell>
                                                        <CustomTableCell >{partnerCode}</CustomTableCell>
                                                        <CustomTableCell >{dayjs(fromDate).format('DD-MM-YYYY')}</CustomTableCell>
                                                        <CustomTableCell >{dayjs(toDate).format('DD-MM-YYYY')}</CustomTableCell>
                                                        <CustomTableCell >₹{totalPartnerDiscount ?? 0}</CustomTableCell>
                                                        <CustomTableCell >₹{totalOrderValue ?? 0}</CustomTableCell>
                                                        <CustomTableCell >₹{totalPartnerCommission ?? 0}</CustomTableCell>
                                                        <CustomTableCell >
                                                            {/* <Tooltip title="Download Detail Report"> */}
                                                            <TextedButton1
                                                                fullWidth
                                                                sx={{ paddingTop: '7px', paddingBottom: '7px', border: `0.5px solid ${theme.palette.primary.main}` }}
                                                                onClick={() => generateCommissionReport(fromDate, toDate, registeredPartner ? 'commissionRegistered' : 'commissionUnregistered', partnerCode)}
                                                            >
                                                                <Typography variant="bodyText4">Download</Typography>
                                                            </TextedButton1>
                                                            {/* </Tooltip> */}
                                                        </CustomTableCell>
                                                    </CustomTableRow>

                                                </>);
                                            })}
                                            {emptyRows > 0 && (
                                                <CustomTableRow style={{ height: 53 * emptyRows }}>
                                                    <CustomTableCell colSpan={COMMISSION_TABLE_HEAD.length} />
                                                </CustomTableRow>
                                            )}
                                        </TableBody>

                                        {(reportFetch && commissionReports.length === 0) && (
                                            <TableBody>
                                                <CustomTableRow>
                                                    <CustomTableCell align="center" colSpan={COMMISSION_TABLE_HEAD.length} sx={{ py: 3 }}>
                                                        No Partner Commission Found
                                                    </CustomTableCell>
                                                </CustomTableRow>
                                            </TableBody>
                                        )}
                                        {isNotFound && (
                                            <TableBody>
                                                <CustomTableRow>
                                                    <CustomTableCell align="center" colSpan={COMMISSION_TABLE_HEAD.length} sx={{ py: 3 }}>
                                                        <Paper
                                                            sx={{
                                                                textAlign: "center"
                                                            }}
                                                        >
                                                            <Typography variant="h6" paragraph>
                                                                Not found
                                                            </Typography>

                                                            <Typography variant="body2">
                                                                No results found for &nbsp;
                                                                <strong>&quot;{filterName}&quot;</strong>.
                                                                <br /> Try checking for typos or using complete words.
                                                            </Typography>
                                                        </Paper>
                                                    </CustomTableCell>
                                                </CustomTableRow>
                                            </TableBody>
                                        )}
                                    </Table>
                                    <CustomTablePagination
                                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                        component="div"
                                        count={commissionReports.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </>
                            )}
                        </TableContainer>
                    </Scrollbar>

                </Card>
            </PageContainer>

        </>
    );
}
