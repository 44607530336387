import { Box, Typography } from '@mui/material';
import { ContextProvider } from 'App';
import { TextedButton1 } from 'components/DesignSystem/Button';
import Icon from 'components/DesignSystem/Icon';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * @module Components/ExitDemoButton
 * @description This module contains the ExitDemoButton component used across the application.
 */
/**
 * A button component that allows users to exit a demo session.
 *
 * The button is only visible if:
 * - The user's role is "partner".
 * - The demo session is active (`isDemoActive` is stored as "true" in session storage).
 *
 * On clicking the button:
 * - The `isDemoActive` flag is removed from session storage.
 * - The user is redirected to the "Partner Landing Page".
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} [props.sx={}] - Optional custom styles to apply to the outer container using MUI's `sx` prop.
 * @returns {JSX.Element|null} A styled button to exit the demo or `null` if conditions are not met.
 */
const ExitDemoButton = ({ sx = {} }) => {
    const navigate = useNavigate();
    const { user } = useContext(ContextProvider);

    // Check if the demo session is active
    const isDemoActive = sessionStorage.getItem('isDemoActive') === 'true';

    /**
     * Handles the button click to exit the demo session.
     * - Removes the `isDemoActive` flag from session storage.
     * - Navigates to the "Partner Landing Page".
     */
    const handleExitDemo = () => {
        sessionStorage.removeItem('isDemoActive');
        navigate('/partnerLandingPage');
    };

    // Render nothing if the user is not a "partner" or the demo session is inactive
    if (user.role !== 'partner' || !isDemoActive) {
        return null;
    }

    return (
        <Box
            sx={{
                ...sx, // Spread the passed custom styles
            }}
        >
            <TextedButton1 sx={{ display: 'flex', gap: '8px', padding: '10px 16px' }} onClick={handleExitDemo}>
                <Icon name="exit-icon.png" height="16px" width="15px" />
                <Typography variant="bodyText3">Exit Demo</Typography>
            </TextedButton1>
        </Box>
    );
};

export default ExitDemoButton;
