/**
 * @module Pages/PartnerSignupPage
 * @description This module defines the PartnerSignUpPage component, which provides a sign-up form for partners. 
 * The page is split into two sections: the left side contains the partner sign-up form, while the right side displays a background image. 
 * The layout is responsive and adapts to various screen sizes. The component uses Material-UI's `Grid` and `Box` for the layout, 
 * and `Helmet` is used to dynamically update the page title for SEO purposes. The form is contained in the `PartnerSignUpForm` component.
 * 
 * @see PartnerSignUpForm
 * @example
 * // Usage
 * <PartnerSignUpPage />
 */
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Helmet } from "react-helmet-async";
import PartnerSignUpForm from "sections/auth/login/PartnerSignUpForm";
import LogoContainer from "../components/commonComponents/LogoContainer";
import useResponsive from "../hooks/useResponsive";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex"
  }
}));

/**
 * PartnerSignUpPage component.
 * 
 * This component renders the partner sign-up page. It contains a form on the left side and a decorative image on the right side. 
 * The form allows partners to sign up for the service. The page is responsive, with different layouts for smaller and larger screens.
 * Helmet is used to update the page's title, improving SEO.
 */
export default function PartnerSignUpPage() {
  const mdUp = useResponsive("up", "md");

  return (
    <>
      <Helmet>
        <title> Sign In | Get Will Done </title>
      </Helmet>
      <StyledRoot>
        <LogoContainer>
          <Grid
            item
            // xs={12}
            md={5}
            style={{
              display: 'flex',
              // flexDirection: 'column',
              justifyContent: 'center',
              // alignItems: 'center',
              paddingTop: '135px',
              backgroundColor: '#fff',
            }}
          >
            <Box style={{ width: '100%', maxWidth: '402px', paddingLeft:0, paddingRight: 0  }}>
              <PartnerSignUpForm />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#f9f9f9',
            }}
          >
            <Box
              component="img"
              src={'/assets/gwd-login-img.svg'}
              alt="Decorative"
              style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
            />
          </Grid>
        </LogoContainer>
      </StyledRoot>
    </>
  );
}
