import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';

/**
 * IconSkeleton is a skeleton loader for an icon, displayed as a rectangular shape.
 * It uses Material-UI's Skeleton component to provide a loading state placeholder for an icon.
 * The size, height, and width of the skeleton can be customized.
 *
 * @component
 * @example
 * // Example usage
 * <IconSkeleton size={40} height={40} width={40} className="icon-skeleton" />
 *
 * @param {Object} props - The component's props.
 * @param {string} [props.className] - An optional CSS class name to apply to the Skeleton component.
 * @param {number} [props.size=32] - The default size of the skeleton if no height or width is provided. It applies to both width and height.
 * @param {string|number} [props.height] - The height of the skeleton. If not provided, the `size` prop is used.
 * @param {string|number} [props.width] - The width of the skeleton. If not provided, the `size` prop is used.
 * @param {Object} [props.style] - Additional inline styles to apply to the Skeleton component.
 * 
 * @returns {React.Element} The IconSkeleton component.
 */
const IconSkeleton = ({ className, size, height, width, style }) => {
	const imgHeight = height || size;
	const imgWidth = width || size;
	return (
		<Skeleton
			className={className}
			style={{ borderRadius: '8px', ...style }}
			variant='rectangular'
			width={imgWidth}
			height={imgHeight}
		/>
	);
};
IconSkeleton.defaultProps = {
	className: '',
	size: 32,
	width: '',
	height: '',
	style: {},
};
IconSkeleton.propTypes = {
	className: PropTypes.string,
	size: PropTypes.number,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	style: PropTypes.object,
};
export default IconSkeleton;
