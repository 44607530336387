/**
 * @module Pages/AdminPages/AdminComponents/RepeatRateCard
 * @description This module contains the RepeatRateCard component which displays the repeat rate of customers
 * as a dashboard card.
 */
import { Skeleton } from '@mui/material';
import DashboardCard from 'components/commonComponents/cards/DashboardCard';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchRepeatRateOfCustomers } from 'services/adminService';
/**
 * A card component that displays the repeat rate of customers.
 * 
 * The component fetches repeat rate data from the `/admin/repeat-rate` API endpoint, which provides the repeat rate
 * of customers who have made repeat purchases or transactions. The data is displayed in a `DashboardCard` component
 * once the data has been fetched. While waiting for the data, a loading skeleton is shown.
 *
 * The card is clickable, and when clicked, it redirects to the `/admin/repeatRate` page for more detailed information.
 *
 * @component
 * @returns {JSX.Element} A loading skeleton while data is being fetched, or a `DashboardCard` displaying the repeat rate.
 */
const RepeatRateCard = () => {
  const [repeatRateData, setRepeatRateData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  /**
   * Fetches the repeat rate data for customers.
   * 
   * This effect runs once when the component mounts. It makes a GET request to the `/admin/repeat-rate` endpoint
   * and updates the state with the retrieved repeat rate data.
   */
  useEffect(() => {
    const fetchRepeatRateData = async () => {
      try {
        const response = await fetchRepeatRateOfCustomers();
        const { repeatRate } = response.data;
        setRepeatRateData({ repeatRate });
      } catch (error) {
        console.error('Error fetching repeat rate data:', error);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };

    fetchRepeatRateData();
  }, []);
  /**
   * Handles the click event on the card and navigates to the `/admin/repeatRate` page.
   */
  const handleClick = () => {
    navigate('/admin/repeatRate');
  };

  return (
    <>
      {
        loading ? (
          <Skeleton variant="rectangular" height={135} sx={{ borderRadius: '1.25rem' }} />
        ) : (
          <DashboardCard
            title="Repeat Rate of Customers"
            subtitle={(repeatRateData.repeatRate ?? '--') || 0}
            handleClick={handleClick}
            isClickable
          />
        )
      }
    </>
  );
};

export default RepeatRateCard;
