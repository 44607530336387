/**
 * @module Components/FeatureComponent/YouTubeVideoSection
 *
 * @description A React component that displays a grid of embedded YouTube videos with titles.
 * The videos are dynamically rendered from an object containing video details.
 * Each video is displayed within a styled card-like structure using Material-UI components.
 */

import React from 'react';
import { Box, Typography, Grid, Paper, useTheme } from '@mui/material';

/**
 * YouTubeVideoSection Component
 *
 * This component renders a section with multiple YouTube video embeds arranged in a responsive grid layout.
 * Each video is displayed inside a Material-UI `Paper` component with a title below it.
 *
 * @component
 * @returns {JSX.Element} The rendered YouTubeVideoSection component.
 *
 * @example
 * // Example usage of YouTubeVideoSection
 * return <YouTubeVideoSection />;
 */
const YouTubeVideoSection = () => {
  const theme = useTheme();

  /**
   * @constant {Object} videos - An object containing video details.
   * @property {Object} app - Details of the app introduction video.
   * @property {string} app.url - URL of the app introduction video.
   * @property {string} app.title - Title of the app introduction video.
   * @property {Object} assets - Details of the assets video.
   * @property {string} assets.url - URL of the assets video.
   * @property {string} assets.title - Title of the assets video.
   * @property {Object} beneficiaries - Details of the beneficiaries video.
   * @property {string} beneficiaries.url - URL of the beneficiaries video.
   * @property {string} beneficiaries.title - Title of the beneficiaries video.
   * @property {Object} executors - Details of the executors video.
   * @property {string} executors.url - URL of the executors video.
   * @property {string} executors.title - Title of the executors video.
   * @property {Object} witnesses - Details of the witnesses video.
   * @property {string} witnesses.url - URL of the witnesses video.
   * @property {string} witnesses.title - Title of the witnesses video.
   * @property {Object} distribution - Details of the distribution video.
   * @property {string} distribution.url - URL of the distribution video.
   * @property {string} distribution.title - Title of the distribution video.
   * @property {Object} preview - Details of the preview video.
   * @property {string} preview.url - URL of the preview video.
   * @property {string} preview.title - Title of the preview video.
   */ const videos = {
    app: {
      url: 'https://www.youtube.com/embed/RhXl2fNhkjk?rel=0',
      title: 'GetWillDone Introduction Video',
    },
    assets: {
      url: 'https://www.youtube.com/embed/bX-NIh3XW-A?rel=0',
      title: 'Who can be a beneficiary?',
    },
    beneficiaries: {
      url: 'https://www.youtube.com/embed/s4OMayI0H_4?rel=0',
      title: 'What is a Beneficiary?',
    },
    executors: {
      url: 'https://www.youtube.com/embed/_n9984AxCZ0?rel=0',
      title: 'Who is an Executor of a Will?',
    },
    witnesses: {
      url: 'https://www.youtube.com/embed/SUE-XG-NBtg?rel=0',
      title: 'Who can be a witness to your Will?',
    },
    distribution: {
      url: 'https://www.youtube.com/embed/T_mauuyt5A0?rel=0',
      title: 'What happens in absence of a Will?',
    },
    preview: {
      url: 'https://www.youtube.com/embed/XZlLsQsYDuA?rel=0',
      title: 'What is a Will?',
    },
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{ display: 'flex', flexDirection: 'row', minWidth: 'max-content', borderRadius: '15px', overflowX: 'auto' }}
    >
      {Object.values(videos).map((video, index) => (
        <Grid item key={index}>
          <Paper
            sx={{
              height: '13.563rem',
              width: '19.875rem',
              p: '11px',
              borderRadius: '15px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: 'none',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                borderRadius: '15px',
                // overflow: 'hidden'
              }}
            >
              <iframe
                width="100%"
                height="100%"
                src={video.url}
                title={video.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ borderRadius: '15px' }} // Apply border radius to iframe
              />
            </Box>
            <Typography
              mt="8px"
              variant="bodyText4"
              sx={{ textAlign: 'left', width: '100%' }}
              color={theme.palette.grey[600]}
            >
              {video.title}
            </Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default YouTubeVideoSection;
