/**
 * @module Components/OTPField
 * 
 * @description The `OTPField` component renders an OTP (One-Time Password) input field, allowing the user to input a 6-digit code. 
 * It utilizes the `react-otp-input` library to handle OTP inputs and customizes the appearance using Material UI's theme.
 * 
 * @component
 * 
 * @example
 * <OTPField value={otpValue} onChange={handleOtpChange} />
 * 
 * @param {string} value - The current value of the OTP input field. It represents the OTP entered by the user.
 * @param {function} onChange - A function that handles changes to the OTP value. It updates the `value` of the OTP input field as the user types.
 */
import { Stack, useTheme } from "@mui/material";
import { useState } from "react";
import OtpInput from "react-otp-input";

/**
 * `OTPField` renders an OTP input field that allows the user to input a 6-digit code.
 * It is styled using Material UI's theme and provides customization options for appearance, 
 * including input field size, padding, background color, and border. 
 * It also supports secure inputs for password-style fields and offers a placeholder for empty inputs.
 * 
 * @function OTPField
 * @param {string} value - The OTP input value, which is controlled by the parent component.
 * @param {function} onChange - Callback function to update the OTP value when the user types in the input.
 * 
 * @returns {JSX.Element} - Returns the `OTPField` component JSX element, which contains a styled OTP input field.
 */
export const OTPField = ({ value, onChange }) => {
  const theme = useTheme(); // Access Material UI theme for styling
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility (not used in this case)
  return <Stack direction={'row'} spacing={1}>
    <OtpInput
      inputStyle={{
        fontSize: theme.typography.fontSize,
        width: '45.22px',
        height: '45px',
        padding: theme.spacing(1.5),
        marginRight: theme.spacing(3),
        backgroundColor: theme.palette.grey[200], // Light gray background
        borderRadius: '10px',
        border: `1px  ${theme.palette.grey[200]}`, // Gray border
      }}
      value={value}
      onChange={onChange}
      numInputs={6}
      isInputNum
      isInputSecure={showPassword}
      placeholder="______"
      sx={{
        '::placeholder': {
          color: theme.palette.grey[300], // Placeholder text color
          fontWeight: "400px", // Typography font weight
        },
      }}
    />
  </Stack>
};