import { Circle } from '@mui/icons-material';
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography, useTheme } from '@mui/material';
import { noop } from 'lodash';

/**
 * @module Components/DesignSystem/RadioField
 * @description This module contains the RadioField component used across the application.
 */
/**
 * A custom radio button field component that allows users to select one of multiple options.
 * The component is built using Material-UI's `Radio`, `RadioGroup`, and related components.
 * It supports custom styling, error handling, and displaying helper text when validation fails.
 *
 * @component
 * @example
 * // Example usage:
 * <RadioField
 *   label="Select your option"
 *   name="option"
 *   value={selectedValue}
 *   onChange={handleChange}
 *   options={[{ label: 'Option 1', value: '1' }, { label: 'Option 2', value: '2' }]}
 *   helperText="This field is required"
 * />
 *
 * @param {Object} props - The properties for the component.
 * @param {string} [props.label=''] - The label displayed next to the radio group.
 * @param {string} [props.name=''] - The name for the radio group, which should be unique within the form.
 * @param {string} [props.value=''] - The current selected value of the radio group.
 * @param {function} [props.onChange=noop] - Callback function for when the value of the radio group changes.
 * @param {Array} [props.options=[]] - An array of objects with `label` and `value` properties representing the available options.
 * @param {string} [props.labelVariant='bodyText4'] - The variant of the typography for the label text. Defaults to 'bodyText4'.
 * @param {string} [props.radioSize='small'] - The size of the radio button. Can be 'small' or 'medium'.
 * @param {string} [props.radioColor='#F47B20'] - The color of the radio button, set using a hexadecimal color code.
 * @param {Object} [props.containerStyle={}] - Custom styles for the container of the radio group.
 * @param {Object} [props.groupStyle={}] - Custom styles for the `RadioGroup` container.
 * @param {boolean} [props.disabled=false] - Whether the radio group is disabled, preventing any interaction.
 * @param {boolean} [props.error=false] - Whether there is an error in the form validation.
 * @param {string} [props.helperText=''] - The helper text that is displayed if the field has an error and is empty.
 *
 * @returns {React.Element} The `RadioField` component with custom radio buttons and error handling.
 */
const RadioField = ({
    label = '',
    name = '',
    value = '',
    onChange = noop,
    options = [],
    labelVariant = 'bodyText4',
    radioSize = 'small',
    radioColor = '#F47B20',
    containerStyle = {},
    groupStyle = {},
    disabled = false,
    error = false,
    helperText = '',
}) => {
    const theme = useTheme();
    return (
        <FormControl error={error} disabled={disabled}>
            <div style={{ display: 'flex', alignItems: 'center', ...containerStyle }}>
                <FormLabel style={{ marginRight: '1rem', color: theme.palette.grey[600] }}>
                    <Typography marginLeft={0} variant={labelVariant}>
                        {label}
                    </Typography>
                </FormLabel>
                <RadioGroup
                    aria-label={name}
                    name={name}
                    value={value}
                    onChange={onChange}
                    style={{ display: 'flex', flexDirection: 'row', ...groupStyle }}
                >
                    {options.map((option) => (
                        <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={
                                <Radio
                                    size={radioSize}
                                    sx={{
                                        color: radioColor,
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 14,
                                        },
                                    }}
                                    checkedIcon={<Circle />}
                                />
                            }
                            label={
                                <Typography sx={{ marginLeft: '-4px' }} variant={labelVariant}>
                                    {option.label}
                                </Typography>
                            }
                        />
                    ))}
                </RadioGroup>
            </div>
            {error && !value && (
                <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                    <Typography variant="errorText" mr={3}>
                        {helperText}
                    </Typography>
                </Box>
            )}
        </FormControl>
    );
};

export default RadioField;
