/**
 * @module Menu-items/administration
 * @description This module defines the structure of the administration section in the application, which includes various menu items
 *              for different sections such as Admin Dashboard, Coupons, Advisors/Partners, and Reports.
 * @constant {Object} administration - The main administration group containing a list of child items.
 * @property {string} administration.id - The unique identifier for the administration section.
 * @property {string} administration.type - The type of the section, which is 'group' in this case.
 * @property {Array<Object>} administration.children - The list of child menu items under the administration section.
 * 
 * @typedef {Object} MenuItem
 * @property {string} id - The unique identifier for the menu item.
 * @property {string} title - The display title of the menu item.
 * @property {string} url - The URL associated with the menu item.
 * @property {React.Component} icon - The React component representing the icon for the menu item.
 * @property {boolean} breadcrumbs - A flag to indicate whether the item has breadcrumbs enabled.
 */
import { ReactComponent as AssetIcon } from 'assets/panel-assets.svg';
import { ReactComponent as DistributionIcon } from 'assets/panel-distribution.svg';
import { ReactComponent as MyPeopleIcon } from 'assets/panel-my-people.svg';

const administration = {
  id: 'administration',
  type: 'group',
  children: [
    {
      id: 'adminDashboard',
      title: 'Admin Dashboard',
      type: 'item',
      url: '/admin/analyticaldashboard',
      icon: MyPeopleIcon,
      breadcrumbs: false
    },
    {
      id: 'assets',
      title: 'Coupons',
      type: 'item',
      url: '/admin/coupons',
      icon: AssetIcon,
      breadcrumbs: false
    },
    {
      id: 'advisorsPartners',
      title: 'Advisors/Partners',
      type: 'item',
      url: '/admin/partners',
      icon: DistributionIcon,
      breadcrumbs: false
    },
    {
      id: 'reports',
      title: 'Reports',
      type: 'item',
      url: '/admin/reports',
      icon: DistributionIcon,
      breadcrumbs: false
    }
  ]
};

export default administration;
