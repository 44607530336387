import { format, getTime, formatDistanceToNow } from 'date-fns';
/**
 * @module timeUtils
 * @description
 * Utility functions for working with date formatting and calculating time differences.
 */
// ----------------------------------------------------------------------
/**
 * Formats a given date into a specified format using the `date-fns` library. 
 * If no format is provided, the default format 'dd MMM yyyy' is used.
 * @function fDate
 * @memberof utils/timeUtils
 * @param {string|Date} date - The date to format. Can be a string or a Date object.
 * @param {string} [newFormat='dd MMM yyyy'] - The format string for the date. If not provided, defaults to 'dd MMM yyyy'.
 * @returns {string} - The formatted date as a string. If the date is invalid or not provided, returns an empty string.
 */
export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

/**
 * Returns a human-readable string representing the time passed since the given date.
 * Uses the `formatDistanceToNow` function from `date-fns` to return a distance in words 
 * @function fToNow
 * @memberof utils/timeUtils
 * @param {string|Date} date - The date to compare to the current date. Can be a string or a Date object.
 * @returns {string} - A string representing the time distance from the current date, e.g., "3 days ago", "in 2 months". 
 * If the date is invalid or not provided, returns an empty string.
 */
export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}
