/**
 * @module menu-items/partnerdashboard
 * @description This module defines the structure of the partner dashboard's navigation menu.
 * The menu contains different sections and items that represent links to different partner-related pages.
 * Each item has its associated route, title, and other properties that control the navigation behavior.
 */
const partnerdashboard = {
    id: 'partnerdashboard',
    type: 'group',
    children: [
        {  
           /**
            * @property {string} id - Unique identifier for the "Number of clients" item.
            */
            id: 'numberOfClients',
            /**
             * @property {string} title - The label to display for the menu item.
             */
            title: 'Number of clients',
            /**
             * @property {string} type - Specifies that this is an individual menu item.
             */
            type: 'item',

            /**
             * @property {string} url - The URL path for the route associated with this item.
             */
            url: '/partner/numberOfClients',
            /**
             * @property {boolean} breadcrumbs - Whether or not breadcrumbs should be shown for this item.
             */
            breadcrumbs: false
        },
        {
            id: 'signupVsPaidUsers',
            title: 'Sign-ups vs Paid Users',
            type: 'item',
            url: '/partner/signupVsPaidUsers',
            breadcrumbs: false
        },
        {
            id: 'completedWills',
            title: 'Completed Wills',
            type: 'item',
            url: '/partner/completedWills',
            breadcrumbs: false
        },
        {
            id: 'incompleteWills',
            title: 'Incomplete Wills',
            type: 'item',
            url: '/partner/incompleteWills',
            breadcrumbs: false
        },
        {
            id: 'earnings',
            title: 'Earnings',
            type: 'item',
            url: '/partner/earnings',
            breadcrumbs: false
        }
    ]
};

export default partnerdashboard;