/**
 * @module RegexPatterns
 *
 * This module defines a collection of regular expressions for validating various types of input data.
 * These patterns cover names, email addresses, phone numbers, GSTINs, PAN numbers, and more.
 * The exported object provides easy access to these patterns for use throughout the application.
 */

const regex = {
    /**
     * @constant {string} nameRegex
     * @description Regular expression for validating names. Ensures only alphabetic characters are allowed.
     *
     * @example
     * const isValidName = new RegExp(regex.nameRegex).test("John");
     * console.log(isValidName); // true
     */
    nameRegex: '((^([a-zA-Z]+)$))',

    /**
     * @constant {RegExp} nameWithVariablesRegex
     * @description Regular expression for validating names with alphanumeric characters and `{}`. Spaces are also allowed.
     *
     * @example
     * const isValidNameWithVars = regex.nameWithVariablesRegex.test("Name {Variable}");
     * console.log(isValidNameWithVars); // true
     */
    nameWithVariablesRegex: /^[A-Za-z0-9{}\s]+$/,

    /**
     * @constant {RegExp} emailRegex
     * @description Regular expression for validating email addresses.
     *
     * - Ensures no spaces in the email.
     * - Requires a single `@` symbol.
     * - Ensures at least one character before `@`.
     * - Requires at least one character between `@` and the dot (`.`).
     * - Requires at least one character after the dot.
     *
     * @example
     * const isValidEmail = regex.emailRegex.test("example@mail.com");
     * console.log(isValidEmail); // true
     */
    emailRegex: /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/,

    /**
     * @constant {RegExp} phoneRegex
     * @description Regular expression for validating Indian phone numbers.
     *
     * - Must start with 6, 7, 8, or 9.
     * - Requires exactly 10 digits.
     *
     * @example
     * const isValidPhone = regex.phoneRegex.test("9876543210");
     * console.log(isValidPhone); // true
     */
    phoneRegex: /^[6789]\d{9}$/,

    /**
     * @constant {RegExp} gstinRegex
     * @description Regular expression for validating GSTIN (Goods and Services Tax Identification Number) in India.
     *
     * - Consists of 15 characters.
     * - Format: `[State Code][PAN][Entity Code][Z][Check Code]`.
     *
     * @example
     * const isValidGSTIN = regex.gstinRegex.test("27ABCDE1234F1Z5");
     * console.log(isValidGSTIN); // true
     */
    gstinRegex: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,

    /**
     * @constant {RegExp} panRegex
     * @description Regular expression for validating PAN (Permanent Account Number) in India.
     *
     * - Format: Five uppercase letters, four digits, and one uppercase letter.
     *
     * @example
     * const isValidPAN = regex.panRegex.test("ABCDE1234F");
     * console.log(isValidPAN); // true
     */
    panRegex: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,

    /**
     * @constant {RegExp} addressInputRegex
     * @description Regular expression for validating address inputs. Allows Unicode letters, numbers, spaces, commas, hyphens, and periods.
     *
     * @example
     * const isValidAddress = regex.addressInputRegex.test("123 Main St., Apt-4");
     * console.log(isValidAddress); // true
     */
    addressInputRegex: /^[\p{L}0-9\s\-,.]+$/u,

    /**
     * @constant {string} zipRegex
     * @description Regular expression (as a string) for validating ZIP codes. Accepts alphanumeric strings of 3 to 10 characters.
     *
     * @example
     * const zipPattern = new RegExp(regex.zipRegex);
     * const isValidZip = zipPattern.test("12345");
     * console.log(isValidZip); // true
     */
    zipRegex: '^[a-zA-Z0-9]{3,10}$',

    /**
     * @constant {string} domainRegex
     * @description Regular expression (as a string) for validating domain names. Ensures a valid domain structure without a protocol prefix.
     *
     * @example
     * const domainPattern = new RegExp(regex.domainRegex);
     * const isValidDomain = domainPattern.test("example.com");
     * console.log(isValidDomain); // true
     */
    domainRegex: '^(?!://)([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}$',

    /**
     * @constant {string} alphaNumericRegex
     * @description Regular expression (as a string) for validating alphanumeric strings.
     *
     * - Disallows consecutive double hyphens (`--`).
     * - Ensures the string does not start or end with whitespace or hyphens.
     *
     * @example
     * const alphaNumericPattern = new RegExp(regex.alphaNumericRegex);
     * const isValidAlphaNumeric = alphaNumericPattern.test("valid-string123");
     * console.log(isValidAlphaNumeric); // true
     */
    alphaNumericRegex: '^(?!.*--)[^\\s-].*[^-\\s]$',

    /**
     * @constant {string} ifscCodeRegex
     * @description Regular expression (as a string) for validating IFSC (Indian Financial System Code).
     *
     * - Format: Four uppercase letters, followed by a zero, and six alphanumeric characters.
     *
     * @example
     * const ifscPattern = new RegExp(regex.ifscCodeRegex);
     * const isValidIFSC = ifscPattern.test("ABCD0123456");
     * console.log(isValidIFSC); // true
     */
    ifscCodeRegex: '^[A-Z]{4}0[A-Z0-9]{6}$',
};

export default regex;
