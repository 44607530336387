/**
 * @module Views/MyWill/MyWill
 *
 * @description This module defines the `MyWill` component that renders the "My Will" page. It includes:
 * - A preview section showing how the details will appear in the final Will.
 * - A checklist for generating a Will.
 * - A button for toggling the full-screen view of the document preview.
 *
 * The component uses Material-UI's `Box`, `Typography`, `IconButton`, and other components for layout and styling.
 * Additionally, it uses the `DocumentPreview` and `WillGenerationChecklist` components, and a modal (`MyWillModal`) to display the preview in full screen.
 *
 * @example
 * // Example of using the MyWill component
 * <MyWill />
 */
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import Icon from 'components/DesignSystem/Icon';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import PageContainer from 'views/dashboard/PageContainer';
import DocumentPreview from './DocumentPreview';
import MyWillModal from './MyWillModal';
import WillGenerationChecklist from './WillGenerationChecklist';

// ==============================|| DEFAULT DASHBOARD ||============================== //

/**
 * MyWill Component
 *
 * Renders a page that displays a preview of a user's Will along with a checklist to guide them through the process of generating their Will.
 * The component includes a button that opens the full-screen modal to view the document in a larger view.
 * The component also passes the generated HTML content to the `DocumentPreview` component for display and interacts with the `WillGenerationChecklist`
 * for updating the HTML content of the Will preview.
 *
 * @component
 * @param {Object} props - The properties passed to the `MyWill` component (currently no props are passed to this component directly).
 *
 * @returns {React.Element} - The rendered `MyWill` component, which includes a document preview, checklist, and full-screen modal for the document.
 */
const MyWill = () => {
    const theme = useTheme(); // Material-UI theme hook
    // Local state to store the HTML content of the Will preview and the full-screen modal visibility
    const [html, setHtml] = useState('');
    const [openFullScreen, setOpenFullScreen] = useState(false);

    const handleOpenFullScreenModal = () => setOpenFullScreen(true); // Function to handle opening the full-screen modal
    const handleCloseFullScreenModal = () => setOpenFullScreen(false); // Function to handle closing the full-screen modal

    return (
        <>
            <Helmet>
                <title> My Will | Get Will Done </title>
            </Helmet>
            <PageContainer>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography variant="title1">My Will</Typography>
                    <Typography variant="bodyText3" color={theme.palette.grey[400]} display={'block'} width={'70%'}>
                        This preview shows you how all your details will appear in the final Will.
                        <br /> Use the Checklist as a guide of progress towards readying and Generating your Will.
                    </Typography>
                    <Box sx={{ display: 'flex', height: '100%' }}>
                        <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '660px',
                                    border: '1px solid #C5C5C5',
                                    borderRadius: '20px',
                                    overflowX: 'auto',
                                }}
                            >
                                <DocumentPreview documentHtml={html} />
                            </Box>
                            <Box sx={{ textAlign: 'end' }}>
                                <IconButton
                                    style={{
                                        color: '#fff',
                                        background: '#fff',
                                    }}
                                    onClick={handleOpenFullScreenModal}
                                >
                                    <Icon name="fullscreen-icon.png" size={21} />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            <WillGenerationChecklist setHtml={setHtml} />
                        </Box>
                    </Box>
                </Box>
                <MyWillModal open={openFullScreen} handleClose={handleCloseFullScreenModal} documentHtml={html} />
            </PageContainer>
        </>
    );
};

export default MyWill;
