/**
 * @module Pages/AdminPages/AdminComponents/TotalRevenueCard
 * @description This module contains the `TotalRevenueCard` component, which fetches and displays 
 * the total revenue generated. It shows the revenue value in a dashboard card and provides 
 * an optional smooth scroll functionality to a related section on the page.
 */
import { Skeleton } from '@mui/material';
import DashboardCard from 'components/commonComponents/cards/DashboardCard';
import { useEffect, useState } from 'react';
import { fetchTotalRevenue } from 'services/adminService';
import { fCurrencyRupee } from 'utils/formatNumber';

/**
 * The `TotalRevenueCard` component fetches the total revenue data from the backend 
 * and displays it in a dashboard card. The card is clickable, and can optionally 
 * scroll to another section of the page.
 * 
 * @component
 * @returns {JSX.Element} A dashboard card displaying the total revenue generated.
 */
const TotalRevenueCard = () => {
  const [repeatRateData, setRepeatRateData] = useState({});
  const [loading, setLoading] = useState(true);
  /**
   * Fetches the total revenue data from the backend and updates the state.
   * 
   * This function is executed inside the `useEffect` hook to fetch data once when the component mounts.
   * 
   * @async
   * @function
   * @returns {void}
   */
  useEffect(() => {
    const fetchTotalRevenueData = async () => {
      try {
        const response = await fetchTotalRevenue();
        const { totalRevenue } = response.data;
        setRepeatRateData({ totalRevenue });
      } catch (error) {
        console.error('Error fetching total revenue data:', error);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };

    fetchTotalRevenueData();
  }, []);

  return (
    <>
      {
        loading ? (
          <Skeleton variant="rectangular" height={135} sx={{ borderRadius: '1.25rem' }} />
        ) : (
          <DashboardCard
            title="Total Revenue Generated"
            subtitle={fCurrencyRupee(repeatRateData?.totalRevenue || 0)}
            // handleClick={scrollToTable}
            isClickable
            sx={{
              cursor: 'default',
              '&:hover': {
                backgroundColor: 'grey.200',
                border: '1px solid transparent',
                transform: 'none',
                boxShadow: 'none',
              },
            }}
          />
        )
      }
    </>
  );
};

export default TotalRevenueCard;
