/**
 * @module Pages/AdminPages/AdminComponents/ServiceWiseRevenue
 * @description This module contains the `ServiceWiseRevenue` component, which fetches and displays
 * revenue data for various services offered on the platform. It shows a grid of dashboard cards, 
 * each representing the revenue for a specific service.
 */
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { fCurrencyRupee } from 'utils/formatNumber';
import DashboardCard from 'components/commonComponents/cards/DashboardCard';
/**
 * The `ServiceWiseRevenue` component fetches service revenue data from the backend and displays 
 * it in the form of dashboard cards, showing the revenue for specific services.
 * 
 * @component
 * @returns {JSX.Element} A layout of dashboard cards displaying service-wise revenue.
 */
const ServiceWiseRevenue = () => {
  const [loading, setLoading] = useState(true);
  const [serviceRevenueData, setServiceRevenueData] = useState({});
  /**
   * Fetches service revenue data from the API on component mount.
   * 
   * This function makes an HTTP GET request to fetch the service revenue data and stores it in
   * the `serviceRevenueData` state. The loading state is updated based on the request status.
   * 
   * @async
   * @function
   * @returns {void}
   */
  useEffect(() => {
    const fetchRevenueData = async () => {
      try {
        const { data } = await axios.get('/admin/service-wise-revenue');
        setServiceRevenueData(data);
      } catch (error) {
        console.error('Error fetching revenue data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRevenueData();
  }, []);        // Empty dependency array ensures the effect runs only once when the component mounts.
  /**
   * Defines the list of services to display in the grid. Each service is associated with a title
   * and a key that is used to fetch the revenue data for that specific service.
   * 
   * @constant {Array} services
   * @property {string} title - The name of the service.
   * @property {string} key - The key used to fetch the corresponding revenue data for the service.
   */
  const services = [
    { title: "Make your own Will", key: "Make_your_own_Will" },
    { title: "Call with Legal Expert", key: "Call_with_Legal_Expert" },
    { title: "Review by Lawyer", key: "Review_by_Lawyer" },
  ];

  return (
    <Box id="service-wise-revenue-container" sx={{ marginTop: '35px' }} >
      <Typography variant="title2">Service Wise Revenue</Typography>
      <Box display='flex' sx={{ marginTop: '20px' }}>
        {loading ? (
          <Skeleton variant="rectangular" height={135} sx={{ borderRadius: '1.25rem' }} />
        ) : (
          <Grid container spacing={2}>
            {services.map(({ title, key }) => (
              <Grid item md={4} key={key}>
                <DashboardCard
                  title={title}
                  subtitle={fCurrencyRupee(serviceRevenueData[key] || 0)}
                  isClickable
                  sx={{
                    backgroundColor: 'primary.lighter',
                    cursor: 'default',
                    '&:hover': {
                      backgroundColor: 'primary.lighter',
                      border: '1px solid transparent',
                      transform: 'none',
                      boxShadow: 'none',
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default ServiceWiseRevenue;
