/**
 * @module Hooks/useStateWithCallback
 *
 * This custom React hook is an enhancement of the `useState` hook. It allows you to pass a callback function that
 * will be triggered whenever the state is updated, after the render cycle. This is useful when you need to perform
 * side effects or computations after the state has changed.
 *
 * @param {any} initialState - The initial state value to set when the hook is first called. This can be any data type.
 * @param {Function} callback - A function to be called whenever the state is updated. The current state will be passed as the argument to this function.
 *
 * @returns {[any, Function]} An array where:
 *  - The first element is the current state.
 *  - The second element is the setter function to update the state, just like the standard `useState` hook.
 *
 * @example
 * const [count, setCount] = useStateWithCallback(0, (newState) => {
 *   console.log("State updated:", newState);
 * });
 *
 * setCount(5);  // Logs "State updated: 5" after state update.
 */
import { useState, useRef, useEffect } from 'react';

/**
 * Custom hook that combines `useState` with the ability to run a callback when the state changes.
 *
 * @param {any} initialState - The initial state value.
 * @param {Function} callback - The callback function to run after state updates.
 *
 * @returns {[any, Function]} - Returns the current state and the setter function to update the state.
 */
export const useStateWithCallback = (initialState, callback) => {
  // State hook to hold the current state value
  const [state, setState] = useState(initialState);

  // Ref to track whether the component has mounted
  const didMount = useRef(false);

  // Effect hook that triggers the callback when state changes
  useEffect(() => {
    // If the component has mounted, execute the callback with the new state value
    if (didMount.current) {
      callback(state);
    } else {
      didMount.current = true; // Set didMount to true on initial mount
    }
  }, [state, callback]); // Only re-run the effect if state or callback changes

  // Return the state and the setter function
  return [state, setState];
};
