/**
 * @module Components/FeatureComponent/ReCaptcha
 * @description
 * This module provides a reusable `ReCaptcha` component that integrates Google reCAPTCHA into React applications.
 * It is designed for use in forms or login flows to enhance security by verifying user authenticity.
 */
import ReCAPTCHA from 'react-google-recaptcha';
import './reCaptcha.scss';

/**
 * ReCaptcha Component
 *
 * @component
 * @description
 * A React functional component that renders a Google reCAPTCHA widget. This component serves as a wrapper around
 * the `react-google-recaptcha` package, allowing easy customization and integration.
 *
 * @param {Object} props - Props passed to the component.
 * @param {Function} props.onChange - Callback function invoked when the reCAPTCHA response token is generated or changes.
 * @param {string} [props.sitekey='6LfYHrYoAAAAABadQVuKliDO4491RrZ7ThRkEAL6'] - The site key for Google reCAPTCHA, used to verify interactions.
 * @param {Object} [props.containerProps] - Additional props spread onto the outer container `<div>`.
 *
 * @returns {JSX.Element} A React component that renders the reCAPTCHA widget.
 *
 * @example
 * // Example usage of ReCaptcha component
 * import ReCaptcha from './ReCaptcha';
 *
 * const handleCaptchaChange = (token) => {
 *     console.log('Captcha token:', token);
 * };
 *
 * const App = () => (
 *     <ReCaptcha
 *         sitekey="your-site-key-here"
 *         onChange={handleCaptchaChange}
 *         style={{ marginTop: '20px' }}
 *     />
 * );
 *
 * export default App;
 */
const ReCaptcha = ({ onChange, sitekey = '6LfYHrYoAAAAABadQVuKliDO4491RrZ7ThRkEAL6', ...containerProps }) => {
    return (
        /**
         * Outer Container
         * @description A `<div>` wrapping the reCAPTCHA widget, styled using the `loginFlow-recaptcha-container` class.
         * Additional container properties can be passed via `containerProps`.
         */
        <div className="loginFlow-recaptcha-container" {...containerProps}>
            <ReCAPTCHA
                sitekey={sitekey} // The site key for verification
                onChange={onChange} // Callback for handling token changes
                className="g-recaptcha" // Additional class for styling
            />
        </div>
    );
};

export default ReCaptcha;
