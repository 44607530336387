/**
 * @module Views/PartnerDashboard/PartnerNumberOfClientsPage
 *
 * This module defines the `PartnerNumberOfClientsPage` component, which displays a table showing the total number of clients,
 * their status, and the services they have purchased. The data is fetched from the `fetchTotalNumberOfPartnerClients` service,
 * and the page provides features such as sorting, filtering, and pagination.
 *
 * @example
 * // Usage example:
 * import PartnerNumberOfClientsPage from 'path/to/PartnerNumberOfClientsPage';
 *
 * <PartnerNumberOfClientsPage />
 */
import { Box, Card, Chip, Table, TableBody, TableContainer, Typography } from '@mui/material';
import { CustomTableCell, CustomTablePagination, CustomTableRow } from 'components/DesignSystem/Table/CustomTable';
import { filter } from 'lodash';
import Scrollbar from 'oldLayouts/components/scrollbar';
import { UserListHead, UserListToolbar } from 'oldLayouts/components/user';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { fetchTotalNumberOfPartnerClients } from 'services/partnerService';
import PageContainer from 'views/dashboard/PageContainer';

/**
 * Comparator function used for sorting the table data. If the property is 'service', the comparison is based on the
 * length of the services array. Otherwise, it compares based on the value of the property.
 *
 * @param {Object} a - The first element to compare.
 * @param {Object} b - The second element to compare.
 * @param {string} orderBy - The property to compare by (e.g., 'service', 'email', etc.).
 *
 * @returns {number} A negative number if `a[orderBy]` is less than `b[orderBy]`,
 * a positive number if `a[orderBy]` is greater than `b[orderBy]`, and 0 if they are equal.
 */
function descendingComparator(a, b, orderBy) {
  if (orderBy === 'service') {
    // Sort by the length of the service array
    const aLength = a[orderBy] ? a[orderBy].length : 0;
    const bLength = b[orderBy] ? b[orderBy].length : 0;
    return bLength - aLength;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 * Returns a comparator function based on the order and the property to sort by.
 *
 * @param {string} order - The current sort order ('asc' or 'desc').
 * @param {string} orderBy - The property to compare the values of.
 *
 * @returns {function} A comparator function that can be used to sort an array.
 */
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * Sorts and filters an array based on the given comparator and search query.
 *
 * @param {Array} array - The array to be sorted and filtered.
 * @param {function} comparator - The comparator function used to sort the array.
 * @param {string} query - The search query to filter the data.
 *
 * @returns {Array} The sorted and filtered array.
 */
function stableSort(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        JSON.stringify(_user ?? {})
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

/**
 * Constant defining the table headers for the clients table. Each object defines the column's label, id,
 * and whether the column is numeric.
 *
 * @constant {Array} TABLE_HEAD
 * @default
 */
const TABLE_HEAD = [
  {
    id: 'email',
    numeric: true,
    label: 'Email',
    alignRight: false,
  },
  {
    id: 'name',
    disablePadding: true,
    label: 'Client Name',
    alignRight: false,
  },
  {
    id: 'phone',
    numeric: true,
    label: 'Phone',
    alignRight: false,
  },
  {
    id: 'pan',
    label: 'PAN',
    alignRight: false,
  },
  {
    id: 'status',
    label: 'Status',
    alignRight: false,
  },
  {
    id: 'service',
    label: 'Service',
    alignRight: false,
  },
];

/**
 * `PartnerNumberOfClientsPage` is a React component that displays the total number of clients, their status,
 * and the services they have purchased. It provides functionality for sorting, filtering, and pagination of the client data.
 *
 * @component
 * @example
 * // Usage example:
 * return <PartnerNumberOfClientsPage />;
 *
 * @returns {JSX.Element} The rendered component for displaying the number of clients.
 */
const PartnerNumberOfClientsPage = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('email');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [numberOfClientsData, setNumberOfClientsData] = useState([]);
  const [filterName, setFilterName] = useState('');

  /**
   * Fetches the total number of clients data from the server and updates the `numberOfClientsData` state.
   *
   * @async
   * @returns {void}
   */
  const fetchClientsData = async () => {
    try {
      const response = await fetchTotalNumberOfPartnerClients(true);
      const { testatorList } = response.data;
      const updatedTestatorList = testatorList?.map((client) => {
        if (client?.subscriptions?.length > 0) {
          return {
            ...client,
            status: 'Service purchased',
            service: client.subscriptions.map((service) => (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', margin: '4px 0' }}>
                <Chip size="small" key={service} label={service} variant="outlined" />
              </Box>
            )),
          };
        }
        return {
          ...client,
          status: 'Service not purchased',
          service: null,
        };
      });
      setNumberOfClientsData(updatedTestatorList || []);
    } catch (error) {
      console.error('Error fetching number of clients data:', error);
    }
  };

  useEffect(() => {
    // Fetch clients data when the component is mounted
    fetchClientsData();
  }, []);

  /**
   * Handles sorting when a column header is clicked. It updates the `order` and `orderBy` state variables.
   *
   * @param {object} event - The event object from the click event.
   * @param {string} property - The property to sort the data by.
   */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  /**
   * Handles page change when the user navigates through the pagination.
   *
   * @param {object} event - The event object triggered when the page is changed.
   * @param {number} newPage - The new page number.
   */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  /**
   * Handles rows per page change when the user adjusts the rows per page in the pagination.
   *
   * @param {object} event - The event object triggered when rows per page is changed.
   */
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when the rows per page is changed
  };

  /**
   * Calculates the number of empty rows to display when the table does not fully fill the page.
   *
   * @constant {number} emptyRows
   * @default
   */
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - numberOfClientsData.length) : 0;

  /**
   * Computes the rows to display in the table by applying sorting and filtering logic.
   *
   * @constant {Array} visibleRows
   * @default
   */
  const visibleRows = useMemo(
    () =>
      stableSort(numberOfClientsData || [], getComparator(order, orderBy), filterName).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, numberOfClientsData, filterName]
  );

  /**
   * Handles filter input changes and updates the `filterName` state.
   *
   * @param {object} event - The event object triggered when the user types in the filter input.
   */
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  return (
    <PageContainer>
      <Helmet>
        <title> Number of Clients | Get Will Done </title>
      </Helmet>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Typography variant="title1">Number of Clients</Typography>
        <Box sx={{ margin: '24px 0 12px 0' }}>
          <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />
        </Box>
      </Box>
      <Card elevation={0}>
        <Scrollbar>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <UserListHead order={order} orderBy={orderBy} headLabel={TABLE_HEAD} onRequestSort={handleRequestSort} />
              <TableBody>
                {visibleRows.map((row, index) => (
                  <CustomTableRow hover tabIndex={-1} key={row.id}>
                    <CustomTableCell>{row.email}</CustomTableCell>
                    <CustomTableCell>{row.name}</CustomTableCell>
                    <CustomTableCell>{row.phone}</CustomTableCell>
                    <CustomTableCell>{row.pan}</CustomTableCell>
                    <CustomTableCell>{row.status}</CustomTableCell>
                    <CustomTableCell>{row.service}</CustomTableCell>
                  </CustomTableRow>
                ))}
                {emptyRows > 0 && (
                  <CustomTableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <CustomTableCell colSpan={6} />
                  </CustomTableRow>
                )}
              </TableBody>
              {numberOfClientsData.length === 0 && (
                <TableBody>
                  <CustomTableRow>
                    <CustomTableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      No Client Data Found
                    </CustomTableCell>
                  </CustomTableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <CustomTablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={numberOfClientsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </PageContainer>
  );
};

export default PartnerNumberOfClientsPage;
