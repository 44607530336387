/**
 * @module Views/Distribution/ScenarioTabs
 * @description A React component that renders tabs for managing multiple scenarios in an asset distribution system.
 * This component supports functionality for adding, selecting, and deleting scenarios,
 * as well as rendering distribution cards for each user/asset.
 */
import { Box, Chip, Stack, Typography, useTheme } from '@mui/material';
import Icon from 'components/DesignSystem/Icon';
import { noop } from 'lodash';
import DistributionCard from './DistributionCard';

/**
 * Renders a tabbed interface for managing scenarios and their associated distributions.
 *
 * @function ScenarioTabs
 * @param {Object} props - Props passed to the component.
 * @param {function} props.handleAddClick - Callback function triggered when the "Add" chip is clicked to add a new scenario.
 * @param {Object} props.generics - Contains generic data including `scenarios`, `beneficiaries`, etc.
 * @param {Array} props.generics.scenarios - List of all scenarios with their details.
 * @param {Array} props.generics.beneficiary - List of all beneficiaries with `id`, `name`, and `relation` properties.
 * @param {Array} props.filteredUsers - Array of user/asset data filtered by the selected scenario.
 * @param {Array} props.mappings - Array of mappings defining the distribution of assets across scenarios.
 * @param {function} props.setMappings - Callback to update the state of mappings.
 * @param {function} props.setDistributionError - Callback to set an error state when distribution is invalid.
 * @param {number} props.selectedScenarioTab - Index of the currently selected scenario tab.
 * @param {function} props.setSelectedScenarioTab - Callback to update the selected scenario tab index.
 * @param {Object} props.selectedScenario - Data for the currently selected scenario.
 * @param {function} props.handleOnResetClick - Callback triggered when a distribution reset is initiated.
 * @param {function} props.deleteScenario - Callback to delete the currently selected scenario.
 * @param {boolean} [props.hideEdit=false] - If true, hides the edit/delete options for scenarios.
 *
 * @returns {JSX.Element} The rendered ScenarioTabs component.
 */
const ScenarioTabs = ({
  handleAddClick,
  generics = {},
  filteredUsers = [],
  mappings = [],
  setMappings = noop,
  setDistributionError = noop,
  selectedScenarioTab = 0,
  setSelectedScenarioTab = noop,
  selectedScenario = {},
  handleOnResetClick = noop,
  deleteScenario = noop,
  hideEdit = false
}) => {
  const theme = useTheme();  // Material-UI theme object for accessing styling properties.

  /**
  * Handles the click event for a scenario chip.
  *
  * @function handleChipClick
  * @param {number} index - The index of the selected scenario chip.
  * @description Updates the selected scenario tab and resets the distribution error state.
  */
  const handleChipClick = (index) => {
    setDistributionError(false);
    setSelectedScenarioTab(index);
  };

  return (
    <Box sx={{ padding: '16px 0' }}>
      {/* Chips for each scenario */}
      <Stack direction="row" spacing={1} justifyContent={'space-between'}>
        <Box>
          {!hideEdit && <Chip
            label={
              <Typography variant="bodyText4" color={theme.palette.grey[600]}>
                Add +
              </Typography>
            }
            onClick={() => {
              // Logic to add a new scenario
              handleAddClick();
            }}
            sx={{
              borderColor: 'transparent',
              borderWidth: '1px',
              borderStyle: 'solid',
              backgroundColor: '#F5F5F5',
              color: '#707070',
              fontWeight: 'normal',
              padding: '0 4px',
              marginRight: '5px',
            }}
          />}
          {generics?.scenarios?.map((scenario, index) => (
            <Chip
              key={index}
              label={<Typography variant="bodyText4">{`Scenario ${index + 1}`}</Typography>}
              onClick={() => handleChipClick(index)}
              sx={{
                borderColor: selectedScenarioTab === index ? theme.palette.primary.main : 'transparent',
                borderWidth: selectedScenarioTab === index ? '1px' : '0px',
                borderStyle: 'solid',
                backgroundColor: selectedScenarioTab === index ? '#FFF3EA' : '#F5F5F5',
                color: selectedScenarioTab === index ? theme.palette.primary.main : '#707070',
                fontWeight: selectedScenarioTab === index ? 'bold' : 'normal',
                margin: '0 5px',
              }}
            />
          ))}
        </Box>
        {!hideEdit && generics?.scenarios?.length > 0 && (
          <Box onClick={deleteScenario}>
            <Icon name="delete-icon-orange-pink-bg.png" size={27} />
          </Box>
        )}
      </Stack>
      {generics?.scenarios?.length > 0 && (
        <>
          <Box p={'20px 0'}>
            <Typography variant="bodyText3" color={theme.palette.grey[400]}>
              In case of demise(s) of
              <Typography variant="bodyText3" color={theme.palette.grey[600]}>
                {selectedScenario?.deaths?.length > 0
                  ? `${selectedScenario?.deaths
                    .map((beneId) => {
                      const bene = generics?.beneficiary?.find((bene) => bene.id === beneId);
                      return ` ${bene.name} (${bene.relation})`;
                    })
                    .join(' and ')}, `
                  : ''}
              </Typography>
              how would you like to distribute your assets?
            </Typography>
          </Box>
          <Box>
            {filteredUsers?.map((row, index) => {
              return (
                <DistributionCard
                  asset={row}
                  key={index}
                  mappings={mappings}
                  handleOnResetClick={handleOnResetClick}
                  generics={generics}
                  setMappings={setMappings}
                  selectedScenario={selectedScenario}
                  hideEdit={hideEdit}
                />
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
};

export default ScenarioTabs;
