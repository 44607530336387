/**
 * @module Components/Logo
 *
 * @description The `Logo` component is used to display the logo of the application. It provides a flexible way to show either a full logo or a minimized version of the logo, depending on the provided `minimized` prop. The logo is displayed within a clickable link that redirects to the home page unless the `disabledLink` prop is set to `true`, which disables the link.
 *
 * This component allows for customization using the `sx` prop for additional styles.
 *
 * @component
 */

import { Box, Link } from '@mui/material';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

/**
 * `Logo` component displays the application logo with optional features like a minimized version and a link to the homepage.
 *
 * @function
 * @param {Object} props - The component props.
 * @param {string} [props.src='/assets/will_logo.png'] - The source URL of the logo image. Defaults to `/assets/will_logo.png`.
 * @param {boolean} [props.disabledLink=false] - If `true`, the logo will not be a clickable link. Defaults to `false`.
 * @param {Object} [props.sx] - Custom styles to be applied to the logo container, using the `sx` prop from MUI.
 * @param {boolean} [props.minimized=false] - If `true`, the minimized version of the logo will be displayed. Defaults to `false`.
 * @param {{...Object}} props.other - Additional props passed to the `Box` component.
 *
 * @returns {JSX.Element} - A `Box` component containing the logo, which can either be a clickable link or a static image depending on the `disabledLink` prop. If `minimized` is `true`, a minimized logo is shown.
 */
const Logo = forwardRef(
  ({ src = '/assets/will_logo.png', disabledLink = false, sx, minimized = false, ...other }, ref) => {
    // Main logo
    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: '100%',
          maxWidth: '350px',
          // paddingLeft: '10%',
          display: 'flex', // Added Later
          justifyContent: 'center', // Added Later
          alignItems: 'center', // Added Later
          ...sx,
        }}
        {...other}
      >
        <img width={'90%'} src={src} alt={'logo'} />
      </Box>
    );

    // Minimized logo
    const miniLogo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: '100%',
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <img src={'/assets/will_mini_logo.png'} alt={'logo'} />
      </Box>
    );

    // Return the logo wrapped in a link or not depending on the disabledLink prop
    if (disabledLink) {
      return <>{logo}</>;
    }

    return (
      <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
        {minimized ? miniLogo : logo}
      </Link>
    );
  }
);

/**
 * `Logo` PropTypes:
 *
 * - `sx`: Custom styles to be applied to the logo container, passed as an object.
 * - `disabledLink`: If `true`, disables the link behavior of the logo. Defaults to `false`.
 * - `minimized`: If `true`, the minimized version of the logo is displayed. Defaults to `false`.
 * - `src`: The source URL for the logo image. Defaults to `/assets/will_logo.png`.
 */
Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
  minimized: PropTypes.bool,
};

export default Logo;
