/**
 * @module Pages/AdminPages/AdminComponents/AgeDemographicChart
 * @description This module contains the AgeDemographicChart component used to display a bar chart of age demographics for the admin dashboard.
 */

import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import { Box, Card, Typography, useTheme } from '@mui/material';
import axios from 'axios';
/**
 * A chart component that displays age demographics data in a bar chart.
 * 
 * The chart is rendered based on the fetched data from the `/admin/age-demographics` API endpoint. 
 * If the data is successfully retrieved, it is displayed as a bar chart with the age groups on the x-axis and their respective counts on the y-axis.
 * 
 * If there is no data to display, a message is shown indicating that no data is available.
 *
 * @component
 * @returns {JSX.Element} A `Card` component containing a `Typography` header and a `canvas` for the chart, or a message indicating no data is available.
 */
const AgeDemographicChart = () => {
  const [ageDemographicsData, setAgeDemographicsData] = useState({});
  const chartContainer = useRef(null);
  const theme = useTheme();

  const labels = Object.keys(ageDemographicsData);
  const counts = Object.values(ageDemographicsData);

  /**
 * Fetches the age demographics data from the API and sets it in the state.
 * 
 * This effect runs once when the component mounts, and retrieves data from the API endpoint `/admin/age-demographics`.
 * If the request is successful, the data is stored in the `ageDemographicsData` state.
 */
  useEffect(() => {
    const fetchAgeDemographicsData = async () => {
      try {
        const response = await axios.get('/admin/age-demographics');
        const { ageDemographics } = response.data;
        setAgeDemographicsData(ageDemographics);
      } catch (error) {
        console.error('Error fetching age demographic data:', error);
      }
    };

    fetchAgeDemographicsData();
  }, []);

  /**
 * Initializes and updates the chart when the age demographics data changes.
 * 
 * This effect runs whenever the `ageDemographicsData` state is updated. It creates a new bar chart using the Chart.js library, 
 * with the labels representing age groups and the counts representing the number of people in each age group. The chart is displayed on a canvas element.
 * 
 * Cleanup is performed by destroying the chart instance when the component unmounts or when the data changes.
 */
  useEffect(() => {
    let myChart = null; // Declare a variable to hold the Chart instance

    if (chartContainer && chartContainer.current) {
      const ctx = chartContainer.current.getContext('2d');

      // Destroy the existing Chart instance if it exists
      if (myChart) {
        myChart.destroy();
      }

      // Create a new Chart instance
      myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels,
          datasets: [
            {
              label: 'Age Demographics',
              data: counts,
              backgroundColor: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              // max: 5,  // Max value 
              beginAtZero: true,
              min: 0,
              ticks: {
                stepSize: 1,
              },
            },
          },
          plugins: {
            legend: {
              fullSize: false,
              position: 'top',
              align: 'end',
            },
            noDataMessage: {
              text: 'No data available',
              color: 'black',
              fontSize: 18,
            },
          },
        },
      });
    }
    // Cleanup function to destroy the Chart instance when the component unmounts
    return () => {
      if (myChart) {
        myChart.destroy();
      }
    };
  }, [ageDemographicsData]);

  return (
    <Card sx={{ position: 'relative', padding: '24px', width: '100%', backgroundColor: theme.palette.grey[200], borderRadius: '15px', boxShadow: 'none' }}>
      <Typography variant="heading1">Age Demographics</Typography>
      {Object.keys(ageDemographicsData).length === 0 ? (
        <Box sx={{ height: '300px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="heading1">No Data To Display</Typography>
        </Box>
      ) : (
        <Box sx={{ height: '300px', width: '100%' }}>
          <canvas ref={chartContainer} id="chart" />
        </Box>
      )}
    </Card>
  );
};

export default AgeDemographicChart;
