/**
 * @module Views/Distribution/PrimaryDistribution
 * @description The `PrimaryDistribution` component handles the management and editing of primary asset distributions. It supports various functionalities such as resetting, saving, and updating distribution mappings. It also integrates modal dialogs for confirmation and distribution selection.
 */
import { Box, Chip, Typography, useTheme } from '@mui/material';
import { ContextProvider } from 'App';
import AlertDialog from 'components/DesignSystem/AlertDialog';
import BottomFloatingAction from 'components/DesignSystem/BottomFloatingAction';
import useSwalAlert from 'components/DesignSystem/SwalAlert';
import SelectDistributionModal from 'components/featureComponent/distribution/SelectDistributionModal';
import { cloneDeep, noop } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { deleteScenario, deleteWill, updateMappings } from 'services/distributionService';
import DistributionCard from './DistributionCard';

/**
 * @component
 * @description A component for managing primary asset distributions, allowing users to view, edit, save, and reset distribution configurations.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array<Object>} props.filteredUsers - The list of filtered user assets to be distributed.
 * @param {Array<Object>} props.normalizedWills - The list of wills with normalized data, focusing on the primary scenario (ScenarioId = 0).
 * @param {Array<Object>} props.mappings - The current mappings of asset distributions.
 * @param {Object} props.generics - General data, including beneficiaries, assets, and scenarios.
 * @param {Function} props.setMappings - Function to update the mappings state.
 * @param {number|null} props.mappingBalance - The count of mappings with an imbalance in asset distributions.
 * @param {Array<boolean>} props.petMappingBalance - Array indicating if pet/vehicle mappings are correctly distributed.
 * @param {Function} props.fetchWillsData - Function to fetch and refresh will data.
 * @param {boolean} props.loading - Indicates whether data is being loaded.
 * @param {Function} props.setLoading - Function to update the loading state.
 * @param {boolean} props.hideEdit - Determines whether editing options should be hidden.
 *
 * @returns {JSX.Element} The rendered PrimaryDistribution component.
 */
const PrimaryDistribution = ({
  filteredUsers = {},
  normalizedWills = [],
  mappings = {},
  generics = {},
  setMappings = noop,
  mappingBalance = null,
  petMappingBalance = [],
  fetchWillsData = noop,
  loading = false,
  setLoading,
  hideEdit = false,
}) => {
  const theme = useTheme(); // Theme from Material-UI.
  /** State Management */
  const [selectDistributionModalOpen, setSelectDistributionModalOpen] = useState(false);
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [distributionError, setDistributionError] = useState(false);
  const [resetInfo, setResetInfo] = useState({});
  const [previousMappings, setPreviousMappings] = useState({});
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({
    modalTitle: '',
    title: '',
    primaryButtonLabel: '',
    secondaryButtonLabel: '',
    onPrimaryButtonClick: () => setDialogOpen(false),
    onSecondaryButtonClick: () => { },
    isSingleButton: false,
  }); // Configuration for dialog content.

  /**
   * Closes the dialog box and resets its configuration after a timeout.
   */
  const handleDialogClose = () => {
    setDialogOpen(false);
    setTimeout(() => {
      setDialogConfig({
        modalTitle: '',
        title: '',
        primaryButtonLabel: '',
        secondaryButtonLabel: '',
        onPrimaryButtonClick: () => setDialogOpen(false),
        onSecondaryButtonClick: () => { },
        isSingleButton: false,
      });
    }, 200);
  };

  const { user } = useContext(ContextProvider); // User context.
  const showSwalAlert = useSwalAlert(); // Custom hook for showing SweetAlert.
  /** Derived State */
  const primaryMappings = normalizedWills?.filter((will) => will.ScenarioId === 0);
  const beneficiaryNotAdded = generics?.beneficiary?.length === 0;

  /**
   * Initializes mappings with normalized data and stores a deep copy in `previousMappings`.
   */
  useEffect(() => {
    const scenarioMappings = normalizedWills.filter((will) => will.ScenarioId === 0);
    setMappings(scenarioMappings);
    setPreviousMappings(cloneDeep(scenarioMappings)); // To create deep copy
  }, [normalizedWills, generics?.scenarios, loading]);

  /** Memoized Values */
  const selectedScenario = useMemo(() => {
    return { id: 0, deaths: [] };
  }, [generics?.scenarios]);

  // useEffect(() => {
  //     console.log("in useeffecttt mappings", mappings);
  //     console.log("in useeffecttt generics.assets", generics.assets);

  //     if (mappings.length === 0 && generics?.assets?.length > 0) {
  //         handleSelectDistributionDialogOpen();
  //     } else {
  //         handleOnClose();
  //     }
  // }, [generics?.assets, mappings]);

  /**
   * Opens the distribution selection dialog if no mappings exist and editing is allowed.
   */
  useEffect(() => {
    // Ensure generics.assets is loaded before running the logic
    if (generics?.assets && mappings.length === 0 && !loading && !hideEdit) {
      handleSelectDistributionDialogOpen();
    } else if (mappings.length > 0) {
      handleOnClose();
    }
  }, [generics?.assets, mappings]);

  /** Handlers for various user interactions */

  const handleSelectDistributionDialogOpen = () => {
    setSelectDistributionModalOpen(true);
  };

  const handleOnClose = () => {
    setSelectDistributionModalOpen(false);
  };

  const handleAlertDialogClose = () => {
    setIsAlertDialogOpen(false);
  };

  /**
   * Opens a confirmation dialog to change the distribution method.
   *
   * @function handleConfirmAlertDialogOpen
   * @description Configures and opens a dialog to confirm if the user wants to change the distribution method.
   * Updates the dialog configuration and opens the dialog.
   */
  const handleConfirmAlertDialogOpen = () => {
    setDialogConfig({
      modalTitle: 'Are you sure?',
      title: 'Are you sure you want to change distribution method?',
      primaryButtonLabel: 'Keep',
      secondaryButtonLabel: 'Change',
      onPrimaryButtonClick: handleDialogClose,
      onSecondaryButtonClick: handleConfirmAlertConfirmation,
    });
    setDialogOpen(true);
  };

  /**
   * Handles confirmation from the dialog to change the distribution method.
   *
   * @function handleConfirmAlertConfirmation
   * @description Closes the confirmation dialog and opens the distribution selection modal.
   */
  const handleConfirmAlertConfirmation = () => {
    handleDialogClose();
    handleSelectDistributionDialogOpen();
  };

  /**
   * Handles reset operation for a specific scenario and asset.
   *
   * @function handleOnResetClick
   * @param {number} selectedScenarioId - The ID of the scenario to reset.
   * @param {number} assetId - The ID of the asset to reset.
   * @description Updates reset information and opens the reset confirmation dialog.
   */
  const handleOnResetClick = (selectedScenarioId, assetId) => {
    setResetInfo({ scenarioId: selectedScenarioId, assetId });
    setIsAlertDialogOpen(true);
  };

  /**
   * Confirms and performs a reset operation for a specific scenario and asset.
   *
   * @async
   * @function handleResetConfirmation
   * @description Deletes the will for the specified scenario and asset, and refreshes the data.
   */
  const handleResetConfirmation = async () => {
    try {
      setLoading(true);
      await deleteWill(resetInfo.scenarioId, resetInfo.assetId);
      await fetchWillsData();
    } catch (err) {
      console.log(err);
    } finally {
      setIsAlertDialogOpen(false);
      setLoading(false);
    }
  };

  /**
   * Updates the mappings data with the current state.
   *
   * @async
   * @function updateMappingsData
   * @description Sends the current mappings to the server and displays success or error alerts based on the outcome.
   */
  const updateMappingsData = useCallback(async () => {
    // setLoading(true);
    try {
      const payload = {
        // mappings: mappings.filter((map) => map.id !== -99) ?? [],
        mappings,
      };
      const response = await updateMappings(0, payload);
      showSwalAlert({
        title: `Distribution updated successfully`,
        icon: 'success',
        timer: 3000,
      });
      await fetchWillsData();
      setLoading(false);
    } catch (err) {
      showSwalAlert({
        icon: 'error',
        title: `Failed to update asset`,
        timer: 3000,
      });
      // setLoading(false)
    }
  }, [mappings]);

  /**
   * Validates the distribution totals for each asset.
   *
   * @function validateDistribution
   * @description Checks if the total distribution for each asset exceeds its value or quantity, and updates the error state.
   */
  useEffect(() => {
    let error = false;
    filteredUsers.forEach((row) => {
      const { id, information } = row;
      const will = mappings?.find(({ PropertyId }) => {
        return PropertyId === id;
      });
      let value = 100;
      let total = 0;
      if (will?.distribution_type === 'value') {
        value =
          information?.value ??
          information?.amount ??
          information?.insured_amount ??
          information?.investment_amount ??
          information?.deposited_amount;
      } else if (will?.distribution_type === 'quantity') {
        value = information?.quantity ?? information?.qty ?? information?.number;
      }
      will?.mapping?.forEach((mapping) => {
        total += +(mapping.share ?? 0);
      });
      if (Math.abs(Number((value - total).toFixed(2))) <= 0.0) {
        // done 0.01 to 0.00
        total = value;
      }
      if (total > value) {
        error = true;
      }
    });
    setDistributionError(error);
  }, [filteredUsers, mappings]);

  /**
   * Saves the current distribution mappings.
   *
   * @function handleSaveDistribution
   * @description Validates the distribution and opens appropriate dialogs or updates the mappings based on the validation result.
   */
  const handleSaveDistribution = () => {
    if (petMappingBalance.length > 0 && petMappingBalance.some((value) => value === false)) {
      setDialogConfig({
        modalTitle: 'Pet/Vehicle assets not distributed properly',
        title: 'Distribution of pet/vehicle assets is limited to a single beneficiary, with entire allocation (100%).',
        primaryButtonLabel: 'Ok',
        onPrimaryButtonClick: handleDialogClose,
        isSingleButton: true,
      });
      setDialogOpen(true);
    } else if (mappingBalance > 0) {
      setDialogConfig({
        modalTitle: 'Are you sure?',
        title: 'Some assets are not distributed completely. Do you wish to continue?',
        primaryButtonLabel: 'Cancel',
        secondaryButtonLabel: 'Save',
        onPrimaryButtonClick: handleDialogClose,
        onSecondaryButtonClick: async () => {
          await updateMappingsData();
        },
      });
      setDialogOpen(true);
    } else {
      updateMappingsData();
    }
  };

  /**
   * Resets the primary distribution to its default state.
   *
   * @async
   * @function handleResetDistribution
   * @description Opens a confirmation dialog and deletes the current scenario data if confirmed.
   */
  const handleResetDistribution = async () => {
    setDialogConfig({
      modalTitle: 'Are you sure?',
      title: 'Do you want to reset the Primary Distribution?',
      primaryButtonLabel: 'No',
      secondaryButtonLabel: 'Yes',
      onPrimaryButtonClick: handleDialogClose,
      onSecondaryButtonClick: async () => {
        await deleteScenario(0);
        await fetchWillsData();
      },
    });
    setDialogOpen(true);
  };

  /**
   * Cancels the distribution changes and restores the previous mappings.
   *
   * @function handleCancelDistribution
   * @description Reverts the mappings to the last saved state.
   */
  const handleCancelDistribution = () => {
    // setMappings(structuredClone(previousMappings)); // To create deep copy
    setMappings(cloneDeep(previousMappings)); // To create deep copy
  };

  return (
    <Box paddingRight="75px" paddingBottom="100px" sx={{ position: 'relative' }}>
      <Box>
        <Box textAlign="end" paddingTop="15px" paddingBottom="17px">
          {!hideEdit && (
            <Chip
              clickable
              sx={{ maxHeight: '24px' }}
              onClick={handleConfirmAlertDialogOpen}
              label={
                <Typography color={theme.palette.primary.main} variant="smallText" sx={{ fontSize: '13px !important' }}>
                  Change Distribution Method
                </Typography>
              }
            />
          )}
        </Box>
        <Box>
          {filteredUsers?.map((row, index) => {
            return (
              <DistributionCard
                asset={row}
                key={index}
                mappings={mappings}
                primaryMappings={primaryMappings}
                handleOnResetClick={handleOnResetClick}
                generics={generics}
                setMappings={setMappings}
                selectedScenario={selectedScenario}
                hideEdit={hideEdit}
              />
            );
          })}
        </Box>
        {['testator', 'partner']?.includes(user?.role) && (
          <Box
            sx={{
              position: 'fixed',
              bottom: 50,
              left: 146,
              right: 146,
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <BottomFloatingAction
              message="Would you like to save or reset the distribution?"
              firstButtonText="Cancel"
              onFirstButtonClick={handleCancelDistribution}
              firstButtonDisabled={beneficiaryNotAdded}
              firstButtonSx={{
                minWidth: '75px',
                padding: '6px 0',
                border: `1px solid ${theme.palette.primary.main}40`,
                // height: '30px',
              }}
              secondButtonText="Reset"
              onSecondButtonClick={handleResetDistribution}
              secondButtonDisabled={beneficiaryNotAdded}
              secondButtonSx={{ minWidth: '75px', padding: '6px 0' }}
              thirdButtonText="Save Distribution"
              onThirdButtonClick={handleSaveDistribution}
              thirdButtonDisabled={beneficiaryNotAdded || distributionError}
              thirdButtonSx={{ padding: '6px 12px' }}
            />
          </Box>
        )}
      </Box>
      <SelectDistributionModal
        open={selectDistributionModalOpen}
        onClose={handleOnClose}
        generics={generics}
        setMappings={setMappings}
        filteredUsers={filteredUsers}
      />
      <AlertDialog
        open={isAlertDialogOpen}
        onClose={handleAlertDialogClose}
        title={`Are you sure you want to reset this asset?`}
        primaryButtonLabel="Keep"
        secondaryButtonLabel="Reset"
        onPrimaryButtonClick={handleAlertDialogClose}
        onSecondaryButtonClick={handleResetConfirmation}
      />
      <AlertDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        modelTitle={dialogConfig.modalTitle}
        title={dialogConfig.title}
        primaryButtonLabel={dialogConfig.primaryButtonLabel}
        secondaryButtonLabel={dialogConfig.secondaryButtonLabel}
        onPrimaryButtonClick={dialogConfig.onPrimaryButtonClick}
        onSecondaryButtonClick={dialogConfig.onSecondaryButtonClick}
        isSingleButton={dialogConfig.isSingleButton}
      />
    </Box>
  );
};

export default PrimaryDistribution;
