import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';
import Icon from '../Icon';

/**
 * @module Components/DesignSystem/CheckBox
 * @description This module contains the CheckBox component used across the application.
 */
/**
 * CustomCheckbox is a styled version of MUI's Checkbox component with no padding.
 * This customization allows the use of custom icons for both checked and unchecked states.
 *
 * @component
 * @param {object} theme - The MUI theme object, used for styling the checkbox.
 * @returns {JSX.Element} A styled checkbox component.
 */
const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    padding: 0,
}));

/**
 * CheckBox is a functional component that renders a styled checkbox with custom icons.
 * It accepts all the props of the MUI Checkbox component, along with an optional `iconSize` prop to customize the icon size.
 *
 * The component displays custom icons based on the checkbox's checked state. When unchecked, it shows a custom "unchecked" icon, and when checked, it shows a "checked" icon.
 *
 * @component
 * @param {number} iconSize - The size of the icons used for checked and unchecked states. Defaults to 16 if not provided.
 * @param {object} props - Other props passed down to the MUI Checkbox component.
 *
 * @returns {JSX.Element} A checkbox component with custom icons and optional size customization.
 */
const CheckBox = ({ iconSize = 16, ...props }) => (
    <CustomCheckbox
        icon={<Icon name="checkbox-unchecked.svg" size={iconSize} />} // Set custom icon for unchecked state
        checkedIcon={<Icon name="checked.svg" size={iconSize} />} // Set custom icon for checked state
        {...props}
    />
);

export default CheckBox;
