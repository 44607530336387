/**
 * @module Components/CustomToggleButtonGroup
 *
 * @description The `CustomToggleButtonGroup` component renders a group of toggle buttons styled with custom styles. Each button in the group can be toggled on or off, and only one button can be selected at a time (`exclusive` behavior). The component allows for customization of button labels and handling of state changes through the `onChange` prop.
 *
 * It uses the Material-UI `ToggleButtonGroup` and `ToggleButton` components, but overrides their default styles to create a custom appearance for the toggle buttons. The selected button is highlighted with a custom background color and border.
 *
 * @component
 */
import { styled, Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

/**
 * Custom styled version of the `ToggleButton` component.
 * The button has a custom height, padding, background color, border, and hover effects.
 *
 * @function CustomToggleButton
 * @param {Object} theme - The Material UI theme.
 * @returns {JSX.Element} - A styled `ToggleButton` component.
 */
const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
    height: '2.5rem',
    padding: '13.5px 16px',
    minWidth: '123px',
    borderRadius: '10px',
    border: 'none',
    backgroundColor: '#F5F5F5',
    color: '#707070',
    marginRight: '10px',
    textTransform: 'none',
    '&:last-child': {
        marginRight: 0,
    },
    '&.Mui-selected': {
        backgroundColor: '#FFF3EA',
        color: '#1E1C1B',
        border: '1px solid #FFA25C',
    },
    '&.Mui-selected:hover': {
        backgroundColor: '#FFD4B8',
    },
}));

/**
 * `CustomToggleButtonGroup` component renders a group of toggle buttons.
 * Each button can be toggled on or off, with a custom appearance.
 *
 * @function
 * @param {Object} props - The component props.
 * @param {Array} props.buttons - An array of button objects with `value` and `label` properties.
 * @param {string} props.value - The current selected value, used to control which button is selected.
 * @param {Function} props.onChange - The callback function triggered when the selected button changes.
 * @param {boolean} [props.disabled=false] - If `true`, the toggle buttons are disabled.
 *
 * @returns {JSX.Element} - A `ToggleButtonGroup` component containing custom styled toggle buttons.
 */
const CustomToggleButtonGroup = ({ buttons, value, onChange, disabled = false }) => {
    const handleChange = (event, newValue) => {
        // Prevent deselecting the selected button
        if (newValue !== null) {
            onChange(event, newValue);
        }
    };

    return (
        <ToggleButtonGroup
            color="primary"
            value={value}
            exclusive
            onChange={handleChange}
            disabled={disabled}
            aria-label="Platform"
            sx={{
                marginTop: '2px',
                display: 'flex',
                border: 'none',
                borderRadius: '20px',
                '& .MuiToggleButtonGroup-grouped': {
                    borderRadius: '20px',
                },
                '& .MuiToggleButtonGroup-grouped:not(:last-of-type)': {
                    borderRadius: '20px',
                },
                '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
                    borderRadius: '20px',
                    borderLeft: 'none',
                },
                '& .MuiToggleButtonGroup-grouped:not(:first-of-type).Mui-selected': {
                    borderRadius: '20px',
                    borderLeft: '1px solid #FFA25C',
                },
            }}
        >
            {buttons.map((button) => (
                <CustomToggleButton key={button.value} value={button.value} disabled={disabled}>
                    <Typography variant="bodyText3">{button.label}</Typography>
                </CustomToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};

/**
 * `CustomToggleButtonGroup` PropTypes:
 *
 * - `buttons` (array): An array of objects representing the toggle buttons. Each object should have a `value` (string or number) and `label` (string) property.
 * - `value` (string | number): The currently selected value, which controls the selected button.
 * - `onChange` (function): A callback function to handle the change in selection. It is triggered when the selected button changes.
 * - `disabled` (boolean, optional): If `true`, all toggle buttons will be disabled. Defaults to `false`.
 */
export default CustomToggleButtonGroup;
