/**
 * MyPeopleCard Component
 *
 * A reusable card component designed for interactive selection or addition of "people" in a user interface.
 * Features dynamic styling and hover effects, and is configurable via props to control behavior and appearance.
 *
 * @module Components/FeatureComponent/MyPeopleCard
 * @param {Object} props - Component properties.
 * @param {React.ElementType} props.icon - Icon component to render in the card.
 * @param {string} props.title - The title text displayed in the card.
 * @param {Function} props.onClick - Callback function triggered when the card is clicked, disabled if `isFulled` is true.
 * @param {boolean} props.isFulled - Determines whether the card is in a "full" state, which disables interaction.
 *
 * @example
 * <MyPeopleCard
 *   icon={MyIconComponent}
 *   title="Add Executor"
 *   onClick={() => console.log('Card clicked')}
 *   isFulled={false}
 * />
 */
import { Box, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { useState } from 'react';
import { IoAddCircle, IoAddCircleOutline } from 'react-icons/io5';

/**
 * A styled card container with conditional styling based on the `isFulled` prop.
 * Uses MUI's `styled` utility to apply styles dynamically.
 *
 * @param {Object} theme - MUI theme object for dynamic theming.
 * @param {boolean} isFulled - Controls the styling of the card.
 */
const StyledCard = styled(Box)(({ theme, isFulled }) => ({
  backgroundColor: isFulled ? '#fff' : '#FFF3EA',
  borderRadius: '12px',
  boxShadow: 'none',
  transition: 'border-color 0.10s ease', // Transition only the border color
  border: isFulled ? `2px dashed ${theme.palette.primary.main}` : `1px solid transparent`,
  minWidth: '167px',
  cursor: isFulled ? 'not-allowed' : 'pointer',
  '&:hover': {
    borderColor: isFulled ? theme.palette.primary.main : theme.palette.primary.light,
    boxShadow: isFulled ? 'none' : `0px 10px 10px -8px ${theme.palette.primary.dark}4D`,
  },
}));

/**
 * MyPeopleCard Component
 *
 * @param {React.ElementType} props.icon - The icon component to be displayed on the card.
 * @param {string} props.title - The title of the card.
 * @param {Function} props.onClick - Function to call when the card is clicked, disabled when `isFulled` is true.
 * @param {boolean} props.isFulled - If true, disables click actions and changes the card appearance.
 */
const MyPeopleCard = ({ icon: Icon, title, onClick, isFulled }) => {
  const theme = useTheme(); // Access theme for consistent styling
  const [isHovered, setIsHovered] = useState(false); // State for hover effect

  return (
    <StyledCard
      isFulled={isFulled}
      onClick={!isFulled ? onClick : undefined} // Disable click if isFulled is true
      onMouseEnter={() => !isFulled && setIsHovered(true)}
      onMouseLeave={() => !isFulled && setIsHovered(false)}
      sx={{ maxHeight: '100px', maxWidth: '167px' }}
    >
      <Box
        p={'0 8px 10px 8px'}
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}
      >
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <Icon style={{ fontSize: '36px' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="bodyText3">{title}</Typography>
          {isHovered ? (
            <IoAddCircle style={{ fontSize: '18px', color: theme.palette.primary.main }} />
          ) : (
            <IoAddCircleOutline style={{ fontSize: '18px', color: theme.palette.primary.main }} />
          )}
        </Box>
      </Box>
    </StyledCard>
  );
};

export default MyPeopleCard;
