/**
 * @module Components/Iconify
 *
 * @description The `Iconify` component is a wrapper around the `Icon` component from the `@iconify/react` package. It allows you to easily display
 * SVG icons from the Iconify library in your React components. The component accepts an icon name or an element, adjusts its size
 * based on the provided `width` prop, and allows for additional custom styles and properties through the `sx` and `other` props.
 *
 * @component
 */
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// icons
import { Icon } from '@iconify/react';
// @mui
import { Box } from '@mui/material';

/**
 * `Iconify` is a React component used for displaying icons from the Iconify library.
 * It allows for easy rendering of SVG icons with customizable size, style, and other properties.
 *
 * @function
 * @param {Object} props - The component props.
 * @param {string|element} props.icon - The icon name (as a string) or an icon element from the Iconify library.
 * @param {number|string} [props.width=20] - The width of the icon. Defaults to 20 if not provided.
 * @param {Object} [props.sx] - Custom styles to be applied to the icon component using the `sx` prop from MUI.
 * @param {{...Object}} props.other - Additional props passed to the `Icon` component from Iconify.
 *
 * @param {React.Ref} ref - A forwarded reference to the `Box` component.
 *
 * @returns {JSX.Element} - A MUI `Box` component containing the `Icon` from Iconify, styled with the provided width and `sx` props.
 */
const Iconify = forwardRef(({ icon, width = 20, sx, ...other }, ref) => (
  <Box ref={ref} component={Icon} icon={icon} sx={{ width, height: width, ...sx }} {...other} />
));

/**
 * `Iconify` PropTypes:
 * 
 * - `sx`: Custom styles to apply to the icon, passed as an object.
 * - `width`: Defines the size (width and height) of the icon. Can be a number or string. Defaults to 20.
 * - `icon`: The icon name (as a string) or an icon element. This is required.
 */
Iconify.propTypes = {
  sx: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default Iconify;
