import axios from "axios";
/**
 * Fetches the list of assets (properties).
 * @module assetService/fetchAssets
 * @description
 * This function fetches the list of assets (properties) available in the system. It does not support any 
 * optional parameters and simply retrieves the full list of assets from the API.
 * @returns {Promise<Object>} The API response containing the list of assets (properties).
 */
export const fetchAssets = async () => {
    const response = await axios.get(`/properties`);
    return response;
}

/**
 * Fetches a specific asset (property) by its ID.
 * @module assetService/fetchAssetById
 * @description
 * This function fetches the details of a specific asset (property) based on the provided asset ID.
 * It makes a GET request to the API endpoint to retrieve information about a single asset.
 * @param {string|number} id - The unique identifier of the asset (property) to fetch.
 * @returns {Promise<Object>} The API response containing the details of the specified asset.
 */
export const fetchAssetById = async (id) => {
    const response = await axios.get(`/properties/${id}`);
    return response;
}

/**
 * Adds a new asset (property) to the system..
 * @module assetService/addAsset
 * @description
 * This function adds a new asset to the system by making a POST request to the `/properties` endpoint. 
 * The function accepts details about the asset, including its category, type, subType, and asset details, 
 * and submits this information to the server for processing and storage.
 * @param {Object} assetData - The data of the asset to be added.
 * @param {string} assetData.category - The category of the asset (e.g., 'Real Estate', 'Vehicles').
 * @param {string} assetData.type - The type of the asset (e.g., 'House', 'Car').
 * @param {string} assetData.subType - The subtype of the asset (e.g., 'Apartment', 'SUV').
 * @param {Object} assetData.asset - Additional asset details such as value, owner, etc.
 * @returns {Promise<Object>} The API response after submitting the new asset data.
 */
export const addAsset = async ({ category, type, subType, asset }) => {
    const response = axios.post("/properties", { category, type, subType, asset });
    return response;
}

/**
 * Updates the details of an existing asset (property).
 * @module assetService/updateAsset
 * @description
 * This function updates the details of an existing asset in the system by making a POST request 
 * to the `/properties/{id}` endpoint, where `{id}` is the unique identifier of the asset to be updated.
 * The function accepts the new asset details, including category, type, subType, and asset information, 
 * and submits the updated data to the server.
 * @param {string|number} id - The unique identifier of the asset to update.
 * @param {Object} assetData - The new details of the asset to update.
 * @param {string} assetData.category - The category of the asset (e.g., 'Real Estate', 'Vehicles').
 * @param {string} assetData.type - The type of the asset (e.g., 'House', 'Car').
 * @param {string} assetData.subType - The subtype of the asset (e.g., 'Apartment', 'SUV').
 * @param {Object} assetData.asset - Additional asset details such as value, owner, etc.
 * @returns {Promise<Object>} The API response after submitting the updated asset data.
 */
export const updateAsset = async (id, { category, type, subType, asset }) => {
    const response = await axios.post(`/properties/${id}`, { category, type, subType, asset });
    return response;
}

/**
 * Deletes an existing asset (property) by its ID.
 * @module assetService/deleteAsset
 * @description
 * This function deletes an asset from the system by making a DELETE request to the `/properties/{id}` 
 * endpoint, where `{id}` is the unique identifier of the asset to be deleted.
 * @param {string|number} id - The unique identifier of the asset to be deleted.
 * @returns {Promise<Object>} The API response confirming the deletion of the asset.
 */
export const deleteAsset = async (id) => {
    const response = await axios.delete(`/properties/${id}`);
    return response;
}