/**
 * @module Views/AssetPage
 *
 * The `AssetPage` module is responsible for rendering a page where users can view, add, edit, and delete their assets.
 * The assets are categorized into various types, such as Real Estate, Financial, Personal Possessions, and Other Assets.
 * The page also provides functionality for filtering, adding new assets, and confirming asset deletions with alerts.
 *
 * @component
 * @returns {JSX.Element} The `AssetPage` component renders the UI for managing and displaying assets.
 */
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { ReactComponent as FinancialIcon } from 'assets/financial-icon.svg';
import { ReactComponent as OtherAssetsIcon } from 'assets/other-assets-icon.svg';
import { ReactComponent as PersonalPosessionsIcon } from 'assets/personal-posessions-icon.svg';
import { ReactComponent as RealEstateIcon } from 'assets/real-estate-icon.svg';
import FilterComponent from 'components/commonComponents/FilterComponent';
import AlertDialog from 'components/DesignSystem/AlertDialog';
import { CustomDialog, CustomDialogContent, CustomDialogTitle } from 'components/DesignSystem/CustomDialog';
import useSwalAlert from 'components/DesignSystem/SwalAlert/SwalAlert';
import AddAssetCard from 'components/featureComponent/assetsFlow/AddAssetCard';
import AssetInfoCardSection from 'components/featureComponent/assetsFlow/AssetInfoCardSection';
import AddAsset from 'pages/AssetPages/AddAsset';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { deleteAsset, fetchAssets } from 'services/assetService';
import { MENU_OPEN } from 'store/actions';
import PageContainer from 'views/dashboard/PageContainer';
// ==============================|| DEFAULT DASHBOARD ||============================== //

/**
 * AssetPage Component
 *
 * This component renders the UI for managing assets, including viewing assets, adding new assets, and deleting existing assets.
 * It handles asset filtering, adding assets to specific categories, and showing confirmation dialogs for deletion.
 *
 * @component
 * @returns {JSX.Element} The JSX to render the AssetPage.
 */
const AssetPage = () => {
    const theme = useTheme();
    const [open, setOpen] = useState(false); // Controls the modal visibility for adding or editing assets
    const [addAssetCategory, setAddAssetCategory] = useState(); // Tracks the category for the asset being added or edited
    const [selectedCategory, setSelectedCategory] = useState([]); // Tracks selected categories for filtering assets
    const showSwalAlert = useSwalAlert(); // Custom hook for displaying SweetAlert notifications
    const [assets, setAssets] = useState({
        realEstate: [],
        financial: [],
        personalPossessions: [],
        otherAssets: [],
    }); // State to hold the assets categorized by type
    const [editId, setEditId] = useState(); // State for the asset id being edited
    const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false); // Controls the visibility of the deletion confirmation dialog
    const [deleteInfo, setDeleteInfo] = useState({ id: null, category: '', title: '' }); // Information for the asset being deleted
    const dispatch = useDispatch(); // Dispatch function for Redux actions

    // Predefined Asset Categories
    const assetCategories = [
        { value: 'real_estate', heading: 'Real Estate', subHeading: 'Select and add a real estate asset.' },
        { value: 'financial', heading: 'Financial', subHeading: 'Select and add a financial asset.' },
        {
            value: 'personal_possessions',
            heading: 'Personal Possessions',
            subHeading: 'Select and add a personal possession.',
        },
        { value: 'other_assets', heading: 'Other Assets', subHeading: 'Select and add an asset from the list below.' },
    ];

    /**
     * Fetches the asset data from the API and filters it into categories.
     *
     * @async
     * @function fetchAssetsData
     * @returns {Promise<void>} A promise that resolves once the data is fetched and the state is updated.
     */
    const fetchAssetsData = async () => {
        try {
            const { data } = await fetchAssets();
            const realEstate = data.assets.filter((asset) => asset.category === 'real_estate');
            const financial = data.assets.filter((asset) => asset.category === 'financial');
            const personalPossessions = data.assets.filter((asset) => asset.category === 'personal_possessions');
            const otherAssets = data.assets.filter((asset) => asset.category === 'other_assets');
            setAssets({ realEstate, financial, personalPossessions, otherAssets });
        } catch (err) {
            console.error(err);
        }
    };

    // Fetch assets data on initial load
    useEffect(() => {
        dispatch({ type: MENU_OPEN, id: 'assets' });
        fetchAssetsData();
    }, []); // Ensure this runs only once after the component mounts

    /**
     * Memoizes the status text to display when no assets are available in any category.
     *
     * @function statusText
     * @returns {string} A message indicating that assets will be shown once added.
     */
    const statusText = useMemo(() => {
        // Check if all asset categories are empty
        const allEmpty = Object.values(assets).every((category) => category.length === 0);

        if (allEmpty) {
            return 'Assets will be shown here once you add them';
        }
        return '';
    }, [assets]);

    /**
     * Opens the modal for adding or editing an asset of a specific category.
     *
     * @function handleClickOpen
     * @param {string} category - The category of the asset to be added or edited.
     * @returns {void}
     */
    const handleClickOpen = (category) => {
        setAddAssetCategory(category);
        setOpen(true);
    };

    /**
     * Closes the modal and resets relevant states.
     *
     * @function handleClose
     * @returns {void}
     */
    const handleClose = () => {
        setOpen(false);
        setEditId();
        setAddAssetCategory(undefined);
        fetchAssetsData();
    };

    /**
     * Handles the change in the selected asset categories for filtering.
     *
     * @function handleFilterChange
     * @param {Array<string>} selectedCategories - The categories selected by the user for filtering.
     * @returns {void}
     */
    const handleFilterChange = (selectedCategories) => {
        if (selectedCategories.length === 0) {
            setSelectedCategory(selectedCategories);
        } else {
            setSelectedCategory(selectedCategories);
        }
    };

    /**
     * Finds the current asset category object based on the selected category value.
     *
     * @function currentAssetCategory
     * @returns {Object} The asset category object with heading and subheading.
     */
    const currentAssetCategory = assetCategories.find((category) => category.value === addAssetCategory) || {};

    /**
     * Handles the event when the user clicks to edit an asset.
     *
     * @function handleOnEditClick
     * @param {string} id - The ID of the asset to be edited.
     * @param {string} category - The category of the asset to be edited.
     * @returns {void}
     */
    const handleOnEditClick = (id, category) => {
        setEditId(id);
        handleClickOpen(category);
    };

    /**
     * Handles the event when the user clicks to delete an asset.
     *
     * @function handleOnDeleteClick
     * @param {Object} asset - The asset to be deleted.
     * @param {string} category - The category of the asset to be deleted.
     * @returns {void}
     */
    const handleOnDeleteClick = (asset, category) => {
        const title = Object.entries(asset?.information).slice(0, 1)?.[0]?.[1] ?? 'default value';
        setDeleteInfo({ asset, category, title });
        setIsAlertDialogOpen(true);
    };

    /**
     * Closes the alert dialog without performing any actions.
     *
     * @function handleAlertDialogClose
     * @returns {void}
     */
    const handleAlertDialogClose = () => {
        setIsAlertDialogOpen(false);
    };

    /**
     * Confirms the deletion of an asset and performs the deletion action.
     *
     * @async
     * @function handleDeleteConfirmation
     * @returns {Promise<void>} A promise that resolves once the asset is deleted and the SweetAlert is shown.
     */
    const handleDeleteConfirmation = async () => {
        const { asset } = deleteInfo;
        const id = asset.id;
        if (id) {
            setIsAlertDialogOpen(false); // Close the dialog before asset deletion and SweetAlert
            await deleteAsset(id); // Wait for asset deletion

            // Show SweetAlert2 notification and wait for it to close
            await showSwalAlert({
                title: 'Asset deleted successfully',
                icon: 'success',
                timer: 1500,
            });

            fetchAssetsData(); // Fetch assets data after deletion is complete
        }
        setIsAlertDialogOpen(false); // Close dialog irrespective of asset deletion
    };

    /**
     * Filters the available asset categories based on whether they have assets in the respective categories.
     *
     * @function availableAssetCategories
     * @returns {Array<Object>} The filtered array of asset categories that contain assets.
     */
    const availableAssetCategories = assetCategories.filter((category) => {
        if (category.value === 'real_estate') return assets.realEstate.length > 0;
        if (category.value === 'financial') return assets.financial.length > 0;
        if (category.value === 'personal_possessions') return assets.personalPossessions.length > 0;
        if (category.value === 'other_assets') return assets.otherAssets.length > 0;
        return false;
    });

    return (
        <>
            <Helmet>
                <title> My Assets | Get Will Done </title>
            </Helmet>
            <PageContainer defaultFaqType="assets">
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography variant="title1">My Assets</Typography>
                    <Typography
                        variant="bodyText3"
                        color={theme.palette.grey[400]}
                        display={'inline-block'}
                        maxWidth={'100%'}
                        width={'44.563rem'}
                    >
                        Thinking about all that you own can lead you up memory lane. The nostalgia may help you recall precious
                        items you want to keep in the family.
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: '16px', flexWrap: 'wrap', mt: '20px' }}>
                    <AddAssetCard icon={RealEstateIcon} title="Real Estate" onClick={() => handleClickOpen('real_estate')} />
                    <AddAssetCard icon={FinancialIcon} title="Financial" onClick={() => handleClickOpen('financial')} />
                    <AddAssetCard
                        icon={PersonalPosessionsIcon}
                        title="Personal Possessions"
                        onClick={() => handleClickOpen('personal_possessions')}
                    />
                    <AddAssetCard icon={OtherAssetsIcon} title="Other Assets" onClick={() => handleClickOpen('other_assets')} />
                </Box>

                <FilterComponent
                    title="All Assets"
                    onFilterChange={handleFilterChange}
                    categoryHeadings={availableAssetCategories}
                    containerSx={{ mt: 4, paddingRight: '70px' }}
                />

                <Box sx={{ padding: '24px 0' }}>
                    {((assets.realEstate.length !== 0 && selectedCategory.length === 0) ||
                        selectedCategory.includes('real_estate')) && (
                            <AssetInfoCardSection
                                title="Real Estate"
                                icon={RealEstateIcon}
                                assets={assets.realEstate}
                                onEditClick={(id) => handleOnEditClick(id, 'real_estate')}
                                onDeleteClick={(asset) => handleOnDeleteClick(asset, 'real_estate')}
                            />
                        )}
                    {((assets.financial.length !== 0 && selectedCategory.length === 0) ||
                        selectedCategory.includes('financial')) && (
                            <AssetInfoCardSection
                                title="Financial"
                                icon={FinancialIcon}
                                assets={assets.financial}
                                onEditClick={(id) => handleOnEditClick(id, 'financial')}
                                onDeleteClick={(asset) => handleOnDeleteClick(asset, 'financial')}
                            />
                        )}
                    {((assets.personalPossessions.length !== 0 && selectedCategory.length === 0) ||
                        selectedCategory.includes('personal_possessions')) && (
                            <AssetInfoCardSection
                                title="Personal Possessions"
                                icon={PersonalPosessionsIcon}
                                assets={assets.personalPossessions}
                                onEditClick={(id) => handleOnEditClick(id, 'personal_possessions')}
                                onDeleteClick={(asset) => handleOnDeleteClick(asset, 'personal_possessions')}
                            />
                        )}
                    {((assets.otherAssets.length !== 0 && selectedCategory.length === 0) ||
                        selectedCategory.includes('other_assets')) && (
                            <AssetInfoCardSection
                                title="Other Assets"
                                icon={OtherAssetsIcon}
                                assets={assets.otherAssets}
                                onEditClick={(id) => handleOnEditClick(id, 'other_assets')}
                                onDeleteClick={(asset) => handleOnDeleteClick(asset, 'other_assets')}
                            />
                        )}
                </Box>
                {selectedCategory.length === 0 && (
                    <Box sx={{ marginTop: '-32px' }}>
                        <Typography color={theme.palette.grey[400]} variant="bodyText3">
                            {statusText}
                        </Typography>
                    </Box>
                )}
                <CustomDialog
                    open={open}
                    onClose={handleClose}
                    maxWidth={false} // Disable default maxWidth
                >
                    <CustomDialogTitle onClose={handleClose}>
                        <Stack spacing={0.3} mt={1}>
                            <Typography variant="heading1" sx={{ color: theme.palette.secondary.main }}>
                                {currentAssetCategory.heading}
                            </Typography>
                            <Typography variant="smallText" sx={{ color: theme.palette.grey[400] }}>
                                {currentAssetCategory.subHeading}
                            </Typography>
                        </Stack>
                    </CustomDialogTitle>
                    <CustomDialogContent>
                        <AddAsset
                            assetCategory={addAssetCategory}
                            editId={editId}
                            handleClose={handleClose}
                            fetchAssetsData={fetchAssetsData}
                            assets={assets}
                        />
                    </CustomDialogContent>
                </CustomDialog>
                <AlertDialog
                    open={isAlertDialogOpen}
                    onClose={handleAlertDialogClose}
                    title={`Are you sure you want to delete this ${deleteInfo.title} asset?`}
                    primaryButtonLabel="Keep"
                    secondaryButtonLabel="Delete"
                    onPrimaryButtonClick={handleAlertDialogClose} // Handle 'Keep' action
                    onSecondaryButtonClick={handleDeleteConfirmation} // Handle 'Delete' action
                />
            </PageContainer>
        </>
    );
};

export default AssetPage;
