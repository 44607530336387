/**
 * @module Routes/AuthenticationRoutes
 *
 * @description This module defines the routing configuration for authentication-related pages in the application.
 * It uses lazy loading for improved performance and leverages a `MinimalLayout` for pages requiring minimal UI structure.
 * The module also integrates custom authentication guards through `AuthRoutes` to ensure that unauthenticated users are properly redirected.
 */

import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import AuthRoutes from 'helpers/auth';
import PartnerSignUpPage from 'pages/PartnerSignupPage';

// Lazy-loaded pages for authentication
const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));
const ForgotPasswordPage = Loadable(lazy(() => import('../pages/ForgotPasswordPage')));
const VerifyOtpPage = Loadable(lazy(() => import('../pages/VerifyOtpPage')));
const VerifyRegistrationOtpForm = Loadable(lazy(() => import('../pages/VerifyRegistrationOtpPage')));
const PasswordSuccessPage = Loadable(lazy(() => import('../pages/PasswordSuccessPage')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

/**
 * @constant {Object} AuthenticationRoutes
 * @description Defines the routing configuration for authentication-related pages.
 *
 * - Uses `AuthRoutes` as a wrapper to ensure proper authentication checks.
 * - Employs `MinimalLayout` for simple page layouts.
 * - Configures routes for login, forgot password, OTP verification, registration OTP verification, password success, and partner signup.
 *
 * @property {string} path The base path for authentication routes (`'/'`).
 * @property {JSX.Element} element The root element for authentication routes, wrapped with `AuthRoutes`.
 * @property {Array} children Array of child routes under the base path.
 *
 */
const AuthenticationRoutes = {
  path: '/',
  element: <AuthRoutes />,
  children: [
    {
      path: '/',
      element: <MinimalLayout />,
      children: [
        {
          index: true,
          element: <LoginPage />,
        },
        {
          path: '/login',
          element: <LoginPage />,
        },
        {
          path: '/forgot-password',
          element: <ForgotPasswordPage />,
        },
        {
          path: '/verify-otp',
          element: <VerifyOtpPage />,
        },
        {
          path: '/verifyRegistrationOtp',
          element: <VerifyRegistrationOtpForm />,
        },
        {
          path: '/password-success',
          element: <PasswordSuccessPage />,
        },
        {
          path: '/partner',
          element: <PartnerSignUpPage />,
        },
      ],
    },
  ],
};

export default AuthenticationRoutes;
