/**
 * @module Views/Distribution/SecondaryDistribution
 * @description This module manages the secondary distribution of assets, providing functionality to add, reset, or delete scenarios,
 * validate distributions, and handle user interactions for complex asset mapping scenarios.
 */

import { Box, useTheme } from '@mui/material';
import { ContextProvider } from 'App';
import AlertDialog from 'components/DesignSystem/AlertDialog';
import BottomFloatingAction from 'components/DesignSystem/BottomFloatingAction';
import useSwalAlert from 'components/DesignSystem/SwalAlert';
import CreateDistributionModal from 'components/featureComponent/distribution/CreateDistributionModal';
import { cloneDeep, noop } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { deleteScenario, deleteWill, fetchWills, updateMappings } from 'services/distributionService';
import ScenarioTabs from './ScenarioTabs';

/**
 * SecondaryDistribution component
 * @function SecondaryDistribution
 * @param {Object} props - Props passed to the component.
 * @param {Object} [props.filteredUsers={}] - Object containing users and their respective assets for distribution.
 * @param {Array} [props.normalizedWills=[]] - Array of normalized wills containing scenario details and mappings.
 * @param {Object} [props.mappings={}] - Object containing current mappings for asset distributions.
 * @param {Object} [props.generics={}] - Generic data including scenarios, beneficiaries, and assets.
 * @param {function} [props.setMappings=noop] - Callback function to update the mappings state.
 * @param {number} [props.mappingBalance=0] - Number representing the balance of undistributed assets.
 * @param {Array} [props.petMappingBalance=[]] - Array tracking the balance status of pet or vehicle assets.
 * @param {function} [props.setGenerics=noop] - Callback to update the generics state.
 * @param {boolean} props.loading - Boolean representing the loading state of the component.
 * @param {function} props.setLoading - Callback function to update the loading state.
 * @param {boolean} [props.hideEdit=false] - Flag to determine if edit/delete options should be hidden.
 *
 * @returns {JSX.Element} The rendered component for managing secondary distributions.
 */
const SecondaryDistribution = ({
    filteredUsers = {},
    normalizedWills = [],
    mappings = {},
    generics = {},
    setMappings = noop,
    mappingBalance = 0,
    petMappingBalance = [],
    setGenerics = noop,
    loading,
    setLoading,
    hideEdit = false,
}) => {
    const [selectDistributionModalOpen, setSelectDistributionModalOpen] = useState(false);
    const [distributionError, setDistributionError] = useState(false);
    const [selectedScenarioTab, setSelectedScenarioTab] = useState(0);
    const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
    const [resetInfo, setResetInfo] = useState({});
    const [previousMappings, setPreviousMappings] = useState({});
    const [step, setStep] = useState(1);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [dialogConfig, setDialogConfig] = useState({
        modalTitle: '',
        title: '',
        primaryButtonLabel: '',
        secondaryButtonLabel: '',
        onPrimaryButtonClick: () => setDialogOpen(false),
        onSecondaryButtonClick: () => { },
        isSingleButton: false,
    });
    // Hooks
    const theme = useTheme(); // Material-UI theme hook
    const showSwalAlert = useSwalAlert(); // Custom hook for displaying alerts

    const handleDialogClose = () => {
        setDialogOpen(false);
        setTimeout(() => {
            setDialogConfig({
                modalTitle: '',
                title: '',
                primaryButtonLabel: '',
                secondaryButtonLabel: '',
                onPrimaryButtonClick: () => setDialogOpen(false),
                onSecondaryButtonClick: () => { },
                isSingleButton: false,
            });
        }, 200);
    };

    const { user } = useContext(ContextProvider);
    const beneficiaryNotAdded = generics?.beneficiary?.length === 0;

    // Effects
    /**
     * Synchronize mappings and set defaults when normalized wills or scenarios change.
     */
    useEffect(() => {
        const scenarioMappings = normalizedWills.filter(
            (will) => will.ScenarioId === generics?.scenarios?.[selectedScenarioTab]?.id
        );

        setMappings(scenarioMappings);
        // setPreviousMappings(structuredClone(scenarioMappings)); // To create deep copy
        setPreviousMappings(cloneDeep(scenarioMappings)); // To create deep copy

        if (
            !hideEdit &&
            generics?.scenarios?.length > 0 &&
            scenarioMappings.length === 0 &&
            !loading &&
            generics?.assets?.length > 0
        ) {
            setSelectDistributionModalOpen(true);
            setStep(2);
        } else {
            setSelectDistributionModalOpen(false);
            setStep(1);
        }
    }, [normalizedWills, generics?.scenarios, selectedScenarioTab, loading]);

    /**
     * Validates the distribution mappings and checks for errors.
     */
    useEffect(() => {
        let error = false;
        filteredUsers.forEach((row) => {
            const { id, information } = row;
            const will = mappings?.find(({ PropertyId }) => {
                return PropertyId === id;
            });
            let value = 100;
            let total = 0;
            if (will?.distribution_type === 'value') {
                value =
                    information?.value ??
                    information?.amount ??
                    information?.insured_amount ??
                    information?.investment_amount ??
                    information?.deposited_amount;
            } else if (will?.distribution_type === 'quantity') {
                value = information?.quantity ?? information?.qty ?? information?.number;
            }
            will?.mapping?.forEach((mapping) => {
                total += +(mapping.share ?? 0);
            });
            if (Math.abs(Number((value - total).toFixed(2))) <= 0.0) {
                // done 0.01 to 0.00
                total = value;
            }
            if (total > value) {
                error = true;
            }
        });
        setDistributionError(error);
    }, [filteredUsers, mappings]);

    // Memos
    /**
     * Retrieves the selected scenario based on the selected scenario tab.
     */
    const selectedScenario = useMemo(() => {
        return generics?.scenarios?.[selectedScenarioTab];
    }, [selectedScenarioTab, generics?.scenarios]);

    // Event Handlers
    /**
     * Opens the modal for adding a new distribution.
     */
    const handleAddClick = () => {
        setStep(1);
        setSelectDistributionModalOpen(true);
    };

    /**
     * Closes the add distribution modal.
     */
    const handleOnClose = () => {
        setSelectDistributionModalOpen(false);
    };

    /**
     * Closes the alert dialog.
     */
    const handleAlertDialogClose = () => {
        setIsAlertDialogOpen(false);
    };

    /**
     * Handles reset click for a specific asset in the selected scenario.
     */
    const handleOnResetClick = (selectedScenarioId, assetId) => {
        setResetInfo({ scenarioId: selectedScenarioId, assetId });
        // setDialogConfig({
        //     modalTitle: 'Are you sure?',
        //     title: 'Are you sure you want to reset this asset?',
        //     primaryButtonLabel: 'Keep',
        //     secondaryButtonLabel: 'Change',
        //     onPrimaryButtonClick: handleDialogClose,
        //     onSecondaryButtonClick: handleResetConfirmation
        //   });
        //   setDialogOpen(true);
        setIsAlertDialogOpen(true);
    };

    // API-related Functions
    /**
     * Fetches wills data and updates the state.
     * @async
     * @param {boolean} switchToNew - Whether to switch to the newest scenario.
     */
    const fetchWillsData = async (switchToNew = false) => {
        try {
            const response = await fetchWills();
            setGenerics((generics) => ({
                ...generics,
                wills: response.data?.wills ?? [],
                scenarios: response.data?.scenarios ?? [],
            }));
            if (switchToNew) {
                setSelectedScenarioTab(response.data?.scenarios?.length - 1 ?? 0);
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    /**
     * Handles confirmation of resetting the mapping for a scenario and asset.
     * Deletes the specific will mapping and fetches updated data.
     *
     * @async
     * @function handleResetConfirmation
     * @returns {Promise<void>} Resolves when the reset operation is completed.
     */
    const handleResetConfirmation = async () => {
        try {
            await deleteWill(resetInfo.scenarioId, resetInfo.assetId);
            await fetchWillsData();
        } catch (err) {
            console.log(err);
        } finally {
            setIsAlertDialogOpen(false);
        }
    };

    /**
     * Updates the mappings data for the selected scenario.
     * Displays a success or error notification upon completion.
     *
     * @async
     * @function updateMappingsData
     * @returns {Promise<void>} Resolves when the mappings update is completed.
     */
    const updateMappingsData = async () => {
        setLoading(true);
        try {
            const payload = {
                // mappings: mappings.filter((map) => map.id !== -99) ?? [],
                mappings,
            };
            const response = await updateMappings(selectedScenario?.id ?? 0, payload);
            showSwalAlert({
                title: `Distribution updated successfully`,
                position: 'top-center',
                icon: 'success',
                timer: 3000,
            });
            await fetchWillsData();
        } catch (err) {
            // toastr.error("Failed to update asset")
            showSwalAlert({
                icon: 'error',
                title: `Failed to update asset`,
                timer: 3000,
            });
            setLoading(false);
        }
    };

    /**
     * Resets the mappings for a given set of properties.
     * Displays a success or error notification upon completion.
     *
     * @async
     * @function resetMappingData
     * @param {Array<Object>} newMappings - The new mappings data to be applied.
     * @returns {Promise<void>} Resolves when the reset operation is completed.
     */
    const resetMappingData = async (newMappings) => {
        try {
            const payload = {
                mappings: newMappings.filter((map) => map.id !== -99) ?? [],
                // mappings,
            };
            const response = await updateMappings(selectedScenario?.id ?? 0, payload);
            showSwalAlert({
                icon: 'success',
                title: `Mapping reset successfully`,
                timer: 3000,
            });
            await fetchWillsData();
        } catch (err) {
            // toastr.error("Failed to update asset")
            showSwalAlert({
                icon: 'error',
                title: `Failed to reset asset`,
                timer: 3000,
            });
        }
    };

    /**
     * Deletes the selected scenario. Confirms the action with the user through a dialog.
     * Fetches updated data and shows success or error notifications.
     *
     * @function deleteScenarioData
     * @returns {void}
     */
    const deleteScenarioData = () => {
        setDialogConfig({
            modalTitle: 'Are you sure?',
            title: `Do you want to delete the Scenario?`,
            primaryButtonLabel: 'Cancel',
            secondaryButtonLabel: 'Yes',
            onPrimaryButtonClick: handleDialogClose,
            onSecondaryButtonClick: async () => {
                try {
                    const response = await deleteScenario(selectedScenario.id);
                    handleDialogClose();
                    showSwalAlert({
                        icon: 'success',
                        title: `Distribution deleted successfully`,
                        timer: 3000,
                    });
                    await fetchWillsData();
                    setSelectedScenarioTab(0);
                } catch (err) {
                    showSwalAlert({
                        icon: 'error',
                        title: `Failed to delete scenario`,
                        timer: 3000,
                    });
                }
            },
            isSingleButton: false,
        });
        setDialogOpen(true);
    };

    /**
     * Handles saving the distribution mappings.
     * Displays appropriate dialogs and validation alerts if required.
     *
     * @function handleSaveDistribution
     * @returns {void}
     */
    const handleSaveDistribution = () => {
        if (petMappingBalance.length > 0 && petMappingBalance.some((value) => value === false)) {
            setDialogConfig({
                modalTitle: 'Pet/Vehicle assets not distributed properly',
                title: `Distribution of pet/vehicle assets is limited to a single beneficiary, with entire allocation (100%).`,
                primaryButtonLabel: 'Ok',
                onPrimaryButtonClick: handleDialogClose,
                isSingleButton: true,
            });
            setDialogOpen(true);
        } else if (mappingBalance > 0) {
            setDialogConfig({
                modalTitle: 'Are you sure?',
                title: 'Some assets are not distributed completely. Do you wish to continue?',
                primaryButtonLabel: 'Cancel',
                secondaryButtonLabel: 'Save',
                onPrimaryButtonClick: handleDialogClose,
                onSecondaryButtonClick: async () => {
                    await updateMappingsData();
                },
            });
            setDialogOpen(true);
        } else {
            updateMappingsData();
        }
    };

    /**
     * Resets the mappings for the current scenario by creating new default mappings.
     *
     * @async
     * @function handleResetScenario
     * @returns {Promise<void>} Resolves when the reset operation is completed.
     */
    const handleResetScenario = async () => {
        const newMappings = filteredUsers?.map((as) => {
            const distributionType = as.sub_type === 'livestock' ? 'quantity' : 'percentage';
            return {
                id: -1,
                PropertyId: as.id,
                distribution_type: distributionType,
                mapping: [],
            };
        });
        setMappings(newMappings);
        await resetMappingData(newMappings);
    };

    /**
     * Confirms and resets the current scenario's distribution mappings.
     * Opens a dialog for user confirmation before proceeding.
     *
     * @function handleResetDistribution
     * @returns {void}
     */
    const handleResetDistribution = async () => {
        setDialogConfig({
            modalTitle: 'Are you sure?',
            title: 'Do you want to Reset the Scenario?',
            primaryButtonLabel: 'No',
            secondaryButtonLabel: 'Yes',
            onPrimaryButtonClick: handleDialogClose,
            onSecondaryButtonClick: async () => {
                handleDialogClose();
                await handleResetScenario();
            },
        });
        setDialogOpen(true);
    };

    /**
     * Cancels the distribution changes and restores the previous mappings.
     *
     * @function handleCancelDistribution
     * @returns {void}
     */
    const handleCancelDistribution = () => {
        // setMappings(structuredClone(previousMappings));
        setMappings(cloneDeep(previousMappings));
    };

    return (
        <>
            <Box paddingRight="75px" paddingBottom="84px">
                <ScenarioTabs
                    handleAddClick={handleAddClick}
                    generics={generics}
                    filteredUsers={filteredUsers}
                    mappings={mappings}
                    setMappings={setMappings}
                    normalizedWills={normalizedWills}
                    setDistributionError={setDistributionError}
                    selectedScenarioTab={selectedScenarioTab}
                    setSelectedScenarioTab={setSelectedScenarioTab}
                    selectedScenario={selectedScenario}
                    handleOnResetClick={handleOnResetClick}
                    deleteScenario={deleteScenarioData}
                    hideEdit={hideEdit}
                />
                {generics?.scenarios?.length > 0 && ['testator', 'partner']?.includes(user?.role) && (
                    <Box
                        sx={{
                            position: 'fixed',
                            bottom: 50,
                            left: 146,
                            right: 146,
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <BottomFloatingAction
                            message="Would you like to save or reset the distribution?"
                            firstButtonText="Cancel"
                            onFirstButtonClick={handleCancelDistribution}
                            firstButtonDisabled={beneficiaryNotAdded}
                            firstButtonSx={{
                                minWidth: '75px',
                                padding: '6px 0',
                                border: `1px solid ${theme.palette.primary.main}40`,
                                // height: '30px',
                            }}
                            secondButtonText="Reset"
                            onSecondButtonClick={handleResetDistribution}
                            secondButtonDisabled={beneficiaryNotAdded}
                            secondButtonSx={{ minWidth: '75px', padding: '6px 0' }}
                            thirdButtonText="Save Distribution"
                            onThirdButtonClick={handleSaveDistribution}
                            thirdButtonDisabled={beneficiaryNotAdded || distributionError}
                            thirdButtonSx={{ padding: '6px 12px' }}
                        />
                    </Box>
                )}
            </Box>
            <CreateDistributionModal
                open={selectDistributionModalOpen}
                onClose={handleOnClose}
                generics={generics}
                fetchWillsData={fetchWillsData}
                selectedScenario={selectedScenario}
                step={step}
                setStep={setStep}
                filteredUsers={filteredUsers}
                setMappings={setMappings}
            />
            <AlertDialog
                open={isAlertDialogOpen}
                onClose={handleAlertDialogClose}
                title={`Are you sure you want to reset this asset?`}
                primaryButtonLabel="Keep"
                secondaryButtonLabel="Reset"
                onPrimaryButtonClick={handleAlertDialogClose} // Handle 'Keep' action
                onSecondaryButtonClick={handleResetConfirmation} // Handle 'Delete' action
                selectedScenario={selectedScenario}
            />
            <AlertDialog
                open={isDialogOpen}
                onClose={handleDialogClose}
                modelTitle={dialogConfig.modalTitle}
                title={dialogConfig.title}
                primaryButtonLabel={dialogConfig.primaryButtonLabel}
                secondaryButtonLabel={dialogConfig.secondaryButtonLabel}
                onPrimaryButtonClick={dialogConfig.onPrimaryButtonClick}
                onSecondaryButtonClick={dialogConfig.onSecondaryButtonClick}
                isSingleButton={dialogConfig.isSingleButton}
            />
        </>
    );
};

export default SecondaryDistribution;
