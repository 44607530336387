// src/hooks/useSwalAlert.js
import { useCallback } from 'react';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

/**
 * @module Components/DesignSystem/useSwalAlert
 * @description This module contains the useSwalAlert component used across the application.
 */
/**
 * Custom hook to show SweetAlert2 alerts with customizable options and styling.
 * It allows showing success, error, or information alerts with customized styles, positions, and durations.
 * This hook also ensures that SweetAlert2's default styles are overridden with custom CSS.
 * 
 * @hook
 * @example
 * // Example usage:
 * const showSwalAlert = useSwalAlert();
 * showSwalAlert({
 *   title: 'Operation Successful',
 *   icon: 'success',
 *   text: 'The operation was completed successfully.',
 *   timer: 5000
 * });
 * 
 * @returns {function} A function `showSwalAlert` that triggers a SweetAlert2 popup.
 * The function takes an object with properties for customizing the alert.
 * 
 * @param {Object} alertConfig - Configuration for the SweetAlert2 alert.
 * @param {string} alertConfig.title - The title text of the alert.
 * @param {string} [alertConfig.icon='success'] - The icon type to display in the alert. Can be 'success', 'error', 'info', 'warning', 'question'.
 * @param {string} [alertConfig.text=''] - The text to display in the alert body.
 * @param {number} [alertConfig.timer=3000] - The duration for which the alert is shown (in milliseconds).
 * @param {Object} [alertConfig.props={}] - Additional custom props for SweetAlert2, such as `showConfirmButton`, `background`, `color`, etc.
 * 
 * @returns {Promise} A promise that resolves when the SweetAlert2 alert is closed.
 */
const useSwalAlert = () => {
    /**
    * Function to show a SweetAlert2 alert with customizable options.
    * 
    * @param {Object} config - Configuration for the alert.
    * @returns {Promise} SweetAlert2's promise when the alert is closed.
    */
    const showSwalAlert = useCallback(({ title, icon = 'success', text = '', timer = 3000, ...props }) => {
        // Create a <style> element with custom CSS for SweetAlert2
        const style = document.createElement('style');
        style.innerHTML = `
            .swal2-popup {
                font-family: 'DM Sans', sans-serif !important;
                font-size: 20px !important; /* Adjust font size if needed */
                border-radius: 20px !important; /* Rounded corners */
            }
            .swal2-title {
                font-family: 'DM Sans', sans-serif !important;
                font-size: 20px !important; /* Adjust font size if needed */
            }
            .swal2-html-container {
                font-family: 'DM Sans', sans-serif !important;
                font-size: 18px !important; /* Adjust font size if needed */
            }
            .swal2-confirm {
                font-family: 'DM Sans', sans-serif !important;
            }
        `;
        document.head.appendChild(style);

        // Show SweetAlert2 alert
        return Swal.fire({
            position: 'center',
            icon,
            color: '#fff',
            background: '#00838F',
            toast: true,
            title,
            text,
            showConfirmButton: false,
            timer,
            ...props,
            willClose: () => {
                // Remove the style element when the alert closes
                document.head.removeChild(style);
            }
        });
    }, []);

    return showSwalAlert;
};

export default useSwalAlert;
