/**
 * @module Pages/AdminPages/AdminComponents/IncompleteWillsCard
 * @description This module contains the IncompleteWillsCard component used to display the count of incomplete wills,
 * including a breakdown of individual and partner incomplete wills.
 */
import { Skeleton } from '@mui/material';
import DashboardCard from 'components/commonComponents/cards/DashboardCard';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchInCompleteWills } from 'services/adminService';
/**
 * A card component that displays the total number of incomplete wills, along with a breakdown of individual and partner incomplete wills.
 * 
 * The component fetches data from the `/admin/incomplete-wills` API endpoint, which provides the total count of incomplete wills, 
 * the count of individual incomplete wills, and the count of partner incomplete wills. This data is displayed in the card's subtitle.
 * 
 * While the data is being fetched, a loading skeleton is shown. Once the data is fetched, it is displayed within a `DashboardCard`.
 * The card is clickable and redirects to the `/admin/incompleteWills` page when clicked.
 *
 * @component
 * @returns {JSX.Element} A loading skeleton while data is being fetched, or a `DashboardCard` displaying the incomplete wills count.
 */
const IncompleteWillsCard = () => {
  const [incompleteWillsData, setIncompleteWillsData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
 /**
   * Fetches the data for the total number of incomplete wills, along with the breakdown of individual and partner incomplete wills.
   * 
   * This effect runs once when the component mounts. It fetches data from the `/admin/incomplete-wills` API endpoint
   * and updates the state with the retrieved data. Once the data is fetched, the loading state is set to `false`.
   */
  useEffect(() => {
    const fetchIncompleteWillsData = async () => {
      try {
        const response = await fetchInCompleteWills();
        const { incompleteWillsCount, testatorIncompleteWillsCount, partnerIncompleteWillsCount } = response.data;
        setIncompleteWillsData({ incompleteWillsCount, testatorIncompleteWillsCount, partnerIncompleteWillsCount });
      } catch (error) {
        console.error('Error fetching total incomplete wills data:', error);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };

    fetchIncompleteWillsData();
  }, []);
  /**
   * Handles the click event on the dashboard card, navigating to the "/admin/incompleteWills" page.
   */
  const handleClick = () => {
    navigate('/admin/incompleteWills');
  };

  return (
    <>
      {
        loading ? (
          <Skeleton variant="rectangular" height={135} sx={{ borderRadius: '1.25rem' }} />
        ) : (
          <DashboardCard
            title="Total Incomplete Wills (Individual v/s Partner)"
            subtitle={`${incompleteWillsData?.incompleteWillsCount ?? '--'} (${incompleteWillsData?.testatorIncompleteWillsCount ?? '--'
              } / ${incompleteWillsData?.partnerIncompleteWillsCount ?? '--'})`}
            handleClick={handleClick}
            isClickable
          />
        )
      }
    </>
  );
};

export default IncompleteWillsCard;
