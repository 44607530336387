import dayjs from 'dayjs';
/**
 * A utility function to get a date range based on the provided time range.
 * @module utils/dateUtils
 * @description
 * Utility functions for working with dates, such as getting the current date, 
 * yesterday's date, the last 7 days, the last 30 days, and the start of the current month.
 * @param {string} timeRange - The time range to get the start and end dates for.
 * This can be one of the following values:
 * - 'today': Returns today's date as both start and end date.
 * - 'yesterday': Returns yesterday's date as both start and end date.
 * - 'last7days': Returns the date range for the last 7 days (from 7 days ago to today).
 * - 'last30days': Returns the date range for the last 30 days (from 30 days ago to today).
 * - 'thismonth': Returns the date range from the start of the current month to today.
 * @returns {Object|null} - Returns an object with `startDate` and `endDate` properties, or `null` if the time range is not recognized.
 * - `startDate`: The start date in 'YYYY-MM-DD' format.
 * - `endDate`: The end date in 'YYYY-MM-DD' format.
 */
const today = dayjs().format('YYYY-MM-DD');
const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
const last7days = dayjs().subtract(6, 'day').format('YYYY-MM-DD');
const last30days = dayjs().subtract(29, 'day').format('YYYY-MM-DD');
const startOfMonth = dayjs().startOf('month').format('YYYY-MM-DD');
// const endOfMonth = dayjs().endOf('month').format('YYYY-MM-DD');

/**
 * Get a date range object based on a predefined time range.
 * @function getDate
 * @memberof utils/dateUtils
 * @param {string} timeRange - The time range for which to get the date range. Valid options are:
 * - `'today'`: Today's date as both start and end date.
 * - `'yesterday'`: Yesterday's date as both start and end date.
 * - `'last7days'`: From 7 days ago to today.
 * - `'last30days'`: From 30 days ago to today.
 * - `'thismonth'`: From the start of the current month to today.
 * @returns {Object|null} - An object containing `startDate` and `endDate` in 'YYYY-MM-DD' format, or `null` if the input is invalid.
 */
const getDate = (timeRange) => {
  switch (timeRange) {
    case 'today':
      return { startDate: today, endDate: today };
    case 'yesterday':
      return { startDate: yesterday, endDate: yesterday };
    case 'last7days':
      return { startDate: last7days, endDate: today };
    case 'last30days':
      return { startDate: last30days, endDate: today };
    case 'thismonth':
      return { startDate: startOfMonth, endDate: today };
    default:
      return null;
  }
};

/**
 * Generate an array of dates between the given start and end dates.
 * @function generateDateRange
 * @memberof utils/dateUtils
 * @param {string} startDate - The start date in 'YYYY-MM-DD' format.
 * @param {string} endDate - The end date in 'YYYY-MM-DD' format.
 * @returns {Array<Date>} - An array of Date objects representing all dates in the range.
 */
function generateDateRange(startDate = '', endDate = '') {
  const dates = [];

  // Convert startDate and endDate to Date objects if they are not empty strings
  const currentDate = startDate ? new Date(startDate) : null;
  const endTime = endDate ? new Date(endDate).getTime() : null;

  // Check if currentDate and endTime are valid before proceeding
  while (currentDate && endTime && currentDate.getTime() <= endTime) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}

/**
 * Match raw data entries with a specified date range.
 * For dates with no matching data, returns default entries with the specified `identifier` and value `0`.
 * @function matchDataWithDateRange
 * @memberof utils/dateUtils
 * @param {string} startDate - The start date in 'YYYY-MM-DD' format.
 * @param {string} endDate - The end date in 'YYYY-MM-DD' format.
 * @param {Array<Object>} rawData - The raw data array. Each entry must contain a `date` field in 'YYYY-MM-DD' format.
 * @param {string} timeRange - The predefined time range ('today', 'last7days', etc.). Use `'custom'` for manual date ranges.
 * @param {string} identifier - The key to use for matching data (e.g., 'count', 'value').
 * @returns {Array<Object>} - An array of objects, each containing:
 * - `date`: The date in 'YYYY-MM-DD' format.
 * - `[identifier]`: The value for the corresponding date, or `0` if no data matches.
 */
export function matchDataWithDateRange(startDate = '', endDate = '', rawData = [], timeRange = '', identifier) {
  let dateRange;
  if (timeRange === 'custom') {
    dateRange = generateDateRange(startDate, endDate);
  } else {
    const { startDate, endDate } = getDate(timeRange);
    dateRange = generateDateRange(startDate, endDate);
  }
  const matchedData = dateRange?.map((date) => {
    const matchingEntry = rawData?.find((entry) => entry.date === date.toISOString().split('T')[0]);
    return matchingEntry || { date: date.toISOString().split('T')[0], [identifier]: 0 };
  });

  return matchedData;
}
