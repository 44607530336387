const { Typography, useTheme, ListItemText } = require('@mui/material');

/**
 * @module Components/DesignSystem/CustomListItemText
 * @description This module contains the CustomListItemText component used across the application.
 */
/**
 * A customized version of the Material-UI `ListItemText` component.
 * The component customizes the `primary` and `secondary` text with specific typography and colors.
 * 
 * @component
 * @example
 * // Example usage:
 * <CustomListItemText 
 *   primary="Main Text" 
 *   secondary="Additional Information" 
 * />
 * 
 * @param {Object} props - The props for the component.
 * @param {string} props.primary - The primary text to display.
 * @param {string} props.secondary - The secondary text to display.
 * @param {Object} [props] - Additional props that are passed down to the `ListItemText` component.
 * 
 * @returns {React.Element} The `CustomListItemText` component, rendering styled typography for primary and secondary text.
 */
const CustomListItemText = ({ primary, secondary, ...props }) => {
    const theme = useTheme();
    return (
        <ListItemText
            primary={
                <Typography component={'div'} variant="smallText" color={theme.palette.grey[600]}>
                    {primary}
                </Typography>
            }
            secondary={
                <Typography component={'div'} variant="smallText" color={theme.palette.grey[400]}>
                    {secondary}
                </Typography>
            }
            {...props}
        />
    );
};

export { CustomListItemText };
