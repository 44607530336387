/**
 * @module Pages/PartnerLandingPage/PartnerLandingPage
 * @description This module defines the `PartnerLandingPage` component, which serves as the landing page for 
 * partners. It displays various statistics related to the partner's performance, including client counts, 
 * sign-ups vs paid users, incomplete and completed wills, and earnings. The page also allows partners to 
 * initiate the enrollment process, acknowledge the partner terms, and copy their referral link to the clipboard. 
 * It fetches the relevant data from APIs and displays it in cards, and also includes modals for the acknowledgment 
 * and enrollment process.
 */
import { Box, Card, CardContent, Container, Divider, Grid, IconButton, InputAdornment, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { ContextProvider } from 'App';
import DashboardCard from 'components/commonComponents/cards/DashboardCard';
import AlertDialog from 'components/DesignSystem/AlertDialog';
import { ContainedButton1, ContainedButton2 } from 'components/DesignSystem/Button';
import Icon from 'components/DesignSystem/Icon';
import InputField from 'components/DesignSystem/InputField';
import useSwalAlert from 'components/DesignSystem/SwalAlert';
import Footer from 'components/featureComponent/landingPage/Footer';
import Header from 'components/featureComponent/landingPage/Header';
import YouTubeVideoSection from 'components/featureComponent/landingPage/YouTubeVideoSection';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { IoClose } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { enrollAcknowledgeApi, enrollAsPartnerApi, fetchCommissionData, fetchCompleteWills, fetchConversionRatio, fetchIncompleteWills, fetchTotalNumberOfPartnerClients } from 'services/partnerService';
import { fCurrencyRupee } from 'utils/formatNumber';
import FAQSection from '../../components/featureComponent/landingPage/FAQSection';

/**
 * PartnerLandingPage component that provides the partner's dashboard with key statistics 
 * and options to interact with their partner enrollment status.
 *
 * It includes:
 * - Fetching and displaying data like the number of clients, incomplete wills, earnings, etc.
 * - Handling partner enrollment and acknowledgment processes.
 * - Providing a demo mode to explore the platform's features.
 */
const PartnerLandingPage = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const showSwalAlert = useSwalAlert();
    const { user, fetchUser } = useContext(ContextProvider);
    const [loading, setLoading] = useState(true);
    const [cardsData, setCardsData] = useState([]); // State for cards data
    const [openEnrollAcknowledge, setOpenEnrollAcknowledge] = useState(null);
    const [isEnrollAlertOpen, setIsEnrollAlertOpen] = useState(false);

    const referralLink = `${process.env.REACT_APP_PUBLIC_URL}/login?partner_code=${user.partner_code}`;

    /**
     * Fetches data from APIs for the partner dashboard and updates the state.
     */
    const fetchData = async () => {
        setLoading(true);
        try {
            // Simulate API calls (replace with actual API calls)
            const fetchedCards = await Promise.all([
                fetchTotalNumberOfPartnerClients(),
                fetchConversionRatio(),
                fetchIncompleteWills(),
                fetchCompleteWills(),
                fetchCommissionData()
            ]);

            // Map fetched data to your cards
            setCardsData([
                { title: 'Number of Clients', count: fetchedCards[0].data.totalTestators ?? '--', link: '/partner/numberOfClients' },
                { title: 'Sign-ups vs Paid Users', count: fetchedCards[1].data.signupVsPaidRatio ?? '-/-', link: '/partner/signupVsPaidUsers' },
                { title: 'Incomplete Wills', count: fetchedCards[2].data.incompleteWills ?? '--', link: '/partner/incompleteWills' },
                { title: 'Completed Wills', count: fetchedCards[3].data.completeWills ?? '--', link: '/partner/completedWills' },
                { title: 'Earnings', count: fCurrencyRupee(fetchedCards[4].data.totalCommission ?? 0), link: '/partner/earnings' }
            ]);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    /**
    * Fetches data when the component mounts or when the user role changes.
    * Also checks if partner acknowledgment is required.
    */
    useEffect(() => {
        if (user.role === 'partner' && user.partner_enroll_acknowledge === false && user.partner_enroll_status === 'APPROVED') {
            setOpenEnrollAcknowledge(true);
        }
        fetchData();
    }, [user]);

    /**
     * Copies the referral link to the clipboard and shows a success alert.
     * @param {string} text - The text to copy to clipboard.
     */
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            showSwalAlert({
                title: 'Link copied to clipboard!',
                icon: 'success',
                timer: 1500,
            });
        }).catch(err => {
            console.error("Could not copy text: ", err);
        });
    };

    /**
    * Initiates the partner enrollment process by calling the relevant API.
    * Shows an alert after the process is initiated.
    */
    const enrollAsPartner = async () => {
        try {
            await enrollAsPartnerApi();
            setIsEnrollAlertOpen(false);
            showSwalAlert({
                title: 'Partner Enrollment process is initiated.',
                text: ("You will receive an Email with Partner Enrollment Approval Details within 7 working days from GetWillDone."),
                timer: 5000,
            });
            await fetchUser();
        } catch (error) {
            console.log("error", error)
        }
    };

    /**
     * Acknowledges the partner enrollment status by calling the relevant API.
     */
    const enrollAcknowledge = async () => {
        try {
            await enrollAcknowledgeApi();
            setOpenEnrollAcknowledge(false);
            // showSwalAlert({
            //     title: 'Acknowledged successfully',
            //     icon: 'success',
            //     timer: 1500,
            // });
            await fetchUser();
        } catch (error) {
            setIsEnrollAlertOpen(false);
        }
    };

    /**
 * Starts the demo mode by setting a session storage item and navigating to the welcome page.
 */
    const startDemoMode = async () => {
        sessionStorage.setItem('isDemoActive', 'true');
        navigate('/welcome')
    };

    return (
        <>
            <Helmet>
                <title> Partner Dashboard | Get Will Done </title>
            </Helmet>
            <Container maxWidth='false' disableGutters sx={{ maxWidth: '120rem', padding: '0 31px', }}>
                <Header />

                <AlertDialog
                    open={isEnrollAlertOpen}
                    onClose={() => {
                        setIsEnrollAlertOpen(false);
                    }}
                    primaryButtonLabel={'No'}
                    secondaryButtonLabel={'Yes'}
                    onSecondaryButtonClick={() => { enrollAsPartner() }}
                    onPrimaryButtonClick={() => {
                        setIsEnrollAlertOpen(false);
                    }} >
                    {(user.partner_enroll_status === null || user.partner_enroll_status === 'REJECTED') && (
                        <Typography variant="heading2" sx={{ marginBottom: 4, color: theme.palette.grey[600] }}>
                            Are you sure you want to initiate partner enrollment?
                        </Typography>
                    )}
                </AlertDialog>

                {/* Congratulations Box */}
                {openEnrollAcknowledge && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1.25rem' }}  >
                        < Box sx={{ backgroundColor: theme.palette.grey[200], borderRadius: '1.25rem', padding: '1rem 1.875rem', width: 'fit-content' }} >
                            <Typography variant="heading2" sx={{ color: theme.palette.primary.main }}>
                                Congratulations! You are now an affiliate partner with GetWillDone.
                            </Typography>
                        </Box>
                        <IconButton
                            sx={{ backgroundColor: theme.palette.primary.lighter, width: '2rem', height: '2rem', marginLeft: '0.625rem', padding: 0, borderRadius: '0.625rem', '&:hover': { backgroundColor: '#FFE0B2' }, }}
                            onClick={() => { enrollAcknowledge() }}
                        >
                            <IoClose style={{ color: theme.palette.error.main }} />
                        </IconButton>
                    </Box>)}

                <Box sx={{ padding: '1.875rem 0', margin: 'auto' }}>
                    <Stack>
                        <Typography variant="largeTitle" sx={{ color: theme.palette.grey[600] }}>
                            Welcome, {user.profile?.first_name}
                        </Typography>
                        <Typography variant="heading2" sx={{ marginBottom: 4, color: theme.palette.grey[400] }}>
                            Navigate the platform through your Dashboard. Our videos and FAQs can guide you if you need help.
                        </Typography>
                    </Stack>
                </Box>

                {/* First Row with Demo Mode and Affiliate Partner */}
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Card sx={{ backgroundColor: theme.palette.secondary.lighter, display: 'flex', flexDirection: 'column', height: '100%', borderRadius: '1.25rem', boxShadow: 'none' }}>
                            <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <Typography variant="title2" sx={{ color: theme.palette.grey[600] }}>Start Demo Mode</Typography>
                                <Box sx={{ width: '80%' }}>
                                    <Stack spacing={1} marginTop={1} >
                                        <Typography variant="bodyText3" sx={{ color: theme.palette.grey[600] }}>
                                            Give your clients a detailed walkthrough on how to make their Will with GetWillDone.
                                        </Typography>
                                    </Stack>
                                </Box>
                                <Box sx={{ marginTop: 'auto', display: 'flex', justifyContent: 'flex-end', marginLeft: '8px' }}>
                                    <ContainedButton2 sx={{ width: '145px', height: '40px' }}
                                        onClick={() => { startDemoMode() }}
                                    >
                                        <Typography variant="bodyText3">Start</Typography>
                                    </ContainedButton2>
                                </Box>

                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Card sx={{ backgroundColor: theme.palette.primary.lighter, display: 'flex', flexDirection: 'column', height: '100%', borderRadius: '1.25rem', boxShadow: 'none' }}>
                            {(["partner"].includes(user?.role) && !user.is_approved && user.partner_enroll_status !== "APPROVED") ? (
                                <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <Typography variant="title2" sx={{ color: theme.palette.primary.main }}>Partner with Us</Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <Stack spacing={1} marginTop={1}>
                                            <Typography variant="bodyText3" sx={{ color: theme.palette.grey[600] }}>
                                                • Earn through your referrals.
                                            </Typography>
                                            <Typography variant="bodyText3" sx={{ color: theme.palette.grey[600] }}>
                                                • Enroll now to get approved and receive your GetWillDone referral link to share with your clients.
                                            </Typography>
                                        </Stack>
                                        <Box sx={{ marginTop: 'auto', display: 'flex', justifyContent: 'flex-end', marginLeft: '8px' }}>
                                            {((user.partner_enroll_status === null || user.partner_enroll_status === 'REJECTED') && <ContainedButton1
                                                sx={{ width: '145px', height: '40px' }}
                                                onClick={() => { setIsEnrollAlertOpen(true) }}
                                            >
                                                <Typography variant="bodyText3">Enroll Now</Typography>
                                            </ContainedButton1>)}
                                            {((user.partner_enroll_status === 'REQUESTED') && <ContainedButton1
                                                sx={{ width: '145px', height: '40px', backgroundColor: '#fff !important', color: `${theme.palette.primary.light} !important`, border: `1px solid ${theme.palette.primary.light} !important` }}
                                                onClick={() => {
                                                    showSwalAlert({
                                                        title: 'We have received your request, we will get back to you soon! ',
                                                        icon: 'info',
                                                        timer: 3000,
                                                    });
                                                }}
                                            >
                                                <Typography variant="bodyText3">Request Sent</Typography>
                                            </ContainedButton1>)}
                                        </Box>
                                    </Box>
                                </CardContent>) :
                                (<CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <Typography variant="title2" sx={{ color: theme.palette.primary.main }}>Your Referral Link</Typography>
                                    <Stack spacing={1} marginTop={1}>
                                        <Typography variant="bodyText3" sx={{ color: theme.palette.grey[600] }}>
                                            Share this with your clients to earn commissions from your referral.
                                        </Typography>
                                    </Stack>
                                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '1.25rem', gap: '1.25rem' }}>
                                        <Box width='100%'>
                                            <InputField
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                value={referralLink}
                                                sx={{
                                                    flexGrow: 1,
                                                    '& .MuiInputBase-root': {
                                                        backgroundColor: 'white',
                                                        border: `1px solid ${theme.palette.primary.light}`,
                                                        '& input': {
                                                            fontSize: '12px', // Set the font size to 12px
                                                        },
                                                    },
                                                }}
                                                InputProps={{
                                                    readOnly: true, // Makes the input read-only
                                                    startAdornment: (
                                                        <InputAdornment position="start" onClick={() => copyToClipboard(referralLink)}>
                                                            <Icon name="link-icon.png" height='20px' width='24px' />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Box>
                                        <ContainedButton1 sx={{ width: '145px', height: '40px', display: 'flex', gap: '8px' }} onClick={() => copyToClipboard(referralLink)}>
                                            <Icon name="copy-icon.png" height='20px' width='16px' />
                                            <Typography variant="bodyText3">Copy</Typography>
                                        </ContainedButton1>
                                    </Box>
                                </CardContent>)}
                        </Card>
                    </Grid>
                </Grid>

                {/* Skeleton for StatCards */}
                <Grid container sx={{ margin: '1.875rem 0', width: '70%' }}>
                    {loading ? (
                        Array.from({ length: 5 }).map((_, index) => (
                            <Grid item xs={12} sm={5.5} md={3} sx={{ margin: '8px' }} key={index}>
                                <Skeleton variant="rectangular" height={100} sx={{ borderRadius: '1.25rem', margin: '0.5rem', minHeight: '135px' }} />
                            </Grid>
                        ))
                    ) : (
                        cardsData.map((card, index) => (
                            <Grid item xs={12} sm={5.5} md={4} sx={{ padding: '8px' }}>
                                <DashboardCard
                                    key={index}
                                    title={card.title}
                                    subtitle={card.count}
                                    handleClick={() => user.is_approved && navigate(card.link)}
                                    isClickable={user.is_approved}
                                />
                            </Grid>
                        ))
                    )}
                </Grid>

            </Container >
            {/* Third Box */}
            < Grid container
                sx={{ display: 'flex', alignItems: 'center', alignContent: 'space-between', bgcolor: 'lightBlue', height: 712, width: '100%', marginTop: '25px', padding: '50px', paddingRight: '0px', backgroundImage: 'url(/assets/landing-page-box-bg-img.png)', backgroundSize: 'cover', backgroundPosition: 'center' }}   >
                <Grid item xs={12} md={12} xl={12}>
                    <Typography variant="title1" color={theme.palette.secondary.main}> Resource Hub</Typography>
                </Grid>
                <Grid item container xs={12} md={12} xl={12} >
                    <Grid item xs={2} md={2} xl={2} mt={2}>
                        <Grid item>
                            <Typography variant="title2" color={theme.palette.grey[600]}>Videos</Typography>
                        </Grid>
                        <ContainedButton2 sx={{ padding: '8px 20px', mt: '25px' }} variant="contained" onClick={() => navigate('/resourceHub', { state: { reference: 'videos' } })} >
                            <Typography variant="bodyText3">Watch All →</Typography>
                        </ContainedButton2>
                    </Grid>
                    <Grid item xs={10} md={10} xl={10} sx={{ overflowX: 'auto', '::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none' }}>
                        <YouTubeVideoSection />
                    </Grid>
                </Grid>

                <Divider sx={{ width: '96%', height: '0px', border: '1px solid #C5C5C5' }} />
                <Grid item container xs={12} md={12} xl={12} >
                    <Grid item xs={2} md={2} xl={2} mt={2}>
                        <Grid item>
                            <Typography variant="title2" color={theme.palette.grey[600]}>FAQs</Typography>
                        </Grid>
                        <ContainedButton2 sx={{ padding: '8px 20px', mt: '25px' }} variant="contained" onClick={() => navigate('/resourceHub')} >
                            <Typography variant="bodyText3">Find More →</Typography>
                        </ContainedButton2>
                    </Grid>
                    <Grid item xs={10} md={10} xl={10} sx={{ overflowX: 'auto', '::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none' }}>
                        <FAQSection />
                    </Grid>
                </Grid>
            </Grid >
            <Footer />
        </>
    );
};

export default PartnerLandingPage;