/**
 * @module Hooks/useResponsive
 *
 * @description This module provides two custom hooks: `useResponsive`.
 *
 * - `useResponsive` allows developers to manage responsive behavior based on Material-UI's theme breakpoints.
 */
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

/**
 * `useResponsive` is a custom hook to evaluate a media query against Material-UI's theme breakpoints.
 *
 * @function useResponsive
 *
 * @param {string} query - The type of query to evaluate. Acceptable values are:
 *   - `'up'`: Matches the viewport width greater than or equal to the `start` breakpoint.
 *   - `'down'`: Matches the viewport width less than or equal to the `start` breakpoint.
 *   - `'between'`: Matches the viewport width between the `start` and `end` breakpoints.
 *   - `'only'`: Matches the viewport width exactly at the `start` breakpoint.
 * @param {string} start - The starting breakpoint to evaluate (e.g., `'xs'`, `'sm'`, `'md'`, `'lg'`, `'xl'`).
 * @param {string} [end] - The ending breakpoint for `'between'` queries (e.g., `'sm'`, `'md'`, `'lg'`, `'xl'`).
 *
 * @returns {boolean} - A boolean indicating whether the query condition is satisfied.
 *
 * @example
 * // Example usage of useResponsive
 * import useResponsive from './useResponsive';
 *
 * const Component = () => {
 *   const isDesktop = useResponsive('up', 'md');
 *   return <div>{isDesktop ? 'Desktop View' : 'Mobile View'}</div>;
 * };
 */
export default function useResponsive(query, start, end) {
  const theme = useTheme();
  const mediaUp = useMediaQuery(theme.breakpoints.up(start));
  const mediaDown = useMediaQuery(theme.breakpoints.down(start));
  const mediaBetween = useMediaQuery(theme.breakpoints.between(start, end));
  const mediaOnly = useMediaQuery(theme.breakpoints.only(start));

  if (query === 'up') {
    return mediaUp;
  }

  if (query === 'down') {
    return mediaDown;
  }

  if (query === 'between') {
    return mediaBetween;
  }

  return mediaOnly;
}
