/**
 * @module Helpers/AuthRoutes
 *
 * @description This module provides the `AuthRoutes` component, which handles route protection and user redirection based on authentication status and * user roles.
 * It fetches the authenticated user, sets their details in the context, and redirects to appropriate routes based on their role.
 */

import { ContextProvider } from 'App';
import FullScreenLoader from 'components/DesignSystem/FullScreenLoader';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

/**
 * `AuthRoutes` is a component that manages route-level authentication and role-based redirection.
 *
 * @component
 *
 * @returns {JSX.Element} - Returns an `Outlet` component if the user is authenticated, or a full-screen loader while checking authentication status.
 *
 * @example
 * import AuthRoutes from './AuthRoutes';
 * import { Routes, Route } from 'react-router-dom';
 *
 * const App = () => {
 *   return (
 *     <Routes>
 *       <Route element={<AuthRoutes />}>
 *         <Route path="/admin/analyticaldashboard" element={<AdminDashboard />} />
 *         <Route path="/landingPage" element={<LandingPage />} />
 *       </Route >
 *     </Routes >
 *   );
 * };
 */
const AuthRoutes = () => {
    const { fetchUser, setLoggedIn, setUser } = useContext(ContextProvider); // Accessing global context
    const navigate = useNavigate(); // Navigation hook to programmatically redirect
    const location = useLocation(); // Provides information about the current route
    const [isLoading, setIsLoading] = useState(false); // Tracks loading state for authentication check

    useEffect(() => {
        /**
         * Async function to fetch user data and handle authentication logic.
         * Redirects the user based on their role and authentication status.
         *
         * @async
         */
        const checkUser = async () => {
            try {
                const response = await fetchUser(); // Fetch user details from context
                const { user } = response.data;
                setUser(user); // Save user details in the context
                setLoggedIn(true); // Mark the user as logged in

                if (location.pathname === '/login' || location.pathname === '/') {
                    // Redirect based on role
                    if (user.role === 'admin') {
                        navigate('/admin/analyticaldashboard', { replace: true });
                        // } else if (user.role === 'testator' || user.role === 'partner') {
                    } else if (user.role === 'testator') {
                        if (user.profile_saved) {
                            navigate('/landingPage', { replace: true });
                        } else {
                            navigate('/welcome', { replace: true });
                        }
                    } else if (user.role === 'partner') {
                        // if (user.profile_saved) {
                        navigate('/partnerLandingPage', { replace: true });
                        // } else {
                        //     navigate('/welcome', { replace: true });
                        // }
                    } else if (user.role === 'reviewer') {
                        navigate('/reviewer/reviewerDashboard', { replace: true });
                    }
                }
            } catch (err) {
                setLoggedIn(false); // Mark the user as logged out on failure
                console.error('Failed to fetch user:', err);
                if (location.pathname !== '/login') {
                    navigate('/login', { replace: true }); // Redirect to login if unauthenticated
                }
            } finally {
                setIsLoading(false); // Stop loading animation
            }
        };

        if (location.pathname === '/login' || location.pathname === '/') {
            setIsLoading(true); // Start loading animation
            checkUser(); // Invoke user check
        }
    }, [location, navigate, fetchUser, setLoggedIn, setUser]); // Dependencies for useEffect

    if (isLoading) {
        return <FullScreenLoader />; // Show a loader while checking authentication
    }

    return <Outlet />; // Render nested routes
};

export default AuthRoutes;
