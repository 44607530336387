/**
 * @module Views/MyWill/MyWillModal
 *
 * @description This module defines the `MyWillModal` component, which displays a modal that shows the Will document in full-screen mode.
 * The modal includes:
 * - A logo displayed at the top left of the screen.
 * - A scrollable content area where the Will document is rendered from the provided `documentHtml`.
 * - A close button that allows the user to exit the full-screen mode and close the modal.
 *
 * The modal is controlled by the `open` and `handleClose` props, with the document content passed as `documentHtml`.
 *
 * @example
 * // Example of using the MyWillModal component
 * <MyWillModal open={isOpen} handleClose={handleClose} documentHtml={htmlContent} />
 */
import { Box, Dialog, DialogContent, IconButton } from '@mui/material';
import Icon from 'components/DesignSystem/Icon';

/**
 * MyWillModal Component
 *
 * A modal dialog component that displays the user's Will document in full-screen mode.
 * The modal includes:
 * - A logo at the top of the modal for branding.
 * - A scrollable content area that renders the provided HTML content as the Will document.
 * - A close button to exit the full-screen modal view.
 *
 * The modal is controlled via the `open` and `handleClose` props, which determine whether the modal is visible and allow the user to close it.
 *
 * @component
 * @param {Object} props - The properties passed to the `MyWillModal` component.
 * @param {boolean} props.open - Controls whether the modal is visible or not.
 * @param {function} props.handleClose - A function to close the modal.
 * @param {string} props.documentHtml - The HTML content of the Will document to be rendered inside the modal.
 *
 * @returns {React.Element} - The rendered `MyWillModal` component, which includes the modal dialog with the Will document preview and close button.
 */
const MyWillModal = ({ open, handleClose, documentHtml }) => {
    return (
        <Dialog open={open} onClose={handleClose} fullScreen>
            <Box sx={{ position: 'absolute', top: 36, left: 30 }}>
                <Icon name="will-logo.png" height={90} width={169} />
            </Box>
            <DialogContent
                style={{
                    padding: '48px 64px 32px 64px',
                    backgroundColor: '#C5C5C5',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                }}
            >
                <Box
                    style={{
                        width: '50%',
                        height: '90vh',
                        overflowY: 'scroll',
                        backgroundColor: '#ffffff',
                        padding: '24px',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                        borderRadius: '20px',
                    }}
                >
                    <div className="document-container" dangerouslySetInnerHTML={{ __html: documentHtml }} />
                </Box>
                <IconButton
                    style={{
                        // position: 'absolute',
                        // top: 16,
                        // right: 16,
                        color: '#fff',
                        background: '#fff',
                        marginLeft: '12px',
                    }}
                    onClick={handleClose}
                >
                    <Icon name="cross-icon.png" size={13} />
                </IconButton>
            </DialogContent>
        </Dialog>
    );
};

export default MyWillModal;
