/**
 * @module Components/FeatureComponent/DistributionCardModal
 * @description A card component used to represent a selectable option in a distribution modal.
 * It includes a label, an icon, and visual indicators to show whether it is selected.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {string} props.label - The text label displayed on the card.
 * @param {React.ElementType} props.icon - A React component for the icon to be displayed on the card (e.g., an SVG or MUI icon component).
 * @param {boolean} props.isSelected - Indicates whether the card is currently selected.
 * @param {Function} props.onSelect - Callback function triggered when the card is clicked. Typically toggles the selection state.
 *
 * @returns {JSX.Element} The DistributionCardModal component.
 */

import React from 'react';
import { Card, Typography, Box, useTheme } from '@mui/material';

const DistributionCardModal = ({ label, icon: IconComponent, isSelected, onSelect }) => {
    const theme = useTheme();
    return (
        <Card
            onClick={onSelect}
            sx={{
                width: '167px',
                borderRadius: 3,
                paddingLeft: '12px',
                border: `1px solid ${isSelected ? theme.palette.primary.dark : 'transparent'}`,
                backgroundColor: isSelected ? theme.palette.primary.lighter : theme.palette.grey[200],
                cursor: 'pointer',
                boxShadow: isSelected ? `0px 10px 10px -8px ${theme.palette.primary.dark}4D` : 'none',
                transition: 'all 0.3s ease',
                '&:hover': {
                    boxShadow: `0px 10px 10px -8px ${theme.palette.common.black}9D`,
                },
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 1 }}>
                <Box
                    component="span"
                    sx={{
                        color: isSelected ? theme.palette.primary.main : theme.palette.common.black,
                        marginRight: 1,
                    }}
                >
                    {/* Render SVG Icon */}
                    <IconComponent style={{ fontSize: 30, color: isSelected ? theme.palette.primary.main : theme.palette.common.black }} />
                </Box>
                <Typography
                    variant="bodyText3"
                    sx={{ color: isSelected ? theme.palette.primary.main : theme.palette.common.black, lineHeight: '18.9px !important' }}
                >
                    {label}
                </Typography>
            </Box>
        </Card>
    );
};

export default DistributionCardModal;
