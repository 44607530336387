import { useTheme } from '@emotion/react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { noop } from 'lodash';

/**
 * @module Components/DashboardCard
 * @description This module contains the DashboardCard component used across the application.
 */
/**
 * A customizable card component for displaying a title, subtitle, and content. The card can be made clickable
 * and styled using the `sx` prop. It also supports hover effects based on the `isClickable` prop.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.title=''] - The title text displayed at the top of the card.
 * @param {string} [props.subtitle=''] - The subtitle text displayed below the title.
 * @param {Object} [props.sx={}] - Custom `sx` styles to apply to the card. This allows for additional styling.
 * @param {Function} [props.handleClick=noop] - A callback function that is triggered when the card is clicked.
 * @param {boolean} [props.isClickable=false] - Determines if the card should be clickable. If true, it applies a hover effect and the card becomes interactive.
 *
 * @returns {JSX.Element} A styled `Card` component that displays a title and subtitle, with optional clickable behavior.
 *
 * @example
 * // Example usage of the DashboardCard component
 * const handleCardClick = () => {
 *   console.log('Card clicked');
 * };
 *
 * <DashboardCard
 *   title="Dashboard Title"
 *   subtitle="Subtitle Text"
 *   handleClick={handleCardClick}
 *   isClickable={true}
 *   sx={{ width: '300px' }}
 * />
 */
const DashboardCard = ({ title = '', subtitle = '', sx = {}, handleClick = noop, isClickable = false }) => {
  const theme = useTheme();

  return (
    <Card
      onClick={handleClick}
      sx={{
        backgroundColor: 'grey.200',
        border: '1px solid transparent',
        cursor: isClickable ? 'pointer' : 'not-allowed',
        borderRadius: '1.25rem',
        boxShadow: 'none',
        transition: '0.3s ease',
        '&:hover': {
          backgroundColor: isClickable ? 'primary.lighter' : 'grey.200',
          border: isClickable ? `1px solid ${theme.palette.primary.dark}` : '1px solid transparent',
          transform: isClickable ? 'scale(-5px)' : 'none',
          boxShadow: isClickable ? '0px 10px 10px -8px #F47B204D' : 'none',
        },
        ...sx,
      }}
    >
      <CardContent
        sx={{
          padding: '22px 24px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '135px',
        }}
      >
        <Typography
          variant="bodyText3"
          sx={{ color: isClickable ? theme.palette.primary.main : theme.palette.grey[100] }}
        >
          {title}
        </Typography>
        <Box>
          <Typography
            variant="heading1"
            sx={{ color: isClickable ? theme.palette.grey[600] : theme.palette.grey[100] }}
          >
            {subtitle}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default DashboardCard;
