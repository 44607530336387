/**
 * A module containing utility functions for handling price formatting.
 * @module utils/priceUtils
 * @description
 * Provides utility functions to format prices into human-readable strings 
 * using international standards and localized formatting.
 */

/**
 * Formats a numeric price into a localized, human-readable string.
 * @function formatPrice
 * @memberof utils/priceUtils
 * @description
 * This function uses the `Intl.NumberFormat` API to format a numeric value 
 * into a locale-specific string representation. It is particularly useful 
 * for formatting prices in a consistent manner.
 * @param {number} price - The numeric value to format as a price.
 * @returns {string} - The formatted price as a string.
 */
// Function to format the price
export const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US').format(price);
};