/**
 * @module Views/AdminDashboard/TopPerformerPage
 * @description
 * This module represents the "Top Performing Partners" page in the admin dashboard. It displays a table with partner
 * information such as partner code, partner name, testator count, and total revenue. The page supports sorting, pagination,
 * filtering by partner name, and dynamic data fetching from the backend API. The table displays partner data and allows
 * navigation back to the analytical dashboard.
 *
 * @component TopPerformerPage
 * @returns {JSX.Element} The rendered page displaying top performing partners' details with sorting, filtering,
 * pagination controls, and a back button.
 * @hooks useState, useEffect, useMemo
 * @function descendingComparator
 * @function getComparator
 * @function stableSort
 * @function handleRequestSort
 * @function handleChangePage
 * @function handleChangeRowsPerPage
 * @function handleBackClick
 * @function handleFilterByName
 *
 */
import { Box, Table, TableBody, TableContainer, Typography } from '@mui/material';
import axios from 'axios';
import { CustomTableCell, CustomTablePagination, CustomTableRow } from 'components/DesignSystem/Table/CustomTable';
import { filter } from 'lodash';
import Scrollbar from 'oldLayouts/components/scrollbar';
import { UserListHead, UserListToolbar } from 'oldLayouts/components/user';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import PageContainer from 'views/dashboard/PageContainer';
import { fCurrencyRupee } from '../../utils/formatNumber';

/**
 * Comparator function that sorts values in descending order based on the specified property.
 *
 * @function descendingComparator
 * @param {Object} a - The first object to compare.
 * @param {Object} b - The second object to compare.
 * @param {string} orderBy - The property key to compare the objects by.
 * @returns {number} - A negative value if `a` should come before `b`, a positive value if `b` should come before `a`,
 * 0 if the values are equal.
 */
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 * Returns a comparator function based on the order (`asc` or `desc`) and the property by which to sort.
 *
 * @function getComparator
 * @param {string} order - The sorting order ("asc" for ascending, "desc" for descending).
 * @param {string} orderBy - The property to sort the data by.
 * @returns {function} - The comparator function used for sorting.
 */
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * A stable sorting function that ensures sorting consistency. It uses the comparator function to sort the array
 * and can also filter the data based on a search query.
 *
 * @function stableSort
 * @param {Array} array - The array to be sorted and filtered.
 * @param {function} comparator - The comparator function for sorting.
 * @param {string} query - The query string used for filtering the array.
 * @returns {Array} - The sorted and filtered array.
 */
// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        JSON.stringify(_user ?? {})
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

/**
 * Table header configuration for displaying partner data.
 *
 * @constant {Array} TABLE_HEAD - Contains column definitions with id, label, and alignment properties.
 * @property {string} id - The unique identifier for each column.
 * @property {string} label - The label to display in the table header.
 * @property {boolean} [numeric] - Whether the column values are numeric (optional).
 * @property {boolean} [alignRight] - Whether to right-align the column values (optional).
 */
const TABLE_HEAD = [
  {
    id: 'partner_code',
    disablePadding: true,
    label: 'Partner Code',
    alignRight: false,
  },
  {
    id: 'partner_name',
    label: 'Partner Name',
    alignRight: false,
  },
  {
    id: 'testator_count',
    label: 'Testator Count',
    alignRight: false,
  },
  {
    id: 'total_revenue',
    label: 'Total Revenue',
    alignRight: false,
  },
];

/**
 * Main component to render the "Top Performing Partners" page.
 * It manages sorting, pagination, and filtering of the data.
 *
 * @component
 * @function TopPerformerPage
 * @returns {JSX.Element} The rendered page, including table with top performing partners, filters, pagination, and a back button.
 */
const TopPerformerPage = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('partner_code');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [topPerformerPageData, setTopPerformerPageData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const navigate = useNavigate();

  /**
   * Fetches the top performing partners' data from the backend API.
   *
   * @function fetchTopPreformersData
   * @async
   * @returns {void}
   * @throws {Error} If the request fails, logs an error to the console.
   */
  const fetchTopPreformersData = async () => {
    try {
      const response = await axios.get(`/admin/top-performing-partner?list=${true}`);
      const { partnerList } = response.data;
      setTopPerformerPageData({ partnerList: partnerList || [] });
    } catch (error) {
      console.error('Error fetching revenue data:', error);
    }
  };

  /**
   * Hook to fetch the data on initial render.
   */
  useEffect(() => {
    fetchTopPreformersData();
  }, []);

  /**
   * Handles sorting of the table when a header is clicked.
   *
   * @function handleRequestSort
   * @param {Event} event - The event triggered by the sort request.
   * @param {string} property - The property to sort by.
   * @returns {void}
   */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  /**
   * Handles the pagination of the table when the page is changed.
   *
   * @function handleChangePage
   * @param {Event} event - The event triggered by the page change.
   * @param {number} newPage - The new page number.
   * @returns {void}
   */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  /**
   * Handles changing the number of rows displayed per page.
   *
   * @function handleChangeRowsPerPage
   * @param {Event} event - The event triggered by the change in rows per page.
   * @returns {void}
   */
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**
   * Calculates the number of empty rows to be displayed when there is not enough data to fill the page.
   *
   * @constant {number} emptyRows - The number of empty rows.
   */
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - topPerformerPageData?.partnerList?.length) : 0;

  /**
   * Filters and sorts the top performing partner data based on current sorting and filtering parameters.
   *
   * @constant {Array} visibleRows - The rows of data that are currently visible based on sorting and filtering.
   */
  const visibleRows = useMemo(
    () =>
      stableSort(topPerformerPageData?.partnerList || [], getComparator(order, orderBy), filterName).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, topPerformerPageData, filterName]
  );

  /**
   * Handles filtering the partners by name based on user input.
   *
   * @function handleFilterByName
   * @param {Event} event - The input event triggered by the user typing in the filter field.
   * @returns {void}
   */
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  return (
    <PageContainer showBackButton showHelpIcon={false}>
      <Helmet>
        <title> Top Performer Partners | Get Will Done </title>
      </Helmet>
      <Box ssx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Typography variant="title1">Top Performing Partners Details</Typography>
        <Box sx={{ margin: '24px 0 12px 0' }}>
          <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />
        </Box>
      </Box>
      <Box>
        <Scrollbar>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <UserListHead order={order} orderBy={orderBy} headLabel={TABLE_HEAD} onRequestSort={handleRequestSort} />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <CustomTableRow hover tabIndex={-1} key={index}>
                      <CustomTableCell>{row.partner_code}</CustomTableCell>
                      <CustomTableCell>{row.partner_name}</CustomTableCell>
                      <CustomTableCell>{row.testator_count}</CustomTableCell>
                      <CustomTableCell>{fCurrencyRupee(row.total_revenue)}</CustomTableCell>
                    </CustomTableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <CustomTableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <CustomTableCell colSpan={6} />
                  </CustomTableRow>
                )}
              </TableBody>
              {topPerformerPageData?.partnerList?.length === 0 && (
                <TableBody>
                  <CustomTableRow>
                    <CustomTableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      No Top Performer Data Found
                    </CustomTableCell>
                  </CustomTableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <CustomTablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={topPerformerPageData?.partnerList?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </PageContainer>
  );
};

export default TopPerformerPage;
