/**
 * @module Views/AdminDashboard/CompletedWillsPage
 * @description A React component that displays a table of completed wills data with sorting, filtering, and pagination functionality.
 */

import { Box, Table, TableBody, TableContainer, Typography } from '@mui/material';
import axios from 'axios';
import { Dropdown } from 'components/DesignSystem/DropDown';
import { CustomTableCell, CustomTablePagination, CustomTableRow } from 'components/DesignSystem/Table/CustomTable';
import dayjs from 'dayjs';
import { filter } from 'lodash';
import Scrollbar from 'oldLayouts/components/scrollbar';
import { UserListHead, UserListToolbar } from 'oldLayouts/components/user';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import PageContainer from 'views/dashboard/PageContainer';

/**
 * Compares two objects in descending order based on the specified property.
 * 
 * @param {Object} a - The first object to compare.
 * @param {Object} b - The second object to compare.
 * @param {string} orderBy - The property name to compare.
 * @returns {number} - Returns -1 if `b[orderBy]` is less than `a[orderBy]`, 
 * 1 if `b[orderBy]` is greater, or 0 if they are equal.
 */
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 * Returns a comparator function to sort an array in ascending or descending order.
 * 
 * @param {'asc'|'desc'} order - The order of sorting ('asc' for ascending, 'desc' for descending).
 * @param {string} orderBy - The property name to sort by.
 * @returns {Function} - A comparator function for sorting.
 */
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * Stabilizes and sorts an array, optionally filtering it based on a query.
 * 
 * @param {Array<Object>} array - The array to be sorted and filtered.
 * @param {Function} comparator - A comparator function used for sorting.
 * @param {string} [query] - A search query used to filter the array.
 * @returns {Array<Object>} - The sorted and filtered array.
 */
function stableSort(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        JSON.stringify(_user ?? {})
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

/**
 * @constant
 * @type {Array<Object>}
 * @description The table headers for the completed wills table.
 * @property {string} id - The unique identifier for the column.
 * @property {boolean} numeric - Whether the column contains numeric values.
 * @property {string} label - The label to display for the column.
 * @property {boolean} alignRight - Whether the content is aligned to the right.
 */
const TABLE_HEAD = [
  {
    id: 'email',
    numeric: true,
    label: 'Email',
    alignRight: false,
  },
  {
    id: 'name',
    disablePadding: true,
    label: 'Client Name',
    alignRight: false,
  },
  {
    id: 'phone',
    numeric: true,
    label: 'Phone',
    alignRight: false,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    alignRight: false,
  },
  {
    id: 'role',
    disablePadding: true,
    label: 'Role',
    alignRight: false,
  },
];

/**
 * @constant
 * @type {Array<Object>}
 * @description The role options used for filtering the table.
 * @property {string} label - The display label for the role.
 * @property {string} value - The value corresponding to the role.
 */
const roleOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Testator/Direct',
    value: 'testator',
  },
  {
    label: 'Partner',
    value: 'partner',
  },
];

/**
 * @function CompletedWillsPage
 * @description Displays a list of completed wills with filtering, sorting, and pagination.
 * 
 * @returns {JSX.Element} The completed wills page component.
 */
const CompletedWillsPage = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('email');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [completedWillsData, setCompletedWillsData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [filterValue, setFilterValue] = useState('all');
  const navigate = useNavigate();

   /**
   * Fetches the completed wills data from the server.
   * 
   * @async
   * @function fetchCompletedWillsData
   */
  const fetchCompletedWillsData = async () => {
    try {
      const response = await axios.get(`/admin/complete-wills-count?list=${true}`);
      const { completeWillsList } = response.data;
      setCompletedWillsData(completeWillsList || []);
    } catch (error) {
      console.error('Error fetching completed wills page data:', error);
    }
  };

  useEffect(() => {
    fetchCompletedWillsData();
  }, []);

  /**
   * Filters the data based on the selected role.
   * 
   * @constant
   * @type {Array<Object>}
   */
  const filteredData = useMemo(() => {
    if (filterValue === 'testator') {
      return completedWillsData.filter((client) => client.role === 'testator');
    }
    if (filterValue === 'partner') {
      return completedWillsData.filter((client) => client.role === 'partner');
    }
    return completedWillsData;
  }, [filterValue, completedWillsData]);

  /**
   * Handles the sorting request when a column header is clicked.
   * @function
   * @param {Event} event - The event triggered by clicking a column header.
   * @param {string} property - The property to sort by.
   */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

   /**
   * Handles the page change for pagination.
   * @function
   * @param {Event} event - The pagination event.
   * @param {number} newPage - The new page index.
   */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  /**
   * Handles changes to the rows per page for pagination.
   * @function
   * @param {Event} event - The pagination event.
   */
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**
   * Handles the filter dropdown change event.
   * @function
   * @param {Event} event - The event triggered by selecting a new filter value.
   */
  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - completedWillsData.length) : 0;

   /**
   * Filters and sorts the visible rows based on user input and sorting criteria.
   * 
   * @constant
   * @type {Array<Object>}
   */
  const visibleRows = useMemo(
    () =>
      stableSort(filteredData || [], getComparator(order, orderBy), filterName).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, completedWillsData, filterName, filterValue]
  );

  /**
   * Handles the name filter input change event.
   * @function
   * @param {Event} event - The input change event.
   */
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  return (
    <PageContainer showBackButton showHelpIcon={false}>
      <Helmet>
        <title> Completed Wills | Get Will Done </title>
      </Helmet>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Typography variant="title1">Completed Wills</Typography>
        <Box sx={{ margin: '18px 0 12px 0' }}>
          <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            justifyContent: 'flex-end',
            marginBottom: '16px',
          }}
        >
          <Typography variant="bodyText4">Role</Typography>
          <Dropdown
            placeholder="Role"
            value={filterValue}
            handleChange={handleFilterChange}
            options={roleOptions}
            dropdownWidth="180px"
          />
        </Box>
      </Box>
      <Box>
        <Scrollbar>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <UserListHead order={order} orderBy={orderBy} headLabel={TABLE_HEAD} onRequestSort={handleRequestSort} />
              <TableBody>
                {visibleRows.map((row, index) => (
                  <CustomTableRow hover tabIndex={-1} key={index}>
                    <CustomTableCell>{row.email}</CustomTableCell>
                    <CustomTableCell>{row.name}</CustomTableCell>
                    <CustomTableCell>{row.phone}</CustomTableCell>
                    <CustomTableCell >{dayjs(row.createdAt).format('DD-MM-YYYY')}</CustomTableCell>
                    <CustomTableCell>{row.role}</CustomTableCell>
                  </CustomTableRow>
                ))}
                {emptyRows > 0 && (
                  <CustomTableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <CustomTableCell colSpan={6} />
                  </CustomTableRow>
                )}
              </TableBody>
              {completedWillsData.length === 0 && (
                <TableBody>
                  <CustomTableRow>
                    <CustomTableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      No Wills Data Found
                    </CustomTableCell>
                  </CustomTableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <CustomTablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </PageContainer>
  );
};

export default CompletedWillsPage;
