import { Box, Card, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { ReactComponent as DeleteIcon } from 'assets/delete-icon.svg';
import { ReactComponent as EditIcon } from 'assets/edit-icon.svg';

/**
 * @module Components/AssetInfoCard
 * @description This module contains the AssetInfoCard component used across the application.
 */
/**
 * A styled version of the Material UI Card component with custom background color, padding, border-radius,
 * and box shadow. It is used to wrap the content of the `AssetInfoCard`.
 *
 * @component
 * @param {Object} theme - The MUI theme to apply styles from.
 *
 * @returns {Object} A styled MUI Card component with custom styling.
 */
const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: '#F7F7F7', // Light grey background color
    borderRadius: '8px', // Rounded corners
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)', // Soft shadow
    padding: '12px', // Padding inside the card
    width: '30%', // Card width
    minHeight: '105px', // Minimum height for the card
    display: 'flex', // Flexbox layout
    justifyContent: 'space-between', // Space out children horizontally
}));

/**
 * A customizable card component that displays asset information. It shows the asset's title, a few key-value pairs,
 * and includes edit and delete icons for interaction. The card is styled using MUI's `styled` utility and supports
 * custom actions for editing and deleting.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.asset - The asset object containing the information to be displayed.
 * @param {Function} props.onEdit - Callback function to be invoked when the edit icon is clicked.
 * @param {Function} props.onDelete - Callback function to be invoked when the delete icon is clicked.
 *
 * @returns {JSX.Element} A `Card` component displaying asset details along with edit and delete actions.
 *
 * @example
 * // Example usage of the AssetInfoCard component
 * const asset = {
 *   information: {
 *     'assetinfo'
 *   }
 * };
 *
 * const handleEdit = () => { console.log('Edit clicked'); };
 * const handleDelete = () => { console.log('Delete clicked'); };
 *
 * <AssetInfoCard asset={asset} onEdit={handleEdit} onDelete={handleDelete} />
 */

const AssetInfoCard = ({ asset, onEdit, onDelete }) => {
    const theme = useTheme();
    const { information } = asset;
    const title = Object.entries(information).slice(0, 1)?.[0]?.[1] ?? 'default value';
    const fields = Object.entries(information).slice(1, 3);

    function formatKey(key) {
        // Split the key by underscores, capitalize each part, and join them back together
        return key
            .split('_') // Split by underscore
            .map((part) => part.charAt(0).toUpperCase() + part.slice(1)) // Capitalize each part
            .join(' '); // Join with a space
    }

    return (
        <StyledCard>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '100%' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        mb: '4px',
                    }}
                >
                    <Typography variant="bodyText3" sx={{ width: '100%' }}>
                        {title}
                    </Typography>
                </Box>
                {fields.map(([key, value], index) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        {value && (
                            <Typography key={key} variant="bodyText4" color={theme.palette.grey[400]}>
                                {formatKey(key)}:&nbsp;&nbsp; {value}
                            </Typography>
                        )}
                    </Box>
                ))}
            </Box>
            <Box margin={'-4px'}>
                <Box onClick={onEdit} sx={{ cursor: 'pointer', margin: '-2px 0' }}>
                    <EditIcon sx={{ width: '16px', height: '16px' }} />
                </Box>
                <Box onClick={onDelete} sx={{ cursor: 'pointer' }}>
                    <DeleteIcon sx={{ width: '16px', height: '16px' }} />
                </Box>
            </Box>
        </StyledCard>
    );
};

export default AssetInfoCard;
