import axios from "axios";
/**
 * Fetches generic data based on the specified type.
 * @module distributionService/deleteAsset
 * @description
 * @description
 * This function retrieves generic data from the server by making a GET request to the `/generics/{type}` endpoint. 
 * The `type` parameter determines the category or type of generic data to be fetched.
 * @param {string} type - The type of generic data to fetch (e.g., 'categories', 'statuses', 'roles').
 * @returns {Promise<Object>} The API response containing the requested generic data.
 */
export const fetchGenerics = async (type) => {
    const response = await axios.get(`/generics/${type}`);
    return response;
}

/**
 * Fetches a list of all assets (properties) from the server.
 * @module distributionService/fetchAssets
 * @description
 * This function retrieves the complete list of assets available in the system by sending a GET request 
 * to the `/properties` endpoint. The assets may include properties such as real estate, vehicles, or other categories.
 * @returns {Promise<Object>} The API response containing the list of assets.
 */
export const fetchAssets = async () => {
    const response = await axios.get('/properties');
    return response;
}

/**
 * Fetches the list of wills.
 * @module distributionService/fetchWills
 * @description
 * This function retrieves a list of all wills from the server. It makes a GET request 
 * to the `/wills` endpoint and returns the server response containing details of the wills.
 * @returns {Promise<Object>} The API response containing the list of wills.
 */
export const fetchWills = async () => {
    const response = await axios.get('/wills');
    return response;
}

/**
 * Deletes a specific will based on the provided scenario and ID.
 * @module distributionService/deleteWill
 * @description
 * This function deletes a will identified by its ID within a given scenario. 
 * It makes a DELETE request to the `/wills/:scenario/:id` endpoint to remove the will from the system.
 * @param {string} scenario - The scenario under which the will exists (e.g., "draft", "completed").
 * @param {number|string} id - The unique identifier of the will to be deleted.
 * @returns {Promise<Object>} The API response confirming the deletion of the will.
 */
export const deleteWill = async (scenario, id) => {
    const response = await axios.delete(`/wills/${scenario}/${id}`);
    return response;
};

/**
 * Deletes a scenario associated with a will.
 * @module distributionService/deleteScenario
 * @description
 * This function deletes a specific scenario associated with a will by making a DELETE request 
 * to the `/wills/scenario/:id` endpoint, where `id` is the unique identifier of the scenario.
 * @param {number|string} id - The unique identifier of the scenario to be deleted.
 * @returns {Promise<Object>} The API response confirming the deletion of the scenario.
 */
export const deleteScenario = async (id) => {
    const response = await axios.delete(`/wills/scenario/${id}`);
    return response;
};

/**
 * Updates mappings for a selected scenario.
 * @module distributionService/updateMappings
 * @description
 * This function sends a bulk update request to the server to map data for a specified scenario. 
 * It makes a POST request to the `/wills/bulk/map/:selectedScenario` endpoint, with optional payload data.
 * @param {string} selectedScenario - The scenario identifier for which mappings are to be updated.
 * @param {Object} [payloadData={}] - The payload data containing mapping details. Default is an empty object.
 * @returns {Promise<Object>} The API response after updating the mappings.
 */
export const updateMappings = async (selectedScenario, payloadData = {}) => {
    const response = await axios.post(`/wills/bulk/map/${selectedScenario}`, payloadData)
    return response;
};