import { CountryList } from 'utils/helper/CountryList';
/**
 * @module /helper/CountryCodeHandler
 * @description
 * This module contains a utility function for handling changes to a country code field in forms managed by Formik. 
 * It updates the corresponding country name field based on the selected country code and resets the country name 
 * if no code is selected.
 */

/**
 * Handles changes to a country code field in a Formik-managed form.
 * This function updates the country name field associated with the provided country code field.
 * If the country code is cleared, the corresponding country name is set to `null`.
 * @function handleCountryCodeChange
 * @memberof module:CountryCodeHandler
 * @param {Object} formik - The Formik instance used to manage form state.
 * @param {Object} event - The event object triggered by the country code field change.
 * @param {Object} event.target - The target element of the event.
 * @param {string} event.target.value - The value of the selected country code.
 * @param {string} field - The name of the country code field being updated.
 * 
 * @example
 * handleCountryCodeChange(formik, event, 'countryCode');
 */
export const handleCountryCodeChange = (formik, event, field) => {
    const { value } = event.target;
    formik.setFieldValue(field, value);

    if (!value) {
        formik.setFieldValue(field.replace('Code', ''), null);
    } else {
        const selectedCountry = CountryList.find((country) => country.value === value);
        if (selectedCountry) {
            const countryLabel = selectedCountry.label;
            formik.setFieldValue(field.replace('Code', ''), countryLabel);
        }
    }
};

/**
 * Checks if a given set of values exists in the collection of people.
 * It compares the normalized values of `name` and `email` with the corresponding fields in the `myPeople` array.
 * @function checkIfExists
 * @memberof module:CountryCodeHandler
 * @param {Object} values - The values (typically `name`, `email`, etc.) to check.
 * @param {Array} myPeople - An array of people objects to check against.
 * @returns {Promise<boolean>} - A promise resolving to `true` if a match exists, otherwise `false`.
 */
export const checkIfExists = async (values, myPeople) => {
    // Helper function to normalize string values
    const normalize = (str) => {
        if (typeof str !== 'string') {
            return ''; // Return an empty string if the value is not a string
        }
        return str.trim().toLowerCase();
    };

    // Helper function to check if a normalized value exists in a list
    const existsInList = (list, { firstName, lastName, dob }) =>
        list.some(
            (item) =>
                normalize(item.first_name) === firstName &&
                normalize(item.last_name) === lastName &&
                normalize(item.dob) === dob
        );

    // Extract lists from myPeople object
    const {
        beneNames = [],
        witnessNames = [],
        executorNames = [],
        testatorNames = [],
        beneGuardianDetails = [],
    } = myPeople;

    // Normalize input values
    const normalizedValues = {
        firstName: normalize(values.first_name),
        lastName: normalize(values.last_name),
        dob: normalize(values.dob),
    };

    // Check if the values exist in any of the lists
    const existsInBeneficiaries = existsInList(beneNames, normalizedValues);
    const existsInWitnesses = existsInList(witnessNames, normalizedValues);
    const existsInExecutors = existsInList(executorNames, normalizedValues);
    const existInTestator = existsInList(testatorNames, normalizedValues);
    const existsInGuardian = existsInList(beneGuardianDetails, normalizedValues);

    return {
        existsInBeneficiaries,
        existInTestator,
        existsInWitnesses,
        existsInExecutors,
        existsInGuardian,
    };
};


// Helper function to get formatted PANs
/**
 * Retrieves a list of PAN (Permanent Account Number) values from a list of items, excluding a specific ID.
 * This function processes the items, filters out the one with the given `excludeId`, and then maps the rest of the items to their PAN values (converting them to uppercase).
 * 
 * @function getPanList
 * @memberof module:CountryCodeHandler
 * @param {Array} items - The array of items containing `id` and `pan` properties.
 * @param {string|number} excludeId - The ID of the item to exclude from the list.
 * @returns {Array<string>} - A list of PAN values in uppercase, excluding the one with the specified `excludeId`.
 */
export const getPanList = (items, excludeId) =>
    (items ?? [])
        .filter((item) => item.id !== excludeId)
        .map((item) => item.pan?.toUpperCase() ?? '')
        .filter(Boolean);

/**
 * Helper function to get a list of formatted names from an array of items.
 * The function excludes the item with the specified `excludeId` and maps each item to an object containing
 * `first_name`, `last_name`, and `dob` properties. Any items that are falsy are filtered out.
 * @function getNameList
 * @memberof module:helper/CountryCodeHandler
 * @param {Array} items - The array of items to process. Each item should have at least `id`, `first_name`, `last_name`, and `dob` properties.
 * @param {string|number} excludeId - The ID to exclude from the resulting list.
 * @returns {Array} - An array of objects, each containing `first_name`, `last_name`, and `dob` properties of the items, excluding the item with the specified `excludeId`.
 */
export const getNameList = (items, excludeId) =>
    (items ?? [])
        .filter((item) => item.id !== excludeId)
        .map((item) => ({
            first_name: item.first_name,
            last_name: item.last_name,
            dob: item.dob,
        }))
        .filter(Boolean);

/**
 * Helper function to get a list of formatted email addresses.
 * This function filters out an item based on the provided `excludeId` and returns a list of email addresses in uppercase.
 * It ensures that only valid emails are included in the result.
 * @function getEmailList
 * @memberof module:CountryCodeHandler
 * @param {Array} items - The list of items containing user data.
 * @param {string} excludeId - The ID of the item to exclude from the resulting email list.
 * @returns {string[]} - A list of formatted (uppercase) email addresses.
 */
export const getEmailList = (items, excludeId) =>
    (items ?? [])
        .filter((item) => item.id !== excludeId)
        .map((item) => item.email?.toUpperCase() ?? '')
        .filter(Boolean);

/**
 * Helper function to get a formatted list of phone numbers.
 * This function filters out an item by `excludeId`, normalizes the phone numbers to uppercase, 
 * and returns a list of non-empty phone numbers.
 * 
 * @function getphoneList
 * @memberof module:/helper/CountryCodeHandler
 * @param {Array} items - The list of items containing phone numbers.
 * @param {string|number} excludeId - The ID of the item to exclude from the list.
 * @returns {string[]} - A list of formatted phone numbers (uppercase), excluding the item with the `excludeId`.
 */
export const getphoneList = (items, excludeId) =>
    (items ?? [])
        .filter((item) => item.id !== excludeId)
        .map((item) => item.phone?.toUpperCase() ?? '')
        .filter(Boolean);
