/**
 * @module Routes/ThemeRoutes
 *
 * @description This module is responsible for configuring and rendering all application routes.
 * It combines authentication routes and main application routes using `react-router-dom`'s `useRoutes` hook.
 * This setup ensures a streamlined routing configuration for the application.
 */

import { useRoutes } from 'react-router-dom';
import AuthenticationRoutes from './AuthenticationRoutes';
import MainRoutes from './MainRoutes';
// ==============================|| ROUTING RENDER ||============================== //

/**
 * @function ThemeRoutes
 * @description Combines and renders the application's route configuration.
 *
 * - Uses the `useRoutes` hook to dynamically configure and return the app's routes.
 * - Integrates two primary route groups: `AuthenticationRoutes` for authentication-related pages and `MainRoutes` for the core application pages.
 * - Simplifies the overall routing logic for better maintainability.
 *
 * @returns {JSX.Element|null} The rendered routes for the application, or `null` if no routes are matched.
 *
 */
export default function ThemeRoutes() {
    const routes = useRoutes([
        AuthenticationRoutes, // Routes related to authentication, such as login and signup
        MainRoutes, // Core application routes, such as dashboards and user-specific features
    ]);
    return routes;
}
