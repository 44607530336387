/**
 * @module Components/FeatureComponent/LandingFAQSection
 * 
 * @description This module defines the `FAQSection` React component, which fetches and displays a list of Frequently Asked Questions (FAQs).
 * The FAQs are presented in a styled grid layout, with each FAQ shown in a scrollable card.
 * The component utilizes MUI (Material-UI) for styling and layout.
 */

import { Grid, Paper, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { fetchFaqsApi } from 'services/landingPageService';

/**
 * FAQSection Component
 * 
 * 
 * Displays a grid of FAQ cards fetched from an API. Each card includes a question and its corresponding answer.
 * 
 * @component
 * @example
 * return <FAQSection />
 */
const FAQSection = () => {
  const theme = useTheme();
  const [faqs, setFaqs] = useState([]); // State to hold FAQs
  const [loading, setLoading] = useState(true); // Loading state

  /**
   * Fetches FAQs from the API when the component mounts.
   * 
   * @function
   * @async
   * @returns {void}
   */
  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await fetchFaqsApi(); // Fetch FAQs using the API service
        setFaqs(response.data); // Store the fetched FAQs in the state
        setLoading(false); // Set loading to false after data is fetched

      } catch (err) {
        setLoading(false); // Ensure loading state is updated even if there's an error
      }
    };

    fetchFaqs();
  }, []);


  return (
    <Grid container spacing={3} sx={{ display: 'flex', flexDirection: 'row', minWidth: 'max-content', borderRadius: '15px' }}>
      {/** Render filtered FAQ items in a grid */}
      {faqs.filter((row) => [1, 2, 13, 58, 60, 135, 145].includes(row.id)).map((faq, index) => (
        <Grid item key={index}>
          <Paper sx={{
            height: '13.563rem', width: '19.875rem', p: '20px', borderRadius: '15px', boxShadow: 'none', overflowY: 'scroll', // Enable vertical scrolling
            '&::-webkit-scrollbar': {
              display: 'none', // Hide the scrollbar for webkit browsers
            },
          }}>
            {/** Render the question */}
            <Typography variant="bodyText4" color={theme.palette.grey[600]}>{faq.question}</Typography>
            <Grid item sx={12} mt='16px'>
              <Typography variant="bodyText4" color={theme.palette.grey[400]}>
                <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
              </Typography>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default FAQSection;
