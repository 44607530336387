/**
 * @module Components/FeatureComponent/FaqSection
 * @description FaqSection Component to display FAQs based on selected type.
 */

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { fetchFaqsApi } from 'services/landingPageService';

/**
 * Custom styled ToggleButton for FAQ type selection.
 * @component
 */
const CustomToggleButton2 = styled(ToggleButton)(({ theme }) => ({
    height: '2.5rem',
    padding: '13.5px 16px',
    minWidth: '123px',
    borderRadius: '10px',
    border: '1px solid #3CA1AA',
    backgroundColor: 'transparent',
    color: '#00838F',
    marginRight: '10px',
    textTransform: 'none',
    '&:last-child': {
        marginRight: 0,
    },
    '&.Mui-selected': {
        backgroundColor: '#FFFFFF',
        color: '#00838F',
        border: '1px solid #3CA1AA',
    },
    '&.Mui-selected:hover': {
        backgroundColor: '#FFFFFF', // No hover effect change
    },
    '&:hover': {
        backgroundColor: '#FFFFFF', // No hover effect change
    },
}));

/**
 * Custom styled AccordionSummary for FAQ accordion.
 * @component
 */
const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    margin: '8px 0', // Margin between each question
    borderRadius: '8px', // Border radius for AccordionSummary
    '&.Mui-expanded': {
        // If you need to adjust styles when expanded
        borderRadius: '8px',
    },
}));

/**
 * Custom styled Accordion component for displaying FAQs.
 * @component
 */
const CustomAccordion = styled(Accordion)(({ theme }) => ({
    borderRadius: '8px', // Apply border radius to Accordion itself
    marginRight: '10px',
    '&:before': {
        display: 'none', // Remove default before pseudo-element
    },
}));

/**
 * 
 * Handles FAQ fetching, filtering based on category and search query, and display of FAQs.
 *
 * @param {Object} props - Component props.
 * @param {Function} props.onFaqTypeChange - Callback function to handle FAQ type change.
 * @param {string} props.searchQuery - Search query for filtering FAQs.
 * @param {string} [props.defaultFaqType='general'] - Default FAQ type (category).
 *
 * @returns {JSX.Element} Rendered FaqSection component.
 */
const FaqSection = ({ onFaqTypeChange, searchQuery, defaultFaqType }) => {
    const [faqs, setFaqs] = useState([]); // State to hold FAQs
    const [faqType, setFaqType] = useState(defaultFaqType || 'general');
    const [expanded, setExpanded] = useState(false);

    /**
     * Mapping of FAQ categories to their respective questions.
     * @constant
     */
    const categoryMapping = {
        general: ['Will Overview', 'Essentials of a Will', 'Types of Wills'],
        mypeople: ['Beneficiary', 'Witness', 'Executor'],
        assets: ['Properties', 'Assets'],
        distribution: ['Distribution of Property'],
    };

    const [loading, setLoading] = useState(false);

    /**
     * Handles change in selected FAQ type (category).
     *
     * @param {object} event - The event object.
     * @param {string} newFaqType - The new FAQ type (category).
     */
    const handleFaqTypeChange = (event, newFaqType) => {
        if (newFaqType !== null) {
            setFaqType(newFaqType);
            onFaqTypeChange(newFaqType); // Call the parent function
        }
    };

    /**
     * Handles change in accordion expansion state.
     *
     * @param {string} panel - The panel ID.
     * @returns {Function} - The event handler function for accordion expansion.
     */
    const handleAccordionChange = (panel) => (event, isExpanded) => {
        console.log(`Panel: ${panel}, isExpanded: ${isExpanded}`);
        setExpanded(isExpanded ? panel : false);
    };

    /**
     * Converts a string to sentence case (first letter of each word capitalized).
     *
     * @param {string} str - The input string.
     * @returns {string} - The sentence case version of the string.
     */
    const toSentenceCase = (str) => {
        return str.toLowerCase().replace(/(^\w{1})|(\.\s*\w{1})/g, (match) => match.toUpperCase());
    };


    /**
     * Fetches FAQs from the API and sets the state.
     */
    useEffect(() => {
        const fetchFaqs = async () => {
            try {
                const response = await fetchFaqsApi(); // Replace with your API endpoint
                setFaqs(response.data); // Assuming data is an array of FAQs
                setLoading(false); // Set loading to false after data is fetched
            } catch (err) {
                setLoading(false);
            }
        };
        fetchFaqs();
    }, []);

    // Filter FAQs based on selected category and search query
    const filteredFaqs = faqs.filter((faq) => {
        const searchText = searchQuery.toLowerCase();
        const matchesSearch = faq.question.toLowerCase().includes(searchText);
        // If searchQuery exists, we display all matching results regardless of type
        if (searchText) {
            return matchesSearch;
        }
        // Otherwise, filter by selected faqType
        const matchesCategory = categoryMapping[faqType].includes(faq.category);
        return matchesCategory;
    });

    return (
        <Grid item container xs={12} mt="15px">
            {/* Toggle Buttons and Search Bar Row */}
            <Box display="flex" alignItems="center" width="100%" mb={2} mr="10px">
                <ToggleButtonGroup
                    color="primary"
                    value={faqType}
                    exclusive
                    onChange={handleFaqTypeChange}
                    aria-label="FAQ and Resources"
                    sx={{
                        display: 'flex',
                        border: 'none',
                        borderRadius: '20px',
                        marginRight: '16px',
                        '& .MuiToggleButtonGroup-grouped': {
                            borderRadius: '20px',
                        },
                        '& .MuiToggleButtonGroup-grouped:not(:last-of-type)': {
                            borderRadius: '20px',
                        },
                        '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
                            borderRadius: '20px',
                            border: '1px solid #3CA1AA',
                        },
                        '& .MuiToggleButtonGroup-grouped:not(:first-of-type).Mui-selected': {
                            borderRadius: '20px',
                            borderLeft: '1px solid #3CA1AA', // Only apply border when selected
                        },
                    }}
                >
                    <CustomToggleButton2 value="general">
                        <Typography variant="bodyText3">General</Typography>
                    </CustomToggleButton2>
                    <CustomToggleButton2 value="mypeople">
                        <Typography variant="bodyText3">My People</Typography>
                    </CustomToggleButton2>
                    <CustomToggleButton2 value="assets">
                        <Typography variant="bodyText3">My Assets</Typography>
                    </CustomToggleButton2>
                    <CustomToggleButton2 value="distribution">
                        <Typography variant="bodyText3">My Distribution</Typography>
                    </CustomToggleButton2>
                </ToggleButtonGroup>
            </Box>

            {/* Content Section */}
            <Box width="100%">
                {faqType && (
                    <Box width="100%">
                        {/* FAQs Display */}
                        <Box mb={3}>
                            {filteredFaqs.map((faq, index) => (
                                <CustomAccordion
                                    key={index}
                                    expanded={expanded === `panel-${index}`}
                                    onChange={handleAccordionChange(`panel-${index}`)}
                                >
                                    <CustomAccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${index}bh-content`}
                                        id={`panel${index}bh-header`}
                                    >
                                        <Typography variant="heading2" color="#1E1C1B">
                                            {' '}
                                            {toSentenceCase(faq.question)}
                                        </Typography>
                                    </CustomAccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="bodyText1" color="#707070">
                                            {faq.answer}
                                        </Typography>
                                    </AccordionDetails>
                                </CustomAccordion>
                            ))}
                        </Box>
                    </Box>
                )}
            </Box>
        </Grid>
    );
};

export default FaqSection;
