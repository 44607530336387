/**
 * @module Theme/ThemeProvider
 *
 * @description This module defines a custom `ThemeProvider` component that wraps the application in Material UI's theme provider
 * and applies global styles. It customizes the Material UI theme, including colors, typography, shadows, and components' styles.
 * The `ThemeProvider` component injects the theme and global styles into the component tree.
 *
 */
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider as MUIThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import GlobalStyles from './globalStyles';
import palette from './palette';
import shadows from './shadows';
import typography from './typography';

/**
 * `ThemeProvider` is a custom component that provides the theme and global styles
 * to the rest of the application by wrapping its children inside Material UI's `ThemeProvider` and
 * applying custom styles and theme overrides.
 *
 * @param {Object} props - The props passed to the `ThemeProvider` component.
 * @param {React.ReactNode} props.children - The child components to be wrapped by the theme.
 *
 * @returns {JSX.Element} The rendered component with the applied theme and global styles.
 */
ThemeProvider.propTypes = {
  children: PropTypes.node,
};

/**
 * @component
 *
 * A React component that provides Material UI theme and global styles.
 *
 * @returns {JSX.Element} The rendered component that includes the `MUIThemeProvider` with the custom theme.
 * @example
 * return (
 *   <ThemeProvider>
 *     <YourComponent />
 *   </ThemeProvider>
 * );
 */
export default function ThemeProvider({ children }) {
  const themeOptions = useMemo(   // Use `useMemo` to memoize the theme options, ensuring that the theme is only recalculated when necessary.
    () => ({
      palette,  // Custom color palette defined in palette.js
      shape: { borderRadius: 4 },  // Custom border radius for components
      typography,  // Custom typography options defined in typography.js
      shadows: shadows(),  // Custom shadow definitions
    }),
    []
  );

  // Create the Material UI theme using the custom theme options.
  const theme = createTheme(themeOptions);

  // Override the default styles of Material UI components
  theme.components.MuiChip = {
    variants: [
      {
        props: { variant: 'flat' },
        style: {
          border: 0,
          borderRadius: 0,
          background: 'none',
          backgroundColor: 'transparent',
        },
      },
    ],
  };

  // Return the rendered JSX for the theme provider and global styles.
  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />  {/* Apply baseline CSS styles to normalize default styles */}
        <GlobalStyles />  {/* Apply global styles for the app */}
        {children}  {/* Render the children passed to this component */}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
