/**
 * @module Views/AdminDashboard/PartnersPage
 * @description
 * This module is responsible for managing the partners in the admin panel. It provides functionality to fetch, display,
 * edit, and manage partner details such as commission, discount, enrollment status, and approval status. The page also
 * includes pagination, sorting, and filtering capabilities for the partner list.
 * It integrates with an API to fetch partner data and update partner details.
 *
 * The module uses Material UI for table rendering, custom sorting, filtering, and various UI features.
 *
 */
import {
  Box,
  ButtonGroup,
  Divider,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Slider,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import { ContainedButton1, TextedButton1 } from 'components/DesignSystem/Button';
import CheckBox from 'components/DesignSystem/CheckBox';
import CustomDatePicker from 'components/DesignSystem/CustomDatePicker';
import { Dropdown } from 'components/DesignSystem/DropDown';
import Icon from 'components/DesignSystem/Icon';
import InputField from 'components/DesignSystem/InputField';
import { CustomListItemText } from 'components/DesignSystem/ListItem/ListItem';
import useSwalAlert from 'components/DesignSystem/SwalAlert';
import { CustomTableCell, CustomTablePagination, CustomTableRow } from 'components/DesignSystem/Table/CustomTable';
import Iconify from 'components/iconify';
import dayjs from 'dayjs';
import { filter } from 'lodash';
import Scrollbar from 'oldLayouts/components/scrollbar';
import { UserListHead, UserListToolbar } from 'oldLayouts/components/user';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { getCorrectCase } from 'utils/formatString';
import PageContainer from 'views/dashboard/PageContainer';

/**
 * Table Header Configuration
 * @constant {Array} TABLE_HEAD - The columns that will be rendered in the partner list table.
 * Each item contains the `id` and an optional `label` for display.
 */
const TABLE_HEAD = [
  { id: 'edit-action' },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'profile.partnerType', label: 'Type', alignRight: false },
  { id: 'partner_code', label: 'Partner Code', alignRight: false },
  { id: 'profile.companyName', label: 'Company', alignRight: false },
  { id: 'commission', label: 'Commission', alignRight: false },
  { id: 'discount', label: 'Coupon Discount', alignRight: false },
  { id: 'applyType', label: 'Apply Coupon Discount', alignRight: false },
  { id: 'xCustomers', label: 'For X Individuals', alignRight: false },
  { id: 'endDate', label: 'Till X Date', alignRight: false },
  { id: 'partner_enroll_requested_on', label: 'Enrollment Requested', alignRight: false },
  { id: 'partner_enroll_status', label: 'Enrollment Status', alignRight: false },
  { id: 'action' },
];

/**
 * Discount Options for the partner table filters
 * @constant {Array} discountOptions - Options available to filter the discount information.
 */
const discountOptions = [
  {
    label: 'For X Individuals',
    value: 'xCustomers',
  },
  {
    label: 'Till X Date',
    value: 'xDate',
  },
];

/**
 * Enrollment Status Options for the partner table filters
 * @constant {Array} enrollStatusOptions - Options available for filtering the enrollment status of a partner.
 */
const enrollStatusOptions = [
  {
    label: 'None',
    value: null,
  },
  {
    label: 'Requested',
    value: 'REQUESTED',
  },
  {
    label: 'Approved',
    value: 'APPROVED',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
  },
];

/**
 * Comparator function to compare two values in descending order.
 * @function descendingComparator
 * @param {Object} a - First partner object to compare.
 * @param {Object} b - Second partner object to compare.
 * @param {string} orderBy - The field name to order by (e.g., "companyName", "discount", etc.).
 * @returns {number} - Returns -1 if a < b, 1 if a > b, or 0 if they are equal.
 */
function descendingComparator(a, b, orderBy) {
  console.log('descendingComparator', orderBy, a);
  if (orderBy === 'profile.companyName') {
    if (b.profile.companyName < a.profile.companyName) {
      return -1;
    }
    if (b.profile.companyName > a.profile.companyName) {
      return 1;
    }
  } else if (['discount', 'commission', 'applyType', 'xCustomers', 'endDate'].includes(orderBy)) {
    if (a.PartnerCommissions.length === 0) return 1;
    if (b.PartnerCommissions.length === 0) return -1;
    if (b?.PartnerCommissions[0][orderBy] < a?.PartnerCommissions[0][orderBy]) {
      return -1;
    }
    if (b?.PartnerCommissions[0][orderBy] > a?.PartnerCommissions[0][orderBy]) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
}

/**
 * Returns the comparator function based on the provided sorting order.
 * @function getComparator
 * @param {string} order - The current sorting order, can be 'asc' or 'desc'.
 * @param {string} orderBy - The field name to order by (e.g., "companyName", "discount", etc.).
 * @returns {function} - A comparator function to be used with array sorting.
 */
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * Applies sorting and filtering to the provided array.
 * @function applySortFilter
 * @param {Array} array - The array of partner data to apply sorting and filtering.
 * @param {function} comparator - The comparator function for sorting the array.
 * @param {string} query - The query string to filter the array.
 * @returns {Array} - The sorted and/or filtered array of partner data.
 */
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    console.log('filter', JSON.stringify(array[0] ?? {}).toLowerCase());
    return filter(
      array,
      (_user) =>
        JSON.stringify(_user ?? {})
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

/**
 * PartnersPage Component - Main component for managing partners in the admin panel.
 * This component handles table rendering, filtering, sorting, pagination, and editing of partner data.
 *
 * @component
 * @function PartnersPage
 * @returns {JSX.Element} - The rendered Partners page with the partner table.
 */
export default function PartnersPage() {
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('enrollStatus');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [partners, setPartners] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [errors, setErrors] = useState({});
  const [discount, setDiscount] = useState(0);
  const [xCustomers, setXCustomers] = useState('');
  const [commission, setCommission] = useState(0);
  const [rcmAdvocate, setRCMAdvocate] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [applyType, setApplyType] = useState('');
  const [enrollStatus, setEnrollStatus] = useState('');
  const [approveStatus, setApproveStatus] = useState(false);
  const [endDateError, setEndDteError] = useState(null);
  const [isFormatValid, setIsFormatValid] = useState(true);
  const theme = useTheme();
  const showSwalAlert = useSwalAlert();

  /**
   * Fetches the list of partners from the API and updates the state with the fetched data.
   * It also applies a custom sorting function to the partners' list.
   * @function fetchPartners
   * @returns {void}
   */
  const fetchPartners = () => {
    axios
      .get('/partners')
      .then((response) => {
        // Custom sorting function
        const customSort = (a, b) => {
          if (a.is_approved === true && b.is_approved !== true) {
            return 1; // Move a to the end
          }
          if (a.is_approved !== true && b.is_approved === true) {
            return -1; // Move b to the end
          }
          return 0; // Maintain the order
        };

        // Sort the array using the custom sorting function
        // const sortedArray = response.data?.partners.slice().sort(customSort);
        setPartners(response.data?.partners ?? []);
      })
      .catch((err) => console.error(err));
  };

  /**
   * Handles the editing of a partner by sending a post request to update the partner's data.
   * After the update, it fetches the partner list again, resets the edit modal state, and shows a success alert.
   * @function editPartner
   * @returns {void}
   */
  const editPartner = () => {
    axios
      .post(`/partners/${editingId}`, {
        commission,
        discount,
        enrollStatus,
        applyType,
        xCustomers,
        endDate,
        approveStatus,
        rcmAdvocate,
      })
      .then((response) => {
        // toastr.success("Partner updated successfully");

        showSwalAlert({
          icon: 'success',
          title: `Partner updated successfully`,
          timer: 3000,
        });
        fetchPartners();
        setEdit(false);
        setOpen(false);
        setEditingId(null);
      })
      .catch((err) => {
        // toastr.error("Failed to update asset");
        showSwalAlert({
          icon: 'error',
          title: `Failed to update partner`,
          timer: 3000,
        });
      });
  };

  /**
   * useEffect hook that runs once after the component mounts and fetches the partner data.
   * @function useEffect
   * @param {Array} [] - Empty dependency array ensures it runs once.
   * @returns {void}
   */
  useEffect(() => {
    fetchPartners();
  }, []);

  /**
   * Opens the menu to edit the partner's data when a specific partner row is clicked.
   * It also initializes the state with the partner's current values.
   * @function handleOpenMenu
   * @param {Event} event - The event triggered by clicking a partner row.
   * @param {Object} row - The partner object that contains the current partner's details.
   * @returns {void}
   */
  const handleOpenMenu = (event, row) => {
    setOpen(event.currentTarget);
    setEditingId(row.id);

    setApproveStatus(row.is_approved);
    setEnrollStatus(row.partner_enroll_status);
    setRCMAdvocate(row.rcmAdvocate);
    if (row.PartnerCommissions.length > 0) {
      setCommission(row.PartnerCommissions[0].commission);
      setDiscount(row.PartnerCommissions[0].discount);
      setApplyType(row.PartnerCommissions[0].applyType);
      setXCustomers(row.PartnerCommissions[0].xCustomers);
      setEndDate(row.PartnerCommissions[0].endDate);
    }
  };

  /**
   * Closes the menu and resets the states used for editing a partner's data.
   * @function handleCloseMenu
   * @returns {void}
   */
  const handleCloseMenu = () => {
    setOpen(null);
    setApproveStatus(false);
    setCommission(0);
    setDiscount(0);
    setApplyType('');
    setXCustomers('');
    setEndDate('');
    setEditingId(null);
    setEdit(false);
  };

  /**
   * Handles the sorting of the partner list when a table header is clicked.
   * It updates the sorting order and the sorting property.
   * @function handleRequestSort
   * @param {Event} event - The event triggered by clicking a table header.
   * @param {string} property - The property of the partner data to sort by.
   * @returns {void}
   */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  /**
   * Handles the "select all" functionality for the partner list.
   * It selects all partners if the checkbox is checked, otherwise deselects all.
   * @function handleSelectAllClick
   * @param {Event} event - The event triggered by clicking the select all checkbox.
   * @returns {void}
   */
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = partners.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  /**
   * Handles page changes in the partner list table (pagination).
   * @function handleChangePage
   * @param {Event} event - The event triggered by clicking the page navigation controls.
   * @param {number} newPage - The new page number.
   * @returns {void}
   */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  /**
   * Handles the change in the number of rows per page in the partner list table (pagination).
   * @function handleChangeRowsPerPage
   * @param {Event} event - The event triggered by selecting a new number of rows per page.
   * @returns {void}
   */
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  /**
   * Handles filtering of the partner list by the partner's name.
   * @function handleFilterByName
   * @param {Event} event - The event triggered by typing in the filter input field.
   * @returns {void}
   */
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - partners.length) : 0;
  const filteredUsers = applySortFilter(partners, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUsers.length && !!filterName;

  /**
   * Converts a phone number string in the format `in:91:<phone number>` to a formatted string `+91-<phone number>`.
   * If the input format is incorrect or doesn't match the expected structure, it returns the original input.
   * @function convertPhoneNumber
   * @param {string} input - The phone number string to convert.
   * @returns {string} - The formatted phone number or the original input if the format is incorrect.
   */
  const convertPhoneNumber = (input) => {
    // Split the input string by ":"
    if (input && input.indexOf(':') !== -1) {
      const parts = input.split(':');

      // Check if there are at least three parts (including the "+" sign)
      if (parts.length >= 3) {
        // Extract the "in" and "91" values
        const countryCode = parts[1];
        const phoneNumber = parts[2];

        // Construct the formatted phone number
        const formattedPhoneNumber = `+${countryCode}-${phoneNumber}`;

        return formattedPhoneNumber;
      }
    }

    // Return the original input if it doesn't match the expected format
    return input;
  };

  return (
    <>
      <Helmet>
        <title> Advisors/Partners | Get Will Done </title>
      </Helmet>

      <PageContainer showBackButton showHelpIcon={false}>
        <Stack direction="column" alignItems="flex-start" justifyContent="space-between" mb={2}>
          <Typography variant="title1" gutterBottom>
            Advisors/Partners
          </Typography>
          <div style={{ flexGrow: 1 }}>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          </div>
        </Stack>
        <Box paddingBottom={'80px'}>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={partners.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    // const { id, type, sub_type: subType, information } = row;
                    const { id, email, phone } = row;
                    const selectedUser = selected.indexOf(id) !== -1;

                    return (
                      <>
                        <CustomTableRow
                          sx={{ bgcolor: (theme) => (editingId === id ? 'rgba(255, 156, 65, 0.18)' : 'initial') }}
                          key={id}
                          tabIndex={-1}
                        >
                          <CustomTableCell align="right">
                            <IconButton
                              onClick={(e) => {
                                if (editingId === id) {
                                  handleCloseMenu();
                                } else {
                                  handleOpenMenu(e, row);
                                }
                              }}
                            >
                              <Iconify icon={editingId === id ? 'uil:angle-down' : 'uil:angle-right'} />
                            </IconButton>
                          </CustomTableCell>

                          <CustomTableCell align="left">
                            {row.profile?.first_name && getCorrectCase(row.profile?.first_name)}{' '}
                            {row.profile?.last_name && getCorrectCase(row.profile?.last_name)}
                          </CustomTableCell>
                          <CustomTableCell align="left">{email}</CustomTableCell>
                          <CustomTableCell align="left">{convertPhoneNumber(phone)}</CustomTableCell>
                          <CustomTableCell align="left">{row.profile.partnerType}</CustomTableCell>
                          <CustomTableCell align="left">{row.partner_code}</CustomTableCell>
                          <CustomTableCell align="left">{row.profile.companyName}</CustomTableCell>
                          <CustomTableCell align="left">
                            {row.PartnerCommissions.length > 0 ? `${row.PartnerCommissions[0].commission}%` : ''}
                          </CustomTableCell>
                          <CustomTableCell align="left">
                            {row.PartnerCommissions.length > 0 ? `${row.PartnerCommissions[0].discount}%` : ''}
                          </CustomTableCell>
                          <CustomTableCell align="left">
                            {row.PartnerCommissions.length > 0
                              ? row.PartnerCommissions[0].applyType === 'xCustomers'
                                ? 'For X Individuals'
                                : 'Till X Date'
                              : ''}
                          </CustomTableCell>
                          <CustomTableCell align="left">
                            {row.PartnerCommissions.length > 0 ? row.PartnerCommissions[0].xCustomers : ''}
                          </CustomTableCell>
                          <CustomTableCell align="left">
                            {row.PartnerCommissions.length > 0
                              ? row.PartnerCommissions[0].endDate !== null
                                ? dayjs(row.PartnerCommissions[0].endDate).format('DD-MM-YYYY')
                                : ''
                              : ''}
                          </CustomTableCell>
                          <CustomTableCell align="left">
                            {row.partner_enroll_requested_on !== null
                              ? dayjs(row.partner_enroll_requested_on).format('DD-MM-YYYY')
                              : ''}
                          </CustomTableCell>
                          <CustomTableCell align="left">{row.partner_enroll_status}</CustomTableCell>
                          <CustomTableCell>
                            {
                              <>
                                <ButtonGroup>
                                  <Tooltip title={`Edit Partner`}>
                                    <IconButton
                                      size="small"
                                      color="primary"
                                      onClick={async (e) => {
                                        handleOpenMenu(e, row);
                                        setEdit(true);
                                      }}
                                    >
                                      <Iconify icon={'fluent:edit-32-regular'} />
                                    </IconButton>
                                  </Tooltip>
                                </ButtonGroup>
                              </>
                            }
                          </CustomTableCell>
                        </CustomTableRow>
                        {editingId === id && (
                          <CustomTableRow
                            sx={{ bgcolor: (theme) => (editingId === id ? 'rgba(255, 156, 65, 0.18)' : 'initial') }}
                          >
                            <CustomTableCell colSpan={15}>
                              <Box sx={{ width: '100%', bgcolor: 'white', p: 2 }}>
                                {/* <Stack direction={"row"} spacing={1} alignItems="center"> */}
                                <Grid container spacing={1}>
                                  <Grid item xs={12} md={2}>
                                    <Typography
                                      sx={{ mt: 4, mb: 2 }}
                                      component="div"
                                      variant="bodyText4"
                                      color="primary.main"
                                    >
                                      Personal Details
                                    </Typography>
                                    <List dense={Boolean(true)}>
                                      <ListItem>
                                        <CustomListItemText
                                          primary="Name"
                                          secondary={`${getCorrectCase(row.profile.first_name)} ${getCorrectCase(row.profile.last_name) || '-'
                                            }`}
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <CustomListItemText
                                          primary="Date of Birth"
                                          secondary={`${row.profile?.dob ? dayjs(row.profile?.dob).format('DD-MM-YYYY') : '-'
                                            }`}
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <CustomListItemText
                                          primary="Partner Code"
                                          secondary={`${row.partner_code !== null ? row.partner_code : '-'}`}
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <CustomListItemText primary="PAN" secondary={row.profile.pan ?? '-'} />
                                      </ListItem>
                                      <ListItem>
                                        <CustomListItemText
                                          primary="GST Identification Number"
                                          secondary={row.profile.gstin ?? '-'}
                                        />
                                      </ListItem>
                                    </List>
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                    <Typography
                                      sx={{ mt: 4, mb: 2 }}
                                      variant="bodyText4"
                                      color="primary.main"
                                      component="div"
                                    >
                                      Contact Details
                                    </Typography>
                                    <List dense={Boolean(true)}>
                                      <ListItem>
                                        <CustomListItemText primary="Email" secondary={`${row.email ?? '-'}`} />
                                      </ListItem>
                                      <ListItem>
                                        <CustomListItemText
                                          primary="Phone"
                                          secondary={
                                            row.profile.phone ? `${convertPhoneNumber(row.profile.phone)}` : '-'
                                          }
                                        />
                                      </ListItem>
                                    </List>
                                  </Grid>
                                  <Grid item xs={12} md={2}>
                                    <Typography
                                      sx={{ mt: 4, mb: 2 }}
                                      variant="bodyText4"
                                      color="primary.main"
                                      component="div"
                                    >
                                      Company Details
                                    </Typography>
                                    <List dense={Boolean(true)}>
                                      <ListItem>
                                        <CustomListItemText
                                          primary="Partner Type"
                                          secondary={row.profile.partnerType ?? '-'}
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <CustomListItemText
                                          primary="Company Name"
                                          secondary={row.profile.companyName ?? '-'}
                                        />
                                      </ListItem>
                                    </List>
                                  </Grid>
                                  <Grid item xs={12} md={2}>
                                    <Typography
                                      sx={{ mt: 4, mb: 2 }}
                                      variant="bodyText4"
                                      color="primary.main"
                                      component="div"
                                    >
                                      Banking Details
                                    </Typography>
                                    <List dense={Boolean(true)}>
                                      <ListItem>
                                        <CustomListItemText
                                          primary="Bank Name"
                                          secondary={row.profile.accountBankName ?? '-'}
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <CustomListItemText
                                          primary="Account Name"
                                          secondary={row.profile.accountName ?? '-'}
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <CustomListItemText
                                          primary="Account Number"
                                          secondary={row.profile.accountNumber ?? '-'}
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <CustomListItemText
                                          primary="IFSC Code"
                                          secondary={row.profile.accountIFSC ?? '-'}
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <CustomListItemText
                                          primary="Branch Name"
                                          secondary={row.profile.accountBranch ?? '-'}
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <CustomListItemText primary="City" secondary={row.profile.accountCity ?? '-'} />
                                      </ListItem>
                                    </List>
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                    <Typography
                                      sx={{ mt: 4, mb: 2 }}
                                      variant="bodyText4"
                                      color="primary.main"
                                      component="div"
                                    >
                                      Address
                                    </Typography>
                                    <List dense={Boolean(true)}>
                                      <ListItem>
                                        <CustomListItemText
                                          primary="Current Address"
                                          secondary={
                                            row.profile.current
                                              ? `${row.profile.current?.house_no}, ${row.profile.current?.house_name
                                              }, ${row.profile.current?.street ? `${row.profile.current.street}, ` : ''
                                              } ${row.profile.current?.area}, ${row.profile.current?.city}, ${row.profile.current?.state
                                              }, ${row.profile.current?.country}, ${row.profile.current?.zip}`
                                              : '-'
                                          }
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <CustomListItemText
                                          primary="Permanent Address"
                                          secondary={
                                            row.profile.permanent
                                              ? `${row.profile.permanent?.house_no}, ${row.profile.permanent?.house_name
                                              }, ${row.profile.current?.street ? `${row.profile.current.street}, ` : ''
                                              } ${row.profile.permanent?.area}, ${row.profile.permanent?.city}, ${row.profile.permanent?.state
                                              }, ${row.profile.permanent?.country}, ${row.profile.permanent?.zip}`
                                              : '-'
                                          }
                                        />
                                      </ListItem>
                                    </List>
                                  </Grid>
                                </Grid>
                                <Divider sx={{ marginBottom: '24px' }} />
                                <Grid container spacing={4}>
                                  <Grid item xs={12} md={3}>
                                    <SliderLabel label="Commission" value={commission} />
                                    <Slider
                                      value={commission}
                                      onChange={(e) => setCommission(e.target.value)}
                                      valueLabelDisplay="auto"
                                      valueLabelFormat={(value) => `${value}%`}
                                      step={0.5}
                                      min={0}
                                      max={99.5}
                                      size="small"
                                      aria-labelledby="commission-slider-label"
                                      sx={{ marginTop: '12px' }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                    <SliderLabel label="Customer Discount" value={discount} />
                                    <Slider
                                      value={discount}
                                      onChange={(e) => {
                                        setDiscount(e.target.value);
                                        if (e.target.value === 0) setApplyType('');
                                      }}
                                      valueLabelDisplay="auto"
                                      valueLabelFormat={(value) => `${value}%`}
                                      step={0.5}
                                      min={0}
                                      max={99.5}
                                      size="small"
                                      aria-labelledby="slider-label"
                                      sx={{ marginTop: '12px' }}
                                    />
                                  </Grid>
                                  {discount > 0 && (
                                    <Grid item xs={12} md={3}>
                                      <Box>
                                        <Typography
                                          variant="bodyText4"
                                          color="primary.main"
                                          sx={{ marginBottom: '8px', display: 'inline-block' }}
                                        >
                                          Discount
                                        </Typography>
                                        <Dropdown
                                          name="Discount"
                                          value={applyType}
                                          handleChange={(e) => {
                                            setApplyType(e.target.value);
                                            setXCustomers('');
                                            setEndDate('');
                                          }}
                                          placeholder="Discount"
                                          options={discountOptions}
                                        />
                                      </Box>
                                    </Grid>
                                  )}
                                  {applyType === 'xCustomers' && (
                                    <Grid item xs={12} md={3}>
                                      <Box>
                                        <Typography
                                          variant="bodyText4"
                                          color="primary.main"
                                          sx={{ marginBottom: '8px', display: 'inline-block' }}
                                        >
                                          Discount for Individuals
                                        </Typography>
                                        <InputField
                                          type="number"
                                          fullWidth
                                          placeholder="Discount for Individuals"
                                          value={xCustomers}
                                          inputProps={{
                                            step: 1, // To allow floating-point numbers
                                            min: 1, // Minimum value
                                            max: 1000, // Maximum value
                                          }}
                                          onChange={(e) => {
                                            let { value } = e.target;
                                            const integerRegex = /^[0-9]+$/;
                                            // const regex = /^\d*\.?\d{0,2}0*$/;
                                            if (integerRegex.test(value)) {
                                              if (Number.isNaN(value)) {
                                                value = 1;
                                              } else if (value !== '' && value < 1) {
                                                value = 1;
                                              } else if (value > 1000) {
                                                value = 1000;
                                              }
                                              setXCustomers(value);
                                            }
                                          }}
                                        />
                                      </Box>
                                    </Grid>
                                  )}
                                  {applyType === 'xDate' && (
                                    <Grid item xs={12} md={3}>
                                      <FormControl required error={Boolean(true)} fullWidth variant="standard">
                                        <Typography
                                          variant="bodyText4"
                                          color="primary.main"
                                          sx={{ marginBottom: '8px', display: 'inline-block' }}
                                        >
                                          End Date (Expiry Date)
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                          <CustomDatePicker
                                            placeholder="End Date (Expiry Date)"
                                            labelId={`input-end-date-label`}
                                            id={`input-end-date`}
                                            value={endDate}
                                            error={Boolean(true)}
                                            inputFormat={'dd-MM-yyyy'}
                                            isValid={(date) => {
                                              console.log('isValid', date);
                                            }}
                                            minDate={dayjs().startOf('day')}
                                            onChange={(date) => {
                                              setIsFormatValid(true);
                                              setEndDate(date);
                                              const regex = /^\d{2}-\d{2}-(20\d{2}|21\d{2})$/;
                                              if (regex.test(dayjs(date).format('DD-MM-YYYY'))) {
                                                if (dayjs(date).startOf('day').isSameOrAfter(dayjs().startOf('day'))) {
                                                  setEndDteError(null);
                                                } else {
                                                  setEndDteError('End date must be today or future date');
                                                }
                                              } else {
                                                setEndDteError('Please enter a valid date (DD-MM-YYYY) with hyphen');
                                              }
                                            }}
                                            fieldError={endDateError ?? false}
                                            fieldHelperText={endDateError ?? ''}
                                            fieldInputProps={{ readOnly: true }}
                                          />
                                        </LocalizationProvider>
                                      </FormControl>
                                    </Grid>
                                  )}
                                  <Grid item xs={12} md={12}>
                                    <Grid container spacing={1}>
                                      <Grid item xs={12} md={3}>
                                        <Typography
                                          sx={{ marginBottom: '8px', display: 'inline-block' }}
                                          variant="bodyText4"
                                          color="primary.main"
                                        >
                                          Enrolment Status
                                        </Typography>
                                        <Dropdown
                                          placeholder="Enroll Status"
                                          value={enrollStatus}
                                          handleChange={(e) => {
                                            setEnrollStatus(e.target.value);
                                          }}
                                          options={enrollStatusOptions}
                                        />
                                      </Grid>
                                      {!row.profile.gstin && (
                                        <Grid item xs={12} md={3} sx={{ marginBottom: '8px' }} alignContent={'end'}>
                                          {/* label="RCM (Advocates)" */}
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              gap: '8px',
                                              marginBottom: '10px',
                                              marginLeft: '50px',
                                            }}
                                          >
                                            <CheckBox
                                              checked={rcmAdvocate}
                                              onChange={(e) => {
                                                setRCMAdvocate(e.target.checked);
                                              }}
                                              icon={<Icon name="orange-checkbox-default.png" size={20} />}
                                              checkedIcon={<Icon name="orange-checkbox-checked.svg" size={20} />}
                                              inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                            <Typography variant="bodyText4" color={theme.palette.grey[600]}>
                                              RCM (Advocates)
                                            </Typography>
                                          </Box>
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Stack direction="row" justifyContent={'flex-start'} spacing={1}>
                                  <TextedButton1
                                    sx={{
                                      marginTop: '8px',
                                      background: theme.palette.primary.lighter,
                                      paddingX: '12px',
                                      paddingY: '8px',
                                    }}
                                    onClick={() => {
                                      handleCloseMenu();
                                    }}
                                  >
                                    <Typography color="primary.main" variant="bodyText4">
                                      Cancel
                                    </Typography>
                                  </TextedButton1>
                                  {edit && (
                                    <ContainedButton1
                                      disabled={
                                        (discount === 0 ? false : applyType === '') ||
                                        (discount === 0
                                          ? false
                                          : applyType === 'xCustomers'
                                            ? xCustomers === ''
                                              ? Boolean(true)
                                              : Boolean(false)
                                            : endDate === ''
                                              ? !dayjs(endDate).startOf('day').isSameOrAfter(dayjs().startOf('day'))
                                              : !dayjs(endDate).startOf('day').isSameOrAfter(dayjs().startOf('day'))) ||
                                        errors.length > 0
                                      }
                                      style={{ marginTop: '8px' }}
                                      sx={{
                                        paddingX: '12px',
                                        paddingY: '8px',
                                      }}
                                      onClick={() => {
                                        editPartner();
                                      }}
                                    >
                                      <Typography color="common.white" variant="bodyText4">
                                        Save
                                      </Typography>
                                    </ContainedButton1>
                                  )}
                                </Stack>
                              </Box>
                            </CustomTableCell>
                          </CustomTableRow>
                        )}
                      </>
                    );
                  })}
                  {emptyRows > 0 && (
                    <CustomTableRow style={{ height: 53 * emptyRows }}>
                      <CustomTableCell colSpan={6} />
                    </CustomTableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <CustomTableRow>
                      <CustomTableCell align="center" colSpan={14} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </CustomTableCell>
                    </CustomTableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <CustomTablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={partners.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </PageContainer>
    </>
  );
}

const SliderLabel = ({ label, value }) => {
  const theme = useTheme();
  return (
    <Typography id="slider-label" variant="bodyText4" color={'primary.main'}>
      {label}
      <Typography
        variant="smallText"
        color={theme.palette.grey[600]}
        component={'span'}
        sx={{
          background: theme.palette.grey[200],
          padding: '8px 10px',
          borderRadius: '8px',
          marginLeft: '8px',
        }}
      >
        {value}%
      </Typography>
    </Typography>
  );
};
