import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { LuUpload } from 'react-icons/lu';
import { PiCheckCircleFill } from 'react-icons/pi';
import Icon from '../Icon';
import useSwalAlert from '../SwalAlert/SwalAlert';

/**
 * @module Components/DesignSystem/UploadPicture
 * @description This module contains the UploadPicture component used across the application.
 */
/**
 * UploadPicture component allows users to upload an image, displays a preview of the image,
 * handles file validation (size/type), and allows users to delete the uploaded file.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} [props.value] - The current value of the uploaded file (in base64 and file name).
 * @param {function} props.onChange - A callback function to handle changes to the uploaded file.
 * @param {boolean} [props.error] - Flag to indicate if there is an error with the file upload.
 * @param {string} [props.uploadText="Upload Picture"] - The text to display when no file is selected.
 * @param {string} [props.fileTypeText="Supports File up to 2MB"] - The text that informs the user about the supported file types/size.
 * @param {string} [props.accept] - A comma-separated string specifying the accepted file types (e.g., "image/jpeg,image/png").
 * @param {number} [props.maxFileSize] - The maximum file size allowed in bytes (e.g., 2 * 1024 * 1024 for 2MB).
 *
 * @returns {JSX.Element} A functional component for uploading and previewing a picture.
 */
const UploadPicture = ({
    value,
    onChange,
    error,
    uploadText = 'Upload Picture',
    fileTypeText = 'Supports File up to 2MB',
    accept,
    maxFileSize,
}) => {
    const [fileName, setFileName] = useState('');
    const [isSelected, setIsSelected] = useState(false);
    const fileInputRef = useRef(null);
    const [base64Image, setBase64Image] = useState(''); // State for base64 image
    const theme = useTheme();
    const showSwalAlert = useSwalAlert();

    /**
     * Effect hook to update the state when the value prop changes.
     * Initializes file name and base64 image if value contains a file.
     *
     * @function
     * @name useEffect
     * @param {Array} [value] - The value passed from the parent, which can include the image in base64.
     */
    useEffect(() => {
        // If there's already a value (in edit mode), show the filename
        if (value && value.add_picture) {
            setFileName(value.fileName ?? '');
            setBase64Image(value.add_picture);
            setIsSelected(true);
        } else {
            // Reset state if value is null or empty
            setFileName('');
            setBase64Image('');
            setIsSelected(false);
        }
    }, [value]); // Trigger on change of value

    /**
     * Handles file change events. Validates file type and size, converts the file to base64,
     * and updates the parent component with the file information.
     *
     * @function
     * @param {Object} event - The file input change event.
     * @param {File} event.target.files[0] - The file selected by the user.
     */
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (accept && !accept.split(',').includes(file.type)) {
                showSwalAlert({
                    title: `File type not allowed. Expected: ${accept}`,
                    icon: 'error',
                    timer: 1500,
                });
                // Reset the file input field to allow re-uploading the same file
                if (fileInputRef.current) {
                    fileInputRef.current.value = ''; // Clear file input for next upload
                }
                return;
            }
            if (maxFileSize && file.size > maxFileSize) {
                showSwalAlert({
                    title: 'File size exceeds the limit',
                    icon: 'error',
                    timer: 1500,
                });
                // Reset the file input field to allow re-uploading the same file
                if (fileInputRef.current) {
                    fileInputRef.current.value = ''; // Clear file input for next upload
                }
                return;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setFileName(file.name);
                setBase64Image(base64String); // Store base64 image
                setIsSelected(true);

                // Pass the base64 image and file name to onChange
                if (onChange) {
                    onChange({
                        add_picture: base64String, // base64 string of the image
                        fileName: file.name, // file name
                    });
                }
            };
            reader.readAsDataURL(file);
        } else {
            setFileName('');
            setBase64Image(''); // Clear base64 image
            setIsSelected(false);
            if (onChange) {
                onChange(null);
            }
        }
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    /**
     * Deletes the selected file, resets the file state, and informs the parent component.
     *
     * @function
     * @name handleDelete
     */
    const handleDelete = () => {
        // Clear the file name and reset isSelected state
        setFileName('');
        setBase64Image(''); // Clear base64 image
        setIsSelected(false);
        // Inform the parent component that the file has been removed
        if (onChange) {
            onChange(null); // Pass null or an empty object to signal file deletion
        }
        // Reset the file input field to allow re-uploading the same file
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Clear file input for next upload
        }
    };

    return (
        <Grid container>
            <Grid item sm={12}>
                <Box display="flex" alignItems="center" gap={0.5}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            width: '14.75rem',
                            height: '5rem',
                            borderRadius: '0.625rem',
                            backgroundColor: theme.palette.grey[200],
                        }}
                    >
                        <Box
                            sx={{
                                width: '14.25rem',
                                height: '4.5rem',
                                borderRadius: '0.5rem',
                                border: `0.0625rem dashed ${theme.palette.grey[100]}`,
                                backgroundColor: isSelected ? theme.palette.secondary.lighter : theme.palette.grey[0],
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                '&:hover': {
                                    borderColor: theme.palette.primary.main,
                                },
                            }}
                            onClick={handleClick}
                        >
                            {isSelected ? (
                                <Typography
                                    variant="bodyText2"
                                    style={{ margin: '0.5rem' }}
                                    sx={{ color: theme.palette.secondary.darker }}
                                >
                                    Upload Successful
                                </Typography>
                            ) : (
                                <Typography variant="bodyText2" style={{ margin: '1rem' }} sx={{ color: theme.palette.secondary.main }}>
                                    {uploadText}
                                </Typography>
                            )}
                            {isSelected ? (
                                <PiCheckCircleFill
                                    size={'2.2rem'}
                                    color={theme.palette.secondary.main}
                                    style={{ marginRight: '0.5rem' }}
                                />
                            ) : (
                                <LuUpload size={'1.875rem'} color={theme.palette.secondary.main} style={{ margin: '1rem' }} />
                            )}
                        </Box>
                    </Box>

                    {isSelected ? (
                        <Box display="flex" alignItems="center" ml={2} gap={1}>
                            <Typography variant="bodyText3" sx={{ color: theme.palette.grey[800] }}>
                                {fileName}
                            </Typography>
                            <Icon name="delete-icon-pink.svg" height="40px" width="40px" onClick={handleDelete} />
                        </Box>
                    ) : (
                        <Box display="flex" alignItems="center" ml={2} gap={1}>
                            <Typography variant="bodyText3" sx={{ color: theme.palette.grey[100] }}>
                                File Name
                            </Typography>
                        </Box>
                    )}

                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        accept={accept}
                        onChange={handleFileChange}
                    />
                </Box>
            </Grid>
            <Grid item sm={6} mt={0.5} display={'flex'} justifyContent="center">
                {!isSelected && (
                    <Typography variant="errorText" mr={3}>
                        {fileTypeText}
                    </Typography>
                )}
            </Grid>
            {isSelected && (
                <Grid item sm={12} mt={2} display={'flex'} justifyContent="flex-start">
                    <img
                        src={base64Image}
                        alt={fileName}
                        style={{
                            maxWidth: '100%',
                            maxHeight: '200px',
                            borderRadius: '0.5rem',
                            objectFit: 'cover',
                        }}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default UploadPicture;
