// material-ui
import { Typography } from '@mui/material';

// project imports
import { ContextProvider } from 'App';
import menuItem from 'menu-items';
import { useContext } from 'react';
import NavGroup from './NavGroup';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = ({ isPartnerDashboard }) => {
  const { user } = useContext(ContextProvider);

  const navItems = menuItem.items.map((item) => {
    switch (item.type) {
      case 'group':
        if (item.id === 'dashboard' && ['testator', 'partner'].includes(user.role) && !isPartnerDashboard)
          return (
            <>
              <NavGroup key={item.id} item={item} isPartnerDashboard={isPartnerDashboard} />
            </>
          );
        if (item.id === 'administration' && ['admin'].includes(user.role))
          return <NavGroup key={item.id} item={item} />;
        if (item.id === 'reviewerDashboard' && ['reviewer'].includes(user.role))
          return <NavGroup key={item.id} item={item} />;
        if (item.id === 'partnerdashboard' && isPartnerDashboard) return <NavGroup key={item.id} item={item} />;
        return <></>;
      // return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
