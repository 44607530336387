/**
 * @module Components/IdleTimeout
 *
 * @description The `IdleTimeout` component monitors user activity and automatically logs out the user after a specified idle period.
 * If the user is about to be logged out, a prompt is shown with a countdown, allowing the user to reset the timer and stay logged in.
 * The component utilizes session storage clearing and navigates to the login page upon logout. Additionally, a Swal alert is displayed when the user is logged out due to inactivity.
 *
 * @component
 *
 * @example
 * <IdleTimeout />
 *
 */
import { Stack, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import AlertDialog from './DesignSystem/AlertDialog';
import { ContainedButton1 } from './DesignSystem/Button';
import useSwalAlert from './DesignSystem/SwalAlert';

// Timeout and promptBeforeIdle are configured for 15 minutes and 10 seconds respectively
const timeout = 900_000; // 15 minutes
const promptBeforeIdle = 10_000; // 10 seconds before idle timeout prompt

/**
 * `IdleTimeout` component handles user inactivity timeout and prompt.
 * It shows a dialog to notify the user of an impending logout due to inactivity.
 *
 * - Logs the user out automatically after a specified time of inactivity.
 * - Prompts the user with a countdown of the remaining time before being logged out.
 * - Clears session storage upon logout and redirects the user to the login page.
 * - Alerts the user using a custom Swal alert notification upon automatic logout.
 *
 * @function IdleTimeout
 * @returns {JSX.Element} - Returns the alert dialog and buttons for user interaction.
 */
const IdleTimeout = () => {
    // State to track the remaining time before timeout
    const [remaining, setRemaining] = useState(timeout);
    // State to control the visibility of the timeout alert dialog
    const [open, setOpen] = useState(false);
    const navigate = useNavigate(); // For navigation to the login page after logout
    const showSwalAlert = useSwalAlert(); // Custom Swal alert hook for showing notifications

    /**
     * Called when the user goes idle. Logs the user out and shows an alert.
     * Clears session storage and redirects the user to the login page.
     *
     * @function onIdle
     */
    const onIdle = async () => {
        // Perform logout
        axios
            .post('/auth/logout')
            .then(() => {
                showSwalAlert({
                    icon: 'warning',
                    title: 'You have been logged out due to inactivity! Please login again to use the application.',
                    timer: 3000,
                });
                // Clear session storage upon logout
                sessionStorage.clear(); // This clears all items, which may be what you want
            })
            .finally(() => navigate('/login', { replace: true }));
        setOpen(false);
    };

    /**
     * Called when the user becomes active again. Closes the idle prompt dialog.
     *
     * @function onActive
     */
    const onActive = () => {
        setOpen(false);
    };

    /**
     * Called when the idle timeout is near. Opens the idle prompt dialog.
     *
     * @function onPrompt
     */
    const onPrompt = () => {
        setOpen(true);
    };

    /**
     * Initializes the idle timer with specific configurations.
     * Uses the `useIdleTimer` hook to handle inactivity, timeout, and prompt.
     *
     * @constant
     * @type {Object}
     */
    const { getRemainingTime, activate } = useIdleTimer({
        onIdle, // Triggered on idle state
        onActive, // Triggered when the user becomes active
        onPrompt, // Triggered when a prompt is shown
        timeout, // Timeout duration in milliseconds (15 minutes)
        promptBeforeIdle, // Time before showing the prompt (10 seconds before idle)
        throttle: 500, // Throttle the events to prevent frequent firing
    });

    // Effect hook to update the remaining time and handle the countdown
    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));
        }, 500); // Update every 500 milliseconds

        return () => {
            clearInterval(interval); // Clear the interval when the component unmounts
        };
    });

    /**
     * Resets the idle timer and keeps the user logged in by activating the idle timer again.
     *
     * @function handleStillHere
     */
    const handleStillHere = () => {
        activate();
    };

    return (
        <>
            <AlertDialog
                open={open}
                onClose={() => {
                    handleStillHere();
                }}
                title={`You will be logged out in ${remaining} seconds.`}
            >
                <Stack sx={{ m: -1, mb: 0, p: 1 }} direction={'row'} justifyContent={'space-between'}>
                    <Typography variant="bodyText3" gutterBottom>
                        &nbsp;
                    </Typography>
                    <ContainedButton1
                        style={{ marginTop: '16px' }}
                        sx={{ padding: '8px 28px' }}
                        onClick={() => {
                            handleStillHere();
                        }}
                    >
                        <Typography variant="bodyText3" gutterBottom>
                            I am still here
                        </Typography>
                    </ContainedButton1>
                </Stack>
            </AlertDialog>
        </>
    );
};
export default IdleTimeout;
