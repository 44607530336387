import { Box, Typography, useTheme } from '@mui/material';
import { noop } from 'lodash';
import { ContainedButton1, TextedButton1 } from '../Button';

/**
 * @module Components/DesignSystem/BottomFloatingAction
 * @description This module contains the BottomFloatingAction component used across the application.
 */
/**
 * BottomFloatingAction component displays a floating action box with a message and up to three buttons.
 * The component is typically used for actions that need to be performed at the bottom of the screen, 
 * such as confirmations or other user interactions.
 *
 * @component
 * @example
 * <BottomFloatingAction
 *   message="Are you sure you want to proceed?"
 *   firstButtonText="Cancel"
 *   secondButtonText="Confirm"
 *   thirdButtonText="Details"
 *   onFirstButtonClick={handleCancel}
 *   onSecondButtonClick={handleConfirm}
 *   onThirdButtonClick={handleDetails}
 * />
 *
 * @param {Object} props - The props for the component.
 * @param {string} [props.message] - The message to display in the component. Default is an empty string.
 * @param {Object} [props.containerStyles] - Custom styles for the container of the component. Default is an empty object.
 * @param {string} [props.firstButtonText] - The text to display on the first button. Default is an empty string.
 * @param {function} [props.onFirstButtonClick=noop] - The callback function to execute when the first button is clicked. Default is a no-op function.
 * @param {boolean} [props.firstButtonDisabled=false] - Whether the first button is disabled. Default is false.
 * @param {Object} [props.firstButtonSx] - The custom styles for the first button. Default is an empty object.
 * @param {string} [props.secondButtonText] - The text to display on the second button. Default is an empty string.
 * @param {function} [props.onSecondButtonClick=noop] - The callback function to execute when the second button is clicked. Default is a no-op function.
 * @param {boolean} [props.secondButtonDisabled=false] - Whether the second button is disabled. Default is false.
 * @param {Object} [props.secondButtonSx] - The custom styles for the second button. Default is an empty object.
 * @param {string} [props.thirdButtonText] - The text to display on the third button. Default is an empty string.
 * @param {function} [props.onThirdButtonClick=noop] - The callback function to execute when the third button is clicked. Default is a no-op function.
 * @param {boolean} [props.thirdButtonDisabled=false] - Whether the third button is disabled. Default is false.
 * @param {Object} [props.thirdButtonSx] - The custom styles for the third button. Default is an empty object.
 *
 * @returns {JSX.Element} A floating action component containing a message and up to three action buttons.
 */
const BottomFloatingAction = ({
    message = '',
    containerStyles = {},
    firstButtonText = '',
    onFirstButtonClick = noop,
    firstButtonDisabled = false,
    firstButtonSx = {},
    secondButtonText = '',
    onSecondButtonClick = noop,
    secondButtonDisabled = false,
    secondButtonSx = {},
    thirdButtonText = '',
    onThirdButtonClick = noop,
    thirdButtonDisabled = false,
    thirdButtonSx = {}
}) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                backgroundColor: theme.palette.common.white,
                border: `1px solid ${theme.palette.primary.dark}90`,
                borderRadius: '15px',
                padding: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '50%',
                zIndex: 1000,
                flexFlow: 'row wrap',
                ...containerStyles,
            }}
        >
            <Box sx={{ width: '50%', '@media (max-width:1408px)': { width: "100%", textAlign: "center", marginBottom: "8px" } }}>
                <Typography variant="smallText2">{message}</Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center', justifyContent: 'center', '@media (max-width:1194px)': { flexFlow: 'nowrap' } }}>
                <TextedButton1
                    disabled={firstButtonDisabled}
                    onClick={onFirstButtonClick}
                    sx={{ ...firstButtonSx }}
                >
                    <Typography variant="smallText2">{firstButtonText}</Typography>
                </TextedButton1>
                <ContainedButton1
                    disabled={secondButtonDisabled}
                    onClick={onSecondButtonClick}
                    sx={{ ...secondButtonSx }}
                >
                    <Typography variant="smallText2">{secondButtonText}</Typography>
                </ContainedButton1>
                <ContainedButton1
                    sx={{ ...thirdButtonSx }}
                    disabled={thirdButtonDisabled}
                    onClick={onThirdButtonClick}
                >
                    <Typography variant="smallText2">{thirdButtonText}</Typography>
                </ContainedButton1>
            </Box>
        </Box>
    );
};

export default BottomFloatingAction;
