/**
 * @module Components/FeatureComponent/SelectDistributionModalCard
 * @description A card component used for selecting an option in a distribution modal. The card supports a title, an icon, 
 * and selection state styling to indicate whether it is selected.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {React.ElementType} props.icon - A React component for the icon to be displayed on the card (e.g., an SVG or MUI icon component).
 * @param {string} props.title - The title text displayed on the card.
 * @param {Function} props.onClick - Callback function triggered when the card is clicked.
 * @param {boolean} props.isSelected - Indicates whether the card is currently selected. Changes the visual styling of the card.
 *
 * @returns {JSX.Element} The SelectDistributionModalCard component.
 */

import { Box, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';

const StyledCard = styled(Box)(({ theme, isSelected }) => ({
    backgroundColor: theme.palette.primary.lighter,
    borderRadius: '12px',
    boxShadow: isSelected ? `0px 10px 10px -8px ${theme.palette.primary.dark}4D` : 'none',
    transition: 'border-color 0.10s ease',
    border: isSelected ? `1px solid ${theme.palette.primary.dark} !important` : `1px solid transparent`,
    minWidth: '167px',
    cursor: 'pointer',
    '&:hover': {
        borderColor: theme.palette.primary.light,
        boxShadow: `0px 10px 10px -8px ${theme.palette.primary.dark}4D`,
    },
}));

const SelectDistributionModalCard = ({ icon: Icon, title, onClick, isSelected }) => {
    const theme = useTheme();
    return (
        <StyledCard
            onClick={onClick}
            sx={{ maxHeight: '100px', maxWidth: '167px' }}
            isSelected={isSelected}
        >
            <Box
                p={'0 8px 10px 8px'}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}
            >
                <Box display="flex" alignItems="center" justifyContent="flex-start">
                    <Icon style={{ fontSize: '36px' }} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                        maxHeight: '38px',
                        maxWidth: '90%',
                    }}
                >
                    <Typography variant="bodyText3" sx={{ lineHeight: '18.9px !important' }}>
                        {title}
                    </Typography>
                </Box>
            </Box>
        </StyledCard>
    );
};

export default SelectDistributionModalCard;
