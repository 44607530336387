import { Box, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import { ContainedButton1 } from 'components/DesignSystem/Button';
import CheckBox from 'components/DesignSystem/CheckBox';
import {
    CustomDialog,
    CustomDialogActions,
    CustomDialogContent,
    CustomDialogTitle,
} from 'components/DesignSystem/CustomDialog';
import useSwalAlert from 'components/DesignSystem/SwalAlert';
import { noop } from 'lodash';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateDistributionCard from './cards/CreateDistributionCard';

/**
 * A modal component for creating a new distribution scenario for a will.
 *
 * The component allows the user to select beneficiaries and assets, and manage their distribution scenarios.
 * @module Components/FeatureComponent/CreateDistributionModal
 * @description A modal component for creating a new distribution scenario for a will.
 * The component allows the user to select beneficiaries and assets, and manage their distribution scenarios.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {boolean} [props.open=false] - Indicates whether the modal is open.
 * @param {Function} [props.onClose=noop] - Callback function triggered when the modal is closed.
 * @param {Object} [props.generics={}] - Generic data for beneficiaries, assets, and scenarios.
 * @param {Function} [props.fetchWillsData=noop] - Function to fetch will data after changes.
 * @param {Object} props.selectedScenario - The selected scenario for distribution.
 * @param {number} [props.step=1] - Current step in the distribution scenario creation process.
 * @param {Function} [props.setStep=noop] - Function to set the current step.
 * @param {Array} props.filteredUsers - List of filtered users to be used in distribution.
 * @param {Function} props.setMappings - Function to update the mappings of assets and beneficiaries.
 *
 * @returns {JSX.Element} The CreateDistributionModal component.
 */
const CreateDistributionModal = ({
    open = false,
    onClose = noop,
    generics = {},
    fetchWillsData = noop,
    selectedScenario,
    step = 1,
    setStep = noop,
    filteredUsers,
    setMappings,
}) => {
    const theme = useTheme();
    const [distributionDefinition, setDistributionDefinition] = useState(new Set());
    const [middleColumnSelection, setMiddleColumnSelection] = useState(new Set());
    const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
    const navigate = useNavigate();
    const showSwalAlert = useSwalAlert();

    /**
     * Creates a new scenario by checking if the current selection already exists.
     * Sends an API request to add the scenario if it does not exist.
     */
    const createScenario = () => {
        const array1 = Array.from(distributionDefinition);
        const array2 = generics.scenarios;

        const scenarioExists = array2.some(
            (obj) => obj?.deaths?.length === array1.length && array1.every((num) => obj.deaths.includes(num))
        );

        if (scenarioExists) {
            showSwalAlert({
                icon: 'error',
                title: `Scenario already exists for this situation`,
                timer: 3000,
            });
        } else {
            axios
                .post('/wills/scenario', { deaths: Array.from(distributionDefinition) })
                .then((response) => {
                    //   toastr.success("Scenario added successfully");
                    showSwalAlert({
                        icon: 'success',
                        title: `Scenario added successfully`,
                        timer: 3000,
                    });
                    fetchWillsData(true);
                    // setNewDistributionOpen(false);
                    setStep(2);
                    setDistributionDefinition(new Set());
                })
                .catch((err) => {
                    // toastr.error("Failed to add scenario");
                    showSwalAlert({
                        icon: 'error',
                        title: `Failed to add scenario`,
                        timer: 3000,
                    });
                });
        }
    };

    /**
     * Distributes assets evenly among selected beneficiaries.
     * Updates the mappings and resets internal states after distribution.
     */
    const distributeEvenly = useCallback(() => {
        const combinedSelection = new Set([...middleColumnSelection]);
        setMappings(
            filteredUsers?.map((as) => {
                const distributionType = as.sub_type === 'livestock' ? 'quantity' : 'percentage';

                return {
                    id: -1,
                    PropertyId: as.id,
                    distribution_type: distributionType,
                    mapping: Array.from(combinedSelection)?.map((b) => {
                        if (['pets', 'car', 'two_wheeler', 'large_vehicles', 'other_vehicles'].includes(as.sub_type)) {
                            // Check if there is only one beneficiary
                            const isSingleBeneficiary = combinedSelection.size === 1;

                            return {
                                beneficiary_id: b,
                                share: isSingleBeneficiary ? (100).toFixed(2) : 0,
                            };
                        }

                        if (as.sub_type === 'livestock') {
                            // Check if there is only one beneficiary
                            const isSingleBeneficiary = combinedSelection.size === 1;
                            return {
                                beneficiary_id: b,
                                share: isSingleBeneficiary ? as.information?.number : 0,
                            };
                        }
                        // For other types, calculate share as percentage with two decimal places
                        return {
                            beneficiary_id: b,
                            share: (100 / (combinedSelection.size ?? 1)).toFixed(2),
                        };
                    }),
                };
            })
        );

        // Reset states
        onClose();
        setMiddleColumnSelection(new Set());
        setIsSelectAllChecked(false);
        setDistributionDefinition(new Set());
    }, [generics?.assets, middleColumnSelection]);

    return (
        <CustomDialog open={open} onClose={onClose} maxWidth={false}>
            <CustomDialogTitle onClose={onClose}>
                <Typography variant="heading1" color={theme.palette.secondary.main}>
                    Create new distribution scenario
                </Typography>
                {step === 1 && (
                    <Typography maxWidth="628px" variant="bodyText4" color={theme.palette.grey[400]}>
                        If you survive any beneficiaries, you can give their share to someone else. You can add scenarios for
                        precise distribution.
                    </Typography>
                )}
            </CustomDialogTitle>
            <CustomDialogContent>
                {step === 1 && (
                    <>
                        <Box sx={{ display: 'flex', gap: '15px', margin: '25px 0 15px 0' }}>
                            <Typography variant="bodyText2">In case of demise(s) of</Typography>
                        </Box>
                        <Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', mb: '24px' }}>
                                {generics?.beneficiary
                                    ?.filter((bene, index) => bene.type === 'person')
                                    .map((bene, index) => (
                                        <CreateDistributionCard
                                            key={index}
                                            name={bene.name}
                                            isSelected={distributionDefinition.has(bene.id)}
                                            onSelect={(value) => {
                                                if (value) {
                                                    setDistributionDefinition((dist) => {
                                                        dist.add(bene.id);
                                                        return new Set(Array.from(dist));
                                                    });
                                                } else {
                                                    setDistributionDefinition((dist) => {
                                                        dist.delete(bene.id);
                                                        return new Set(Array.from(dist));
                                                    });
                                                }
                                            }}
                                        />
                                    ))}
                            </Box>
                            <Typography variant="bodyText4" fontStyle="italic" color={theme.palette.grey[400]}>
                                You can select multiple people.
                            </Typography>
                        </Box>
                    </>
                )}
                {step === 2 && (
                    <>
                        <Box sx={{ display: 'flex', gap: '15px', margin: '25px 0 15px 0' }}>
                            <Typography variant="bodyText2">Asset(s) will be distributed to</Typography>
                        </Box>
                        <Box
                            sx={{ display: 'flex', flexDirection: 'row', gap: '8px', mt: '15px', mb: '12px', alignItems: 'center' }}
                        >
                            <CheckBox
                                label="Select All to Distribute Equally"
                                sx={{ Padding: '0px !important' }}
                                checked={isSelectAllChecked}
                                onChange={(e, value) => {
                                    if (value) {
                                        generics?.beneficiary
                                            ?.filter((bene) => selectedScenario?.deaths?.indexOf(bene.id) === -1)
                                            ?.forEach((bene) => {
                                                setMiddleColumnSelection((dist) => {
                                                    const newDist = new Set(dist);
                                                    newDist.add(bene.id);
                                                    return newDist;
                                                });
                                            });
                                        setIsSelectAllChecked(true);
                                    } else {
                                        setIsSelectAllChecked(false);
                                        setMiddleColumnSelection(new Set());
                                    }
                                }}
                            />
                            <Typography variant="bodyText4">Select All to Distribute Equally</Typography>
                        </Box>
                        <Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', mb: '15px' }}>
                                {generics?.beneficiary
                                    ?.filter((bene) => selectedScenario?.deaths?.indexOf(bene.id) === -1)
                                    ?.map((bene) => (
                                        <CreateDistributionCard
                                            key={bene.id}
                                            name={bene.type === 'person' ? bene.name : bene.organisation_name}
                                            isSelected={middleColumnSelection.has(bene.id)}
                                            onSelect={(value) => {
                                                setMiddleColumnSelection((dist) => {
                                                    const newDist = new Set(dist);
                                                    if (value) {
                                                        newDist.add(bene.id);
                                                    } else {
                                                        newDist.delete(bene.id);
                                                    }
                                                    return newDist;
                                                });
                                            }}
                                        />
                                    ))}
                            </Box>
                            <Typography variant="bodyText4" color={theme.palette.grey[400]}>
                                or{' '}
                                <Typography
                                    variant="bodyText3"
                                    sx={{
                                        color: (theme) => theme.palette.secondary.main,
                                        mb: 5,
                                        '&:hover': {
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                        },
                                    }}
                                    onClick={() => navigate('/myPeople')}
                                >
                                    {`Click here `}
                                </Typography>
                                to add new beneficiary
                            </Typography>
                        </Box>
                    </>
                )}
            </CustomDialogContent>
            <CustomDialogActions>
                {step === 1 && (
                    <ContainedButton1
                        onClick={() => createScenario()}
                        // onClick={handleNext}
                        sx={{ minHeight: '45px', minWidth: '122px' }}
                        disabled={
                            distributionDefinition.size === 0 ||
                            (distributionDefinition.size === generics?.beneficiary?.filter((bene) => bene.type === 'person').length &&
                                generics?.beneficiary?.filter((bene) => bene.type === 'ngo_organisation').length === 0)
                        }
                    >
                        <Typography variant="bodyText3">Next</Typography>
                    </ContainedButton1>
                )}
                {step === 2 && (
                    <ContainedButton1
                        onClick={() => distributeEvenly()}
                        // onClick={handleNext}
                        sx={{ minHeight: '45px', minWidth: '122px' }}
                        disabled={middleColumnSelection.size === 0}
                    >
                        <Typography variant="bodyText3">Next</Typography>
                    </ContainedButton1>
                )}
            </CustomDialogActions>
        </CustomDialog>
    );
};

export default CreateDistributionModal;
