/**
 * @module Components/FeatureComponent/LogoutSection
 * 
 * @description A section that handles the user logout functionality. When clicked, the component triggers a logout API request,
 * clears the user data from the context and session storage, and shows a success notification. Upon successful logout,
 * the user is redirected to the login page.
 */
import { Typography } from '@mui/material';
import { ContextProvider } from 'App';
import useSwalAlert from 'components/DesignSystem/SwalAlert/SwalAlert';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { logoutUserApi } from 'services/profileService';

/**
 * LogoutSection is a React functional component that handles user logout.
 * 
 * When the user clicks on the "Logout" text, it triggers the `handleLogout` function which:
 * - Logs the user out by calling the `logoutUserApi` function.
 * - Resets the user data and login status in the global context.
 * - Removes session storage items related to the demo session.
 * - Displays a success notification via SweetAlert.
 * - Redirects the user to the login page upon successful logout.
 * 
 * @component
 * 
 * @returns {JSX.Element} A Typography component that triggers the logout process when clicked.
 */
const LogoutSection = () => {
    /**
     * Custom hook to trigger SweetAlert notifications for user feedback.
     * 
     * @function
     * @returns {Function} The function to trigger the SweetAlert notification.
     */
    const showSwalAlert = useSwalAlert();

    /**
     * React Router hook to navigate the user after logout.
     * 
     * @function
     * @returns {Function} The function to trigger navigation.
     */
    const navigate = useNavigate();

    /**
    * Context hook to access global user data and login status.
    * 
    * @type {Object}
    * @property {Function} setUser - Function to update user data in the context.
    * @property {Function} setLoggedIn - Function to update login status in the context.
    */
    const { setUser, setLoggedIn } = useContext(ContextProvider)

    /**
     * Handles the user logout process.
     * This function performs the following:
     * - Calls the `logoutUserApi` to log out the user from the system.
     * - Clears the user data from the global context and sets the logged-in state to false.
     * - Removes any session storage items related to the user session (e.g., 'isDemoActive').
     * - Displays a success notification using SweetAlert.
     * - Redirects the user to the login page after logout.
     * 
     * @async
     * @function
     * @throws {Error} If there is an issue during logout, an error is logged to the console.
     */
    const handleLogout = async () => {
        try {
            await logoutUserApi();
            setUser({});
            setLoggedIn(false);
            sessionStorage.removeItem('isDemoActive');
            showSwalAlert({
                position: 'top-center',
                icon: 'success',
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: 'Logged out!',
                showConfirmButton: false,
                timer: 3000,
            });
        } catch (err) {
            console.log('err ', err);
        } finally {
            navigate('/login', { replace: true });
        }
    };

    return (
        /**
        * The returned JSX includes a Typography component that renders a "Logout" text.
        * When clicked, it triggers the `handleLogout` function to log out the user.
        * 
        * @returns {JSX.Element} A clickable "Logout" text styled with a hover effect.
        */
        <Typography
            variant="bodyText3"
            sx={{
                color: (theme) => theme.palette.error.main,
                mb: 5,
                '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer'
                },
            }}
            onClick={handleLogout}
        >
            Logout
        </Typography>
    );
};

export default LogoutSection;
