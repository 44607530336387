import { Box, Grid } from '@mui/material';
import Icon from 'components/DesignSystem/Icon';

/**
 * @module Components/LogoContainer
 * @description This module contains the LogoContainer component used across the application.
 * A container component that provides a layout with a logo section on the left 
 * and a content area on the right.
 *
 * The logo section displays a logo image, while the content area renders the 
 * children passed to the component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The content to be displayed in the right section of the layout.
 * @returns {JSX.Element} A full-height grid layout with a logo on the left and customizable content on the right.
 */
const LogoContainer = ({ children }) => {
    return (
        <Grid container style={{ height: '100vh', position: 'relative' }}>
            {/* Left Section with Logo */}
            <Grid
                item
                // xs={12}
                md={2}
            >
                <Box sx={{ marginTop: '60px', marginLeft: '75px', padding: 0 }}>
                    <Icon name="will-logo.png" width={169} height={90} />
                </Box>
            </Grid>

            {/* Right Section with Content */}
            {children}
        </Grid>
    );
};

export default LogoContainer;
