/**
 * @module Theme/Palette
 *
 * @description This module defines a custom Material UI color palette for the application.
 * The palette includes primary, secondary, success, warning, error, and info color schemes,
 * along with common colors, graph colors, and various other UI-related color settings like text and background.
 * This palette is used to customize the theme and create a consistent design throughout the app.
 */

import { alpha } from '@mui/material/styles';

/**
 * Defines various grey shades used throughout the application.
 * These grey values are used for backgrounds, borders, and text styling.
 *
 * @constant {Object} GREY
 * @property {string} 0 - Lightest grey (white).
 * @property {string} 100 - Lighter grey.
 * @property {string} 200 - Very light grey (used for backgrounds).
 * @property {string} 300 - Light medium grey.
 * @property {string} 400 - Medium grey.
 * @property {string} 500 - Grey for text and borders.
 * @property {string} 600 - Darker grey for subtle text.
 * @property {string} 700 - Dark grey for text.
 * @property {string} 800 - Darkest grey for dark text or backgrounds.
 * @property {string} 900 - Almost black.
 */
const GREY = {
  0: '#FFFFFF',
  100: '#C5C5C5',
  200: '#F5F5F5',
  300: '#888888',
  400: '#707070',
  500: '#919EAB',
  600: '#1E1C1B',
  700: '#454F5B',
  800: '#212121',
  900: '#161C24',
};

/**
 * Defines an array of colors used for graphs and charts.
 * These colors are intended for graph elements like bars or lines.
 *
 * @constant {Array<string>} GRAPHS
 * @example
 * // Example of graph colors:
 * // [ "#f5b59e", "#f7a78a", "#f79977", "#f78b63", "#f67c50", "#f46d3c", "#f15d27"]
 */
const GRAPHS = ['#f5b59e', '#f7a78a', '#f79977', '#f78b63', '#f67c50', '#f46d3c', '#f15d27'];

/**
 * Defines the primary color scheme for the application.
 * The primary color is used in buttons, highlights, and other core elements.
 *
 * @constant {Object} PRIMARY
 * @property {string} lighter - Lightest shade of the primary color, often used for backgrounds or hover effects.
 * @property {string} light - A lighter version of the primary color, typically used for accents.
 * @property {string} main - The main primary color.
 * @property {string} dark - A darker version of the primary color.
 * @property {string} darker - The darkest shade of the primary color.
 * @property {string} contrastText - The contrast color used for text, ensuring readability.
 */

const PRIMARY = {
  lighter: '#FFF3EA',
  light: '#FFD5B5',
  main: '#F47B20',
  dark: '#FFA25C',
  darker: '#FF8C35',
  contrastText: '#fff',
};

/**
 * Defines the secondary color scheme for the application.
 * The secondary color is typically used for accents, buttons, and other non-primary elements.
 *
 * @constant {Object} SECONDARY
 * @property {string} lighter - Lightest shade of the secondary color.
 * @property {string} light - A lighter version of the secondary color.
 * @property {string} main - The main secondary color.
 * @property {string} dark - A darker version of the secondary color.
 * @property {string} darker - The darkest version of the secondary color.
 * @property {string} panelColor - Specific color used for panels or similar UI elements.
 * @property {string} contrastText - Text color used on top of the secondary color to ensure readability.
 */
const SECONDARY = {
  lighter: '#EFF9F9',
  light: '#B3DBDF',
  main: '#00838F',
  dark: '#78BEC4',
  darker: '#3CA1AA',
  panelColor: '#104E5B',
  contrastText: '#fff',
};

/**
 * Defines the info color scheme for the application.
 * Info colors are generally used for informational elements like alerts or messages.
 *
 * @constant {Object} INFO
 * @property {string} lighter - Lightest shade of the info color.
 * @property {string} light - A lighter version of the info color.
 * @property {string} main - The main info color.
 * @property {string} dark - Darker version of the info color.
 * @property {string} darker - The darkest shade of the info color.
 * @property {string} contrastText - Text color used for readability on top of info color.
 */
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
};

/**
 * Defines the success color scheme for the application.
 * Success colors are used to represent positive feedback or actions.
 *
 * @constant {Object} SUCCESS
 * @property {string} lighter - Lightest shade of the success color.
 * @property {string} light - Lighter version of the success color.
 * @property {string} main - The main success color.
 * @property {string} dark - Darker shade of the success color.
 * @property {string} darker - The darkest success color.
 * @property {string} contrastText - Text color for success elements to ensure readability.
 */
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#43A047',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
};

/**
 * Defines the warning color scheme for the application.
 * Warning colors are used to indicate potential issues or cautions.
 *
 * @constant {Object} WARNING
 * @property {string} lighter - Lightest shade of the warning color.
 * @property {string} light - Lighter version of the warning color.
 * @property {string} main - The main warning color.
 * @property {string} dark - Darker shade of the warning color.
 * @property {string} darker - Darkest warning color.
 * @property {string} contrastText - Text color used for readability in warning contexts.
 */
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

/**
 * Defines the error color scheme for the application.
 * Error colors are used to represent negative feedback or alerts.
 *
 * @constant {Object} ERROR
 * @property {string} lighter - Lightest shade of the error color.
 * @property {string} light - Lighter version of the error color.
 * @property {string} main - The main error color.
 * @property {string} dark - Darker error color.
 * @property {string} darker - Darkest error color.
 * @property {string} contrastText - Text color used for readability in error contexts.
 */
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#F51D1D',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
};

/**
 * @constant {Object} palette
 *
 * A complete object representing the entire color palette for the application.
 * This includes colors for common UI elements like text, backgrounds, and borders, as well as the custom color schemes for
 * primary, secondary, success, error, warning, info, and graph elements.
 *
 * This object is intended to be used for theming in Material UI.
 *
 * @property {Object} common - Defines common colors like black and white.
 * @property {Array<string>} graphs - Array of colors used for graph elements.
 * @property {Object} primary - Primary color scheme.
 * @property {Object} secondary - Secondary color scheme.
 * @property {Object} info - Info color scheme.
 * @property {Object} success - Success color scheme.
 * @property {Object} warning - Warning color scheme.
 * @property {Object} error - Error color scheme.
 * @property {Object} grey - Grey shades for various UI elements.
 * @property {string} divider - Color for dividers, usually a slightly transparent grey.
 * @property {Object} text - Text colors for primary, secondary, and disabled text.
 * @property {Object} background - Background colors for paper, default, and neutral backgrounds.
 * @property {Object} action - Colors for actions like hover, active, and disabled states.
 */
const palette = {
  common: { black: '#000', white: '#fff' },
  graphs: GRAPHS,
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: '#fff',
    default: GREY[0],
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: 'rgba(255, 156, 65, 0.18)',
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;
