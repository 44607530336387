/**
 * @module menu-items/dashboard
 * @description 
 * This module contains the configuration for the dashboard menu items, 
 * including the menu items for "My People", "My Assets", and "My Distribution".
 * It defines the structure of the dashboard menu with associated icons and URLs.
 */
import { IconDashboard } from '@tabler/icons-react';
// import { ReactComponent as MyPeopleIcon } from 'assets/panel-my-people.svg';
import { ReactComponent as MyPeopleIcon } from 'assets/panel-my-people.svg';
import { ReactComponent as AssetIcon } from 'assets/panel-assets.svg';
import { ReactComponent as DistributionIcon } from 'assets/panel-distribution.svg';


// ==============================|| DASHBOARD MENU ITEMS ||============================== //
/**
 * @constant {Object} dashboard
 * @description 
 * Configuration for the dashboard menu. It contains menu items like "My People", 
 * "My Assets", and "My Distribution", with their respective URLs and icons.
 */

const dashboard = {
  id: 'dashboard',
  // title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'myPeople',
      title: 'My People',
      type: 'item',
      url: '/myPeople',
      icon: MyPeopleIcon,
      breadcrumbs: false
    },
    {
      id: 'assets',
      title: 'My Assets',
      type: 'item',
      url: '/assets',
      icon: AssetIcon,
      breadcrumbs: false
    },
    {
      id: 'distribution',
      title: 'My Distribution',
      type: 'item',
      url: '/distribution',
      icon: DistributionIcon,
      breadcrumbs: false
    }
  ]
};

export default dashboard;
