import axios from "axios";

/**
 * Fetches the list of frequently asked questions (FAQs).
 * @module landingPageService/fetchFaqsApi
 * @description
 * This function retrieves a list of FAQs from the server. It makes a GET request 
 * to the `/dashboard/faqs` endpoint and returns the server response containing the FAQs.
 * @returns {Promise<Object>} The API response containing the list of FAQs.
 */
export const fetchFaqsApi = async () => {
    const response = axios.get("/dashboard/faqs");
    return response;
}

/**
 * Fetches the pre-checks data for the dashboard.
 * @module landingPageService/preChecksApi
 * @description
 * This function retrieves the pre-checks data from the server for the dashboard. It sends a GET request 
 * to the `/dashboard/preChecks` endpoint and returns the server response containing the pre-checks information.
 * @returns {Promise<Object>} The API response containing the pre-checks data.
 */
export const preChecksApi = async () => {
    const response = axios.get("/dashboard/preChecks");
    return response;
}