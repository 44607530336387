/**
 * @module helper/AssetTypes
 * @description
 * This module exports an object containing different asset types categorized into various groups,
 * including real estate, financial assets, personal possessions, and other assets.
 */

/**
 * Represents a single asset type category with a value and label.
 * @typedef {Object} AssetTypeCategory
 * @property {string} value - The value identifier of the asset type.
 * @property {string} label - The label representing the asset type.
 */

/**
 * List of asset types categorized into various groups.
 * Each group (real estate, financial, personal possessions, and other assets) contains a list of asset types.
 * @type {{
*   real_estate: AssetTypeCategory[],
*   financial: AssetTypeCategory[],
*   personal_possessions: AssetTypeCategory[],
*   other_assets: AssetTypeCategory[]
* }}
* @exports AssetTypes
*/
export const assetTypes = {
    real_estate: [
        { value: 'residential', label: 'Residential' },
        { value: 'commercial', label: 'Commercial' },
        { value: 'plot', label: 'Plot' }
    ],
    financial: [
        { value: 'bank_account', label: 'Bank Accounts' },
        { value: 'listed_securities', label: 'Listed Securities' },
        { value: 'government_investment', label: 'Government Investment' },
        { value: 'unlisted_securities', label: 'Unlisted Securities' }
    ],
    personal_possessions: [
        { value: 'ornaments', label: 'Ornaments' },
        { value: 'vehicles', label: 'Vehicles' },
        { value: 'pets', label: 'Pets' },
        { value: 'art', label: 'Art' }
    ],
    other_assets: [
        { value: 'post_retiral', label: 'Post Retiral' },
        { value: 'insurances', label: 'Insurances' },
        { value: 'digital_assets', label: 'Digital Assets' },
        { value: 'intellectual_property_rights', label: 'Intellectual Property Rights' },
        { value: 'other_assets', label: 'Other Assets' }
    ]
};
