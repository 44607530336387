import React, { useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Divider,
  useTheme,
} from '@mui/material';
import SortRoundedIcon from '@mui/icons-material/SortRounded';
import { noop } from 'lodash';

/**
 * @module Components/FilterComponent
 * @description This module contains the FilterComponent component used across the application.
 * A reusable filter component for displaying a list of category filters with checkboxes
 * and a "View All" button. Includes an option to hide filters and customizable styles.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.title='All'] - The title displayed at the start of the filter component.
 * @param {Array<{value: string, heading: string}>} [props.categoryHeadings=[]] - 
 *   An array of category objects. Each category should have a `value` (identifier) and a `heading` (display text).
 * @param {Function} [props.onFilterChange=noop] - Callback function that is triggered when the selected categories change.
 * @param {Object} [props.typographyProps={}] - Props passed to the `Typography` component for the title.
 * @param {Object} [props.containerSx={}] - Custom `sx` styles for the container box.
 * @param {boolean} [props.hideFilter=false] - Determines if the filter section (checkboxes and "View All" button) should be hidden.
 *
 * @returns {JSX.Element} A styled filter component.
 *
 * @example
 * // Example usage of the FilterComponent
 * const categories = [
 *   { value: 'category1', heading: 'Category 1' },
 *   { value: 'category2', heading: 'Category 2' }
 * ];
 * 
 * const handleFilterChange = (selectedCategories) => {
 *   console.log('Selected categories:', selectedCategories);
 * };
 * 
 * <FilterComponent
 *   title="Filter Options"
 *   categoryHeadings={categories}
 *   onFilterChange={handleFilterChange}
 *   containerSx={{ width: '300px' }}
 * />
 */
const FilterComponent = ({
  title = 'All',
  categoryHeadings = [],
  onFilterChange = noop,
  typographyProps = {},
  containerSx = {},
  hideFilter = false,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const open = Boolean(anchorEl);
  const isDisabled = categoryHeadings.length === 0; // Check if categories are available

  const handleClick = (event) => {
    if (isDisabled) return; // Do nothing if disabled
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCategoryChange = (event, category) => {
    const newSelectedCategories = event.target.checked
      ? [...selectedCategories, category]
      : selectedCategories.filter((c) => c !== category);

    setSelectedCategories(newSelectedCategories);
    onFilterChange(newSelectedCategories);
  };

  const handleViewAllClick = () => {
    setSelectedCategories([]);
    onFilterChange([]);
    handleClose(); // Close the menu
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        ...containerSx,
      }}
    >
      <Typography sx={{ flexShrink: 0, textAlign: 'left' }} variant="heading2" {...typographyProps}>
        {title}
      </Typography>

      {/* Underscore line */}
      <Box
        sx={{
          mt: 2,
          flexGrow: 1,
          borderBottom: `0.5px solid ${theme.palette.grey[100]}`,
          mx: 2, // Space between the line and the text boxes
        }}
      />

      {/* "View All" button with grey background */}
      {!hideFilter && (
        <Box
          sx={{
            bgcolor: theme.palette.grey[200],
            display: 'flex',
            alignItems: 'center',
            width: '106px',
            height: '26px',
            borderRadius: '4px',
            flexShrink: 0,
          }}
        >
          {/* More options (three lines icon) */}
          <IconButton
            onClick={handleClick}
            sx={{ color: theme.palette.primary.main, cursor: isDisabled ? 'not-allowed' : 'pointer' }}
            disabled={isDisabled} // Disable button if no categories
          >
            <SortRoundedIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {categoryHeadings.map((category, index) => (
              <Box key={category.value}>
                <MenuItem sx={{ padding: '4px 8px' }}>
                  <FormControlLabel
                    sx={{ margin: 0, padding: 0 }}
                    control={
                      <Checkbox
                        checked={selectedCategories.includes(category.value)}
                        onChange={(event) => handleCategoryChange(event, category.value)}
                        sx={{
                          height: '19px',
                          width: '19px',
                          marginRight: '4px',
                          color: 'secondary.main', // Default color
                          '&.Mui-checked': {
                            color: 'secondary.main', // Color when checked
                          },
                        }}
                      />
                    }
                    label={<Typography variant="smallText">{category.heading}</Typography>}
                  />
                </MenuItem>
                {index < categoryHeadings.length - 1 && <Divider sx={{ borderStyle: 'dashed' }} />}
              </Box>
            ))}
          </Menu>
          <Typography
            sx={{ flexShrink: 0, textAlign: 'left', cursor: 'pointer' }}
            variant="smallText"
            ml="auto"
            mr={1}
            onClick={handleViewAllClick}
          >
            View All
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default FilterComponent;
