import PropTypes from 'prop-types';

// material-ui
import { Divider, Stack, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { RiArrowLeftLine } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { drawerWidth } from 'store/constant';

// project imports
import { ContextProvider } from 'App';
import Icon from 'components/DesignSystem/Icon';
import { useContext } from 'react';
import MenuList from './MenuList';
import './sidebar.scss';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(ContextProvider);
  const hideDashboardRedirection = user.role === 'admin' || user.role === 'reviewer';
  const isDemoActive = sessionStorage.getItem('isDemoActive') === 'true';

  const isPartnerDashboard = [
    '/partner/numberOfClients',
    '/partner/signupVsPaidUsers',
    '/partner/completedWills',
    '/partner/incompleteWills',
    '/partner/earnings',
  ].some((path) => location.pathname.includes(path));

  const handleDashboardClick = () => {
    if (isPartnerDashboard) {
      navigate('/partnerLandingPage');
    } else {
      if (isDemoActive === true || user.role === 'testator') {
        navigate('/landingPage');
        return;
      }
      navigate('/partnerLandingPage');
    }
  };

  const drawer = (
    <>
      <Box>
        <PerfectScrollbar
          component="div"
          style={{
            height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
            paddingLeft: '16px',
            paddingRight: '16px',
            marginTop: '30px',
            textAlign: 'center',
            background: theme.palette.secondary.panelColor,
            position: 'relative',
          }}
          id='sidebar-container'
        >
          <Box>
            <Icon name="gwd-logo-white.png" height={90} width={171} style={{ margin: 'auto' }} />
          </Box>
          <Box marginY={3}>
            {!hideDashboardRedirection && (
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={'4px'}
                marginRight={4}
                sx={{ cursor: 'pointer' }}
              >
                <RiArrowLeftLine size={18} color={theme.palette.secondary.light} />
                <Typography
                  color={theme.palette.secondary.light}
                  variant="bodyText4"
                  fontWeight={500}
                  onClick={handleDashboardClick}
                >
                  Go to Dashboard
                </Typography>
              </Stack>
            )}
            <Divider orientation="horizontal" sx={{ marginTop: '24px' }} />
          </Box>
          <MenuList isPartnerDashboard={isPartnerDashboard} />
          {/* <MenuCard /> */}
        </PerfectScrollbar>
      </Box>
    </>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      // sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        // variant={matchUpMd ? 'persistent' : 'temporary'}
        variant='permanent'
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            // background: theme.palette.secondary.panelColor,
            color: theme.palette.text.primary,
            background: 'unset',
            border: 'none',
            // [theme.breakpoints.up('md')]: {
            //   // top: '88px',
            // },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default Sidebar;
