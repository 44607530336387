import axios from "axios";
/**
 * Previews a draft of a will.
 * @module myWillService/previewDraftApi
 * @description
 * This function sends a PATCH request to the `/wills/draft/preview` endpoint to preview a draft of a will.
 * It does not require a `type` parameter in this case, although it could be used for different use cases.
 * @param {string} [type] - The type of preview to be generated (optional, if needed for customization in future implementations).
 * @returns {Promise<Object>} The API response containing the preview of the will draft.
 */
export const previewDraftApi = async (type) => {
    const response = await axios.patch(`/wills/draft/preview`);
    return response;
}

/**
 * Generates a will based on the provided draft data.
 * @module myWillService/generateWillApi
 * @description
 * This function sends a PATCH request to the `/wills/draft/generate` endpoint with the provided payload (`apiPayload`), 
 * which contains the necessary information to generate a will. It returns the server's response after processing the request.
 * @param {Object} apiPayload - The data used to generate the will. It includes the necessary fields for creating the will.
 * @param {string} apiPayload.testatorName - The name of the testator.
 * @param {string} apiPayload.willDetails - The details or content of the will.
 * @param {string} apiPayload.dateCreated - The date the will was drafted.
 * @param {string} [apiPayload.status='Draft'] - The status of the will (default is "Draft").
 * @returns {Promise<Object>} The API response containing the result of the will generation.
 */
export const generateWillApi = async (apiPayload) => {
    console.log("request got at api")
    const response = await axios.patch(`/wills/draft/generate`, apiPayload);
    return response;
}