import { Box, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { useState } from 'react';
import { IoAddCircle, IoAddCircleOutline } from "react-icons/io5";

/**
 * A styled card component used to display an asset addition option.
 * The card changes appearance on hover and includes an interactive icon.
 *
 * @module Components/FeatureComponent/AddAssetCard
 * @description This component is designed to represent a card for adding an asset. 
 * It includes a title, an icon, and hover effects. On hover, the border color changes,
 * and the icon transitions between two states.
 */

/**
 * Styled component for the card container.
 * Applies specific styles for background color, border radius, box shadow, and hover effects.
 * 
 * @constant
 * @type {Object}
 */
const StyledCard = styled(Box)(({ theme }) => ({
  backgroundColor: '#FFF3EA',
  borderRadius: '12px',
  boxShadow: 'none',
  transition: 'border-color 0.10s ease', // Transition only the border color
  border: `1px solid transparent`,
  minWidth: '167px',
  cursor: 'pointer',
  '&:hover': {
    borderColor: theme.palette.primary.light,
    boxShadow: `0px 10px 10px -8px ${theme.palette.primary.dark}4D`,
  },
}));

/**
 * A reusable card component for adding assets, featuring an icon, title, and hover effects.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.ElementType} props.icon - A React component for the main icon of the card.
 * @param {string} props.title - The title to display within the card.
 * @param {Function} props.onClick - The callback function to execute when the card is clicked.
 * @returns {JSX.Element} The rendered AddAssetCard component.
 */
const AddAssetCard = ({ icon: Icon, title, onClick }) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <StyledCard
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{ maxHeight: '100px', maxWidth: '167px' }}
    >
      <Box p={'0 8px 10px 8px'} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <Icon style={{ fontSize: '36px' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
          <Box maxHeight='38px'>
            <Typography variant="bodyText3" >
              {title}
            </Typography>
          </Box>
          <Box display='flex' alignItems="center" >
            {isHovered ? (
              <IoAddCircle style={{ fontSize: '18px', color: theme.palette.primary.main }} />
            ) : (
              <IoAddCircleOutline style={{ fontSize: '18px', color: theme.palette.primary.main }} />
            )}
          </Box>
        </Box>
      </Box>
    </StyledCard>
  );
};


export default AddAssetCard;
