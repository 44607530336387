/**
 * @module Pages/OnboardingFlow/JourneyPage
 * @description This module defines the `JourneyPage` component, which is part of the onboarding flow 
 * for will creation. It provides a step-by-step journey for the user to follow, with each step guiding 
 * them through various stages of will creation, such as creating their profile, adding beneficiaries, 
 * listing assets, and distributing the assets among beneficiaries. The page uses a vertical stepper 
 * component to display the progress and includes a "Next" button to move between steps.
 */

import { useTheme } from '@emotion/react';
import { Box, Grid, Step, StepLabel, Stepper, Typography } from '@mui/material';
import ActionButtons from 'components/actionsButtons';
import BackButtonWithDivider from 'components/DesignSystem/BackButtonWithDivider';
import { ContainedButton1 } from 'components/DesignSystem/Button';
import Icon from 'components/DesignSystem/Icon';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

export default function JourneyPage() {
  const theme = useTheme();
  const navigate = useNavigate(); // Initialize useNavigate

  const handleNext = () => {
    navigate('/personalDetails'); // Navigate to the JourneyPage
  };

  return (
    <>
      <Helmet>
        <title> Welcome | Get Will Done </title>
      </Helmet>
      <Grid container>
        <Grid item xs={12} sm={3} md={2} lg={2} xl={2} alignItems={'center'} sx={{ pt: '2%', pl: '2%' }}>
          <Icon name="will-logo.svg" width={'65%'} height={'45%'} />
        </Grid>
        <Grid item sm={9} md={10} lg={10} xl={10}>
          <Box
            sx={{
              maxWidth: 900,
              height: '100%',
              paddingX: '5%',
              paddingY: '5%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Box>
              <Box sx={{ mb: '20px', display: 'flex', alignItems: 'flex-start', flexDirection: 'column', width: '100%' }}>
                <BackButtonWithDivider />
                <Typography variant="title1" gutterBottom>
                  Your path to Will creation
                </Typography>
              </Box>

              <Stepper
                orientation="vertical"
                sx={{
                  '& .MuiStepConnector-root': {
                    marginLeft: '25px',
                  },
                  '& .MuiStepConnector-line': {
                    borderColor: theme.palette.primary.dark,
                  },
                }}
              >
                {steps.map((step, index) => (
                  <Step key={index} active>
                    <StepLabel
                      icon={<img src={step.icon} alt={`Step ${index + 1}`} style={{ width: '50px', height: '50px' }} />}
                    >
                      <Typography variant="bodyText3" fontWeight={'400'} sx={{ mb: '63px' }}>
                        {step.label}
                      </Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Box sx={{ display: 'flex', justifyContent: 'left', md: 4 }}>
                <ContainedButton1
                  sx={{
                    marginTop: { xl: '8%', lg: '8%', md: '10%', sm: '16%', xs: '25%' },
                    minWidth: '125px',
                  }}
                  onClick={handleNext}
                >
                  <Typography variant="bodyText3">Next</Typography>
                </ContainedButton1>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <ActionButtons />
    </>
  );
}

const steps = [
  {
    label: 'First, create your profile with important personal details.',
    icon: '/assets/icons/Profile.svg',
  },
  {
    label: 'Next, add the people you love and trust to be an integral part of your Will.',
    icon: '/assets/icons/Beneficiary.svg',
  },
  {
    label: 'After that, add all your assets that you have worked hard for and want to pass on.',
    icon: '/assets/icons/Assets.svg',
  },
  {
    label: 'Lastly, distribute the assets among your beneficiaries, the way you want.',
    icon: '/assets/icons/Distribution.svg',
  },
  {
    label: 'You can Preview your Will at any time, and Generate it when you are ready.',
    icon: '/assets/icons/Alert_Copy.svg',
  },
];
