import numeral from 'numeral';

// ----------------------------------------------------------------------
/**
 * A utility module for number formatting.
 * @module utils/numberUtils
 * @description
 * This module provides functions to format numbers into various readable formats such as:
 * - General number formatting (`fNumber`)
 * - Currency formatting (`fCurrency`, `fCurrencyRupee`)
 * - Percentages (`fPercent`)
 * - Shortened numbers (`fShortenNumber`)
 * - Data sizes (`fData`)
 *  It also includes helper functions to handle formatting details.
 */

/**
 * Formats a given number into a US Dollar currency string.
 * @function fCurrency
 * @memberof utils/numberUtils
 * @param {number} number - The numeric value to format.
 * @returns {string} - The formatted currency string.
 */
export function fCurrency(number) {
  const format = number ? numeral(number).format('$0,0.00') : '';
  return result(format, '.00');
}

/**
 * Formats a given number into a human-readable data size string (e.g., 1.5 GB).
 * @function fData
 * @memberof utils/numberUtils
 * @param {number} number - The numeric value to format.
 * @returns {string} - The formatted data size string.
 */
export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

/**
 * A helper function to refine the format result.
 * @private
 * @function result
 * @memberof utils/numberUtils
 * @param {string} format - The formatted string.
 * @param {string} key - A substring to check for adjustments.
 * @returns {string} - The refined formatted string.
 */
function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

/**
 * Formats a given number into Indian Rupee currency string.
 * @function fCurrencyRupee
 * @memberof utils/numberUtils
 * @param {number} amount - The numeric value to format.
 * @returns {string} - The formatted currency string in INR.
 */
export function fCurrencyRupee(amount) {
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(amount);
}
