import { Card as MuiCard, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

/**
 * @module Components/DesignSystem/ButtonCheckBox
 * @description This module contains the ButtonCheckBox component used across the application.
 */
/**
 * StyledCard is a custom styled MUI Card component.
 * It is used as a button-like card that reacts to different states, like `checked` and `disabled`.
 * It also allows for dynamic background color via the `bgColor` prop.
 *
 * @component
 * @param {object} theme - The MUI theme object, used for consistent styling.
 * @param {boolean} checked - Determines if the card is checked. A checked state modifies the border color.
 * @param {boolean} disabled - If true, the card is non-interactive and has a "not-allowed" cursor.
 * @param {string} bgColor - Background color of the card. Defaults to white if not provided.
 */
const StyledCard = styled(MuiCard)(({ theme, checked, disabled, bgColor }) => ({
    padding: '7px 17px',
    maxWidth: '300px',
    borderRadius: '10px',
    backgroundColor: bgColor ?? '#fff', // Set background based on bgColor prop
    border: checked ? '1px solid #104E5B' : '1px solid #00838F',
    cursor: disabled ? 'not-allowed' : 'pointer',
    textTransform: 'capitalize',
    transition: 'background-color 0.3s, border 0.3s',
    boxShadow: 'none',
    overflow: 'inherit',
    '&:hover': {
        backgroundColor: bgColor ?? theme.palette.common.white,
    },
}));

/**
 * ButtonCheckBox is a functional component that represents a toggleable button-like card.
 * It displays different button text based on the `checked`, `disabled`, `RequestForQuote`, and `partnerNotApproved` props.
 * The card changes its border and background color based on the `checked` and `disabled` states.
 *
 * @component
 * @param {boolean} checked - The current state of the checkbox. Determines if the button is active or inactive.
 * @param {function} onChange - A callback function to update the `checked` state when the button is clicked.
 * @param {boolean} disabled - If true, the card is non-interactive and disabled.
 * @param {string} bgColor - Custom background color for the card.
 * @param {boolean} RequestForQuote - If true, the button text changes according to the request for a quote logic.
 * @param {boolean} partnerNotApproved - If true, the button text changes to indicate the partner is not approved for requesting a quote.
 * 
 * @returns {JSX.Element} A styled card that can toggle between different states based on the props.
 */
const ButtonCheckBox = ({ checked, onChange, disabled, bgColor, RequestForQuote, partnerNotApproved }) => {
    const theme = useTheme();
    // const buttonContent = checked ? (disabled ? 'Request Sent' : 'Added') : (disabled ? 'Get a Quote' : '+Add');
    let buttonContent;

    if (RequestForQuote) {
        buttonContent = partnerNotApproved ? 'Get a Quote' : disabled ? 'Request Sent' : 'Get a Quote';
    } else {
        buttonContent = checked ? 'Added' : '+Add';
    }

    const handleClick = () => {
        if (!disabled) {
            onChange(!checked);
        }
    };
    return (
        <StyledCard checked={checked} onClick={handleClick} disabled={disabled} bgColor={bgColor}>
            <Typography variant='bodyText3' color={theme.palette.secondary.main}>
                {buttonContent}
            </Typography>
        </StyledCard>
    );
};

export default ButtonCheckBox;