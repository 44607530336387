/**
 * @module ReviewerDashboardLandingPage
 * @description This module represents the dashboard landing page for reviewers. It displays request summaries and allows 
 * reviewers to manage and close review requests by adding comments and performing actions on each request.
 */
import { CheckCircle, Close, Visibility } from '@mui/icons-material';
import { Box, Card, CardContent, Grid, IconButton, Modal, Table, TableBody, TableContainer, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ContextProvider } from 'App';
import axios from 'axios';
import { ContainedButton1 } from 'components/DesignSystem/Button';
import InputField from 'components/DesignSystem/InputField';
import useSwalAlert from 'components/DesignSystem/SwalAlert';
import { CustomTableCell, CustomTablePagination, CustomTableRow } from 'components/DesignSystem/Table/CustomTable';
import { filter } from 'lodash';
import AppWidgetSummary from 'oldLayouts/components/AppWidgetSummary';
import Scrollbar from 'oldLayouts/components/scrollbar';
import { UserListHead, UserListToolbar } from 'oldLayouts/components/user';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import PageContainer from 'views/dashboard/PageContainer';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const TABLE_HEAD = [
    { id: 'user', label: 'User', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'comment', label: 'Comment', alignRight: false },
    // { id: "nominee", label: "Nominee", alignRight: false, alignCenter: true },
    { id: 'action', label: 'View' },
];

/**
 * Function to compare two values for sorting in descending order.
 * 
 * @param {Object} a The first object to compare.
 * @param {Object} b The second object to compare.
 * @param {string} orderBy The property name to compare.
 * @returns {number} The comparison result: -1 if a < b, 1 if a > b, and 0 if equal.
 */
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

/**
 * Function to determine the comparator based on the sorting order and property.
 * 
 * @param {string} order The sorting order (either 'asc' or 'desc').
 * @param {string} orderBy The property to be used for comparison.
 * @returns {function} A comparator function based on the order and property.
 */
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy === 'comment' ? 'type' : orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy === 'comment' ? 'type' : orderBy);
}

/**
 * Function to apply sorting and filtering to the list of requests.
 * 
 * @param {Array} array The list of requests to be sorted and filtered.
 * @param {function} comparator The comparator function for sorting.
 * @param {string} query The search query for filtering.
 * @returns {Array} The filtered and sorted list of requests.
 */

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    console.log('query', query);
    if (query) {
        console.log(
            'query',
            filter(
                array,
                (_user) =>
                    JSON.stringify(_user ?? {})
                        .toLowerCase()
                        .indexOf(query.toLowerCase()) !== -1
            )
        );
        return filter(array, (_user) => {
            const { review, ReviewerId, ...userWithoutReview } = _user;
            const userWithoutReviewModified = {
                ...userWithoutReview,
                Testator: { fullName: _user.Testator?.full_name, email: _user.Testator?.email },
            };
            return (
                JSON.stringify(userWithoutReviewModified ?? {})
                    .toLowerCase()
                    .indexOf(query.toLowerCase()) !== -1
            );
        });
    }
    return stabilizedThis.map((el) => el[0]);
}

/**
 * ReviewerDashboardLandingPage is the main component that displays the dashboard of requests for reviewers.
 * It allows reviewers to view, filter, and manage review requests, including adding comments and closing requests.
 * 
 * @returns {JSX.Element} The ReviewerDashboardLandingPage component.
 */
export default function ReviewerDashboardLandingPage() {
    const theme = useTheme();
    const { user, fetchUser } = useContext(ContextProvider);

    const [profile, setProfile] = useState(user?.profile ?? {});

    useEffect(() => {
        setProfile(user?.profile ?? {});
        if (user.Requests) {
            setRequests(user.Requests);
        }
    }, [user]);

    const [open, setOpen] = useState(null);
    const [reviewId, setReviewId] = useState(null);
    const [comment, setComment] = useState('');
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('createdAt');
    const [filterName, setFilterName] = useState('');
    const [requests, setRequests] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const showSwalAlert = useSwalAlert();

    /**
   * Function to close a request by sending a PATCH request to the server with the comment.
   * Displays success or error alerts based on the result.
   */
    const closeRequest = useCallback(() => {
        axios
            .patch(`/wills/draft/review/${reviewId}`, { comment })
            .then((res) => {
                // toastr.success("Request closed successfully")
                showSwalAlert({
                    icon: 'success',
                    title: `Request closed successfully`,
                    timer: 3000,
                });
            })
            .catch((e) => {
                // toastr.error("Failed to close request")
                showSwalAlert({
                    icon: 'error',
                    title: `Failed to close request`,
                    timer: 3000,
                });
            })
            .finally(() => {
                setOpen(false);
                fetchUser();
            });
    }, [reviewId]);

    /**
     * Handler for sorting requests based on the selected property.
     * 
     * @param {Object} event The event that triggered the sort.
     * @param {string} property The property by which to sort.
     */
    const handleRequestSort = (event, property) => {
        console.log('handleRequestSort', property);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    /**
   * Handler for changing the page when pagination controls are used.
   * 
   * @param {Object} event The event that triggered the page change.
   * @param {number} newPage The new page number.
   */
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    /**
  * Handler for changing the number of rows per page in the pagination controls.
  * 
  * @param {Object} event The event that triggered the rows change.
  */
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    /**
     * Handler for filtering requests by name (search functionality).
     * 
     * @param {Object} event The event that triggered the filter change.
     */
    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requests.length) : 0;
    console.log('user.Requests', order, orderBy);
    const filteredRequests = applySortFilter(requests, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredRequests.length && !!filterName;

    return (
        <>
            <Helmet>
                <title> Dashboard | Get Will Done </title>
            </Helmet>

            <PageContainer>
                <Typography variant="title1" sx={{ mb: 5 }}>
                    Requests Summary
                </Typography>
                <Modal
                    open={open}
                    onClose={() => {
                        setOpen(false);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={() => setOpen(false)}>
                            <Close />
                        </IconButton>
                        <Typography id="modal-modal-title" variant="bodyText2">
                            Close Request
                        </Typography>
                        <InputField
                            // label={<Typography variant='smallText'>Comment</Typography>}
                            placeholder='Comment'
                            fullWidth
                            multiline
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            id={'review-comment'}
                            rows={4}
                            sx={{ margin: '8px 0' }}
                        />
                        <ContainedButton1 style={{ marginTop: '8px', padding: '8px 12px' }} onClick={() => closeRequest()}>
                            <Typography variant='bodyText3'>Close Request</Typography>
                        </ContainedButton1>
                    </Box>
                </Modal>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={4}>
                                <AppWidgetSummary
                                    title={<Typography variant="bodyText2">Total reviews</Typography>}
                                    total={user?.Requests?.length ?? 0}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <AppWidgetSummary
                                    title={<Typography variant="bodyText2">Open reviews</Typography>}
                                    total={user?.Requests?.filter((req) => req.status === 'REQUESTED')?.length ?? 0}
                                    color="info"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <AppWidgetSummary
                                    title={<Typography variant="bodyText2">Closed reviews</Typography>}
                                    total={user?.Requests?.filter((req) => req.status === 'CLOSED')?.length ?? 0}
                                    color="info"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography
                                    variant={'h5'}
                                    component={'h5'}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    Requests
                                </Typography>
                                <Box sx={{ marginTop: '8px', marginBottom: '16px' }}>
                                    <UserListToolbar
                                        numSelected={selected.length}
                                        filterName={filterName}
                                        onFilterName={handleFilterByName}
                                    />
                                </Box>
                                <Scrollbar>
                                    <TableContainer sx={{ minWidth: 800 }}>
                                        <Table>
                                            <UserListHead
                                                order={order}
                                                orderBy={orderBy}
                                                headLabel={TABLE_HEAD}
                                                rowCount={requests.length}
                                                numSelected={selected.length}
                                                onRequestSort={handleRequestSort}
                                            />
                                            <TableBody>
                                                {/* {requests?.map(request => { */}
                                                {filteredRequests
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((request, index) => {
                                                        return (
                                                            <CustomTableRow>
                                                                <CustomTableCell>
                                                                    {request.Testator?.full_name ? request.Testator?.full_name : request.Testator?.email}
                                                                </CustomTableCell>
                                                                <CustomTableCell>{request.status}</CustomTableCell>
                                                                <CustomTableCell>
                                                                    {request.type === 'NOTARIZATION' && 'Notary at Doorstep'}
                                                                    {request.type === 'REGISTRATION' && 'Register your Will'}
                                                                    {request.type === 'REVIEW' && 'Review by Lawyer'}
                                                                    {request.type === 'SUPPORT' && 'Support Call Requested'}
                                                                    {request.type === 'CALL' && 'Call with Legal Expert'}
                                                                    {request.comment && ` - ${request.comment}`}
                                                                </CustomTableCell>
                                                                <CustomTableCell>
                                                                    <IconButton
                                                                        disabled={
                                                                            request.status === 'CLOSED' ||
                                                                            request.type === 'CALL' ||
                                                                            request.type === 'SUPPORT'
                                                                        }
                                                                        component={Link}
                                                                        to={`/reviewer/${request.id}/distribution`}
                                                                    >
                                                                        <Visibility />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        disabled={request.status === 'CLOSED'}
                                                                        onClick={() => {
                                                                            setOpen(true);
                                                                            setReviewId(request.id);
                                                                        }}
                                                                    >
                                                                        <CheckCircle />
                                                                    </IconButton>
                                                                </CustomTableCell>
                                                            </CustomTableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Scrollbar>

                                <CustomTablePagination
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    component="div"
                                    count={filteredRequests.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </PageContainer>
        </>
    );
}
