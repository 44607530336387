/**
 * @module Views/AdminDashboard/RepeatRatePage
 * @description
 * This module represents the "Repeated Clients Details" page in the admin panel. It displays a table with client information
 * who have repeated subscriptions. The page supports sorting, pagination, filtering by name, and dynamic data fetching from
 * the backend API. The table displays the client ID, name, email, and phone, with the ability to navigate back to the previous
 * page in the admin panel.
 *
 * @component RepeatRatePage
 * @returns {JSX.Element} The rendered page for repeated clients' details with a table, filters, and pagination controls.
 * @hooks useState, useEffect, useMemo
 * @function descendingComparator
 * @function getComparator
 * @function stableSort
 * @function handleRequestSort
 * @function handleChangePage
 * @function handleChangeRowsPerPage
 * @function handleBackClick
 * @function handleFilterByName
 *
 * @see {@link https://mui.com/components/tables/} Material UI Table documentation.
 */
import { Box, Table, TableBody, TableContainer, Typography } from '@mui/material';
import axios from 'axios';
import { CustomTableCell, CustomTablePagination, CustomTableRow } from 'components/DesignSystem/Table/CustomTable';
import { filter } from 'lodash';
import Scrollbar from 'oldLayouts/components/scrollbar';
import { UserListHead, UserListToolbar } from 'oldLayouts/components/user';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import PageContainer from 'views/dashboard/PageContainer';

/**
 * Comparator function that sorts values in descending order based on the property `orderBy`.
 *
 * @function descendingComparator
 * @param {Object} a - The first object to compare.
 * @param {Object} b - The second object to compare.
 * @param {string} orderBy - The property key by which the comparison will be made.
 * @returns {number} - A negative value if `a` should come before `b`, a positive value if `b` should come before `a`,
 * 0 if the values are equal.
 */
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 * Returns a comparator function based on the order (`asc` or `desc`) and the field to be sorted.
 *
 * @function getComparator
 * @param {string} order - The order to sort in ("asc" or "desc").
 * @param {string} orderBy - The property by which to sort.
 * @returns {function} - A comparator function used for sorting the data.
 */
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * A stable sort function to ensure sorting consistency across different browser versions.
 * It uses the comparator function to sort the array and apply filtering by a query string.
 *
 * @function stableSort
 * @param {Array} array - The array to be sorted and filtered.
 * @param {function} comparator - The comparator function to be used for sorting.
 * @param {string} query - The query string used for filtering the array.
 * @returns {Array} - The sorted and filtered array.
 */
// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        JSON.stringify(_user ?? {})
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

/**
 * Table header configuration for displaying client data in the repeat rate table.
 *
 * @constant {Array} TABLE_HEAD - Contains column definitions with id, label, and alignment properties.
 * @property {string} id - The unique identifier for each column.
 * @property {string} label - The label to display in the table header.
 * @property {boolean} [numeric] - Whether the column values are numeric (optional).
 * @property {boolean} [alignRight] - Whether to right-align the column values (optional).
 */
const TABLE_HEAD = [
  {
    id: 'id',
    disablePadding: true,
    label: 'Client ID',
    alignRight: false,
  },
  {
    id: 'name',
    numeric: true,
    label: 'Name',
    alignRight: false,
  },
  {
    id: 'email',
    numeric: true,
    label: 'Email',
    alignRight: false,
  },
  {
    id: 'phone',
    numeric: true,
    label: 'Phone',
    alignRight: false,
  },
];

/**
 * Main component to render the repeated clients details page.
 * It manages sorting, pagination, and filtering of the data.
 *
 * @component
 * @function RepeatRatePage
 * @returns {JSX.Element} The rendered page, including table with repeated clients, filters, pagination, and a back button.
 */
const RepeatRatePage = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [repeatRateData, setRepeatRateData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const navigate = useNavigate();

  /**
   * Fetches the repeated subscription data from the backend and updates the component state.
   *
   * @function fetchRepeatRateData
   * @async
   * @returns {void}
   * @throws {Error} If the request fails, it logs an error to the console.
   */
  const fetchRepeatRateData = async () => {
    try {
      const response = await axios.get(`/admin/repeat-rate?list=${true}`);
      const { usersWithRepeatedSubscription } = response.data;
      setRepeatRateData({ usersWithRepeatedSubscription });
    } catch (error) {
      console.error('Error fetching repeat rate data:', error);
    }
  };

  /**
   * Hook to fetch data on initial render.
   */
  useEffect(() => {
    fetchRepeatRateData();
  }, []);

  /**
   * Handles sorting of the table by a selected column.
   *
   * @function handleRequestSort
   * @param {Event} event - The event triggered by the sort request.
   * @param {string} property - The column property to sort by.
   * @returns {void}
   */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  /**
   * Handles changing the current page when pagination controls are used.
   *
   * @function handleChangePage
   * @param {Event} event - The event triggered by the page change.
   * @param {number} newPage - The new page number.
   * @returns {void}
   */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  /**
   * Handles changing the number of rows per page.
   *
   * @function handleChangeRowsPerPage
   * @param {Event} event - The event triggered by changing rows per page.
   * @returns {void}
   */
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**
   * Calculates the number of empty rows when the table is not fully populated.
   *
   * @constant {number} emptyRows - The number of empty rows to display in the table.
   */ const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - repeatRateData?.usersWithRepeatedSubscription?.length) : 0;

  /**
   * Filters and sorts the repeated subscription data based on the current sorting and filtering parameters.
   *
   * @constant {Array} visibleRows - The rows of data that are currently visible based on sorting and filtering.
   */
  const visibleRows = useMemo(
    () =>
      stableSort(repeatRateData?.usersWithRepeatedSubscription || [], getComparator(order, orderBy), filterName).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, repeatRateData, filterName]
  );

  /**
   * Handles the filtering of rows by the input value.
   *
   * @function handleFilterByName
   * @param {Event} event - The input event triggered by the filter input field.
   * @returns {void}
   */
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  return (
    <PageContainer showBackButton showHelpIcon={false}>
      <Helmet>
        <title> Repeated Clients Details | Get Will Done </title>
      </Helmet>
      <Box>
        <Typography variant="title1">Repeated Clients Details</Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '24px 0 12px 0' }}>
        <Box>
          <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />
        </Box>
      </Box>
      <Box>
        <Scrollbar>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <UserListHead order={order} orderBy={orderBy} headLabel={TABLE_HEAD} onRequestSort={handleRequestSort} />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <CustomTableRow hover tabIndex={-1} key={row.id}>
                      <CustomTableCell>{row.id}</CustomTableCell>
                      <CustomTableCell>{row.name}</CustomTableCell>
                      <CustomTableCell>{row.email}</CustomTableCell>
                      <CustomTableCell>{row.phone}</CustomTableCell>
                    </CustomTableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <CustomTableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <CustomTableCell colSpan={6} />
                  </CustomTableRow>
                )}
              </TableBody>
              {repeatRateData?.usersWithRepeatedSubscription?.length === 0 && (
                <TableBody>
                  <CustomTableRow>
                    <CustomTableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      No Repeat Rate Data Found
                    </CustomTableCell>
                  </CustomTableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <CustomTablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={repeatRateData?.usersWithRepeatedSubscription?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </PageContainer>
  );
};

export default RepeatRatePage;
