/**
 * @module Pages/AdminPages/AdminComponents/TotalNumberOfClientsCard
 * @description This module contains the `TotalNumberOfClientsCard` component, which fetches and displays 
 * the total number of clients (testators). It shows the count in a dashboard card, and provides navigation 
 * to a detailed clients page when clicked.
 */
import { Skeleton } from '@mui/material';
import DashboardCard from 'components/commonComponents/cards/DashboardCard';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchTotalClients } from 'services/adminService';
/**
 * The `TotalNumberOfClientsCard` component fetches the total number of clients (testators) 
 * from the backend and displays the count in a dashboard card. The card is clickable, and 
 * when clicked, it navigates to a detailed page listing the clients.
 * 
 * @component
 * @returns {JSX.Element} A dashboard card displaying the total number of clients.
 */
const TotalNumberOfClientsCard = () => {
  const [clientsData, setClientsData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  /**
   * Fetches the total number of clients data (total testators) from the backend and 
   * updates the state.
   * 
   * This function is executed inside the `useEffect` hook to fetch data once when the component mounts.
   * 
   * @async
   * @function
   * @returns {void}
   */
  useEffect(() => {
    const fetchTotalNumberOfClients = async () => {
      try {
        const response = await fetchTotalClients();
        const { totalTestators } = response.data;
        setClientsData({ totalTestators });
      } catch (error) {
        console.error('Error fetching number of clients data:', error);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };

    fetchTotalNumberOfClients();
  }, []);
  /**
   * Handles the click event on the dashboard card. Redirects the user to the detailed page for clients.
   * 
   * @function
   * @returns {void}
   */
  const handleClick = () => {
    navigate('/admin/noOfClients');
  };

  return (
    <>
      {
        loading ? (
          <Skeleton variant="rectangular" height={135} sx={{ borderRadius: '1.25rem' }} />
        ) : (
          <DashboardCard
            title="No of Clients"
            subtitle={(clientsData.totalTestators ?? '--') || 0
            }
            handleClick={handleClick}
            isClickable
          />
        )
      }
    </>
  );
};

export default TotalNumberOfClientsCard;
