import axios from "axios";
/**
 * Fetches the total number of partner clients (testators).
 * @module partnerService/fetchTotalNumberOfPartnerClients
 * @description
 * This function retrieves the total number of clients (testators) associated with a partner. 
 * It makes a GET request to the `/partners/dashboard/totalTestators` endpoint, with an optional 
 * `list` parameter to include a detailed list of clients along with the total count.
 * @param {boolean} [list=false] - Whether to include the list of partner clients in the response. 
 * Default is `false`.
 * @returns {Promise<Object>} The API response containing the total number of partner clients 
 * and, optionally, a list of clients.
 */
export const fetchTotalNumberOfPartnerClients = async (list = false) => {
    const response = await axios.get(`/partners/dashboard/totalTestators${list ? '?list=true' : ''}`);
    return response;
}

/**
 * Fetches the conversion ratio between signups and paid users.
 * @module partnerService/fetchConversionRatio
 * @description
 * This function retrieves the conversion ratio between signups and paid users for partners. 
 * It supports an optional `list` parameter to include a detailed list of signups and paid users 
 * along with the conversion ratio.
 * @param {boolean} [list=false] - Whether to include the detailed list of signups and paid users. Default is `false`.
 * @returns {Promise<Object>} The API response containing the conversion ratio and, optionally, the list of signups and paid users.
 */
export const fetchConversionRatio = async (list = false) => {
    const response = await axios.get(`/partners/dashboard/signupVsPaidRatio${list ? '?list=true' : ''}`);
    return response;
}

/**
 * Fetches the list of incomplete wills.
 * @module partnerService/fetchIncompleteWills
* @description
 * This function retrieves a list of incomplete wills from the server. It makes a GET request 
 * to the `/partners/dashboard/incompleteWills` endpoint and optionally includes a list of incomplete wills 
 * if the `list` parameter is set to `true`.
 * @param {boolean} [list=false] - Whether to include the detailed list of incomplete wills. Default is `false`.
 * @returns {Promise<Object>} The API response containing the list of incomplete wills, or just the count if `list=false`.
 */
export const fetchIncompleteWills = async (list = false) => {
    const response = await axios.get(`/partners/dashboard/incompleteWills${list ? '?list=true' : ''}`);
    return response;
}

/**
 *  Fetches the list of complete wills.
 * @module partnerService/fetchCompleteWills
 * @description
 * This function retrieves data on the complete wills from the server. It supports an optional `list` 
 * parameter to include a detailed list of complete wills in the response, along with the total count.
 * It makes a GET request to the `/partners/dashboard/completeWills` endpoint and returns the server response 
 * containing the complete wills details.
 * @param {boolean} [list=false] - Whether to include the list of complete wills in the response. Default is `false`.
 * @returns {Promise<Object>} The API response containing the complete wills data and, optionally, the list of wills.
 */
export const fetchCompleteWills = async (list = false) => {
    const response = await axios.get(`/partners/dashboard/completeWills${list ? '?list=true' : ''}`);
    return response;
}

/**
 * Fetches the total commission data for partners.
 * @module partnerService/fetchCommissionData
 * @description
 * This function retrieves the total commission data for partners from the server. It makes a GET request 
 * to the `/partners/dashboard/totalCommission` endpoint. The function supports an optional `list` parameter 
 * to include a detailed list of commissions.
 * @param {boolean} [list=false] - Whether to include the list of commissions in the response. Default is `false`.
 * @returns {Promise<Object>} The API response containing the total commission data and optionally, the list of commissions.
 * 
 */
export const fetchCommissionData = async (list = false) => {
    const response = await axios.get(`/partners/dashboard/totalCommission${list ? '?list=true' : ''}`);
    return response;
}

/**
 * Sends an acknowledgment for the testator enrollment.
 * @module partnerService/enrollAcknowledgeApi
 * @description
 * This function sends a PUT request to the `/testator/enroll-acknowledge` endpoint 
 * to acknowledge the testator's enrollment. It does not require any parameters 
 * and returns the server's response confirming the acknowledgment action.
 * @returns {Promise<Object>} The API response confirming the acknowledgment.
 */
export const enrollAcknowledgeApi = async () => {
    const response = await axios.put(`/testator/enroll-acknowledge`);
    return response;
}

/**
 * Enrolls a user as a partner.
 * @module partnerService/enrollAsPartnerApi
 * @description
 * This function sends a GET request to the `/partners/enroll/partner` endpoint to enroll a user as a partner.
 * It returns the server's response after the request is processed.
 * @returns {Promise<Object>} The API response indicating the success or failure of the enrollment process.
 */
export const enrollAsPartnerApi = async () => {
    const response = axios.get('/partners/enroll/partner');
    return response;
}

