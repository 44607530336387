/**
 * @module Components/FileUpload
 * 
 * @description `FileUpload` is a React component that allows users to upload files. It includes support for validating file type and size, displaying feedback for successful uploads, and allowing the user to delete the selected file. The component is highly customizable through props like `uploadText`, `fileTypeText`, `accept`, and `maxFileSize`.
 * 
 * This component includes an input for selecting a file, and provides visual feedback for the file's state (e.g., file name, upload success, or error).
 * 
 * @component
 * 
 * @example
 * <FileUpload 
 *     value={file} 
 *     onChange={handleFileChange} 
 *     error="Invalid file type" 
 *     uploadText="Upload your file" 
 *     fileTypeText="Supports PDF up to 10MB" 
 *     accept=".pdf" 
 *     maxFileSize={10485760} 
 *     isUploaded={false} 
 * />
 */
import React, { useState, useRef, useEffect } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { LuUpload } from "react-icons/lu";
import { PiCheckCircleFill } from "react-icons/pi";
import Icon from './DesignSystem/Icon';

/**
 * `FileUpload` component allows users to upload a file, showing visual feedback about the file selection and upload status. 
 * It supports file validation for type and size and provides an option to delete the uploaded file.
 * 
 * @function FileUpload
 * @param {Object} props - The component props.
 * @param {File|null} [props.value] - The currently uploaded file object. If no file is uploaded, this is `null`.
 * @param {Function} [props.onChange] - Callback function triggered when the file is selected or deleted. Receives the selected file or `null`.
 * @param {string} [props.error] - An error message to display if the file validation fails (e.g., wrong file type or size).
 * @param {string} [props.uploadText="Upload File"] - Customizable text displayed when no file is selected.
 * @param {string} [props.fileTypeText="Supports PDF up to 10MB"] - Customizable text displaying file type and size restrictions.
 * @param {string} [props.accept] - The accepted file types (e.g., ".pdf, .jpg"). Defaults to any file type if not specified.
 * @param {number} [props.maxFileSize] - The maximum file size allowed, in bytes (e.g., 10MB is 10485760 bytes).
 * @param {boolean} [props.isUploaded=false] - A boolean flag indicating whether the file has been successfully uploaded or not. Controls the display state.
 * 
 * @returns {JSX.Element} - A `Grid` component that contains the upload UI with input fields and file details.
 */
const FileUpload = ({ value, onChange, error, uploadText = "Upload File", fileTypeText = "Supports PDF up to 10MB", accept, maxFileSize, isUploaded=false }) => {
    const [fileName, setFileName] = useState('');
    const [isSelected, setIsSelected] = useState(false);
    const fileInputRef = useRef(null);
    const theme = useTheme();

    /**
     * Resets the state of the component when the `isUploaded` flag changes.
     * Clears the file name and selection state.
     * 
     * @function useEffect
     */
    useEffect(()=>{
        setFileName("");
        setIsSelected(false);
    }, [isUploaded])

    /**
     * Handles the file selection and performs validation for file type and size.
     * 
     * @function handleFileChange
     * @param {React.ChangeEvent<HTMLInputElement>} event - The event triggered when the file input changes.
     */
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (accept && !accept.split(',').includes(file.type)) {
                alert(`File type not allowed. Expected: ${accept}`);
                return;
            }
            if (maxFileSize && file.size > maxFileSize) {
                alert('File size exceeds the limit');
                return;
            }
            setFileName(file.name);
            setIsSelected(true);
            if (onChange) {
                onChange(file);
            }
        } else {
            setFileName('');
            setIsSelected(false);
            if (onChange) {
                onChange(null);
            }
        }
    };

    /**
     * Triggers the file input click action programmatically.
     * 
     * @function handleClick
     */
    const handleClick = () => {
        fileInputRef.current.click();
    };

    /**
     * Deletes the currently selected file, resetting the state and input field.
     * 
     * @function handleDelete
     */
    const handleDelete = () => {
        setFileName('');
        setIsSelected(false);
        if (onChange) {
            onChange(null);
        }
        // Reset the file input field
        fileInputRef.current.value = '';
    };

    return (
        <Grid container>
            <Grid item sm={12}>
                <Box display="flex" alignItems="center" gap={0.5}>
                    <Box
                        display="flex" alignItems="center" justifyContent="center"
                        sx={{
                            width: '14.75rem',
                            height: '5rem',
                            borderRadius: '0.625rem',
                            backgroundColor: theme.palette.grey[200],
                        }}
                    >
                        <Box
                            sx={{
                                width: '14.25rem',
                                height: '4.5rem',
                                borderRadius: '0.5rem',
                                border: `0.0625rem dashed ${theme.palette.grey[100]}`,
                                backgroundColor: isSelected ? theme.palette.secondary.lighter : theme.palette.grey[0],
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                '&:hover': {
                                    borderColor: theme.palette.primary.main,
                                },
                            }}
                            onClick={handleClick}
                        >
                            {isSelected ? (
                                <Typography variant="bodyText2" style={{ margin: '0.5rem' }} sx={{ color: theme.palette.secondary.darker }}>
                                    Upload Successful
                                </Typography>
                            ) : (
                                <Typography variant="bodyText2" style={{ margin: '1rem' }} sx={{ color: theme.palette.secondary.main }}>
                                    {uploadText}
                                </Typography>
                            )}
                            {isSelected ? (

                                <PiCheckCircleFill
                                    size={'2.2rem'}
                                    color={theme.palette.secondary.main}
                                    style={{ marginRight: '0.5rem' }}
                                />
                            ) : (
                                <LuUpload
                                    size={'1.875rem'}
                                    color={theme.palette.secondary.main}
                                    style={{ margin: '1rem' }}
                                />
                            )}
                        </Box>
                    </Box>

                    {isSelected ? (
                        <Box display="flex" alignItems="center" ml={2} gap={1}>
                            <Typography variant="bodyText3" sx={{ color: theme.palette.grey[800] }}>
                                {fileName}
                            </Typography>
                            <Icon name='delete-icon-pink.svg' height='40px' width='40px' onClick={handleDelete} />
                        </Box>
                    ) :
                        <Box display="flex" alignItems="center" ml={2} gap={1}>
                            <Typography variant="bodyText3" sx={{ color: theme.palette.grey[100] }}>
                                File Name
                            </Typography>
                        </Box>
                    }

                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        accept={accept}
                        onChange={handleFileChange}
                    />
                </Box>
            </Grid>
            <Grid item sm={6} mt={0.5} display={'flex'} justifyContent='center'>
                {!isSelected && <Typography variant="errorText" mr={3}>
                    {error ?? fileTypeText}
                </Typography>}
                {/* {error && (
                    <Grid item xs={12}>
                        <Typography variant="body2" color="error">
                            {error}
                        </Typography>
                    </Grid>
                )} */}
            </Grid>
        </Grid >
    );
};

export default FileUpload;