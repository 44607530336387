/**
 * @module Pages/AdminPages/AdminComponents/ConversionRatioCard
 * @description This module contains the ConversionRatioCard component used to display the conversion ratio between sign-ups and paid users.
 */
import { Skeleton } from '@mui/material';
import DashboardCard from 'components/commonComponents/cards/DashboardCard';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchConversionRatio } from 'services/adminService';

/**
 * A card component that displays the conversion ratio between sign-ups and paid users.
 * 
 * The component fetches the conversion ratio data from the `/admin/conversion-ratio` API endpoint. 
 * The fetched data represents the ratio of sign-ups to paid users, which is displayed in the card's subtitle.
 * 
 * While the data is being fetched, a loading skeleton is displayed. Once the data is successfully retrieved, 
 * it is shown within a `DashboardCard`. The card is clickable and redirects to the `/admin/conversionRatio` page when clicked.
 *
 * @component
 * @returns {JSX.Element} A loading skeleton while data is being fetched, or a `DashboardCard` displaying the sign-ups to paid users ratio.
 */
const ConversionRatioCard = () => {
  const [conversionRatioData, setConversionRatioData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  /**
  * Fetches the conversion ratio data for sign-ups vs paid users and updates the state.
  * 
  * This effect runs once when the component mounts. It fetches data from the `/admin/conversion-ratio` API endpoint
  * and updates the state with the retrieved conversion ratio. Once the data is fetched, the loading state is set to `false`.
  */
  useEffect(() => {
    const fetchConversionRatioData = async () => {
      try {
        const response = await fetchConversionRatio();
        const { signupVsPaidRatio } = response.data;
        setConversionRatioData({ signupVsPaidRatio });
      } catch (error) {
        console.error('Error fetching conversion ratio data:', error);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };

    fetchConversionRatioData();
  }, []);
  /**
     * Handles the click event on the dashboard card, navigating to the "/admin/conversionRatio" page.
     */
  const handleClick = () => {
    navigate('/admin/conversionRatio');
  };

  return (
    <>
      {
        loading ? (
          <Skeleton variant="rectangular" height={135} sx={{ borderRadius: '1.25rem' }} />
        ) : (
          <DashboardCard
            title="Sign-Ups vs Paid Users"
            subtitle={conversionRatioData.signupVsPaidRatio || '-/-'}
            handleClick={handleClick}
            isClickable
          />
        )
      }
    </>
  );
};

export default ConversionRatioCard;
