/**
 * @module Views/PartnerDashboard/PartnerCompletedWillsPage
 *
 * @description A page component that displays a list of completed wills. It fetches the completed wills data from an API,
 * provides filtering and sorting functionality, and handles pagination and display of the list in a table format.
 *
 * @returns {JSX.Element} The rendered React component.
 *
 * @example
 * // To use this module, you can import it in your main React application like so:
 * import PartnerCompletedWillsPage from 'path/to/PartnerCompletedWillsPage';
 *
 * <PartnerCompletedWillsPage />
 */
import { Box, Table, TableBody, TableContainer, Typography } from '@mui/material';
import { CustomTableCell, CustomTablePagination, CustomTableRow } from 'components/DesignSystem/Table/CustomTable';
import { filter } from 'lodash';
import Scrollbar from 'oldLayouts/components/scrollbar';
import { UserListHead, UserListToolbar } from 'oldLayouts/components/user';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { fetchCompleteWills } from 'services/partnerService';
import PageContainer from 'views/dashboard/PageContainer';

/**
 * Comparator function for sorting data in descending order.
 *
 * @param {Object} a - The first element to compare.
 * @param {Object} b - The second element to compare.
 * @param {string} orderBy - The key to compare the values of.
 *
 * @returns {number} - A negative number if `a[orderBy]` is less than `b[orderBy]`,
 * a positive number if `a[orderBy]` is greater than `b[orderBy]`, and 0 if they are equal.
 */
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 * Returns the comparator function based on the provided order and orderBy properties.
 *
 * @param {string} order - The current sort order ('asc' or 'desc').
 * @param {string} orderBy - The key to compare the values of.
 *
 * @returns {function} - A comparator function that can be used to sort the data.
 */
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * Sorts and filters an array based on a comparator function and a search query.
 *
 * @param {Array} array - The array to sort and filter.
 * @param {function} comparator - The comparator function to sort the array.
 * @param {string} query - The search query to filter the array by.
 *
 * @returns {Array} - The sorted and filtered array.
 */
function stableSort(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        JSON.stringify(_user ?? {})
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

/**
 * Constant defining the headers for the table displaying completed wills data.
 * Each object contains an id (for the column), a label (for the column header), and a numeric flag to indicate
 * whether the column values are numeric or not.
 *
 * @constant {Array} TABLE_HEAD
 * @default
 */
const TABLE_HEAD = [
  {
    id: 'email',
    numeric: true,
    label: 'Email',
    alignRight: false,
  },
  {
    id: 'name',
    disablePadding: true,
    label: 'Client Name',
    alignRight: false,
  },
  {
    id: 'phone',
    numeric: true,
    label: 'Phone',
    alignRight: false,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    alignRight: false,
  },
];

/**
 * `PartnerCompletedWillsPage` is a React component that displays a list of completed wills for a partner.
 * It fetches the completed wills data, sorts and filters it, and manages pagination for displaying the data in a table.
 *
 * @component
 * @example
 * return <PartnerCompletedWillsPage />;
 *
 * @returns {JSX.Element} The PartnerCompletedWillsPage component JSX markup.
 */
const PartnerCompletedWillsPage = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('email');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [completedWillsData, setCompletedWillsData] = useState([]);
  const [filterName, setFilterName] = useState('');

  /**
   * Fetches completed wills data from the API and updates the state.
   * It uses the `fetchCompleteWills` service function to fetch the data and set the `completedWillsData` state.
   *
   * @async
   * @returns {void}
   */
  const fetchCompletedWillsData = async () => {
    try {
      const response = await fetchCompleteWills(true);
      const { completeWillsList } = response.data;
      setCompletedWillsData(completeWillsList || []);
    } catch (error) {
      console.error('Error fetching completed wills page data:', error);
    }
  };

  useEffect(() => {
    // Fetch completed wills data when the component is mounted
    fetchCompletedWillsData();
  }, []);

  /**
   * Handles sorting requests when a column header is clicked. It toggles the sorting order
   * and updates the `order` and `orderBy` state variables.
   *
   * @param {object} event - The event object triggered by clicking the table header.
   * @param {string} property - The property of the table to sort by.
   */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  /**
   * Handles changing the current page when the user navigates through the pagination controls.
   *
   * @param {object} event - The event object triggered by changing the page.
   * @param {number} newPage - The new page number to navigate to.
   */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  /**
   * Handles changing the number of rows displayed per page in the pagination controls.
   *
   * @param {object} event - The event object triggered by changing the rows per page.
   */
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**
   * Calculates the number of empty rows to display in the table when there are fewer data rows than the page size.
   *
   * @constant {number} emptyRows
   * @default
   */
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - completedWillsData.length) : 0;

  /**
   * Filters and sorts the `completedWillsData` array based on the current sort order, page, and filter text.
   *
   * @constant {Array} visibleRows
   * @default
   * @description This memoized array holds the filtered and sorted data that will be displayed in the table.
   */
  const visibleRows = useMemo(
    () =>
      stableSort(completedWillsData || [], getComparator(order, orderBy), filterName).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, completedWillsData, filterName]
  );

  /**
   * Handles filtering of the data by client name. This updates the `filterName` state and resets the pagination to the first page.
   *
   * @param {object} event - The event triggered when the user types in the filter input field.
   */
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  return (
    <PageContainer>
      <Helmet>
        <title> Completed Wills | Get Will Done </title>
      </Helmet>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Typography variant="title1">Completed Wills</Typography>
        <Box sx={{ margin: '24px 0 12px 0' }}>
          <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />
        </Box>
      </Box>
      <Box>
        <Scrollbar>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <UserListHead order={order} orderBy={orderBy} headLabel={TABLE_HEAD} onRequestSort={handleRequestSort} />
              <TableBody>
                {visibleRows.map((row, index) => (
                  <CustomTableRow hover tabIndex={-1} key={index}>
                    <CustomTableCell>{row.email}</CustomTableCell>
                    <CustomTableCell>{row.name}</CustomTableCell>
                    <CustomTableCell>{row.phone}</CustomTableCell>
                    <CustomTableCell>{row.createdAt}</CustomTableCell>
                  </CustomTableRow>
                ))}
                {emptyRows > 0 && (
                  <CustomTableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <CustomTableCell colSpan={6} />
                  </CustomTableRow>
                )}
              </TableBody>
              {completedWillsData.length === 0 && (
                <TableBody>
                  <CustomTableRow>
                    <CustomTableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      No Wills Data Found
                    </CustomTableCell>
                  </CustomTableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <CustomTablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={completedWillsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </PageContainer>
  );
};

export default PartnerCompletedWillsPage;
