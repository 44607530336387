/**
 * @module Views/Distribution/DistributionCard
 * @description 
 * A React component to manage the distribution of assets among beneficiaries, with features to calculate, reset, 
 * and edit shares. It also supports equal distribution of shares across beneficiaries.
 * 
 * @param {Object} props - Component properties.
 * @param {Object} props.asset - The asset being distributed with details like `id`, `type`, `information`, and `sub_type`.
 * @param {Array} props.mappings - Current distribution mappings.
 * @param {Array} props.primaryMappings - Mappings for primary distribution.
 * @param {Function} props.handleOnResetClick - Callback to handle resetting the distribution.
 * @param {Object} props.generics - Generic options including beneficiaries and other related data.
 * @param {Function} props.setMappings - Callback to update the distribution mappings.
 * @param {Object} props.selectedScenario - The selected scenario for distribution.
 * @param {boolean} props.hideEdit - Flag to hide or disable editing features.
 * 
 * @returns {JSX.Element} A distribution card component with a left section showing asset details and a right section for distribution controls.
 */

import { Box, Card, Divider, Grid, Switch, Typography, useTheme } from '@mui/material';
import { sentenceCase } from 'change-case';
import CheckBox from 'components/DesignSystem/CheckBox';
import Icon from 'components/DesignSystem/Icon';
import InputField from 'components/DesignSystem/InputField';
import { noop } from 'lodash';
import React, { useEffect, useState } from 'react';

const DistributionCard = ({
    asset = {},
    mappings = [],
    primaryMappings = [],
    handleOnResetClick = noop,
    generics = {},
    setMappings = noop,
    selectedScenario = {},
    hideEdit = false
}) => {
    const theme = useTheme(); // Access the theme object for styling.
    const [equalDistribution, setEqualDistribution] = useState(false); // Tracks whether equal distribution is enabled.
    const { id, type, information, sub_type: subType } = asset;     // Asset details

    // Determine the current mapping for the asset
    const will = mappings?.find(({ PropertyId }) => {
        return PropertyId === id;
    });

    // Variables for calculations
    let value = 100; // Default value if no specific value is found
    let total = 0; // Sum of all beneficiary shares
    let disableValue = false; // Flag to disable value input
    let disableQty = false; // Flag to disable quantity input
    const disablePercentage = false; // Percentage is enabled by default

    // Determine the distribution type and set default value accordingly
    if (will?.distribution_type === 'value') {
        value =
            information?.value ??
            information?.amount ??
            information?.insured_amount ??
            information?.investment_amount ??
            information?.deposited_amount;
        if (!value) {
            value = 100;
            disableValue = true;
        }
    } else if (will?.distribution_type === 'quantity') {
        value = information?.quantity ?? information?.qty ?? information?.number;
        if (!value) {
            value = 100;
            disableQty = true;
        }
    }
    if (subType === 'livestock') {
        value = information?.quantity ?? information?.qty ?? information?.number;
        if (!value) {
            value = 100;
        }
    }
    const allowValue =
        !disableValue &&
        Object.keys(information ?? {}).some((key) =>
            ['amount', 'insured_amount', 'investment_amount', 'value', 'deposited_amount'].includes(key)
        );
    const allowQty = (!disableQty && information?.quantity) || information?.qty || information?.number;
    const allowPercentage = !disablePercentage && subType !== 'livestock';
    value = +value;

    // Calculate the total share
    will?.mapping?.forEach((mapping) => {
        total += +(mapping.share ?? 0);
    });
    if (Math.abs(Number((value - total).toFixed(2))) <= 0.00) {  // done 0.01 to 0.00
        total = value;
    }

    const primaryWill = primaryMappings?.find(({ PropertyId }) => {
        return PropertyId === id;
    });

    // Determine color and percentage text based on distribution balance
    let color = 'primary';
    let percentageText = '';
    if (value < total) {
        color = 'error.main';
        percentageText = 'Exceeding';
    } else if (value > total) {
        color = 'warning.main';
        percentageText = 'Remaining';
    } else if (value === total) {
        color = theme.palette.grey[400];
        percentageText = 'Remaining';
    }

    // Set unit based on distribution type
    let unit = '%';
    if (will?.distribution_type === 'quantity' || subType === 'livestock') unit = 'nos.';
    else if (will?.distribution_type === 'value') unit = '₹';

    // Handlers and callbacks
    const handleReset = () => {
        handleOnResetClick(selectedScenario?.id ?? 0, id); // 0(zero) is for primary distribution tab
    };

    /**
     * Handles changes in the input field for a beneficiary's share.
     * Updates the mappings based on the input value and distribution type.
     *
     * @param {Object} e - The event object.
     * @param {Object} beneficiary - The beneficiary whose share is being updated.
     */
    const handleInputChange = (e, beneficiary) => {
        const inputValue = e.target.value;
        const regex = /^\d*\.?\d{0,2}$/; // Matches up to two decimal places
        const integerRegex = /^[0-9]+$/; // Matches integers only
        if (subType === 'livestock') {
            // Handle specific case for livestock (only integer values allowed)
            if (integerRegex.test(inputValue) || inputValue === '') {
                setMappings((mps) =>
                    [
                        ...mps,
                        ...(!will
                            ? [
                                {
                                    id: -1,
                                    mapping: [],
                                    PropertyId: id,
                                    distribution_type: subType === 'livestock' ? 'quantity' : will?.distribution_type ?? 'percentage',
                                },
                            ]
                            : []),
                    ].map((mp) => {
                        if (mp.PropertyId === id) {
                            let _mappings = [...(mp.mapping ?? [])];

                            const distIndex = _mappings?.findIndex((map) => beneficiary.id === map.beneficiary_id);

                            if (distIndex === -1) {
                                // Add a new mapping if none exists
                                _mappings.push({
                                    beneficiary_id: beneficiary.id,
                                    share: e.target.value < 0 ? 0 : Math.ceil(e.target.value),
                                });
                            } else {
                                // Update the existing mapping
                                _mappings[distIndex].share = +e.target.value < 0 ? 0 : Math.ceil(+e.target.value);
                                if (e.target.value?.trim() === '' || e.target.value?.trim() === '0') {
                                    _mappings = _mappings.filter((mp) => beneficiary.id !== mp.beneficiary_id);
                                    if (_mappings.length === 0) {
                                        mp.id = -99;  // Reset the mapping ID if no mappings remain
                                    }
                                }
                            }
                            mp.mapping = _mappings;
                        }
                        return mp;
                    })
                );
            }
        } else if (regex.test(inputValue) || inputValue === '') {
            // Handle generic case for percentages or decimal values
            setMappings((mps) =>
                [
                    ...mps,
                    ...(!will
                        ? [
                            {
                                id: -1,
                                mapping: [],
                                PropertyId: id,
                                distribution_type: subType === 'livestock' ? 'quantity' : will?.distribution_type ?? 'percentage',
                            },
                        ]
                        : []),
                ].map((mp) => {
                    if (mp.PropertyId === id) {
                        let _mappings = [...(mp.mapping ?? [])];

                        const distIndex = _mappings?.findIndex((map) => beneficiary.id === map.beneficiary_id);

                        if (distIndex === -1) {
                            // Add a new mapping if none exists
                            _mappings.push({
                                beneficiary_id: beneficiary.id,
                                share: e.target.value < 0 ? 0 : e.target.value,
                            });
                        } else {
                            // Update the existing mapping
                            _mappings[distIndex].share = +e.target.value < 0 ? 0 : +e.target.value;
                            if (e.target.value?.trim() === '' || e.target.value?.trim() === '0') {
                                _mappings = _mappings.filter((mp) => beneficiary.id !== mp.beneficiary_id);
                                if (_mappings.length === 0) {
                                    mp.id = -99; // Reset the mapping ID if no mappings remain
                                }
                            }
                        }
                        mp.mapping = _mappings;
                    }
                    return mp;
                })
            );
        }
    };

    /**
     * Handles the checkbox toggle for a beneficiary's inclusion in the distribution.
     * Updates the mappings to reflect whether the beneficiary is included or excluded.
     *
     * @param {Object} e - The event object.
     * @param {Object} beneficiary - The beneficiary whose inclusion is being toggled.
     */
    const handleCheckboxChange = (e, beneficiary) => {
        const isChecked = e.target.checked;

        setMappings((mps) =>
            [
                ...mps,
                ...(!will
                    ? [
                        {
                            id: -1,
                            mapping: [],
                            PropertyId: id,
                            distribution_type: subType === 'livestock' ? 'quantity' : will?.distribution_type ?? 'percentage',
                        },
                    ]
                    : []),
            ].map((mp) => {
                if (mp.PropertyId === id) {
                    let _mappings = [...(mp.mapping ?? [])];

                    if (isChecked) {
                        // Set share to 100% for the checked beneficiary and reset others
                        _mappings = _mappings.map((map) => ({
                            ...map,
                            share: beneficiary.id === map.beneficiary_id ? '100.00' : 0, // Set 100 for the checked, 0 for others
                        }));

                        const distIndex = _mappings.findIndex((map) => beneficiary.id === map.beneficiary_id);
                        if (distIndex === -1) {
                            _mappings.push({
                                beneficiary_id: beneficiary.id,
                                share: '100.00', // Assign 100% when checked
                            });
                        } else {
                            _mappings[distIndex].share = '100.00'; // Update to 100% if already exists
                        }
                    }
                    // If checkbox is unchecked, set the share to 0 for that beneficiary
                    else {
                        // Set share to 0% for the unchecked beneficiary
                        _mappings = _mappings.map((map) => (map.beneficiary_id === beneficiary.id ? { ...map, share: '0' } : map));
                    }

                    mp.mapping = _mappings;
                }
                return mp;
            })
        );
    };

    /**
     * Handles blur events for the input field to ensure proper formatting of values.
     * Updates the mappings with a formatted share value if valid.
     *
     * @param {Object} e - The event object.
     * @param {Object} beneficiary - The beneficiary whose input field triggered the blur event.
     */
    const handleInputOnBlur = (e, beneficiary) => {
        const inputValue = e.target.value.trim();
        const regex = /^\d*\.?\d{0,2}$/; // Matches up to two decimal places
        if (will?.distribution_type === 'percentage' && parseFloat(inputValue) > 0) {
            const updatedValue = regex.test(inputValue) ? parseFloat(inputValue).toFixed(2) : '';
            setMappings((mps) =>
                mps.map((mp) => {
                    if (mp.PropertyId === id) {
                        const _mappings = [...(mp.mapping ?? [])];
                        const distIndex = _mappings.findIndex((map) => map.beneficiary_id === beneficiary.id);

                        if (distIndex !== -1) {
                            _mappings[distIndex].share = updatedValue;
                            mp.mapping = _mappings;
                        }
                    }
                    return mp;
                })
            );
        }
    };

    useEffect(() => {
        checkIfEqualDistribution();
    }, [mappings]); // Whenever mappings change, check for equality

    /**
     * Handles the toggle for equal distribution of shares among all beneficiaries.
     * Updates the mappings to equally distribute shares or reset them to zero.
     *
     * @param {Object} e - The event object.
     */
    const checkIfEqualDistribution = () => {
        const mp = mappings?.find((mp) => mp?.PropertyId === id);
        const beneficiaries = generics?.beneficiary?.filter(
            (bene) => selectedScenario?.deaths?.indexOf(bene.id) === -1
        )?.length;
        // if (mp && mp?.mapping?.length === beneficiaries) {
        //     const firstShare = mp?.mapping[0]?.share;
        //     const allEqual = mp.mapping.every((map) => map.share === firstShare);
        //     const notZero = firstShare !== 0;
        //     // If all shares are equal, turn on the switch
        //     setEqualDistribution(allEqual && notZero);
        // } else {
        //     setEqualDistribution(false); // Default to false if no mappings or shares
        // }
        if (mp && Array.isArray(mp.mapping) && mp.mapping.length === beneficiaries) {
            const firstShare = mp?.mapping[0]?.share;  // Safely access the first element
            const allEqual = mp.mapping.every((map) => map.share === firstShare);
            const notZero = firstShare !== 0;
            // If all shares are equal, turn on the switch
            setEqualDistribution(allEqual && notZero);
        } else {
            setEqualDistribution(false); // Default to false if no mappings or shares
        }
    };

    const handleEqualDistribution = (e) => {
        setMappings((mps) =>
            [
                ...mps,
                ...(!will
                    ? [
                        {
                            id: -1,
                            mapping: [],
                            PropertyId: id,
                            distribution_type: subType === 'livestock' ? 'quantity' : will?.distribution_type ?? 'percentage',
                        },
                    ]
                    : []),
            ].map((mp) => {
                if (mp.PropertyId === id) {
                    let _mappings = [...(mp.mapping ?? [])];

                    const beneficiaries = generics?.beneficiary
                        ?.filter((bene) => selectedScenario?.deaths?.indexOf(bene.id) === -1)
                        ?.map((map) => map.id);
                    const totalBeneficiaries = beneficiaries?.length;

                    if (equalDistribution) {
                        // When the switch is turned OFF, set all shares to zero
                        _mappings = beneficiaries?.map((id) => ({
                            beneficiary_id: id,
                            share: 0,
                        }));
                    } else {
                        // Distribute shares equally if switch is turned on
                        const equalShare = (100 / totalBeneficiaries).toFixed(2);
                        _mappings = beneficiaries?.map((id) => ({
                            beneficiary_id: id,
                            share: equalShare,
                        }));
                    }
                    mp.mapping = _mappings;
                }
                return mp;
            })
        );

        // Toggle the equalDistribution flag
        setEqualDistribution(!equalDistribution);
    };

    return (
        <Card
            sx={{ display: 'flex', p: 2, mb: 2, backgroundColor: theme.palette.grey[200], borderRadius: '15px' }}
            elevation={0}
        >
            {/* Left Section */}
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="bodyText3">
                        {sentenceCase(type ?? '')} {!!type && '/'} {sentenceCase(subType ?? '')}
                    </Typography>
                    <Typography variant="smallText" color={theme.palette.grey[400]}>
                        {Object.values(information ?? {})?.[0]}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    {!hideEdit && <Icon name="remaining-icon.png" size={24} onClick={handleReset} />}
                    <Typography variant="smallText" color={color}>
                        {percentageText} {(value - total).toFixed(2)} {unit}
                    </Typography>
                </Box>
            </Box>

            {/* Right Section */}
            <Box sx={{ flex: 1.2, pl: 2 }}>
                {/* Top Subsection */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        maxHeight: '30px',
                        paddingBottom: '8px',
                        paddingLeft: '16px',
                    }}
                >
                    <Typography variant="suggestiveText" color={theme.palette.grey[300]}>
                        Beneficiaries
                    </Typography>
                    <Box>
                        <Typography variant="suggestiveText" color={'#1E1C1B'}>
                            Equal
                        </Typography>
                        <Switch
                            color="secondary"
                            disabled={
                                hideEdit ||
                                !Array.isArray(mappings) ||
                                generics?.beneficiary?.length === 0 ||
                                ['pets', 'car', 'two_wheeler', 'large_vehicles', 'other_vehicles', 'livestock'].includes(subType)
                            }
                            checked={equalDistribution}
                            onChange={handleEqualDistribution}
                        />
                    </Box>
                </Box>

                {/* Bottom Subsection */}
                <Grid
                    container
                    spacing={'4px'}
                    sx={{ position: 'relative', maxHeight: 200, overflowY: 'auto', overflowX: 'hidden' }}
                >
                    {generics?.beneficiary
                        ?.filter((bene) => selectedScenario?.deaths?.indexOf(bene.id) === -1)
                        .map((beneficiary, index) => {
                            const dist = will?.mapping?.find((map) => beneficiary?.id === map.beneficiary_id);
                            const primDist = primaryWill?.mapping?.find((map) => beneficiary.id === map.beneficiary_id);
                            return (
                                <React.Fragment key={index}>
                                    <Grid item xs={12} sm={6}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                gap: '8px',
                                                padding: '0 16px',
                                            }}
                                        >
                                            <Box
                                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}
                                            >
                                                {beneficiary.type === 'ngo_organisation' ? (
                                                    <Typography variant="smallText">{beneficiary.organisation_name}</Typography>
                                                ) : (
                                                    <Typography variant="smallText">{beneficiary.name}</Typography>
                                                )}
                                                {subType === 'pets' || type === 'vehicles' ? (
                                                    <CheckBox
                                                        checked={dist?.share === '100.00' || false}
                                                        onChange={(e) => handleCheckboxChange(e, beneficiary)}
                                                        sx={{ paddingTop: '4px' }}
                                                        disabled={hideEdit}
                                                    />
                                                ) : (
                                                    <InputField
                                                        type="number"
                                                        // aria-valuemin={0}
                                                        value={dist?.share ?? 0}
                                                        inputProps={{ min: 0, step: 0.01 }}
                                                        name="share"
                                                        onChange={(e) => handleInputChange(e, beneficiary)}
                                                        onBlur={(e) => handleInputOnBlur(e, beneficiary)}
                                                        disabled={hideEdit}
                                                        sx={{
                                                            // height: "25/px",
                                                            width: '70px',
                                                            '& .MuiInputBase-root': {
                                                                backgroundColor: 'white',
                                                                padding: '4px 8px', // Custom padding
                                                                borderRadius: '15px',
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontSize: '13px', // Custom font size
                                                                color: '##1E1C1B', // Custom text color
                                                            },
                                                            '& .MuiFormLabel-root': {
                                                                color: 'darkgreen', // Custom label color
                                                            },
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                            <Box>
                                                {/* {(index < isEvenBeneLength - itemsToExclude) && */}
                                                {true && (
                                                    <Divider
                                                        sx={{
                                                            borderStyle: 'dashed',
                                                            borderColor: '#C5C5C5',
                                                            //   minWidth: "250px",
                                                            width: {
                                                                xs: '100px', // 100% width for extra-small screens (mobile devices)
                                                                sm: '150px', // 75% width for small screens (tablets)
                                                                md: '100px', // 60% width for medium screens (small laptops)
                                                                lg: '180px', // 50% width for large screens (desktops)
                                                                xl: '300px', // 40% width for extra-large screens (large desktops)
                                                            },
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        </Box>
                                    </Grid>
                                </React.Fragment>
                            );
                        })}

                    {/* Vertical divider */}
                    {beneficiaries.length > 1 && (
                        <Divider
                            orientation="vertical"
                            flexItem
                            sx={{
                                position: 'absolute',
                                left: '50%',
                                top: 0,
                                bottom: 0,
                                border: '1px solid #888888',
                                height: '100%',
                            }}
                        />
                    )}
                </Grid>
            </Box>
        </Card>
    );
};

export default DistributionCard;

const beneficiaries = [
    { name: 'Ananya Chakraborty', percentage: '' },
    { name: 'Saurav Chakraborty', percentage: '' },
    { name: 'Meera Chakraborty', percentage: '' },
    { name: 'Vikram Mehta', percentage: '' },
    { name: 'Pushkar Sahu', percentage: '' },
    { name: 'Om Anand', percentage: '' },
];
