import * as Yup from 'yup';
import regex from 'regex';
/**
 * @module helper/validation
 * @description
 * This module contains a set of validation functions and Yup schemas for validating form inputs, 
 * ensuring that user input meets specific business rules and data integrity requirements. 
 * The validations include checks for date of birth, user age, text inputs, email, phone numbers, 
 * and PAN numbers. Custom logic is applied to handle complex validations, such as ensuring the 
 * user is older than 18 years, ensuring dates are not in the future, and enforcing correct phone 
 * number formats based on country codes.
 * @exports formValidation - An object containing multiple validation schemas for form fields.
 */

const today = new Date();
const futureLimitDate = new Date(today);
futureLimitDate.setFullYear(today.getFullYear() + 1000);
/**
 * Custom function to check if a given date is valid.
 * A valid date is defined as one that can be successfully parsed into a JavaScript `Date` object
 * and matches the input day, month, and year values.
 * @param {string} day - The day of the month.
 * @param {string} month - The month (1-based).
 * @param {string} year - The year (4 digits).
 * @returns {boolean} - Returns `true` if the date is valid, otherwise `false`.
 */
const isValidDate = (day, month, year) => {
    if (!day || !month || !year || year.length !== 4) return false;

    const dateStr = `${year}-${month}-${day}`;
    const date = new Date(dateStr);

    return (
        date.getFullYear() === parseInt(year, 10) &&
        date.getMonth() + 1 === parseInt(month, 10) &&
        date.getDate() === parseInt(day, 10)
    );
};

/**
 * Checks if a user is older than 18 based on the provided date of birth.
 * This function takes a user's birthdate (year, month, and day) and compares it to the current 
 * date to determine if the user is at least 18 years old. It accounts for the case where the 
 * user's birthday has not yet occurred in the current year.
 * @param {number} year - The year of birth (4 digits).
 * @param {number} month - The month of birth (1-12, where 1 is January and 12 is December).
 * @param {number} day - The day of birth (1-31, depending on the month).
 * @returns {boolean} - Returns `true` if the user is 18 or older, otherwise returns `false`.
 */
const isOlderThan18 = (year, month, day) => {
    if (!isValidDate(day, month, year)) return false;

    const birthDate = new Date(`${year}-${month}-${day}`);

    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        return age - 1 >= 18;  // Adjust for the case where the birthday hasn't happened yet this year
    }
    return age >= 18;
};

/**
 * Custom function to check if a given date is in the past (not a future date).
 * This function verifies that the provided day, month, and year correspond to a valid date 
 * and checks whether that date is earlier than or equal to the current date. If the input 
 * date is in the future, the function returns `false`; otherwise, it returns `true`.
 * @param {number} day - The day of the month (1-31).
 * @param {number} month - The month of the year (1-12).
 * @param {number} year - The year (4 digits).
 * @returns {boolean} `true` if the provided date is in the past or today, `false` otherwise.
 */
const isPastDate = (day, month, year) => {
    if (!isValidDate(day, month, year)) return false;

    const inputDate = new Date(`${year}-${month}-${day}`);

    return inputDate <= today;
};

/**
 * Creates a Yup validation schema for validating a date of birth (DOB).
 * This function checks the following:
 * - The date is a valid date (i.e., the format is correct).
 * - The year is within a valid range (between 1900 and the current year).
 * - The date is not in the future.
 * - The user is at least 18 years old based on the provided date of birth.
 * @function createDobValidation
 * @param {Object} errorMessages - An object containing error messages for each validation rule.
 * @param {string} errorMessages.required - Error message for the required field validation.
 * @param {string} errorMessages.validDate - Error message for invalid date format.
 * @param {string} errorMessages.futureDate - Error message for future date validation.
 * @param {string} errorMessages.ageRequirement - Error message for age validation (must be older than 18).
 * @returns {Yup.StringSchema} A Yup validation schema that can be used to validate a date of birth field.
 */

const createDobValidation = (errorMessages) => {
    return Yup.string()
        .required(errorMessages.required)
        .test('is-valid-date', errorMessages.validDate, value => {
            const [year, month, day] = value ? value.split('-') : [];
            return day && month && year && isValidDate(day, month, year);
        })
        .test('is-year-in-range', 'Please enter a valid date', value => {
            const [year] = value ? value.split('-') : [];
            return year && (parseInt(year, 10) >= 1900 && parseInt(year, 10) <= new Date().getFullYear());
        })
        .test('is-not-future-date', errorMessages.futureDate, value => {
            const [year, month, day] = value ? value.split('-') : [];
            return day && month && year && isPastDate(day, month, year);
        })
        .test('is-older-than-18', errorMessages.ageRequirement, value => {
            const [year, month, day] = value ? value.split('-') : [];
            return isOlderThan18(year, month, day);
        });
};

/**
 * A collection of form validation schemas using Yup, intended to validate various fields in a form. 
 * These validations include checks for required fields, pattern matches, numeric validation, and date checks.
 * Each validation is paired with a custom error message when the input does not meet the validation criteria.
 * 
 * @type {Object}
 * @property {Yup.StringSchema} inputTextValidation - Validates that the input for address fields contains only alphabets, numbers, comma, and dash.
 * @property {Yup.StringSchema} firstNameValidation - Ensures the first name contains only alphabets and is required.
 * @property {Yup.StringSchema} middleNameValidation - Ensures the middle name contains only alphabets and is nullable.
 * @property {Yup.StringSchema} lastNameValidation - Ensures the last name contains only alphabets and is required.
 * @property {Yup.StringSchema} emailValidation - Validates the email format using a regular expression.
 * @property {Yup.StringSchema} phoneValidation - Validates phone numbers based on country codes (IN, US, GB, others) and checks for length constraints.
 * @property {Yup.StringSchema} panValidation - Ensures that the PAN number is in a valid format.
 * @property {Yup.StringSchema} testatorDob - Date of birth validation for the testator, checks for a valid date, age > 18, and no future date.
 * @property {Yup.StringSchema} guardianDob - Date of birth validation for the guardian, ensuring a valid date, age > 18, and no future date.
 * @property {Yup.StringSchema} witnessDob - Date of birth validation for the witness, ensuring a valid date, age > 18, and no future date.
 * @property {Yup.StringSchema} executorDob - Date of birth validation for the executor, ensuring a valid date, age > 18, and no future date.
 * @property {Yup.StringSchema} dateValidation - General date validation ensuring that the input is a valid date, no future dates, and within a valid range.
 * @property {Yup.StringSchema} maturityDateValidation - Validates that the maturity date is within a valid range, not in the future, and within 1000 years from now.
 * @property {Yup.StringSchema} houseNoValidation - Validates that the house number contains only valid characters (alphabets, numbers, comma, dash).
 * @property {Yup.StringSchema} houseNameValidation - Ensures the house name is required and contains only valid characters.
 * @property {Yup.StringSchema} areaValidation - Ensures the area name is required and contains only valid characters.
 * @property {Yup.StringSchema} countryValidation - Ensures the country name is required and contains only valid characters.
 * @property {Yup.StringSchema} stateValidation - Ensures the state name is required and contains only valid characters.
 * @property {Yup.StringSchema} cityValidation - Ensures the city name is required and contains only valid characters.
 * @property {Yup.StringSchema} zipValidation - Ensures the zip code is required and follows a valid PIN code format.
 * @property {Yup.BooleanSchema} same_as_currentValidation - Ensures the boolean value for "same as current" is required.
 * @property {Yup.StringSchema} registrarPlaceValidation - Validates that the registrar place field contains only valid characters.
 * @property {Yup.MixedSchema} fileValidation - Ensures a file is uploaded, validates the file size (up to 10MB), and checks for PDF file type.
 * @property {Yup.NumberSchema} numberValidation - Ensures that the value is a number greater than 0.
 * @property {Yup.StringSchema} folioNumberValidation - Ensures that the folio number contains only alphanumeric characters.
 * @property {Yup.StringSchema} bankAccountNoValidation - Ensures that the bank account number contains only alphanumeric characters.
 * @property {Yup.StringSchema} fdNumberValidation - Ensures that the FD number is required and contains only alphanumeric characters.
 * @property {Yup.StringSchema} dpIdValidation - Ensures that the DP ID is required and contains only alphanumeric characters.
 * @property {Yup.StringSchema} domainValidation - Ensures that the domain name follows a valid format using a regular expression.
 * @property {Yup.StringSchema} copyrightRegistrationNoValidation - Ensures that the copyright registration number contains only alphanumeric characters.
 * @property {Yup.StringSchema} trademarkRegistrationNoValidation - Ensures that the trademark registration number contains only alphanumeric characters.
 * @property {Yup.StringSchema} patentRegistrationNoValidation - Ensures that the patent registration number contains only alphanumeric characters.
 * @property {Yup.StringSchema} policyNoValidation - Ensures that the policy number contains only alphanumeric characters.
 * @property {Yup.StringSchema} insuranceAccountNoValidation - Ensures that the insurance account number contains only alphanumeric characters.
 * @property {Yup.StringSchema} prnNoValidation - Ensures that the PRN number contains only alphanumeric characters.
 * @property {Yup.StringSchema} uanNoValidation - Ensures that the UAN number contains only alphanumeric characters.
 * @property {Yup.StringSchema} certificateNoValidation - Ensures that the certificate number contains only alphanumeric characters.
 * @property {Yup.StringSchema} shareCertificateNoValidation - Ensures that the share certificate number contains only alphanumeric characters.
 * @property {Yup.StringSchema} uinNoValidation - Ensures that the UIN number contains only alphanumeric characters.
 * @property {Yup.StringSchema} accountNoValidation - Ensures that the account number or client ID contains only alphanumeric characters.
 * @property {Yup.StringSchema} clientIdValidation - Ensures that the client ID contains only alphanumeric characters.
 * @property {Yup.StringSchema} bankLockerValidation - Ensures that the bank locker number contains only alphanumeric characters.
 * @property {Yup.StringSchema} registerNumValidation - Ensures that the registration number contains only alphanumeric characters.
 * @property {Yup.StringSchema} ifscCodeValidation - Ensures that the IFSC code follows a valid format using a regular expression.
 * @property {Yup.StringSchema} gstNoValidation - Ensures that the GST number contains only alphanumeric characters and follows a valid format.
 */
const formValidation = {
    inputTextValidation: Yup.string()
        .matches(regex.addressInputRegex, "Only alphabets, numbers, comma(,), dash(-) are allowed"),
    firstNameValidation: Yup.string()
        .required("Required field")
        .matches(regex.nameRegex, "Only alphabets are allowed"),

    middleNameValidation: Yup.string().nullable()
        .matches(regex.nameRegex, "Only alphabets are allowed"),

    lastNameValidation: Yup.string()
        .required("Required field")
        .matches(regex.nameRegex, "Only alphabets are allowed"),

    emailValidation: Yup.string()
        .matches(regex.emailRegex, "Invalid email format"),

    phoneValidation: Yup.string()
        .required("Required field")
        .test('phone-number', 'Invalid phone number', value => {
            if (!value) return false;
            const [code, dial, phone] = value.split(':');
            if (['in', 'us'].includes(code)) {
                return phone.length === 10;
            } if (code === 'gb') {
                return phone.length === 11;
            }
            return phone.length >= 7 && phone.length <= 12;
        }),

    panValidation: Yup.string()
        .required("Required field")
        .matches(regex.panRegex, "Please enter a valid PAN"),

    testatorDob: createDobValidation({
        required: 'Required field',
        validDate: 'Please enter a valid date',
        futureDate: 'Date cannot be in the future',
        ageRequirement: 'You must be older than 18',
    }),

    guardianDob: createDobValidation({
        required: 'Required field',
        validDate: 'Please enter a valid date',
        futureDate: 'Date cannot be in the future',
        ageRequirement: 'Guardian should be older than 18',
    }),

    witnessDob: createDobValidation({
        required: 'Required field',
        validDate: 'Please enter a valid date',
        futureDate: 'Date cannot be in the future',
        ageRequirement: 'Witness should be older than 18',
    }),

    executorDob: createDobValidation({
        required: 'Required field',
        validDate: 'Please enter a valid date',
        futureDate: 'Date cannot be in the future',
        ageRequirement: 'Executor should be older than 18',
    }),



    dateValidation: Yup.string()
        .nullable()
        .test('is-valid-date', 'Please enter a valid date', value => {
            // Skip validation if the value is null or empty
            if (!value) return true;
            const [year, month, day] = value ? value.split('-') : [];
            return day && month && year && isValidDate(day, month, year);
        }).test('is-not-future-date', 'Date cannot be in the future', value => {
            // Skip validation if the value is null or empty
            if (!value) return true;
            const [year, month, day] = value ? value.split('-') : [];
            return isPastDate(day, month, year);
        })
        .test('is-year-in-range', 'Please enter a valid date', value => {
            if (!value) return true;
            const [year] = value ? value.split('-') : [];
            return year && (parseInt(year, 10) >= 1900 && parseInt(year, 10) <= new Date().getFullYear());
        }),

    maturityDateValidation: Yup.string()
        .nullable()
        .test('is-valid-date', 'Please enter a valid date', value => {
            // Skip validation if the value is null or empty
            if (!value) return true;
            const [year, month, day] = value ? value.split('-') : [];
            return day && month && year && isValidDate(day, month, year);
        })
        .test('is-year-in-range', 'Please enter a valid date', value => {
            if (!value) return true;
            const [year] = value ? value.split('-') : [];
            return year && (parseInt(year, 10) >= 1900);
        })
        .test('is-future-date', 'Maturity date must be within 1000 years from now', value => {
            // Skip validation if the value is null or empty
            if (!value) return true;
            const [year, month, day] = value ? value.split('-') : [];
            const inputDate = new Date(`${year}-${month}-${day}`);
            return inputDate <= futureLimitDate;
        }),


    // Individual field validation schemas for address
    houseNoValidation: Yup.string()
        .matches(regex.addressInputRegex, "Only alphabets, numbers, comma(,), dash(-) are allowed"),
    houseNameValidation: Yup.string().
        required('Required field')
        .matches(regex.addressInputRegex, "Only alphabets, numbers, comma(,), dash(-) are allowed"),
    areaValidation: Yup.string().
        required('Required field')
        .matches(regex.addressInputRegex, "Only alphabets, numbers, comma(,), dash(-) are allowed"),
    countryValidation: Yup.string().
        required('Required field')
        .matches(regex.addressInputRegex, "Only alphabets, numbers, comma(,), dash(-) are allowed"),
    stateValidation: Yup.string().
        required('Required field')
        .matches(regex.addressInputRegex, "Only alphabets, numbers, comma(,), dash(-) are allowed"),
    cityValidation: Yup.string().
        required('Required field')
        .matches(regex.addressInputRegex, "Only alphabets, numbers, comma(,), dash(-) are allowed"),
    zipValidation: Yup.string().
        required('Required field')
        .matches(regex.zipRegex, "Please enter a valid PIN code"),

    same_as_currentValidation: Yup.boolean()
        .required('Required field'),

    // Existing Will Upload Validation
    registrarPlaceValidation: Yup.string().
        required('Required field')
        .matches(regex.addressInputRegex, "Only alphabets, numbers, comma(,), dash(-) are allowed"),

    fileValidation: Yup.mixed()
        .required('A file is required')
        .test('fileSize', 'File Size is too large', value => value && value.size <= 10485760) // 10MB
        .test('fileType', 'Unsupported File Format', value => value && ['application/pdf'].includes(value.type)),

    // Integer validation
    numberValidation: Yup.number()
        .nullable()
        .typeError('Must be a number') // Error message for non-numeric input
        .moreThan(0, 'Must be greater than 0'),

    folioNumberValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Folio Number'),// Ensure only alphanumeric characters are present

    bankAccountNoValidation: Yup.string()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Account Number'),// Ensure only alphanumeric characters are present

    fdNumberValidation: Yup.string()
        .required('Required field')
        .matches(regex.alphaNumericRegex, 'Please enter a valid Account Number/FD Number'),// Ensure only alphanumeric characters are present
    dpIdValidation: Yup.string()
        .required('Required field')
        .matches(regex.alphaNumericRegex, 'Please enter a valid DP ID'),// Ensure only alphanumeric characters are present

    domainValidation: Yup.string()
        .matches(regex.domainRegex, "Please enter a valid Domain Name"),

    copyrightRegistrationNoValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Copyright Registration Number/Application Number'),// Ensure only alphanumeric characters are present

    trademarkRegistrationNoValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Trademark Registration Number/Application Number'),// Ensure only alphanumeric characters are present

    patentRegistrationNoValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Patent Registration Number/ Application Number'),// Ensure only alphanumeric characters are present

    policyNoValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Policy Number'),// Ensure only alphanumeric characters are present

    insuranceAccountNoValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Insurance Account Number'),// Ensure only alphanumeric characters are present

    prnNoValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid PRN Number'),// Ensure only alphanumeric characters are present

    uanNoValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid UAN Number'),// Ensure only alphanumeric characters are present

    certificateNoValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Certificate Number'),// Ensure only alphanumeric characters are present

    shareCertificateNoValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Share Certificate Number'),// Ensure only alphanumeric characters are present

    uinNoValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Unique Identification Number'),// Ensure only alphanumeric characters are present

    accountNoValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Account Number or Client ID'),// Ensure only alphanumeric characters are present

    clientIdValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Client ID'),// Ensure only alphanumeric characters are present

    bankLockerValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Bank Locker Number'),// 


    registerNumValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Registration Number'),// Ensure only alphanumeric characters are present

    ifscCodeValidation: Yup.string()
        .matches(regex.ifscCodeRegex, 'Please enter a valid IFSC Code'),// Ensure only alphanumeric characters are present

    gstNoValidation: Yup.string()
        .matches(regex.gstinRegex, 'Please enter a valid GST Number'),// Ensure only alphanumeric characters are present

};

export default formValidation;
