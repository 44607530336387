import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputField from '../InputField';

/**
 * @module Components/DesignSystem/CustomDatePicker
 * @description This module contains the CustomDatePicker component used across the application.
 */
/**
 * CustomDatePicker is a wrapper around the MUI `DatePicker` component with custom input rendering and additional props.
 * This component allows setting various props for the date picker, including error handling, custom input formatting, and the ability to pass additional customization to the input field and the date picker popper.
 *
 * @component
 * @param {Date} value - The selected date value of the date picker.
 * @param {string} name - The name attribute for the date picker input.
 * @param {boolean} error - Flag to indicate whether the date picker has an error.
 * @param {boolean} fullWidth - Flag to indicate if the input field should take the full width of its container.
 * @param {string} inputFormat - The format of the date displayed in the input field (e.g., 'MM/dd/yyyy').
 * @param {Date} maxDate - The maximum selectable date.
 * @param {string} maxDateMessage - Error message to display when the selected date exceeds `maxDate`.
 * @param {function} onChange - Callback function to handle the change in the selected date.
 * @param {function} onBlur - Callback function to handle the blur event of the date picker.
 * @param {string} placeholder - Placeholder text to display in the input field.
 * @param {boolean} fieldError - Custom flag to indicate if the input field has an error.
 * @param {string} fieldHelperText - Custom helper text to display below the input field.
 * @param {object} fieldInputProps - Additional input props for customization of the input field.
 * @param {object} props - Any additional props to pass to the `DatePicker` component.
 *
 * @returns {JSX.Element} A customized `DatePicker` component with a custom input field, popper styling, and additional props.
 */
const CustomDatePicker = ({
    value,
    name,
    error,
    fullWidth,
    inputFormat,
    maxDate,
    maxDateMessage,
    onChange,
    onBlur,
    placeholder,
    fieldError = false,
    fieldHelperText = '',
    fieldInputProps = {},
    ...props
}) => {
    return (
        <DatePicker
            className="date-picker-container"
            value={value}
            name={name}
            error={error}
            fullWidth={fullWidth}
            inputFormat={inputFormat}
            maxDate={maxDate}
            maxDateMessage={maxDateMessage}
            onChange={onChange}
            onBlur={onBlur}
            renderInput={(params) => (
                <InputField
                    fullWidth
                    {...params}
                    placeholder={placeholder}
                    InputLabelProps={{ shrink: false }}
                    inputProps={{
                        ...params.inputProps,
                        placeholder,
                        ...fieldInputProps
                    }}
                    error={fieldError}
                    helperText={fieldHelperText}
                />
            )}
            PopperProps={{
                sx: {
                    '& .MuiPaper-root': {
                        fontFamily: 'DM Sans, sans-serif !important',
                    },
                    '&.MuiPickersCalendarHeader-root': {
                        fontFamily: 'DM Sans, sans-serif',
                        background: 'red',
                    },
                    '& .MuiPickersDay-root': {
                        fontFamily: 'DM Sans, sans-serif',
                    },
                    '& .MuiTypography-caption': {
                        fontFamily: 'DM Sans, sans-serif',
                    },
                    '& .MuiPickersYear-yearButton': {
                        fontSize: '16px',
                    },
                    '& .MuiPickersCalendarHeader-label': {
                        fontFamily: 'DM Sans, sans-serif',
                    }
                },
            }}
            {...props}
        />
    );
};

export default CustomDatePicker;
