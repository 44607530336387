/**
 * @module Components/FeatureComponent/StatsCard
 *
 * @description A reusable card component for displaying statistical data with an optional loading state.
 * The component renders a title and a value, and includes a skeleton loader when data is being fetched.
 */

import { Box, Card, CardContent, Skeleton, Typography } from '@mui/material';

/**
 * StatsCard Component
 *
 * A card component used for displaying a statistic value with a title.
 * If the `loading` prop is `true`, a skeleton loader is displayed in place of the value.
 *
 * @component
 * @param {Object} props - Props passed to the component.
 * @param {string} props.title - The title of the stat, displayed below the value.
 * @param {string|number} props.value - The numeric or string value of the stat.
 * @param {boolean} props.loading - Determines if the component should display a loading skeleton.
 * @returns {JSX.Element} The rendered StatsCard component.
 *
 * @example
 * // Example usage of StatsCard
 * return (
 *   <StatsCard
 *     title="Total Users"
 *     value={1200}
 *     loading={false}
 *   />
 * );
 */
const StatsCard = ({ title, value, loading }) => {
    return (
        <Box
            sx={{
                // width: '470px',
                height: '130px',
                borderRadius: '20px',
                display: 'flex', // Flexbox for centering content
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Card
                sx={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    boxShadow: 0,
                }}
            >
                <CardContent>
                    <Box sx={{ textAlign: 'start' }}>
                        {loading ? (
                            // Skeleton Placeholder for value
                            <Skeleton variant="text" width="100%" height="100%" sx={{ marginBottom: 1 }} />
                        ) : (
                            <Typography
                                variant="h5"
                                sx={{
                                    fontFamily: 'DM Sans, sans-serif',
                                    fontSize: '3.438rem',
                                    fontWeight: 400,
                                    color: '#1C1C1B',
                                }}
                            >
                                {value}
                            </Typography>
                        )}
                        <Typography variant="bodyText4">{title}</Typography>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default StatsCard;
