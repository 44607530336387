import { Box, Typography } from "@mui/material";
import { TextedButton1 } from "components/DesignSystem/Button";
import { useNavigate } from "react-router-dom";

/**
 * @module Components/SkipToWillEditorButton
 * @description This module contains the SkipToWillEditorButton component used across the application.
 */
/**
 * A button component that navigates the user to the Will Editor page.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} [props.sx={}] - Optional custom styles to apply to the outer container using MUI's `sx` prop.
 * @returns {JSX.Element} A styled button that redirects to the "Will Editor" page when clicked.
 */
const SkipToWillEditorButton = ({ sx = {} }) => {
    const navigate = useNavigate();

    /**
     * Handles the button click to navigate to the "my people" page.
     */
    const handleSkipToWillEditor = () => {
        navigate('/myPeople');
    };

    return (
        <Box
            sx={{
                ...sx, // Spread the passed custom styles
            }}
        >
            <TextedButton1 onClick={handleSkipToWillEditor} sx={{ padding: '10px 20px' }}>
                <Typography variant="bodyText3">Skip to Will Editor</Typography>
            </TextedButton1>
        </Box>
    );
};

export default SkipToWillEditorButton;
